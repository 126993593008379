import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { isMobile } from "react-device-detect";

import Bottom from "./CommonComponents/Bottom";
import { Loading } from "./CommonComponents/Loading";
import Top from "./CommonComponents/Top";
import { fetchPatternById } from "./store/patternSlice";
import { RootState } from "./store/store";
import styles from "./Writer.module.css";
import SlateWriter from "./Writer/SlateWriter";
import Intro from "./Intro";
import MobileMessage from "./MobileMessage";
import mixpanel from "mixpanel-browser";

export default function Writer() {
  // URL parameter
  const { pathname } = useLocation();
  const [, , patternId] = pathname.split("/");

  // State
  const { pattern } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  const loadDataCallback = useCallback(
    () =>
      patternId && pattern.id.toString() !== patternId
        ? dispatch(fetchPatternById(patternId))
        : null,
    [dispatch, patternId, pattern.id]
  );

  useEffect(() => {
    loadDataCallback();
  }, [loadDataCallback]);

  useEffect(() => {
    mixpanel.track("writer:view");
  }, []);

  if (!patternId) {
    return null;
  }

  if (isMobile) {
    return <MobileMessage />;
  }

  return (
    <>
      <Intro tutorialName={"write"} />
      <Top />
      {pattern.id && pattern.id !== -1 ? (
        <div id="intro-write" className={styles.writer}>
          <SlateWriter
            patternElements={pattern.patternElements}
            patternId={patternId}
          />
        </div>
      ) : (
        <Loading />
      )}
      {pattern.id && pattern.id !== -1 && <Bottom pattern={pattern} />}
    </>
  );
}
