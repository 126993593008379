import React from "react";

import styles from "./TableHead.module.css";
import { useTranslation } from "react-i18next";

type PropType = {
  sizes: string[];
};

function TableHead(props: PropType) {
  const { sizes } = props;

  const { t } = useTranslation();

  return (
    <div className={`${styles.head}`}>
      <div className={styles.name}>{t("name")}</div>
      <div className={`${styles.label}`}>{t("calculations.explanation")}</div>
      <div className={styles.sizes}>
        {sizes.map((size) => (
          <div key={size} className={styles.cell}>
            {size}
          </div>
        ))}
      </div>
      <div className={`${styles.cell} ${styles.unit}`}>Enhet</div>
    </div>
  );
}

export default TableHead;
