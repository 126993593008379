import { Vector2 } from "three";
import { useEffect } from "react";
import { Util } from "../static/util";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";

let mousePos: Vector2;
let lastDiff: Vector2 = new Vector2(0, 0);
let storeHandleSelectDrag: any[] = [];
let storeHandleGapDrag: any[] = [];
function SelectBox(props: any) {
  useEffect(() => {
    props.moveRef.current = move;
    props.deleteRef.current = doDelete;
  }, [props.pointerPattern]);

  const setMousePos = (e: any) => {
    mousePos = new Vector2(e.screenX, e.screenY);
  };

  const { t } = useTranslation();

  useEffect(() => {
    // Only run the latest stored handledrag. Else
    // it queues up multiple moves which takes
    // time to compute/render
    const interval = setInterval(() => {
      if (storeHandleSelectDrag.length > 0) {
        handleDrag(storeHandleSelectDrag[storeHandleSelectDrag.length - 1]);
        storeHandleSelectDrag = [];
      }
    }, 20);
    return () => clearInterval(interval);
  }, [props.pointerPattern]);

  useEffect(() => {
    // Only run the latest stored handledrag. Else
    // it queues up multiple moves which takes
    // time to compute/render
    const interval = setInterval(() => {
      if (storeHandleGapDrag.length > 0) {
        props.setAdjustGapPos(
          storeHandleGapDrag[storeHandleGapDrag.length - 1]
        );
        storeHandleGapDrag = [];
      }
    }, 20);
    return () => clearInterval(interval);
  }, [props.pointerPattern]);

  const handleDrag = (e: React.DragEvent) => {
    const { x, y } = mousePos;
    const offset = props.gridSizeY; // + props.gridSize * 2
    const yDiff = Math.trunc((e.screenY - y) / offset);
    const xDiff = Math.trunc((e.screenX - x) / offset);
    const newDiff = new Vector2(xDiff, yDiff);
    if (xDiff === 0 && yDiff === 0) return;
    if (newDiff.equals(lastDiff)) return;
    lastDiff = newDiff;
    //const isWarning = props.sweaterPart.getOverlap(props.pointerPattern);
    let moved = move(newDiff);
    if (!moved) {
      return;
    }
    /*if (isWarning) {
      props.updateSweater(props.patterns);
    }*/
    lastDiff = new Vector2(0, 0);
    mousePos = mousePos.add(newDiff.multiplyScalar(offset));
  };
  const move = (posDiff: Vector2) => {
    // Thought I had to move it out, to make it available elsewhere.
    // It wasn't necessary, but it doesnt hurt, so I'll let it stay.
    return Util.move(
      posDiff,
      props.sweaterPart,
      props.pointerPattern,
      props.gridHTML,
      props.render,
      props.setOccupiedPos,
      props.grid,
      t
    );
  };

  const doDelete = () => {
    const isWarning = props.sweaterPart.getOverlap(props.pointerPattern);
    props.sweaterPart.deleteGroup(props.pointerPattern, props.gridHTML);
    props.setPointerPattern(undefined);
    if (isWarning) {
      props.updateSweater(props.patterns);
    }
    props.render();
    //Undo is updated at grid: useEffect(() => {}, [props.pointerPattern])
  };

  return (
    <div
      id="SelectBox"
      style={{
        pointerEvents: "none",
      }}
    >
      <div
        style={{
          position: "absolute",
          display: "flex",
          border: props.isPreviewSelect
            ? "2px solid var(--neutral-30)"
            : "2px solid rgb(222, 89, 0)",
          boxSizing: "border-box",
          width: props.width + "px",
          height: props.height + "px",
        }}
      >
        {!props.isPreviewSelect && (
          <>
            {[
              {
                top: "-15px",
                left: props.width / 2 + 0.5 + "px",
                dir: "↑",
                posDiff: new Vector2(0, -1),
              },
              {
                top: props.height / 2 + 0.5 + "px",
                left: "-15px",
                dir: "←",
                posDiff: new Vector2(-1, 0),
              },
              {
                top: props.height + 15 + "px",
                left: props.width / 2 + 0.5 + "px",
                dir: "↓",
                posDiff: new Vector2(0, 1),
              },
              {
                top: props.height / 2 + 0.5 + "px",
                left: props.width + 15 + "px",
                dir: "→",
                posDiff: new Vector2(1, 0),
              },
            ].map((it) => (
              <div
                className="addButton"
                style={{
                  top: it.top,
                  left: it.left,
                  cursor: "pointer",
                  backgroundColor: "var(--whiteish)",
                  pointerEvents: "auto",
                }}
                key={it.dir}
                onClick={() => move(it.posDiff)}
              >
                <p
                  style={{
                    paddingLeft: "0.5px",
                    color: "var(--positive)",
                    fontSize: "10px",
                    margin: "auto",
                    display: "flex",
                  }}
                >
                  {it.dir}
                </p>
              </div>
            ))}
            <Tooltip title={t("model.drag to use")}>
              <div
                className="addButton"
                style={{
                  top: "-20px",
                  left: props.width - 44.5 + "px",
                  cursor: "pointer",
                  backgroundColor: "var(--whiteish)",
                  pointerEvents: "auto",
                  borderColor: "black",
                  width: "24px",
                  height: "24px",
                }}
                onClick={() => {}}
                onDragStart={(e: any) => {
                  props.adjustGap(e, props.pointerPattern);
                }}
                onDrag={(e: any) => {
                  if (props.adjustGapPos.x === e.clientX) return;
                  if (e.clientX <= 0) {
                    props.setShowAdjustGap(false);
                    return;
                  }
                  storeHandleGapDrag.push(new Vector2(e.clientX, e.clientY));
                }}
                onDragOver={(e: any) => {
                  // Doesnt work to prevent delay in this scenario
                  e.preventDefault();
                }}
                onDragEnd={(e: any) => {
                  props.setShowAdjustGap(false);
                }}
              >
                <img
                  src="/3D/ui/width.png"
                  style={{
                    margin: "auto",
                    width: "20px",
                    height: "20px",
                  }}
                  alt="delete"
                ></img>
              </div>
            </Tooltip>
            <div
              className="addButton"
              style={{
                top: "-20px",
                left: props.width - 14.5 + "px",
                cursor: "pointer",
                backgroundColor: "var(--whiteish)",
                pointerEvents: "auto",
                borderColor: "red",
                width: "24px",
                height: "24px",
              }}
              onClick={() => {
                doDelete();
              }}
            >
              <img
                src="/3D/ui/delete2.png"
                style={{
                  margin: "auto",
                  width: "20px",
                  height: "20px",
                }}
                alt="delete"
              ></img>
            </div>
          </>
        )}
      </div>
      <div
        style={{
          position: "absolute",
          width: props.width + "px",
          height: props.height + "px",
          pointerEvents: props.isPreviewSelect ? "none" : "auto",
          cursor: "move",
          opacity: 0,
        }}
        draggable
        onMouseDown={(e: any) => {
          setMousePos(e);
        }}
        onDrag={(e: any) => {
          if (e.buttons !== 0) storeHandleSelectDrag.push(e);
        }}
        onDragOver={(e: any) => {
          e.preventDefault();
        }}
        onDragEnd={() => {
          props.updateUndo();
        }}
      ></div>
    </div>
  );
}

export default SelectBox;
