import React from "react";
import styles from "../Styles/Sections.module.css";
import Card from "./Card";
import yarnIcon from "../../Visualizing/LandingPage/yarnIcon.svg";
import clockIcon from "../../Visualizing/LandingPage/clockIcon.svg";
import calculatorIcon from "../../Visualizing/LandingPage/calculatorIcon.svg";
import webIcon from "../../Visualizing/LandingPage/webIcon.svg";
import { useTranslation } from "react-i18next";

function CardSection() {
  const { t } = useTranslation();

  const card1Title: string = t("landing.direct integration");
  const card1Description: string = t("landing.get access and quality control");

  const card2Title: string = t("landing.time-saving visualizations");
  const card2Description: string = t("landing.save time and try knit sample");

  const card3Title: string = t("landing.advanced support for calculations");
  const card3Description: string = t("landing.put away the calculator");

  const card4Title: string = t("landing.collaborate with your partners");
  const card4Description: string = t(
    "landing.get control over model knitting, proofreading and yarn selection"
  );

  const description: string = t("landing.contact us coffee");

  return (
    <div className={styles.cardSection}>
      <div className={styles.headerDescription}>{description}</div>

      <div className={styles.cardDescriptionContainer}>
        <div style={{ display: "flex" }}>
          <Card
            imgIcon={yarnIcon}
            title={card1Title}
            description={card1Description}
          />
          <Card
            imgIcon={clockIcon}
            title={card2Title}
            description={card2Description}
          />
        </div>
        <div style={{ display: "flex" }}>
          <Card
            imgIcon={calculatorIcon}
            title={card3Title}
            description={card3Description}
          />

          <Card
            imgIcon={webIcon}
            title={card4Title}
            description={card4Description}
          />
        </div>
      </div>
    </div>
  );
}

export default CardSection;
