import { useDispatch, useSelector } from "react-redux";
import { setActiveColorIndex, setColorVariant } from "../store/colorSlice";
import { RootState } from "../store/store";

import ColorType from "./ColorType";
import styles from "./diagram.module.css";

import { addVariantColor, setVariantColor } from "../store/patternSlice";
import { Color } from "../store/pattern";
import { useModal } from "../CommonComponents/useModal";
import YarnColorPicker from "../CommonComponents/YarnColorPicker";
import { PlusIcon } from "@radix-ui/react-icons";
import { setActiveVariant } from "../ISO/displayState";
import { hasAccess } from "../utils/hasAccess";
import { useMeQuery } from "../store/woolitApi";
import mixpanel from "mixpanel-browser";

const SimpleColorList = () => {
  const dispatch = useDispatch();
  const { data: user } = useMeQuery();

  const { variants } = useSelector((state: RootState) => state.pattern.meta);

  const { activeVariantId, variant } = useSelector((state: RootState) => ({
    activeVariantId: state.displayState.activeVariant,
    variant: state.pattern.meta.variants.filter(
      (variant) => variant.id === state.displayState.activeVariant
    )[0],
  }));

  const { activeColorIndex } = useSelector((state: RootState) => ({
    activeColorIndex: state.color.activeIndex,
  }));

  const activeVariantIndex =
    variants.length > 0
      ? variants.findIndex((v) => v.id === activeVariantId)
      : 0;

  const { isShown: showEditColorModal, toggle: toggleEditColorModal } =
    useModal();

  const onColorSelectorComplete = (colorIndex: number, color: Color) => {
    dispatch(
      setVariantColor({
        variantId: activeVariantId,
        colorIndex,
        color,
      })
    );
  };

  // User has access to the full diagram, so then we don't show this
  // to avoid double color lists
  if (hasAccess(user, "full_diagram")) {
    return null;
  }

  return (
    <div className={`${styles.toolContainer} ${styles.overflowTool}`}>
      {variant &&
        variant.colors.length > 0 &&
        variant.colors[activeColorIndex] && (
          <YarnColorPicker
            index={activeColorIndex}
            isShown={showEditColorModal}
            toggle={toggleEditColorModal}
            color={variant.colors[activeColorIndex]}
            onChangeComplete={(col: Color) => {
              onColorSelectorComplete(activeColorIndex, col);
            }}
            yarn={undefined}
            canDelete={true}
            variantId={variant.id}
          />
        )}
      {variants.map((variant, variantIndex) => (
        <div
          key={variantIndex}
          className={styles.hoverRow}
          style={{
            display: "flex",
            width: "100%",
          }}
          onClick={() => {
            dispatch(setActiveVariant(variant.id));
            dispatch(setColorVariant({ variant: variants[variantIndex] }));
          }}
        >
          <div style={{ display: "flex" }}>
            {variant.colors.length === 0 && (
              <p style={{ padding: "5px" }}>
                Trykk på + for å legge til farger
              </p>
            )}
            {variant.colors.map((color, colorIndex) => (
              <div
                key={colorIndex}
                onClick={() => {
                  if (
                    activeVariantIndex !== variantIndex ||
                    activeColorIndex !== colorIndex
                  ) {
                    dispatch(setActiveColorIndex(colorIndex));
                  } else {
                    toggleEditColorModal();
                  }
                }}
              >
                <ColorType
                  variantIndex={activeVariantIndex}
                  row={variantIndex}
                  index={colorIndex}
                  color={color}
                />
              </div>
            ))}
          </div>
          {variantIndex === 0 && (
            <button
              style={{ border: "none" }}
              onClick={() => {
                mixpanel.track("add_color");
                dispatch(addVariantColor({ variantId: activeVariantId }));
              }}
            >
              <PlusIcon strokeWidth="4" aria-label="Ny farge" />
            </button>
          )}
        </div>
      ))}
    </div>
  );
};

export default SimpleColorList;
