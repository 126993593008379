import { DynamicSymbols, Symbols } from "@iterate/woolit-components";
import { useDispatch, useSelector } from "react-redux";
import { isDynamic } from "../CommonComponents/Icons/lookup";
import {
  setContextMenuCoords,
  setGridCursor,
  updateCell,
} from "../store/gridSlice";
import { RootState } from "../store/store";
import { useDarkColor } from "../utils/colorContrast";
import { ClickMenu } from "./ContextMenu/Click";
import { SymbolSelector } from "./SymbolSelector";

interface PropType {
  color: any;
  x: number;
  y: number;
  cellSize: { cellWidth: number; cellHeight: number };
  symbol: Symbols | DynamicSymbols;
  onMouseOver: Function;
  onMouseOut: Function;
  cell: number;
  row: number;
}

const Square = (props: PropType) => {
  const dispatch = useDispatch();

  const { color, activeColorIndex, activeSymbol } = useSelector(
    (state: RootState) => ({
      color: state.color.colors[props.color],
      activeColorIndex: state.color.activeIndex,
      activeSymbol: state.color.activeSymbol,
    })
  );

  const [row, cell] = useSelector((state: RootState) => state.grid.squareMenu);
  const { cursorMode } = useSelector((state: RootState) => state.grid);
  const symbolColor = useDarkColor(color?.hex) ? "black" : "white";

  function onClick() {
    if (cursorMode === "color") {
      dispatch(setGridCursor({ x: props.cell, y: props.row }));
      dispatch(
        updateCell({
          row: props.row,
          col: props.cell,
          cellData: { color: activeColorIndex, symbol: activeSymbol },
        })
      );
    } else if (cursorMode === "erase") {
      dispatch(setGridCursor({ x: props.cell, y: props.row }));
      dispatch(
        updateCell({
          row: props.row,
          col: props.cell,
          cellData: { color: -1, symbol: "stitch" },
        })
      );
    }
  }

  return (
    <>
      {row === props.row && cell === props.cell && (
        <ClickMenu
          {...props}
          handleClose={() =>
            dispatch(setContextMenuCoords({ cell: -1, row: -1 }))
          }
        />
      )}
      <div
        title={
          props.color === -1
            ? `Ingen farge`
            : `Farge: ${props.color + 1} ${color ? color.name : ""}`
        }
        key={`${props.row}_${props.cell}`}
        id={`${props.row}_${props.cell}`}
        style={{
          backgroundColor: color ? color.hex : "#ffffff",
          width: `${props.cellSize.cellWidth}px`,
          height: `${props.cellSize.cellHeight}px`,
          position: "absolute",
          left: props.x,
          top: props.y,
          border: `1px ${color ? "#000000" : "#faf6f2"} solid`,
          zIndex: props.color === -1 ? 0 : 1,
        }}
        //onContextMenu = rightClick
        onContextMenu={(e) => {
          e.preventDefault();
          dispatch(setGridCursor({ x: props.cell, y: props.row }));
          dispatch(setContextMenuCoords({ row: props.row, cell: props.cell }));
        }}
        onMouseOver={(e: any) => {
          props.onMouseOver();
          let flags = e.buttons !== undefined ? e.buttons : e.which;
          let primaryMouseButtonDown = (flags & 1) === 1;
          if (cursorMode !== "cursor" && primaryMouseButtonDown) {
            onClick();
          }
        }}
        onMouseOut={() => props.onMouseOut()}
        onMouseDown={() => {
          onClick();
        }}
      >
        {props.symbol !== "stitch" && !isDynamic(props.symbol) && (
          <SymbolSelector fill={symbolColor} symbol={props.symbol} />
        )}
      </div>
    </>
  );
};

export default Square;
