import { SockPart } from "../SockPart";
import { SockPartArea } from "../enums";
import { Util } from "../static/util";

export class Sock {
  sock = {
    leg: [1878, 371, 2217, 616],
    topFoot: [1941, 1370, 2154, 1668],
    heel: [1962, 2492, 2133, 2629],
    bottomFoot: [1933, 3419, 2162, 3712],
  } as const;

  parts() {
    let leg = new SockPart(
      "Leg",
      SockPartArea.Leg,
      Util.makeGrid(512),
      ...this.sock["leg"],
      -1
    );
    let topFoot = new SockPart(
      "TopFoot",
      SockPartArea.TopFoot,
      Util.makeGrid(512),
      ...this.sock["topFoot"],
      1024
    );
    let heel = new SockPart(
      "Heel",
      SockPartArea.Heel,
      Util.makeGrid(512),
      ...this.sock["heel"],
      -1
    );
    let bottomFoot = new SockPart(
      "BottomFoot",
      SockPartArea.BottomFoot,
      Util.makeGrid(512),
      ...this.sock["bottomFoot"],
      3072
    );

    const sockParts = [leg, topFoot, heel, bottomFoot];
    sockParts.forEach((v: SockPart, i: number) => (v.index = i));
    return sockParts;
  }
}
