import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Provider } from "react-redux";
import mixpanel from "mixpanel-browser";
import store from "./store/store";
import "./i18n/i18n";
import ISO from "./ISO";
import Writer from "./Writer";
import Calculations from "./Calculations";
import { Projects } from "./Projects/Projects";
import PrivateRoute from "./Login/PrivateRoute";
import AdminRoute from "./Login/components/AdminRoute";
import Admin from "./Admin/Admin";
import DiagramWrapper from "./Diagram/DiagramWrapper";
import { MyOrganizations } from "./Organization/MyOrganizations";
import { Organization } from "./Organization/Organization";
import { t } from "i18next";

import SalesOverview from "./SalesOverview/SalesOverview";
import { Route, Routes } from "react-router";
import Login from "./Login/components/Login";
import CheckoutError from "./Checkout/CheckoutError";
import CheckoutSuccess from "./Checkout/CheckoutSuccess";
import WriterRoute from "./Login/WriterRoute";
import CalculationRoute from "./Login/CalculationRoute";
import "intro.js/introjs.css";
import DiagramLandingPage from "./DiagramLandingPage/DiagramLandingPage";

if (window.location.hostname.endsWith("design.woolit.no")) {
  Sentry.init({
    dsn: "https://d7e073a5bdf74944af00aa40a07edd7b@o246345.ingest.sentry.io/5975111",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.1,
  });
}

if (window.location.hostname.endsWith("testdesign.woolit.no")) {
  mixpanel.init("31c79147270e4053b4a2d84ad9b7129e", {
    persistence: "localStorage",
  });
} else if (window.location.hostname.endsWith("design.woolit.no")) {
  mixpanel.init("d56dcf0d230baf46c4e65a2174e6da72", {
    persistence: "localStorage",
  });
} else {
  mixpanel.init("31c79147270e4053b4a2d84ad9b7129e", {
    persistence: "localStorage",
  });
}

// Presumably safe. Even jumps to here from the console,
// so shortening the msgs shouldn't cause any confusion.
/*function shortenThenIgnoreIfErrorContains(errorMsgCharacteristics: String[]) {
  const consoleErrorOriginal = console.error;
  const SHORTEN_ERROR = errorMsgCharacteristics;
  const IGNORE_ERROR: String[] = [];

  console.error = function filterERROR(msg, ...args) {
    const shorten = SHORTEN_ERROR.find((entry) => msg.includes(entry));
    const ignore = IGNORE_ERROR.find((entry) => msg.includes(entry));
    if (shorten) {
      IGNORE_ERROR.push(shorten);
    }
    if (!ignore) {
      consoleErrorOriginal(msg, ...(shorten ? args.slice(0, -1) : args));
      // If changed to console.warn then it will be a warning instead
    }
  };
}

shortenThenIgnoreIfErrorContains(["%s is deprecated in StrictMode"]);
*/
ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary
      fallback={({ error, componentStack }) => {
        const { stack } = error;
        const head = stack?.split("\n").slice(0, 2);

        return (
          <>
            <div
              role="alert"
              style={{
                margin: "20vh auto",
                maxWidth: "800px",
                height: "60vh",
              }}
            >
              <h2>{t("errors.general")}</h2>
              <p>{t("errors.sentry_message")}</p>
              <pre>{error.message}</pre>
              <pre>---</pre>
              <pre>{head?.join("\n")}</pre>
            </div>
            <div style={{ maxWidth: "800px", margin: "0 auto" }}>
              <pre>{componentStack}</pre>
            </div>
          </>
        );
      }}
    >
      <Provider store={store}>
        <Router>
          <Routes>
            <Route
              path="/pattern/:patternId"
              element={
                <WriterRoute>
                  <ISO />
                </WriterRoute>
              }
            />
            <Route
              path="/projects"
              element={
                <PrivateRoute>
                  <Projects />
                </PrivateRoute>
              }
            />
            <Route
              path="/checkout/sucess?/:checkoutId"
              element={
                <PrivateRoute>
                  <CheckoutSuccess />
                </PrivateRoute>
              }
            />
            <Route
              path="/checkout/cancelled"
              element={
                <PrivateRoute>
                  <CheckoutError />
                </PrivateRoute>
              }
            />
            <Route
              path="/edit/:patternId/write"
              element={
                <WriterRoute>
                  <Writer />
                </WriterRoute>
              }
            />
            <Route
              path="/edit/:patternId/draw"
              element={
                <PrivateRoute>
                  <DiagramWrapper />
                </PrivateRoute>
              }
            />
            <Route
              path="/edit/:patternId/calculate"
              element={
                <CalculationRoute>
                  <Calculations />
                </CalculationRoute>
              }
            />
            <Route
              path="/organization/:orgId"
              element={
                <PrivateRoute>
                  <Organization />
                </PrivateRoute>
              }
            />
            <Route
              path="/organization"
              element={
                <PrivateRoute>
                  <MyOrganizations />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin"
              element={
                <AdminRoute>
                  <Admin />
                </AdminRoute>
              }
            />
            <Route path="/health" element={<h1>Healthy</h1>} />
            <Route path="/login" element={<Login />} />
            <Route path="/landing" element={<App />} />
            <Route path="/diagram" element={<DiagramLandingPage />} />
            <Route
              path="/salesOverview"
              element={
                <PrivateRoute>
                  <SalesOverview />
                </PrivateRoute>
              }
            />
            <Route
              path="/salesOverview/:orgId"
              element={
                <PrivateRoute>
                  <SalesOverview />
                </PrivateRoute>
              }
            />

            <Route
              path="/"
              element={
                <PrivateRoute>
                  <Projects />
                </PrivateRoute>
              }
            />
          </Routes>
        </Router>
      </Provider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);
