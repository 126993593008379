import {
  createAsyncThunk,
  createSlice,
  isRejectedWithValue,
  PayloadAction,
} from "@reduxjs/toolkit";
import { backendApiAddress } from "../backendApi";
import { shortUUID } from "../utils/uuid";

import { RootState } from "./store";

import { DynamicSymbols, Symbols } from "@iterate/woolit-components";
import {
  ClothingMetaData,
  Color,
  Comment,
  DiagramData,
  DynamicGridSymbol,
  GridSymbol,
  initialState,
  KnitAlong,
  Needle,
  Pattern,
  PatternElement,
  SizeGroup,
  SIZES,
  YarnProfileVariant,
} from "./pattern";
import { removeColorFromAmounts } from "../Metadata/variantAmounts";
import { notInPattern } from "../utils/canDelete";
import { woolitApi } from "./woolitApi";

export type SymbolType = "single" | "dynamic" | "both";

async function updateVariant(
  updatedVariant: YarnProfileVariant,
  thunkAPI: any
) {
  const {
    meta: { variants },
    id: patternId,
  } = (thunkAPI.getState() as RootState).pattern;

  const variantIndex = variants.findIndex(
    (variant) => variant.id === updatedVariant.id
  );

  const updatedVariants = Object.assign([...variants], {
    [variantIndex]: updatedVariant,
  });

  thunkAPI.dispatch(setVariants(updatedVariants));

  // New endpoint in the future:
  // /api/pattern/${patternId}/variant/${updatedVariant.id}
  const response = await fetch(
    `${backendApiAddress}/api/pattern/${patternId}/variant`,
    {
      method: "PUT",
      body: JSON.stringify(updatedVariants),
      credentials: "include",
    }
  );

  if (response.ok) {
    const jsonRes = (await response.json()) as YarnProfileVariant[];
    thunkAPI.dispatch(setVariants(jsonRes));
  }
}

async function updateVariants(
  updatedVariants: YarnProfileVariant[],
  thunkAPI: any
) {
  const { id: patternId } = (thunkAPI.getState() as RootState).pattern;

  thunkAPI.dispatch(setVariants(updatedVariants));

  // New endpoint in the future:
  // /api/pattern/${patternId}/variant/${updatedVariant.id}
  const response = await fetch(
    `${backendApiAddress}/api/pattern/${patternId}/variant`,
    {
      method: "PUT",
      body: JSON.stringify(updatedVariants),
      credentials: "include",
    }
  );

  if (response.ok) {
    const jsonRes = (await response.json()) as YarnProfileVariant[];
    thunkAPI.dispatch(setVariants(jsonRes));
  }
}

const fetchPatternById = createAsyncThunk(
  "pattern/fetchById",
  async (patternId: string, _thunkAPI) => {
    const response = await fetch(
      `${backendApiAddress}/api/pattern/${patternId}`,
      {
        credentials: "include",
      }
    );
    const jsonRes = (await response.json()) as Pattern;

    // Change kvinne to dame
    if (jsonRes.meta.sizeCategory === "Kvinne") {
      jsonRes.meta.sizeCategory = "Dame";
    }

    // Populate needleOrder with any missing needles
    Object.keys(jsonRes.needles).forEach((id) => {
      if (
        jsonRes.meta.needleOrder &&
        jsonRes.meta.needleOrder.indexOf(id) === -1
      ) {
        jsonRes.meta.needleOrder.push(id);
      }
    });

    return jsonRes;
  }
);

const setMetadata = createAsyncThunk(
  "pattern/setMetadata",
  async (
    data: {
      title?: string;
      design?: string;
      designer?: string;
      collection?: string;
      description?: string;
    },
    thunkAPI
  ) => {
    const patternId = (thunkAPI.getState() as RootState).pattern.id;

    thunkAPI.dispatch(updateMeta(data));

    const response = await fetch(
      `${backendApiAddress}/api/pattern/${patternId}/meta`,
      {
        method: "PUT",
        body: JSON.stringify(data),
        credentials: "include",
      }
    );

    if (response.ok) {
      const jsonRes = await response.json();
      thunkAPI.dispatch(updateMeta(jsonRes));
    }
  }
);

const setMetadataVariants = createAsyncThunk(
  "pattern/setMetadataVariants",
  async (data: { variants: YarnProfileVariant[] }, thunkAPI) => {
    const { variants } = data;

    thunkAPI.dispatch(setVariants(variants));

    const patternId = (thunkAPI.getState() as RootState).pattern.id;

    const response = await fetch(
      `${backendApiAddress}/api/pattern/${patternId}/variant`,
      {
        method: "PUT",
        body: JSON.stringify(variants),
        credentials: "include",
      }
    );

    if (response.ok) {
      const jsonRes = (await response.json()) as YarnProfileVariant[];
      thunkAPI.dispatch(setVariants(jsonRes));
    }
  }
);

const addMetadataVariant = createAsyncThunk(
  "pattern/addMetadataVariant",
  async (_, thunkAPI) => {
    const {
      meta: { variants },
      id: patternId,
    } = (thunkAPI.getState() as RootState).pattern;

    const variantId = shortUUID();
    const basis = variants[variants.length - 1];
    const name = `Variant ${variants.length + 1}`;
    let newVariant: YarnProfileVariant;

    if (basis !== undefined) {
      newVariant = { ...basis, name, id: variantId };
    } else {
      newVariant = {
        name,
        id: variantId,
        colors: [],
        images: [],
        amounts: {},
      };
    }

    thunkAPI.dispatch(addVariant(newVariant));

    const response = await fetch(
      `${backendApiAddress}/api/pattern/${patternId}/variant`,
      {
        method: "POST",
        body: JSON.stringify(newVariant),
        credentials: "include",
      }
    );

    if (response.ok) {
      const jsonRes = (await response.json()) as YarnProfileVariant[];
      thunkAPI.dispatch(setVariants(jsonRes));
    }
  }
);

const deleteMetadataVariant = createAsyncThunk(
  "pattern/deleteMetadataVariant",
  async (data: { variantId: string }, thunkAPI) => {
    const { variantId } = data;

    const {
      meta: { variants },
      id: patternId,
    } = (thunkAPI.getState() as RootState).pattern;

    const updatedVariants = variants.filter(
      (variant) => variant.id !== variantId
    );

    thunkAPI.dispatch(setVariants(updatedVariants));

    const response = await fetch(
      `${backendApiAddress}/api/pattern/${patternId}/variant`,
      {
        method: "PUT",
        body: JSON.stringify(updatedVariants),
        credentials: "include",
      }
    );

    if (response.ok) {
      const jsonRes = (await response.json()) as YarnProfileVariant[];
      thunkAPI.dispatch(setVariants(jsonRes));
    }
  }
);

const setVariantName = createAsyncThunk(
  "pattern/setVariantName",
  async (data: { id: string; name: string }, thunkAPI) => {
    const { id: variantId, name } = data;

    const {
      meta: { variants },
    } = (thunkAPI.getState() as RootState).pattern;

    const variant = variants.find((variant) => variant.id === variantId);

    if (!variant) {
      return;
    }

    const updatedVariant = {
      ...variant,
      name,
    };

    await updateVariant(updatedVariant, thunkAPI);
  }
);

const addVariantColor = createAsyncThunk(
  "pattern/addVariantColor",
  async (
    data: {
      variantId: string;
      color?: Color;
    },
    thunkAPI
  ) => {
    const {
      color = {
        sku: null,
        hex: "#e0e0e0",
        name: "",
      },
      variantId,
    } = data;

    const {
      meta: { variants },
    } = (thunkAPI.getState() as RootState).pattern;

    const variant = variants.find((variant) => variant.id === variantId);

    if (!variant) {
      return;
    }

    const updatedVariant = {
      ...variant,
      colors: [...variant.colors, color],
    };

    await updateVariant(updatedVariant, thunkAPI);
  }
);

const setVariantColor = createAsyncThunk(
  "pattern/setVariantColor",
  async (
    data: {
      variantIndex?: number;
      variantId?: string;
      colorIndex: number;
      color: Color;
    },
    thunkAPI
  ) => {
    const { variantIndex, colorIndex, color, variantId } = data;

    const {
      meta: { variants },
    } = (thunkAPI.getState() as RootState).pattern;

    const index =
      variantIndex ?? variants.findIndex((variant) => variant.id === variantId);

    const variant = variants[index];

    const updatedVariant = {
      ...variant,
      colors: Object.assign([...variant.colors], {
        [colorIndex]: color,
      }),
    };

    updateVariant(updatedVariant, thunkAPI);
  }
);

const removeVariantColor = createAsyncThunk(
  "pattern/removeVariantColor",
  async (data: { variantId: string; colorIndex: number }, thunkAPI) => {
    const { colorIndex, variantId } = data;

    const {
      meta: { variants },
    } = (thunkAPI.getState() as RootState).pattern;

    const variant = variants.find((variant) => variant.id === variantId);

    if (!variant) {
      return;
    }

    const updatedVariant = {
      ...variant,
      colors: variant.colors.filter((_, idx) => idx !== colorIndex),
    };

    updateVariant(updatedVariant, thunkAPI);
  }
);

const moveVariantColor = createAsyncThunk(
  "pattern/moveVariantColor",
  async (
    data: { oldIndex: number; newIndex: number; variantIndex: number },
    thunkAPI
  ) => {
    const { oldIndex, newIndex, variantIndex } = data;

    const {
      meta: { variants },
    } = (thunkAPI.getState() as RootState).pattern;

    const variant = variants[variantIndex];
    const variantColor = variant.colors[oldIndex];
    const colors = variant.colors;

    const colorsOldRemoved = [
      ...colors.slice(0, oldIndex),
      ...colors.slice(oldIndex + 1),
    ];

    const colorsNewAdded = [
      ...colorsOldRemoved.slice(0, newIndex),
      variantColor,
      ...colorsOldRemoved.slice(newIndex),
    ];

    const updatedVariant = {
      ...variant,
      colors: colorsNewAdded,
    };

    updateVariant(updatedVariant, thunkAPI);
  }
);

const deleteColor = createAsyncThunk(
  "pattern/deleteColor",
  async (data: { colorIndex: number }, thunkAPI) => {
    const { colorIndex } = data;

    const {
      id: patternId,
      meta: { variants },
      diagrams,
    } = (thunkAPI.getState() as RootState).pattern;

    Object.values(diagrams).forEach((diagram) => {
      const updatedGrid = diagram.grid.map((list) =>
        list.map((cell) => {
          const updatedCell = {
            ...cell,
            color: cell.color > colorIndex ? cell.color - 1 : cell.color,
          };
          return updatedCell;
        })
      );
      thunkAPI.dispatch(
        updateDiagramById({
          patternId,
          diagram: { ...diagram, grid: updatedGrid },
        })
      );
    });

    const updatedVariants = variants.map((variant) => {
      return {
        ...variant,
        amounts: removeColorFromAmounts(variant.amounts, colorIndex),
        colors: variant.colors.filter((_, idx) => idx !== colorIndex),
      };
    });

    updateVariants(updatedVariants, thunkAPI);
  }
);

const addVariantImage = createAsyncThunk(
  "pattern/addVariantImage",
  async (
    data: {
      src: string;
      alt: string;
      variantIndex?: number;
      variantId?: string;
    },
    thunkAPI
  ) => {
    const { src, alt, variantIndex, variantId } = data;

    const {
      meta: { variants },
    } = (thunkAPI.getState() as RootState).pattern;

    const index =
      variantIndex ?? variants.findIndex((variant) => variant.id === variantId);

    const variant = variants[index];

    const updatedVariant = {
      ...variant,
      images: [...variant.images, { src, alt }],
    };

    updateVariant(updatedVariant, thunkAPI);
  }
);

const removeVariantImage = createAsyncThunk(
  "pattern/removeVariantImage",
  async (
    data: { variantIndex?: number; variantId?: string; imageIndex: number },
    thunkAPI
  ) => {
    const { variantIndex, variantId, imageIndex } = data;

    const {
      meta: { variants },
    } = (thunkAPI.getState() as RootState).pattern;

    const index =
      variantIndex ?? variants.findIndex((variant) => variant.id === variantId);

    const variant = variants[index];

    const updatedVariant = {
      ...variant,
      images: variant.images.filter((_, idx) => idx !== imageIndex),
    };

    updateVariant(updatedVariant, thunkAPI);
  }
);

const setVariantsAmount = createAsyncThunk(
  "pattern/setVariantsAmount",
  async (
    data: { size: string; colorIndex: number; amount: number },
    thunkAPI
  ) => {
    const { size, colorIndex, amount } = data;

    const colorIndexString = colorIndex.toString();

    const {
      meta: { variants },
      id: patternId,
    } = (thunkAPI.getState() as RootState).pattern;

    const updatedVariants = variants.map((variant) => {
      const sizes = Object.keys(variant.amounts);

      let updatedAmountsForSize = {};
      if (sizes.includes(size)) {
        const amountsForSize = variant.amounts[size];
        updatedAmountsForSize = {
          ...amountsForSize,
          [colorIndexString]: amount,
        };
      } else {
        updatedAmountsForSize = {
          [colorIndexString]: amount,
        };
      }
      return {
        ...variant,
        amounts: {
          ...variant.amounts,
          [size]: updatedAmountsForSize,
        },
      };
    });

    thunkAPI.dispatch(setVariants(updatedVariants));

    const response = await fetch(
      `${backendApiAddress}/api/pattern/${patternId}/variant`,
      {
        method: "PUT",
        body: JSON.stringify(updatedVariants),
        credentials: "include",
      }
    );

    if (response.ok) {
      const jsonRes = (await response.json()) as YarnProfileVariant[];
      thunkAPI.dispatch(setVariants(jsonRes));
    }
  }
);

const addSize = createAsyncThunk(
  "pattern/addSize",
  async (data: { size: string; allSizes: string[] }, thunkAPI) => {
    const { size, allSizes } = data;

    const {
      meta: { sizes },
      id: patternId,
    } = (thunkAPI.getState() as RootState).pattern;

    const newSizes = [...sizes, size];
    const updatedSizes = [...newSizes].sort(
      (a, b) => allSizes.indexOf(a) - allSizes.indexOf(b)
    );

    thunkAPI.dispatch(addSizeToState({ newSize: size, updatedSizes }));

    const response = await fetch(
      `${backendApiAddress}/api/pattern/${patternId}/meta/size`,
      {
        method: "PUT",
        body: JSON.stringify({ sizes: updatedSizes }),
        credentials: "include",
      }
    );

    if (response.ok) {
      const jsonRes = (await response.json()) as {
        sizes?: string[];
        sizeCategory?: string;
        sizeGroups?: SizeGroup[];
      };
      thunkAPI.dispatch(updateSizes(jsonRes));
    }

    return { size, allSizes };
  }
);

const removeSize = createAsyncThunk(
  "pattern/removeSize",
  async (data: { size: string }, thunkAPI) => {
    const { size: sizeToRemove } = data;

    const {
      meta: { sizes },
      id: patternId,
    } = (thunkAPI.getState() as RootState).pattern;

    const updatedSizes = sizes.filter((size) => size !== sizeToRemove);

    thunkAPI.dispatch(
      removeSizeFromState({ removeSize: sizeToRemove, updatedSizes })
    );

    const response = await fetch(
      `${backendApiAddress}/api/pattern/${patternId}/meta/size`,
      {
        method: "PUT",
        body: JSON.stringify({ sizes: updatedSizes }),
        credentials: "include",
      }
    );

    if (response.ok) {
      const jsonRes = (await response.json()) as {
        sizes?: string[];
        sizeCategory?: string;
        sizeGroups?: SizeGroup[];
      };
      thunkAPI.dispatch(updateSizes(jsonRes));
    }

    return { size: sizeToRemove };
  }
);

const setCategory = createAsyncThunk(
  "pattern/setCategory",
  async (newCategory: string | null, thunkAPI) => {
    const { id: patternId } = (thunkAPI.getState() as RootState).pattern;

    const sizeCategory = newCategory || null;

    thunkAPI.dispatch(updateSizes({ sizes: [], sizeCategory }));

    const response = await fetch(
      `${backendApiAddress}/api/pattern/${patternId}/meta/size`,
      {
        method: "PUT",
        body: JSON.stringify({ sizes: [], sizeCategory: newCategory || null }),
        credentials: "include",
      }
    );

    if (response.ok) {
      const jsonRes = (await response.json()) as {
        sizes?: string[];
        sizeCategory?: string;
        sizeGroups?: SizeGroup[];
      };
      thunkAPI.dispatch(updateSizes(jsonRes));
    }
  }
);

const addSizeCategory = createAsyncThunk(
  "pattern/addSizeCategory",
  async (data: { name: string }, thunkAPI) => {
    const { name } = data;

    const {
      meta: { sizeGroups },
      id: patternId,
    } = (thunkAPI.getState() as RootState).pattern;

    const updatedSizeGroups = [...sizeGroups, { label: name, sizes: SIZES }];

    thunkAPI.dispatch(
      updateSizes({ sizeCategory: name, sizeGroups: updatedSizeGroups })
    );

    const response = await fetch(
      `${backendApiAddress}/api/pattern/${patternId}/meta/size`,
      {
        method: "PUT",
        body: JSON.stringify({
          sizeCategory: name,
          sizeGroups: updatedSizeGroups,
        }),
        credentials: "include",
      }
    );

    if (response.ok) {
      const jsonRes = (await response.json()) as {
        sizes?: string[];
        sizeCategory?: string;
        sizeGroups?: SizeGroup[];
      };
      thunkAPI.dispatch(updateSizes(jsonRes));
    }
  }
);

const addSizeCategorySize = createAsyncThunk(
  "pattern/addSizeCategorySize",
  async (data: { name: string }, thunkAPI) => {
    const { name } = data;

    const {
      meta: { sizeGroups },
      id: patternId,
    } = (thunkAPI.getState() as RootState).pattern;

    const sizeGroupIdx = sizeGroups.findIndex((size) => size.label === name);
    if (sizeGroupIdx === -1) return;

    const updatedSizes = [...sizeGroups[sizeGroupIdx].sizes, ""];

    const updatedSizeGroups = Object.assign([...sizeGroups], {
      [sizeGroupIdx]: { ...sizeGroups[sizeGroupIdx], sizes: updatedSizes },
    });

    thunkAPI.dispatch(
      updateSizes({ sizeCategory: name, sizeGroups: updatedSizeGroups })
    );

    const response = await fetch(
      `${backendApiAddress}/api/pattern/${patternId}/meta/size`,
      {
        method: "PUT",
        body: JSON.stringify({
          sizeCategory: name,
          sizeGroups: updatedSizeGroups,
        }),
        credentials: "include",
      }
    );

    if (response.ok) {
      const jsonRes = (await response.json()) as {
        sizes?: string[];
        sizeCategory?: string;
        sizeGroups?: SizeGroup[];
      };
      thunkAPI.dispatch(updateSizes(jsonRes));
    }
  }
);

const editSizeCategorySize = createAsyncThunk(
  "pattern/editSizeCategorySize",
  async (
    data: {
      sizeGroup: SizeGroup;
      index: number;
      oldValue: string;
      value?: string;
    },
    thunkAPI
  ) => {
    const { sizeGroup, index, oldValue, value } = data;

    if (oldValue === value) return;

    const {
      meta: { sizeGroups, sizes },
      id: patternId,
    } = (thunkAPI.getState() as RootState).pattern;

    const sizeGroupIdx = sizeGroups.findIndex(
      (size) => size.label === sizeGroup.label
    );

    if (sizeGroupIdx === -1) return;

    // Update any active sizes
    const activeSizeIdx = sizes.findIndex((size) => size === oldValue);

    let updatedSizeGroupSizes, updatedSizes;
    if (value === undefined) {
      // Remove entry
      updatedSizeGroupSizes = sizeGroups[sizeGroupIdx].sizes.filter(
        (_, i) => index !== i
      );

      if (activeSizeIdx !== -1) {
        updatedSizes = sizes.filter((_, i) => activeSizeIdx !== i);
      }
    } else {
      // Update any active sizes
      updatedSizeGroupSizes = Object.assign(
        [...sizeGroups[sizeGroupIdx].sizes],
        { [index]: value }
      );
      if (activeSizeIdx !== -1) {
        updatedSizes = Object.assign([...sizes], { [activeSizeIdx]: value });
      }
    }

    const updatedSizeGroups = Object.assign([...sizeGroups], {
      [sizeGroupIdx]: {
        ...sizeGroups[sizeGroupIdx],
        sizes: updatedSizeGroupSizes,
      },
    });

    thunkAPI.dispatch(
      updateSizes({ sizes: updatedSizes, sizeGroups: updatedSizeGroups })
    );

    const response = await fetch(
      `${backendApiAddress}/api/pattern/${patternId}/meta/size`,
      {
        method: "PUT",
        body: JSON.stringify({
          sizes: updatedSizes,
          sizeGroups: updatedSizeGroups,
        }),
        credentials: "include",
      }
    );

    if (response.ok) {
      const jsonRes = (await response.json()) as {
        sizes?: string[];
        sizeCategory?: string;
        sizeGroups?: SizeGroup[];
      };
      thunkAPI.dispatch(updateSizes(jsonRes));
    }

    return { value, oldValue };
  }
);

const addClothingMetaData = createAsyncThunk(
  "pattern/addClothingMetaData",
  async (_data, thunkAPI) => {
    const {
      meta: { clothingMetaData },
      id: patternId,
    } = (thunkAPI.getState() as RootState).pattern;

    const newClothingMetaData = { title: "", value: "" };

    const updatedClothingMetaData = clothingMetaData
      ? [...clothingMetaData, newClothingMetaData]
      : [newClothingMetaData];

    thunkAPI.dispatch(updateClothingMetaData(updatedClothingMetaData));

    const response = await fetch(
      `${backendApiAddress}/api/pattern/${patternId}/meta/clothing_metadata`,
      {
        method: "PUT",
        body: JSON.stringify(updatedClothingMetaData),
        credentials: "include",
      }
    );

    if (response.ok) {
      const jsonRes = (await response.json()) as string[];
      thunkAPI.dispatch(updateClothingMetaData(jsonRes));
    }
  }
);

const setClothingMetaData = createAsyncThunk(
  "pattern/setClothingMetaData",
  async (data: { index: number; item: ClothingMetaData }, thunkAPI) => {
    const {
      meta: { clothingMetaData },
      id: patternId,
    } = (thunkAPI.getState() as RootState).pattern;
    const { index, item } = data;

    const updatedClothingMetaData = Object.assign([...clothingMetaData], {
      [index]: item,
    });

    thunkAPI.dispatch(updateClothingMetaData(updatedClothingMetaData));

    const response = await fetch(
      `${backendApiAddress}/api/pattern/${patternId}/meta/clothing_metadata`,
      {
        method: "PUT",
        body: JSON.stringify(updatedClothingMetaData),
        credentials: "include",
      }
    );

    if (response.ok) {
      const jsonRes = (await response.json()) as String[];
      thunkAPI.dispatch(updateClothingMetaData(jsonRes));
    }
  }
);

const removeClothingMetaData = createAsyncThunk(
  "pattern/removeClothingMetaData",
  async (index: number, thunkAPI) => {
    const {
      meta: { clothingMetaData },
      id: patternId,
    } = (thunkAPI.getState() as RootState).pattern;
    const updatedClothingMetaData = clothingMetaData.filter(
      (_, idx) => idx !== index
    );

    thunkAPI.dispatch(updateClothingMetaData(updatedClothingMetaData));

    const response = await fetch(
      `${backendApiAddress}/api/pattern/${patternId}/meta/clothing_metadata`,
      {
        method: "PUT",
        body: JSON.stringify(updatedClothingMetaData),
        credentials: "include",
      }
    );

    if (response.ok) {
      const jsonRes = (await response.json()) as string[];
      thunkAPI.dispatch(updateClothingMetaData(jsonRes));
    }
  }
);

const addRole = createAsyncThunk("pattern/addRole", async (_data, thunkAPI) => {
  const {
    meta: { roles },
    id: patternId,
  } = (thunkAPI.getState() as RootState).pattern;

  const newRole = { title: "", value: "" };

  const updatedRoles = roles ? [...roles, newRole] : [newRole];

  thunkAPI.dispatch(updateRoles(updatedRoles));

  const response = await fetch(
    `${backendApiAddress}/api/pattern/${patternId}/meta/roles`,
    {
      method: "PUT",
      body: JSON.stringify(updatedRoles),
      credentials: "include",
    }
  );

  if (response.ok) {
    const jsonRes = (await response.json()) as String[];
    thunkAPI.dispatch(updateRoles(jsonRes));
  }
});

const setRoleTitle = createAsyncThunk(
  "pattern/setRoleTitle",
  async (data: { index: number; title: string }, thunkAPI) => {
    const {
      meta: { roles },
      id: patternId,
    } = (thunkAPI.getState() as RootState).pattern;
    const { index, title } = data;

    const role = roles[index];

    if (!role) {
      return;
    }

    const updatedRole = {
      ...role,
      title,
    };

    const updatedRoles = Object.assign([...roles], { [index]: updatedRole });

    thunkAPI.dispatch(updateRoles(updatedRoles));

    const response = await fetch(
      `${backendApiAddress}/api/pattern/${patternId}/meta/roles`,
      {
        method: "PUT",
        body: JSON.stringify(updatedRoles),
        credentials: "include",
      }
    );

    if (response.ok) {
      const jsonRes = (await response.json()) as String[];
      thunkAPI.dispatch(updateRoles(jsonRes));
    }
  }
);

const setRoleValue = createAsyncThunk(
  "pattern/setRoleValue",
  async (data: { index: number; value: string }, thunkAPI) => {
    const {
      meta: { roles },
      id: patternId,
    } = (thunkAPI.getState() as RootState).pattern;
    const { index, value } = data;

    const role = roles[index];

    if (!role) {
      return;
    }

    const updatedRole = {
      ...role,
      value,
    };

    const updatedRoles = Object.assign([...roles], { [index]: updatedRole });

    thunkAPI.dispatch(updateRoles(updatedRoles));

    const response = await fetch(
      `${backendApiAddress}/api/pattern/${patternId}/meta/roles`,
      {
        method: "PUT",
        body: JSON.stringify(updatedRoles),
        credentials: "include",
      }
    );

    if (response.ok) {
      const jsonRes = (await response.json()) as String[];
      thunkAPI.dispatch(updateRoles(jsonRes));
    }
  }
);

const deleteRole = createAsyncThunk(
  "pattern/deleteRole",
  async (data: { index: number }, thunkAPI) => {
    const {
      meta: { roles },
      id: patternId,
    } = (thunkAPI.getState() as RootState).pattern;
    const { index } = data;

    const updatedRoles = roles.filter((_, idx) => idx !== index);

    thunkAPI.dispatch(updateRoles(updatedRoles));

    const response = await fetch(
      `${backendApiAddress}/api/pattern/${patternId}/meta/roles`,
      {
        method: "PUT",
        body: JSON.stringify(updatedRoles),
        credentials: "include",
      }
    );

    if (response.ok) {
      const jsonRes = (await response.json()) as String[];
      thunkAPI.dispatch(updateRoles(jsonRes));
    }
  }
);

const addNeedle = createAsyncThunk("pattern/addNeedle", async (_, thunkAPI) => {
  const {
    needles,
    meta,
    id: patternId,
  } = (thunkAPI.getState() as RootState).pattern;

  const needleId = shortUUID();

  const updatedGaugeNeedle =
    Object.entries(needles).length === 0 ? needleId : meta.gaugeNeedle;

  const newNeedle: Needle = { type: "rundpinne", diameter: 0, size: 0 };

  const updatedNeedles = {
    ...needles,
    [needleId]: newNeedle,
  };

  const updatedNeedleOrder = [...meta.needleOrder, needleId];

  thunkAPI.dispatch(
    updateNeedles({
      needles: updatedNeedles,
      needleOrder: updatedNeedleOrder,
      gaugeNeedle: updatedGaugeNeedle,
    })
  );

  const response = await fetch(
    `${backendApiAddress}/api/pattern/${patternId}/needles`,
    {
      method: "PUT",
      body: JSON.stringify({
        needles: updatedNeedles,
        needleOrder: updatedNeedleOrder,
        gaugeNeedle: updatedGaugeNeedle,
      }),
      credentials: "include",
    }
  );

  if (response.ok) {
    const jsonRes = (await response.json()) as {
      needles: { [key: string]: Needle } | null;
      needleOrder: string[] | null;
      gaugeNeedle: string | null;
    };
    thunkAPI.dispatch(updateNeedles(jsonRes));
  }
});

const setNeedle = createAsyncThunk(
  "pattern/setNeedle",
  async (
    data: {
      id: string;
      needle: Needle;
    },
    thunkAPI
  ) => {
    const { id, needle } = data;

    const { needles, id: patternId } = (thunkAPI.getState() as RootState)
      .pattern;

    const updatedNeedle =
      needle.type === "rundpinne"
        ? { type: needle.type, diameter: needle.diameter, size: needle.size }
        : { type: needle.type, diameter: needle.diameter };

    const updatedNeedles = { ...needles, [id]: updatedNeedle };

    thunkAPI.dispatch(
      updateNeedles({
        needles: updatedNeedles,
      })
    );

    const response = await fetch(
      `${backendApiAddress}/api/pattern/${patternId}/needles`,
      {
        method: "PUT",
        body: JSON.stringify({
          needles: updatedNeedles,
        }),
        credentials: "include",
      }
    );

    if (response.ok) {
      const jsonRes = (await response.json()) as {
        needles: { [key: string]: Needle } | null;
        needleOrder: string[] | null;
        gaugeNeedle: string | null;
      };
      thunkAPI.dispatch(updateNeedles(jsonRes));
    }
  }
);

const removeNeedle = createAsyncThunk(
  "pattern/removeNeedle",
  async (data: { id: string }, thunkAPI) => {
    const {
      needles,
      meta,
      id: patternId,
    } = (thunkAPI.getState() as RootState).pattern;
    const { id } = data;

    const updatedNeedleOrder = meta.needleOrder.filter(
      (needleId) => needleId !== id
    );

    const updatedNeedles = Object.fromEntries(
      Object.entries(needles).filter(([needleId]) => needleId !== id)
    );

    const updatedGaugeNeedle =
      meta.gaugeNeedle === id ? updatedNeedleOrder[0] : meta.gaugeNeedle;

    thunkAPI.dispatch(
      updateNeedles({
        needles: updatedNeedles,
        needleOrder: updatedNeedleOrder,
        gaugeNeedle: updatedGaugeNeedle,
      })
    );

    const response = await fetch(
      `${backendApiAddress}/api/pattern/${patternId}/needles`,
      {
        method: "PUT",
        body: JSON.stringify({
          needles: updatedNeedles,
          needleOrder: updatedNeedleOrder,
          gaugeNeedle: updatedGaugeNeedle,
        }),
        credentials: "include",
      }
    );

    if (response.ok) {
      const jsonRes = (await response.json()) as {
        needles: { [key: string]: Needle } | null;
        needleOrder: string[] | null;
        gaugeNeedle: string | null;
      };
      thunkAPI.dispatch(updateNeedles(jsonRes));
    }
  }
);

const setNeedleOrder = createAsyncThunk(
  "pattern/setNeedleOrder",
  async (updatedNeedleOrder: string[], thunkAPI) => {
    const { id: patternId } = (thunkAPI.getState() as RootState).pattern;

    thunkAPI.dispatch(
      updateNeedles({
        needleOrder: updatedNeedleOrder,
        gaugeNeedle: updatedNeedleOrder[0],
      })
    );

    const response = await fetch(
      `${backendApiAddress}/api/pattern/${patternId}/needles`,
      {
        method: "PUT",
        body: JSON.stringify({
          needleOrder: updatedNeedleOrder,
          gaugeNeedle: updatedNeedleOrder[0],
        }),
        credentials: "include",
      }
    );

    if (response.ok) {
      const jsonRes = (await response.json()) as {
        needles: { [key: string]: Needle } | null;
        needleOrder: string[] | null;
        gaugeNeedle: string | null;
      };
      thunkAPI.dispatch(updateNeedles(jsonRes));
    }
  }
);

const setPatternElementsById = createAsyncThunk(
  "pattern/setPatternElementsById",
  async (
    patternData: { patternId: string; patternElements?: PatternElement[] },
    thunkAPI
  ) => {
    let { patternElements, patternId } = patternData;

    if (patternElements === undefined) {
      patternElements = (thunkAPI.getState() as RootState).pattern
        .patternElements;
    }

    const response = await fetch(
      `${backendApiAddress}/api/pattern/${patternId}/elements`,
      {
        method: "PUT",
        body: JSON.stringify({
          patternElements,
        }),
        credentials: "include",
      }
    );
    if (response.ok) {
      thunkAPI.dispatch(
        putPatternElementsOnLocalPattern({ patternId, patternElements })
      );
    }
  }
);

const updateDiagramById = createAsyncThunk(
  "pattern/updateDiagram",
  async (
    diagramData: { patternId: number; diagram: DiagramData },
    _thunkAPI
  ) => {
    const { diagram } = diagramData;
    const response = await fetch(
      `${backendApiAddress}/api/pattern/diagram/${diagram.id}`,
      {
        method: "PUT",
        body: JSON.stringify(diagramData),
        credentials: "include",
      }
    );
    return response.json();
  }
);

// #[delete("/<pattern_id>/diagrams/<diagram_key>")]
const deleteDiagramById = createAsyncThunk(
  "pattern/deleteDiagram",
  async (
    diagramData: { patternId: string | number; diagramId: string },
    thunkAPI
  ) => {
    const { patternElements } = (thunkAPI.getState() as RootState).pattern;
    const { patternId, diagramId } = diagramData;

    // Cannot delete if diagram is used in pattern
    // Should be checked before this function is called
    if (!notInPattern(diagramId, patternElements)) {
      return;
    }

    await fetch(
      `${backendApiAddress}/api/pattern/${patternId}/diagrams/${diagramId}`,
      {
        method: "DELETE",
        credentials: "include",
      }
    );
    return;
  }
);

const newPattern = createAsyncThunk(
  "pattern/newPattern",
  async (
    data: {
      title: string;
      collection: string;
      copiedPatternId: number;
      organizationId?: number;
      diagramOnly?: boolean;
    },
    _thunkAPI
  ) => {
    const { title, collection, copiedPatternId, organizationId, diagramOnly } =
      data;
    if (copiedPatternId) {
      const response = await fetch(
        `${backendApiAddress}/api/pattern/${copiedPatternId}/copy/details`,
        {
          credentials: "include",
          method: "POST",
          body: JSON.stringify({
            title: title,
            collection: collection,
            design: "",
            designer: "",
            organizationId: organizationId,
          }),
        }
      );
      const id = await response.json();

      window.location.href = `/edit/${id}/write`;
    } else {
      const response = await fetch(`${backendApiAddress}/api/pattern/new`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify({
          title: title,
          design: "",
          collection: collection,
          designer: "",
          organizationId: organizationId,
          sizes: initialState.meta.sizes,
          sizeGroups: initialState.meta.sizeGroups,
          sizeCategory: initialState.meta.sizeCategory,
          patternElements: initialState.patternElements,
        }),
      });
      const { id } = await response.json();

      const url = diagramOnly ? `/edit/${id}/draw` : `/edit/${id}/write`;

      window.location.href = url;
    }
  }
);

const addPatternComment = createAsyncThunk(
  "pattern/addPatternComment",
  async (commentData: { patternId: string; comment: Comment }, _thunkAPI) => {
    const { patternId, comment } = commentData;
    const response = await fetch(
      `${backendApiAddress}/api/pattern/${patternId}/add_comment`,
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(comment),
      }
    );
    return response.json();
  }
);

const addPatternTags = createAsyncThunk(
  "pattern/addPatternTags",
  async (allTags: string[], thunkAPI) => {
    const { id: patternId } = (thunkAPI.getState() as RootState).pattern;

    thunkAPI.dispatch(updateTags({ tags: allTags }));

    await fetch(`${backendApiAddress}/api/pattern/${patternId}/tags`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(allTags),
    });
  }
);

const answerPatternComment = createAsyncThunk(
  "pattern/answerPatternComment",
  async (
    answerData: { answer: string; patternId: string; commentId: string },
    _thunkAPI
  ) => {
    const { patternId, commentId, answer } = answerData;
    const response = await fetch(
      `${backendApiAddress}/api/pattern/${patternId}/answer_comment/${commentId}`,
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({ answer: answer }),
      }
    );
    return response.json();
  }
);

const resolvePatternComment = createAsyncThunk(
  "pattern/resolvePatternComment",
  async (commentData: { patternId: string; commentId: string }, _thunkAPI) => {
    const { patternId, commentId } = commentData;
    const response = await fetch(
      `${backendApiAddress}/api/pattern/${patternId}/resolve_comment/${commentId}`,
      {
        method: "POST",
        credentials: "include",
      }
    );
    return response.json();
  }
);

const uploadFiles = createAsyncThunk(
  "pattern/uploadFile",
  async (fileData: { patternId: number; files: File[] }, _thunkAPI) => {
    const { patternId, files } = fileData;

    return Promise.allSettled(
      files.map((file) => {
        const formData = new FormData();

        formData.append("file", file);
        formData.append("mimetype", file.type);

        const response = fetch(
          `${backendApiAddress}/api/pattern/${patternId}/image`,
          {
            method: "POST",
            credentials: "include",
            body: formData,
          }
        );

        return response.then((response) => response.json());
      })
    );
  }
);

const deleteFile = createAsyncThunk(
  "pattern/deleteFile",
  async (fileData: { patternId: number; fileId: string }, _thunkAPI) => {
    const { patternId, fileId } = fileData;
    await fetch(
      `${backendApiAddress}/api/pattern/${patternId}/image/${fileId}`,
      {
        method: "DELETE",
        credentials: "include",
      }
    );
  }
);

const publishPattern = createAsyncThunk(
  "pattern/publishPattern",
  async (
    data: {
      pattern: Pattern;
      comment: string;
    },
    _thunkAPI
  ) => {
    const { pattern, comment } = data;

    const response = await fetch(
      `${backendApiAddress}/api/pattern/${pattern.id}/publish`,
      {
        method: "POST",
        body: JSON.stringify({
          pattern: {
            meta: pattern.meta,
            patternElements: pattern.patternElements,
            calculationResults: pattern.calculationResults,
            figures: pattern.figures,
            yarnColors: pattern.yarnColors,
            needles: pattern.needles,
            media: pattern.media,
            symbols: pattern.symbols,
            tags: pattern.tags,
          },
          comment: comment,
          diagrams: Object.keys(pattern.diagrams).map((diagramKey) => {
            return {
              key: diagramKey,
              diagram: pattern.diagrams[diagramKey],
            };
          }),
        }),
        credentials: "include",
      }
    );
    if (!response.ok) {
      throw isRejectedWithValue(response);
    }
  }
);

const createKnitAlong = createAsyncThunk(
  "pattern/createKnitAlong",
  async (_, thunkAPI) => {
    const newKnitAlong = { hashtags: [] };

    const { id: patternId } = (thunkAPI.getState() as RootState).pattern;

    thunkAPI.dispatch(updateKnitAlong(newKnitAlong));

    const response = await fetch(
      `${backendApiAddress}/api/pattern/${patternId}/meta/knit_along`,
      {
        method: "PUT",
        body: JSON.stringify(newKnitAlong),
        credentials: "include",
      }
    );

    if (response.ok) {
      const jsonRes = (await response.json()) as KnitAlong;
      thunkAPI.dispatch(updateKnitAlong(jsonRes));
    }
  }
);

const deleteKnitAlong = createAsyncThunk(
  "pattern/deleteKnitAlong",
  async (_, thunkAPI) => {
    const { id: patternId } = (thunkAPI.getState() as RootState).pattern;

    thunkAPI.dispatch(deleteKnitAlongUpdateState());

    const response = await fetch(
      `${backendApiAddress}/api/pattern/${patternId}/meta/knit_along`,
      {
        method: "DELETE",
        credentials: "include",
      }
    );

    if (response.ok) {
      const jsonRes = (await response.json()) as KnitAlong;
      thunkAPI.dispatch(updateKnitAlong(jsonRes));
    }
  }
);

const setKnitAlongSignUpDate = createAsyncThunk(
  "pattern/setKnitAlongSignUpDate",
  async (data: { signUpDate: string }, thunkAPI) => {
    const {
      meta: { knitAlong },
      id: patternId,
    } = (thunkAPI.getState() as RootState).pattern;

    const { signUpDate } = data;

    const updatedKnitAlong = { ...knitAlong, signupDeadline: signUpDate };

    thunkAPI.dispatch(updateKnitAlong(updatedKnitAlong));

    const response = await fetch(
      `${backendApiAddress}/api/pattern/${patternId}/meta/knit_along`,
      {
        method: "PUT",
        body: JSON.stringify(updatedKnitAlong),
        credentials: "include",
      }
    );

    if (response.ok) {
      const jsonRes = (await response.json()) as KnitAlong;
      thunkAPI.dispatch(updateKnitAlong(jsonRes));
    }
  }
);

const setKnitAlongStartDate = createAsyncThunk(
  "pattern/setKnitAlongStartDate",
  async (data: { startDate: string }, thunkAPI) => {
    const {
      meta: { knitAlong },
      id: patternId,
    } = (thunkAPI.getState() as RootState).pattern;

    const { startDate } = data;

    const updatedKnitAlong = { ...knitAlong, startDate };

    thunkAPI.dispatch(updateKnitAlong(updatedKnitAlong));

    const response = await fetch(
      `${backendApiAddress}/api/pattern/${patternId}/meta/knit_along`,
      {
        method: "PUT",
        body: JSON.stringify(updatedKnitAlong),
        credentials: "include",
      }
    );

    if (response.ok) {
      const jsonRes = (await response.json()) as KnitAlong;
      thunkAPI.dispatch(updateKnitAlong(jsonRes));
    }
  }
);

const setKnitAlongEndDate = createAsyncThunk(
  "pattern/setKnitAlongEndDate",
  async (data: { endDate: string }, thunkAPI) => {
    const {
      meta: { knitAlong },
      id: patternId,
    } = (thunkAPI.getState() as RootState).pattern;

    const { endDate } = data;

    const updatedKnitAlong = { ...knitAlong, endDate };

    thunkAPI.dispatch(updateKnitAlong(updatedKnitAlong));

    const response = await fetch(
      `${backendApiAddress}/api/pattern/${patternId}/meta/knit_along`,
      {
        method: "PUT",
        body: JSON.stringify(updatedKnitAlong),
        credentials: "include",
      }
    );

    if (response.ok) {
      const jsonRes = (await response.json()) as KnitAlong;
      thunkAPI.dispatch(updateKnitAlong(jsonRes));
    }
  }
);

const addKnitAlongHashtag = createAsyncThunk(
  "pattern/addKnitAlongHashtag",
  async (data: { hashtag: string }, thunkAPI) => {
    const {
      meta: { knitAlong = { hashtags: [] } },
      id: patternId,
    } = (thunkAPI.getState() as RootState).pattern;

    const { hashtag } = data;

    const updatedKnitAlong = {
      ...knitAlong,
      hashtags: [...(knitAlong.hashtags || []), hashtag],
    };

    thunkAPI.dispatch(updateKnitAlong(updatedKnitAlong));

    const response = await fetch(
      `${backendApiAddress}/api/pattern/${patternId}/meta/knit_along`,
      {
        method: "PUT",
        body: JSON.stringify(updatedKnitAlong),
        credentials: "include",
      }
    );

    if (response.ok) {
      const jsonRes = (await response.json()) as KnitAlong;
      thunkAPI.dispatch(updateKnitAlong(jsonRes));
    }
  }
);

const deleteKnitAlongHashtag = createAsyncThunk(
  "pattern/deleteKnitAlongHashtag",
  async (data: { index: number }, thunkAPI) => {
    const {
      meta: { knitAlong = { hashtags: [] } },
      id: patternId,
    } = (thunkAPI.getState() as RootState).pattern;

    const { index: hashtagIndex } = data;

    const updatedHashtags = (knitAlong.hashtags || []).filter((_, index) => {
      return index !== hashtagIndex;
    });

    const updatedKnitAlong = {
      ...knitAlong,
      hashtags: updatedHashtags,
    };

    thunkAPI.dispatch(updateKnitAlong(updatedKnitAlong));

    const response = await fetch(
      `${backendApiAddress}/api/pattern/${patternId}/meta/knit_along`,
      {
        method: "PUT",
        body: JSON.stringify(updatedKnitAlong),
        credentials: "include",
      }
    );

    if (response.ok) {
      const jsonRes = (await response.json()) as KnitAlong;
      thunkAPI.dispatch(updateKnitAlong(jsonRes));
    }
  }
);

const setGarmentForm = createAsyncThunk(
  "pattern/setGarmentForm",
  async (data: "freeform" | "sweater" | "sock", thunkAPI) => {
    const { id: patternId } = (thunkAPI.getState() as RootState).pattern;

    const response = await fetch(
      `${backendApiAddress}/api/pattern/${patternId}/meta/garment_form`,
      {
        method: "PUT",
        body: JSON.stringify({ form: data }),
        credentials: "include",
      }
    );

    if (response.ok) {
      thunkAPI.dispatch(_setGarmentForm(data));
    }
  }
);

const setKnitMethod = createAsyncThunk(
  "pattern/setKnitMethod",
  async (data: "raglan" | "stitched-sleeves" | "circular-yoke", thunkAPI) => {
    const { id: patternId } = (thunkAPI.getState() as RootState).pattern;

    const response = await fetch(
      `${backendApiAddress}/api/pattern/${patternId}/meta/knit_method`,
      {
        method: "PUT",
        body: JSON.stringify({ knitMethod: data }),
        credentials: "include",
      }
    );

    if (response.ok) {
      thunkAPI.dispatch(_setKnitMethod(data));
    }
  }
);

const setMeasurements = createAsyncThunk(
  "pattern/setMeasurements",
  async (data: { [key: string]: { [key2: string]: number } }, thunkAPI) => {
    const { id: patternId } = (thunkAPI.getState() as RootState).pattern;

    const response = await fetch(
      `${backendApiAddress}/api/pattern/${patternId}/meta/measurements`,
      {
        method: "PUT",
        body: JSON.stringify({ measurements: data }),
        credentials: "include",
      }
    );

    if (response.ok) {
      thunkAPI.dispatch(_setMeasurements(data));
    }
  }
);

const setVisualRepresentationSize = createAsyncThunk(
  "pattern/setVisualRepresentationSize",
  async (data: string, thunkAPI) => {
    const { id: patternId } = (thunkAPI.getState() as RootState).pattern;

    const response = await fetch(
      `${backendApiAddress}/api/pattern/${patternId}/meta/visual_representation_size`,
      {
        method: "PUT",
        body: JSON.stringify({ visualRepresentationSize: data }),
        credentials: "include",
      }
    );

    if (response.ok) {
      thunkAPI.dispatch(_setVisualRepresentationSize(data));
    }
  }
);

const patternSlice = createSlice({
  name: "pattern",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchPatternById.fulfilled, (state, action) => {
      const meta = { ...state.meta, ...action.payload.meta };
      return {
        ...state,
        ...action.payload,
        meta,
      };
    });
    builder.addCase(addPatternComment.fulfilled, (state, action) => {
      const comment = action.payload as Comment;
      return {
        ...state,
        comments: { ...state.comments, [comment.id]: comment },
      };
    });
    builder.addCase(answerPatternComment.fulfilled, (state, action) => {
      const comment = action.payload as Comment;
      return {
        ...state,
        comments: { ...state.comments, [comment.id]: comment },
      };
    });
    builder.addCase(updateDiagramById.fulfilled, (state, action) => {
      return { ...state, ...action.payload };
    });

    builder.addCase(uploadFiles.fulfilled, (state, action) => {
      const responses = action.payload;

      const imageIds = responses
        .filter((response: any) => response.status === "fulfilled")
        .map((response: any) => response.value.imageId);

      imageIds.forEach(
        (image: string) => (state.media[image] = { src: "", alt: "" })
      );
    });
    builder.addCase(deleteFile.fulfilled, (state, action) => {
      delete state.media[action.meta.arg.fileId];
    });
    builder.addCase(deleteDiagramById.fulfilled, (state, action) => {
      const deletedDiagramId = action.meta.arg.diagramId;
      const diagrams = state.diagrams;
      const updatedDiagrams: any = { ...diagrams };
      delete updatedDiagrams[deletedDiagramId];

      return { ...state, diagrams: updatedDiagrams };
    });
    builder.addMatcher(
      woolitApi.endpoints.logout.matchFulfilled,
      (_state, _action) => {
        return initialState;
      }
    );
  },
  reducers: {
    reset(_state) {
      return initialState;
    },
    updateSizes(
      state,
      action: PayloadAction<{
        sizes?: string[];
        sizeCategory?: string | null;
        sizeGroups?: SizeGroup[];
      }>
    ) {
      const { sizes, sizeGroups, sizeCategory } = action.payload;
      state.meta.sizes = sizes || state.meta.sizes;
      state.meta.sizeCategory = sizeCategory || state.meta.sizeCategory;
      state.meta.sizeGroups = sizeGroups || state.meta.sizeGroups;
    },
    updateTags(
      state,
      action: PayloadAction<{
        tags?: string[];
      }>
    ) {
      const { tags } = action.payload;
      state.tags = tags || state.tags;
    },
    addSizeToState(
      state,
      action: PayloadAction<{ newSize: string; updatedSizes: string[] }>
    ) {
      const { updatedSizes } = action.payload;
      state.meta.sizes = updatedSizes;
    },
    removeSizeFromState(
      state,
      action: PayloadAction<{ removeSize: string; updatedSizes: string[] }>
    ) {
      const { updatedSizes } = action.payload;
      state.meta.sizes = updatedSizes;
    },
    updateRoles(state, action) {
      state.meta.roles = action.payload;
    },
    updateClothingMetaData(state, action) {
      state.meta.clothingMetaData = action.payload;
    },
    updateMeta(
      state,
      action: PayloadAction<{
        title?: string;
        design?: string;
        designer?: string;
        collection?: string;
        description?: string;
      }>
    ) {
      const { title, design, designer, collection, description } =
        action.payload;
      // title || state.meta.title doesn't work if title === ""
      state.meta.title = title !== undefined ? title : state.meta.title;
      state.meta.design = design !== undefined ? design : state.meta.design;
      state.meta.designer =
        designer !== undefined ? designer : state.meta.designer;
      state.meta.collection =
        collection !== undefined ? collection : state.meta.collection;
      state.meta.description =
        description !== undefined ? description : state.meta.description;
    },
    addVariant(state, action: PayloadAction<YarnProfileVariant>) {
      state.meta.variants.push(action.payload);
    },

    setVariants(state, action: PayloadAction<YarnProfileVariant[]>) {
      state.meta.variants = action.payload;
    },
    updateNeedles(
      state,
      action: PayloadAction<{
        needles?: { [key: string]: Needle } | null;
        needleOrder?: string[] | null;
        gaugeNeedle?: string | null;
      }>
    ) {
      const { needles, gaugeNeedle, needleOrder } = action.payload;
      state.needles = needles || state.needles;
      state.meta.needleOrder = needleOrder || state.meta.needleOrder;
      state.meta.gaugeNeedle = gaugeNeedle || state.meta.gaugeNeedle;
    },
    addEmptyDiagram(state, action) {
      const { diagramId, diagramData } = action.payload;
      state.diagrams[diagramId] = {
        ...diagramData,
      };
    },
    setDiagram(state, action) {
      const { diagramId, diagramString } = action.payload;
      try {
        state.diagrams[diagramId] = {
          ...JSON.parse(diagramString),
          id: diagramId,
        };
      } catch (error) {
        // Malformed JSON, do nothing
        console.log(error);
      }
    },
    // Used when deleting a diagram
    setAllDiagrams(state, action) {
      state.diagrams = action.payload;
    },
    resolveComment(state, action) {
      const { commentRef } = action.payload;

      // commentRef = index of patternElement
      // if commentRef -1
      state.patternElements.forEach((element) => {
        if (element.commentId === commentRef) {
          delete element.commentId;
        }
      });
      delete state.comments[commentRef];
    },
    acceptChange(state, action) {
      const { commentRef, change } = action.payload;
      const index = state.patternElements.findIndex(
        (element) => element.commentId === commentRef
      );

      if (state.patternElements[index].type === "span" && index !== -1) {
        state.patternElements[index] = {
          type: "span",
          markdown: change,
        };
        delete state.comments[commentRef];
      }
    },
    addAnswer(state, action) {
      const { user, answer, commentRef, timestamp } = action.payload;
      state.comments[commentRef].answers.push({
        timestamp,
        answer,
        user,
      });
    },
    replaceAllSymbols(
      state,
      action: PayloadAction<{
        toReplace: Symbols | DynamicSymbols;
        replacer: Symbols | DynamicSymbols;
        type: SymbolType;
      }>
    ) {
      const { toReplace, replacer, type } = action.payload;
      if (type === "single") {
        Object.entries(state.diagrams).forEach(([id, diagram], i) => {
          diagram.grid.forEach((row, y) => {
            row.forEach((cell, x) => {
              if (cell.symbol === toReplace) {
                state.diagrams[id].grid[y][x].symbol = replacer as Symbols;
              }
            });
          });
        });
      } else if (type === "dynamic") {
        Object.entries(state.diagrams).forEach(([id, diagram], i) => {
          Object.entries(diagram.dynamicSymbols ?? {}).forEach(
            ([key, data]) => {
              const { symbol } = data;
              if (symbol === toReplace) {
                diagram.dynamicSymbols = {
                  ...diagram.dynamicSymbols,
                  [key]: { ...data, symbol: replacer as DynamicSymbols },
                };
              }
            }
          );
        });
      }
    },
    //TODO save diagram
    removeSymbolFromDiagrams(
      state,
      action: PayloadAction<{
        type: SymbolType;
        symbolToRemove: Symbols | DynamicSymbols;
      }>
    ) {
      const { type, symbolToRemove } = action.payload;
      if (type === "single") {
        Object.entries(state.diagrams).forEach(([id, diagram], i) => {
          diagram.grid.forEach((row, y) => {
            row.forEach((cell, x) => {
              if (cell.symbol === symbolToRemove) {
                state.diagrams[id].grid[y][x].symbol = "stitch";
              }
            });
          });
        });
      } else if (type === "dynamic") {
        Object.entries(state.diagrams).forEach(([id, diagram], i) => {
          Object.entries(diagram.dynamicSymbols ?? {}).forEach(
            ([key, data]) => {
              if (data.symbol === symbolToRemove) {
                delete state.diagrams?.[id].dynamicSymbols?.[key];
              }
            }
          );
        });
      }
    },
    setPatternSymbols(
      state,
      action: PayloadAction<{ symbols: (GridSymbol | DynamicGridSymbol)[] }>
    ) {
      const { symbols } = action.payload;
      state.symbols = [...symbols];
    },
    updateKnitAlong(state, action: PayloadAction<KnitAlong>) {
      state.meta.knitAlong = action.payload;
    },
    deleteKnitAlongUpdateState(state) {
      delete state.meta.knitAlong;
    },
    putPatternElementsOnLocalPattern(state, action) {
      const { patternId, patternElements } = action.payload;

      if (patternId === state.id.toString()) {
        state.patternElements = patternElements;
      }
    },

    _setGarmentForm(state, action) {
      state.meta.form = action.payload;
    },

    _setKnitMethod(state, action) {
      state.meta.knitMethod = action.payload;
    },

    _setMeasurements(state, action) {
      state.meta.measurements = action.payload;
    },

    _setVisualRepresentationSize(state, action) {
      state.meta.visualRepresentationSize = action.payload;
    },
  },
});

export const {
  reset,
  updateSizes,
  updateTags,
  addSizeToState,
  removeSizeFromState,
  updateRoles,
  updateClothingMetaData,
  updateMeta,
  addVariant,
  setVariants,
  setDiagram,
  updateNeedles,
  addEmptyDiagram,
  addAnswer,
  resolveComment,
  acceptChange,
  setAllDiagrams,
  replaceAllSymbols,
  removeSymbolFromDiagrams,
  setPatternSymbols,
  updateKnitAlong,
  deleteKnitAlongUpdateState,
  putPatternElementsOnLocalPattern,
  _setGarmentForm,
  _setKnitMethod,
  _setMeasurements,
  _setVisualRepresentationSize,
} = patternSlice.actions;

export {
  fetchPatternById,
  setMetadata,
  setMetadataVariants,
  addMetadataVariant,
  deleteMetadataVariant,
  setVariantName,
  addVariantColor,
  setVariantColor,
  removeVariantColor,
  moveVariantColor,
  deleteColor,
  addVariantImage,
  removeVariantImage,
  setVariantsAmount,
  addSize,
  removeSize,
  setCategory,
  addSizeCategory,
  addSizeCategorySize,
  editSizeCategorySize,
  addClothingMetaData,
  setClothingMetaData,
  removeClothingMetaData,
  addRole,
  setRoleTitle,
  setRoleValue,
  deleteRole,
  addNeedle,
  setNeedle,
  removeNeedle,
  setNeedleOrder,
  setPatternElementsById,
  patternSlice,
  updateDiagramById,
  newPattern,
  addPatternComment,
  addPatternTags,
  answerPatternComment,
  resolvePatternComment,
  deleteDiagramById,
  uploadFiles,
  deleteFile,
  publishPattern,
  createKnitAlong,
  deleteKnitAlong,
  setKnitAlongSignUpDate,
  setKnitAlongStartDate,
  setKnitAlongEndDate,
  addKnitAlongHashtag,
  deleteKnitAlongHashtag,
  setGarmentForm,
  setMeasurements,
  setKnitMethod,
  setVisualRepresentationSize,
};
