import { Link } from "react-router-dom";
import styles from "./MobileMessage.module.css";
import { useTranslation } from "react-i18next";

export default function MobileMessage() {
  const { t } = useTranslation();

  return (
    <div className={styles.mobileOnly}>
      <p>{t("mobile message")}</p>
      <Link to="/landing">Tilbake</Link>
    </div>
  );
}
