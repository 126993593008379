import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../store/store";
import { addMeasure } from "../store/calculationSlice";

import Plus from "../CommonComponents/Icons/Plus";

import styles from "./NewRowBottom.module.css";
import { useTranslation } from "react-i18next";

type PropType = {
  tableId: string;
};

function NewRowBottom(props: PropType) {
  const { tableId } = props;

  const table = useSelector(
    (state: RootState) => state.calculations.tables[tableId]
  );

  const sizes = useSelector((state: RootState) => state.pattern.meta.sizes);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  return (
    <div
      className={styles.row}
      onClick={() =>
        dispatch(addMeasure({ tableId, orderIndex: table.order.length, sizes }))
      }
    >
      <div className={styles.label}>{t("calculations.new row")}...</div>
      <div className={styles.sizes}>
        {sizes.map((size) => (
          <div key={size} className={styles.sizeCell}>
            —
          </div>
        ))}
      </div>
      <div className={styles.unit}>&nbsp;</div>
      <button className={styles.newButton}>
        <Plus />
      </button>
    </div>
  );
}

export default NewRowBottom;
