export const backendApiAddress = (() => {
  if (window.location.hostname === "localhost") {
    // return "http://localhost:2795";
    return "https://staging.woolit.no";
  }
  if (window.location.hostname.endsWith("snap0.woolit-designer.iterate.iterapp.no")) {
    return "https://staging.woolit.no";
  }
  if (window.location.hostname.endsWith(".dev.woolit.no")) {
    return "https://staging.woolit.no";
  }
  if (window.location.hostname.endsWith(".test.iterate.no")) {
    return "https://staging.woolit.no";
  }
  if (window.location.hostname.endsWith("testdesign.woolit.no")) {
    return "https://staging.woolit.no";
  }
  if (window.location.hostname.endsWith(".app.woolit.no")) {
    return "https://woolit.no";
  }
  if (window.location.hostname.endsWith("design.woolit.no")) {
    return "https://woolit.no";
  }
  return "https://woolit.no";
})();
