import Button from "../ui/Button/Button";
import FacebookButton from "../ui/Button/FacebookButton";
import Input from "../ui/Input/Input";

import React, { useState } from "react";
import { Controller, FieldError, useForm } from "react-hook-form";
import { RegisterUserCredentials, RegisterUserSchema } from "../../types/auth";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

type Props = {
  onSubmit: (registerCredentials: RegisterUserCredentials) => void;
};

type RegisterFieldErrors = {
  first_name?: FieldError | undefined;
  last_name?: FieldError | undefined;
  email?: FieldError | undefined;
  phone?: FieldError | undefined;
  password?: FieldError | undefined;
  confirmPassword?: FieldError | undefined;
  newsletter?: FieldError | undefined;
};

const RegisterForm: React.FC<React.PropsWithChildren<Props>> = ({
  onSubmit,
}) => {
  const { register, handleSubmit, control, clearErrors } =
    useForm<RegisterUserCredentials>({
      reValidateMode: "onSubmit",
      resolver: yupResolver(RegisterUserSchema),
    });

  const [errors, setErrors] = useState<RegisterFieldErrors>();

  const handleValid = (credentials: RegisterUserCredentials) =>
    onSubmit(credentials);

  const handleInvalid = (fieldErrors: RegisterFieldErrors) =>
    setErrors(fieldErrors);

  const loginState = useSelector((state: RootState) => state.loginState);

  const { t } = useTranslation();

  return (
    <div>
      <p style={{ textAlign: "center", marginBottom: "2.5rem" }}>
        {t("registerToWoolit")}
      </p>
      <form
        onSubmit={handleSubmit(handleValid, handleInvalid)}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          rowGap: "0.75rem",
        }}
      >
        <div
          style={{
            display: "grid",
            width: "100%",
            gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
            columnGap: "1rem",
          }}
        >
          <Controller
            control={control}
            name="first_name"
            render={({ field: { value, onChange, name } }) => (
              <Input
                autoComplete="given-name"
                value={value}
                onChange={(e) => {
                  onChange(e);
                  clearErrors(name);
                }}
                name={name}
                error={errors?.first_name?.message}
                type="text"
                label={t("form.given-name")}
              />
            )}
          />
          <Controller
            control={control}
            name="last_name"
            render={({ field: { value, onChange, name } }) => (
              <Input
                autoComplete="family-name"
                value={value}
                onChange={(e) => {
                  onChange(e);
                  clearErrors(name);
                }}
                name={name}
                error={errors?.last_name?.message}
                type="text"
                label={t("form.family-name")}
              />
            )}
          />
        </div>
        <Controller
          control={control}
          name="email"
          render={({ field: { value, onChange, name } }) => (
            <Input
              autoComplete="email"
              className="w-full"
              value={value}
              onChange={(e) => {
                onChange(e);
                clearErrors(name);
              }}
              name={name}
              error={errors?.email?.message}
              label={t("email")}
            />
          )}
        />
        <Controller
          control={control}
          name="phone"
          render={({ field: { value, onChange, name } }) => (
            <Input
              autoComplete="tel-national"
              className="w-full"
              value={value}
              onChange={(e) => {
                onChange(e);
                clearErrors(name);
              }}
              name={name}
              error={errors?.phone?.message}
              type="number"
              label={t("form.phonenr")}
            />
          )}
        />
        <Controller
          control={control}
          name="password"
          render={({ field: { value, onChange, name } }) => (
            <Input
              autoComplete="new-password"
              className="w-full"
              value={value}
              onChange={(e) => {
                onChange(e);
                clearErrors(name);
              }}
              name={name}
              error={errors?.password?.message}
              type="password"
              label={t("password")}
            />
          )}
        />
        <Controller
          control={control}
          name="confirmPassword"
          render={({ field: { value, onChange, name } }) => (
            <Input
              autoComplete="new-password"
              className="w-full"
              value={value}
              onChange={(e) => {
                onChange(e);
                clearErrors(name);
              }}
              name={name}
              error={errors?.password?.message}
              type="password"
              label={t("confirmPassword")}
            />
          )}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
        >
          <input type="checkbox" {...register("newsletter")} />
          <label>{t("newsletter")}</label>
        </div>
        {loginState.loginFailed && (
          <div>
            <p>
              Kunne ikke registere bruker. Har du allerede en bruker på Woolit?
            </p>
          </div>
        )}
        <Button
          type="submit"
          style={{ marginTop: "0.25rem", width: "100%" }}
          loading={loginState.loading}
        >
          {t("registerUser")}
        </Button>
      </form>
      <div
        style={{
          display: "flex",
          marginTop: "1.5rem",
          marginBottom: "1.5rem",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "1px",
        }}
      >
        <span
          style={{
            paddingLeft: "0.5rem",
            paddingRight: "0.5rem",
            backgroundColor: "#ffffff",
          }}
        >
          {t("or")}
        </span>
      </div>
      <FacebookButton />
    </div>
  );
};

export default RegisterForm;
