import { EyeOpenIcon } from "@radix-ui/react-icons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { RootState } from "../store/store";
import EditNavbar from "./EditNavbar";
import { MenuDrawerButton } from "./MenuDrawerButton";
import styles from "./Top.module.css";
import { setMetadata } from "../store/patternSlice";
import Button from "../ISO/Components/Button";
import i18n from "../i18n/i18n";

interface PropType {
  transparent?: boolean;
}

const Top: React.FC<PropType> = (props) => {
  const { t } = useTranslation();
  const { id, meta } = useSelector((state: RootState) => state.pattern);

  const { pathname } = useLocation();
  const [, , , mode] = pathname.split("/");
  const { grid } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();

  const [patternNameInput, setPatternNameInput] = useState("");

  useEffect(() => {
    setPatternNameInput(meta.title);
  }, [meta.title]);

  return (
    <nav className={`${styles.nav} ${props.transparent ? "" : styles.filled}`}>
      <div className={styles.wrapper}>
        <div className={styles.top}>
          <div className={styles.menu}>
            <MenuDrawerButton />
            <div className={styles.vl} />
            <div>
              <input
                className={styles.updated}
                value={patternNameInput}
                onChange={(e) => setPatternNameInput(e.target.value)}
                onBlur={() => {
                  dispatch(setMetadata({ title: patternNameInput }));
                }}
                style={{
                  fontSize: "17px",
                  padding: "0px",
                  width: "200px",
                  pointerEvents: "auto",
                  backgroundColor: "#00000000",
                }}
              />
              {grid.selectedView !== "grid" && (
                <p
                  style={{
                    position: "absolute",
                    fontSize: "12px",
                  }}
                >
                  {t("draw.grid")}
                </p>
              )}
            </div>
          </div>
          <EditNavbar />
          <div className={styles.preview}>
            <Button
              style={{
                border: "none",
                fontWeight: i18n.language === "no" ? 600 : 400,
              }}
              onClick={() => {
                i18n.changeLanguage("no");
              }}
            >
              Norsk
            </Button>
            |
            <Button
              style={{
                border: "none",
                fontWeight: i18n.language === "en" ? 600 : 400,
              }}
              onClick={() => {
                i18n.changeLanguage("en");
              }}
            >
              English
            </Button>
            |
            <Button
              style={{
                border: "none",
                fontWeight: i18n.language === "de" ? 600 : 400,
              }}
              onClick={() => {
                i18n.changeLanguage("de");
              }}
            >
              Deutsch
            </Button>
            {mode === "write" && (
              <Link to={`/pattern/${id}`}>
                <button>
                  <EyeOpenIcon aria-label="Preview" />
                </button>
              </Link>
            )}
          </div>
        </div>
      </div>
      {props.children}
    </nav>
  );
};

export default Top;
