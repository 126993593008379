import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router";
import { GlobalContent } from "../CommonComponents/GlobalContent";
import { Loading } from "../CommonComponents/Loading";
import { RootState } from "../store/store";
import { useMeQuery } from "../store/woolitApi";

function PrivateRoute({ children }: any) {
  let auth = useSelector((state: RootState) => state.loginState);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const { data: user } = useMeQuery();

  if (!user) {
    if (auth.loginFailed) {
      return <Navigate to={"/landing?s=loginfailed"} />;
    } else if (auth.checkedUserLogin && !auth.loading) {
      return <Navigate to={"/landing"} />;
    } else {
      return <Loading />;
    }
  } else {
    if (user.subscriber) {
      return (
        <>
          <GlobalContent />
          {children}
        </>
      );
    } else {
      const onboardingState = query.get("state");
      if (onboardingState) {
        return <Navigate to={`/landing?onboarding=${onboardingState}`} />;
      } else {
        return <Navigate to={"/landing?s=noaccess"} />;
      }
    }
  }
}

export default PrivateRoute;
