import { useState } from "react";
import styles from "../Styles/Sections.module.css";
import woolitIcon from "../../Visualizing/LandingPage/woolitIcon.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LogoutButton from "../../Login/LogoutButton";
import { ArrowRightIcon } from "@radix-ui/react-icons";
import SidebarMenu from "./SidebarMenu";
import { HamburgerMenuIcon } from "@radix-ui/react-icons";
import { useMeQuery } from "../../store/woolitApi";

function LoginButton() {
  const { data: user } = useMeQuery();

  const { t } = useTranslation();

  if (user && !user.subscriber) {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        {t("logged in as")} {user.firstName}{" "}
        <div style={{ marginLeft: "1rem", marginRight: "1rem" }}>
          <LogoutButton></LogoutButton>
        </div>
      </div>
    );
  } else if (user && user.subscriber) {
    return (
      <div style={{ display: "flex" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {t("landing.logged in as")} {user.firstName}{" "}
        </div>
        <Link className={styles.goToWDSLink} to="/projects">
          {t("goToWDS")}
          <ArrowRightIcon
            color={"#3D8036"}
            style={{ alignSelf: "self-end", marginBottom: "-3px" }}
          ></ArrowRightIcon>
        </Link>
        <LogoutButton></LogoutButton>
      </div>
    );
  }

  return (
    <Link to="/login">
      <button className={styles.loginBtn}>{t("login")}</button>
    </Link>
  );
}

function NavigationSection() {
  const [sidebarMenuOpen, setSidebarMenuOpen] = useState(false);

  const { t } = useTranslation();
  return (
    <nav
      style={{ display: "flex", alignItems: "center", paddingRight: "10px" }}
    >
      <div
        style={{
          width: "60%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <SidebarMenu
          open={sidebarMenuOpen}
          onClose={() => setSidebarMenuOpen(false)}
        />

        <button
          onClick={() => setSidebarMenuOpen(true)}
          className={styles.sidebar}
        >
          <HamburgerMenuIcon height={25} width={25} />
        </button>

        <div className={styles.navLinkContainer}>
          <Link to="https://shop.woolit.no" className={styles.navButton}>
            {t("landing.designs")}
          </Link>
          <Link
            to="https://shop.woolit.no/product/yarn"
            className={styles.navButton}
          >
            {t("landing.yarn")}
          </Link>
          <Link
            to="https://shop.woolit.no/product/needle"
            className={styles.navButton}
          >
            {t("landing.needles")}
          </Link>
          <Link to="/" className={styles.navButton}>
            {t("landing.wds")}
          </Link>
        </div>
        <Link to={"/"}>
          <img
            src={woolitIcon}
            className={styles.woolitIcon}
            alt="Woolit ikon"
          ></img>
        </Link>
      </div>
      <div
        style={{ width: "50%", display: "flex", justifyContent: "flex-end" }}
      >
        <LoginButton />
      </div>
    </nav>
  );
}

export default NavigationSection;
