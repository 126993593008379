import { GridCell } from "../../store/pattern";

export const gridCellsStartStops = (
  row: GridCell[]
): { start: number; stop: number }[] => {
  const startStops = row.reduce((result: { start: number;  stop: number}[], currentCell, index, array) => {
    const previousColor = index > 0 ? array[index - 1].color : null;

    if (previousColor === -1 && currentCell.color !== -1) {
      result.push({ start: index, stop: array.length });
    } else if (previousColor === null && currentCell.color !== -1) {
      result.push({ start: index, stop: array.length });
    } else if (previousColor !== -1 && currentCell.color === -1 && result.length > 0) {
      result[result.length - 1].stop = index;
    }

    return result;
  }, []);



  return startStops;
}

export const findStartStops = (
  previous: { start: number, stop: number }[],
  next: { start: number, stop: number }[]
): { start: number; stop: number }[] => {
  const startStops: { start: number; stop: number }[] = [];

  if (previous.length === 0) {
    return next;
  }

  if (next.length === 0) {
    return previous;
  }

  previous.forEach((p) => {
    next.forEach((n) => {
      // next | -XXX-|
      // prev | -XXX-|
      if (p.start === n.start
        && p.stop === n.stop) {
        startStops.push(p);
        // next | --X--|
        // prev | -XXX-|
      } else if (p.start < n.start
        && p.stop > n.stop) {
        startStops.push(p);
        // next | -XXX-|
        // prev | --X--|
      } else if (n.start < p.start
        && n.stop > p.stop) {
        startStops.push(n);
        // next | --XX-|
        // prev | -XX--|
      } else if (p.start <= n.start
        && p.stop > n.start
        && n.stop >= p.stop) {
        startStops.push({ start: p.start, stop: n.stop });
        // next | -XX--|
        // prev | --XX-|
      } else if (n.start <= p.start
        && n.stop > p.start
        && p.stop >= n.stop) {
        startStops.push({ start: n.start, stop: p.stop });
      }
    })
  })

  return startStops;
}

export const gridCellsStartStopsBetweenRows = (
  nextRow: GridCell[] = [],
  previousRow: GridCell[] = []
): { start: number; stop: number }[] => {
  const previous =
    gridCellsStartStops(previousRow);
  const next = gridCellsStartStops(nextRow);

  return findStartStops(previous, next);
};

const gridColumnStartStop = (
  grid: GridCell[][],
  colIndex: number
): { start: number; stop: number }[] => {
  const column = grid.map((row) => row[colIndex]).filter((cell) => cell);
  return gridCellsStartStops(column);
};

export const gridCellsStartStopBetweenColumns = (
  grid: GridCell[][],
  colIndex: number
): { start: number; stop: number }[] => {
  const previous = gridColumnStartStop(
    grid,
    colIndex - 1
  );

  const next = gridColumnStartStop(
    grid,
    colIndex
  );

  return findStartStops(previous, next);
};
