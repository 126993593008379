import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { enablePatches } from "immer";
import loginState from "../Login/LoginState";
import { patternSlice } from "./patternSlice";
import { displayStateSlice } from "../ISO/displayState";
import { writerSlice } from "./writerSlice";
import { adminSlice } from "./adminSlice";
import { colorSlice } from "./colorSlice";
import { gridSlice } from "./gridSlice";
import { calculationSlice } from "./calculationSlice";
import { dragSelectSlice } from "./dragSelectSlice";
import { useDispatch } from "react-redux";
import { modelSlice } from "./modelSlice";
import { woolitApi } from "./woolitApi";

enablePatches();

const rootReducer = combineReducers({
  [woolitApi.reducerPath]: woolitApi.reducer,
  pattern: patternSlice.reducer,
  writer: writerSlice.reducer,
  loginState: loginState.reducer,
  displayState: displayStateSlice.reducer,
  admin: adminSlice.reducer,
  color: colorSlice.reducer,
  grid: gridSlice.reducer,
  calculations: calculationSlice.reducer,
  dragSelect: dragSelectSlice.reducer,
  model: modelSlice.reducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(woolitApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

export default store;

export { rootReducer };

export type RootState = ReturnType<typeof rootReducer>;
