import React from "react";
import { useTranslation } from "react-i18next";
import { useLogoutMutation } from "../store/woolitApi";

function LogoutButton() {
  let [logout] = useLogoutMutation();
  const { t } = useTranslation();
  return (
    <button
      onClick={() => logout()}
      style={{
        maxWidth: "102px",
        background: "var(--lightGreen)",
        borderRadius: "4px",
        border: "none",
        color: "var(--darkest-brown)",
        margin: "1px",
      }}
    >
      {t("logout")}
    </button>
  );
}

export default LogoutButton;
