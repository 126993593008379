import React from "react";
import styles from "../Styles/Sections.module.css";
import norway_chess from "../../Visualizing/LandingPage/norway_chess.png";
import { Link } from "react-router-dom";
import { ArrowRightIcon } from "@radix-ui/react-icons";
import { useTranslation } from "react-i18next";

function EndSection() {
  const { t } = useTranslation();
  return (
    <div className={styles.endSection}>
      <div className={styles.pictureContainer}>
        <img
          style={{ borderRadius: "4px", width: "100%" }}
          src={norway_chess}
          alt="Illustrasjon av Woolit Design Studio Pro"
        />

        <div
          style={{
            position: "absolute",
            bottom: "5%",
            right: "30%",
            left: "18px",
          }}
        >
          <div style={{ color: "white", fontSize: "20px", fontWeight: 500 }}>
            {t("landing.as seen on Norway Chess")}!
          </div>
          <div style={{ color: "white" }}>
            {t("landing.chess grandmasters design sweater")}!
          </div>
        </div>
      </div>

      <div className={styles.endSectionContent}>
        <div
          style={{
            fontSize: "16px",
            color: "#7E6A5B",
            marginBottom: "2%",
            fontFamily: "forma-djr-display",
          }}
        >
          Woolit Design Studio
        </div>

        <div
          style={{
            fontSize: "30px",
            color: "black",
            marginBottom: "2%",
            fontFamily: "forma-djr-display",
          }}
        >
          {t("landing.design free for private use")}!
        </div>

        <div
          style={{
            fontSize: "16px",
            color: "black",
            marginBottom: "2%",
            fontFamily: "forma-djr-display",
          }}
        >
          {t("landing.we have wds3D")}
        </div>

        <Link
          style={{ color: "#33251a", cursor: "pointer", marginTop: "2%" }}
          to="https://3d.woolit.no/"
        >
          <div
            style={{
              fontSize: "18px",
              color: "var(--contrast)",
              fontFamily: "forma-djr-display",
            }}
          >
            {t("goToWDS3D")}
            <p>
              <ArrowRightIcon style={{ marginBottom: "-3px" }}></ArrowRightIcon>
            </p>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default EndSection;
