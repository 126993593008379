import { useTranslation } from "react-i18next";
import styles from "./Roles.module.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import Select from "../../CommonComponents/Select";
import { setVisualRepresentationSize } from "../../store/patternSlice";

export const Design = () => {
  const { t } = useTranslation();
  const { sizes } = useSelector((state: RootState) => state.pattern.meta);
  const { visualRepresentationSize } = useSelector(
    (state: RootState) => state.pattern.meta
  );
  const dispatch = useDispatch();
  return (
    <div className={styles.roles}>
      <div
        className={styles.role}
        style={{
          marginBottom: "-15px",
        }}
      >
        <input
          style={{
            flexBasis: "50%",
          }}
          className={styles.roleTitle}
          disabled
          value={t(`datadrawer.visual representation`)}
        />
        <div
          style={{
            flexBasis: "50%",
          }}
        >
          <Select
            options={sizes.map((needleType) => ({
              value: needleType,
              label:
                needleType.charAt(0).toUpperCase() + needleType.substring(1),
            }))}
            value={{
              value: visualRepresentationSize,
              label:
                visualRepresentationSize.charAt(0).toUpperCase() +
                visualRepresentationSize.substring(1),
            }}
            onChange={(e: any) => {
              const newValue = e.value;
              if (visualRepresentationSize !== newValue) {
                if (
                  window.confirm(t("datadrawer.visual representation warning"))
                ) {
                  dispatch(setVisualRepresentationSize(newValue));
                }
              }
            }}
            isSearchable={false}
          />
        </div>
        <button
          className={styles.removeItem}
          style={{
            display: "flex",
            border: "none",
            pointerEvents: "none",
            opacity: 0,
          }}
          disabled
        >
          <p
            style={{
              margin: "auto",
            }}
          >
            cm
          </p>
        </button>
      </div>
    </div>
  );
};
