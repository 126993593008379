import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";

import { addVariantColor } from "./store/patternSlice";
import { RootState } from "./store/store";
import styles from "./Intro.module.css";
import "./Intro.module.css";
import introJS from "intro.js";
import { setDisableHotkeys } from "./store/gridSlice";
import {
  openSubdrawer,
  setDisableWriterHotkeys,
  toggleDrawer,
} from "./store/writerSlice";
import { setDisable } from "./store/dragSelectSlice";
import { useOrganizationYarnQuery } from "./services/yarnApi";
import { colorFromApiColor } from "./Metadata/Components/YarnSelectorColor";
import { TFunction, useTranslation } from "react-i18next";
import { useMeQuery, useSetTutorialSeenMutation } from "./store/woolitApi";

const generateWriteSteps = (t: TFunction<"translation", undefined>) => {
  const toolToText = {
    color: t("intro.color more"),
    cursor: t("intro.cursor more"),
    eraser: t("intro.eraser more"),
  };

  const woolitCreatorWriteSteps = [
    {
      highlightClass: JSON.stringify({
        element: "intro-write",
        position: "bottom",
      }),
      title: t("intro.writing") + " 1 of 2",
      intro: t("intro.writing more"),
    },
    {
      highlightClass: JSON.stringify({
        element: "intro-write",
        position: "bottom",
      }),
      title: t("intro.writing") + " 2 of 2",
      intro: t("intro.writing shortcut menu more"),
    },
    {
      highlightClass: JSON.stringify({
        element: "intro-information",
        position: "bottom",
      }),
      title: t("information") + " 1 of 5",
      intro: t("intro.information access"),
    },
    {
      highlightClass: JSON.stringify({
        action: "open information",
        element: "intro-information2",
        position: "right",
      }),
      title: t("information") + " 2 of 5",
      intro: t("intro.information set info"),
    },
    {
      highlightClass: JSON.stringify({
        action: "new color",
        element: "intro-information3",
        position: "right",
      }),
      title: t("information") + " 3 of 5",
      intro: t("intro.information color"),
    },
    {
      highlightClass: JSON.stringify({
        element: "intro-information4",
        position: "top",
      }),
      title: t("information") + " 4 of 5",
      intro: t("intro.information color variant"),
    },
    {
      highlightClass: JSON.stringify({
        action: "add colors",
        element: "intro-information5",
        position: "right",
      }),
      title: t("information") + " 5 of 5",
      intro: t("intro.information color added"),
    },
    {
      highlightClass: JSON.stringify({
        action: "close information",
        element: "intro-draw",
        position: "right",
      }),
      title: t("intro.drawing"),
      intro: t("intro.drawing access"),
    },
  ];
  const woolitCreatorSweaterSteps = [
    {
      tooltipClass: styles.big,
      highlightClass: JSON.stringify({
        element: `intro-information`,
        position: "bottom",
      }),
      title: t("intro.show sweater enable"),
      intro: `<img src="/intro_show_sweater.gif" width="500px">`,
    },
  ];

  const woolitCreatorFinishSteps = [
    {
      title: t("intro.finish"),
      intro: t("intro.good luck"),
    },
  ];

  return [
    {
      tooltipClass: styles.big,
      title: t("intro.welcome"),
      intro: `<p style='height:100px;padding-top:5px'>${t(
        "intro.quick rundown"
      )}</p>`,
    },
    ...woolitCreatorWriteSteps,
    {
      highlightClass: JSON.stringify({
        action: "open drawing",
        element: "intro-draw2",
        position: "bottom",
      }),
      title: t("intro.new diagram"),
      intro: t("intro.new diagram more"),
    },
    {
      highlightClass: JSON.stringify({
        action: "new diagram",
        element: "intro-draw3",
        position: "left",
      }),
      title: t("intro.diagram info"),
      intro: t("intro.diagram info more"),
    },
    {
      highlightClass: JSON.stringify({
        action: "create diagram",
        element: "intro-draw5",
        position: "top",
      }),
      title: t("intro.diagram preview"),
      intro: t("intro.diagram preview more"),
    },
    {
      highlightClass: JSON.stringify({
        element: "intro-draw6",
        position: "bottom",
      }),
      title: "Diagram",
      intro: t("intro.diagram more"),
    },
    ...["color", "cursor", "eraser"].map((tool: string) => ({
      tooltipClass: styles.big,
      highlightClass: JSON.stringify({
        element: `intro-${tool}`,
        position: "left",
      }),
      title: t("intro.drawing " + tool),
      intro: `${
        toolToText[tool as "color" | "cursor" | "eraser"]
      }<img src="/intro_${tool}.gif" width="500px">`,
    })),
    {
      tooltipClass: styles.big,
      highlightClass: JSON.stringify({
        element: `intro-knit-sample`,
        position: "top-left",
      }),
      title: t("intro.knit sample showcase"),
      intro: `<img src="/intro_knit_sample.gif" width="500px">`,
    },
    ...woolitCreatorSweaterSteps,
    {
      highlightClass: JSON.stringify({
        element: "intro-delete",
        position: "top-left",
      }),
      title: t("intro.deleting"),
      intro: t("intro.deleting more"),
    },
    {
      highlightClass: JSON.stringify({
        element: "intro-library",
        position: "bottom-left",
      }),
      title: t("intro.library"),
      intro: t("intro.library more"),
    },
    ...woolitCreatorFinishSteps,
  ];
};

const generateDrawSteps = (t: TFunction<"translation", undefined>) => {
  const toolToText = {
    color: t("intro.color more"),
    cursor: t("intro.cursor more"),
    eraser: t("intro.eraser more"),
  };

  const woolitDiagramFinishSteps = [
    {
      tooltipClass: styles.biggest,
      title: t("intro.finish"),
      intro: `<p>${t("intro.good luck")}</p> <p>${t(
        "intro.more features"
      )} <a href="https://design.woolit.no/landing" style="color: var(--contrast)">Woolit Creator</a>. ${t(
        "intro.subscription info"
      )}</p> <div style="display:flex;gap:30px;"><img src="/intro_creator.gif" width="500px"><img src="/intro_show_sweater.gif" width="500px"></div>`,
    },
  ];

  return [
    {
      tooltipClass: styles.big,
      title: t("intro.welcome"),
      intro: `<p style='height:100px;padding-top:5px'>${t(
        "intro.quick rundown"
      )}</p>`,
    },
    {
      highlightClass: JSON.stringify({
        action: "open drawing",
        element: "intro-draw2",
        position: "bottom",
      }),
      title: t("intro.new diagram"),
      intro: t("intro.new diagram more"),
    },
    {
      highlightClass: JSON.stringify({
        action: "new diagram",
        element: "intro-draw3",
        position: "left",
      }),
      title: t("intro.diagram info"),
      intro: t("intro.diagram info more"),
    },
    {
      highlightClass: JSON.stringify({
        action: "create diagram",
        element: "intro-draw5",
        position: "top",
      }),
      title: t("intro.diagram preview"),
      intro: t("intro.diagram preview more"),
    },
    {
      highlightClass: JSON.stringify({
        element: "intro-draw6",
        position: "bottom",
      }),
      title: "Diagram",
      intro: t("intro.diagram more"),
    },
    ...["color", "cursor", "eraser"].map((tool: string) => ({
      tooltipClass: styles.big,
      highlightClass: JSON.stringify({
        element: `intro-${tool}`,
        position: "left",
      }),
      title: t("intro.drawing " + tool),
      intro: `${
        toolToText[tool as "color" | "cursor" | "eraser"]
      }<img src="/intro_${tool}.gif" width="500px">`,
    })),
    {
      tooltipClass: styles.big,
      highlightClass: JSON.stringify({
        element: `intro-knit-sample`,
        position: "top-left",
      }),
      title: t("intro.knit sample showcase"),
      intro: `<img src="/intro_knit_sample.gif" width="500px">`,
    },
    ...woolitDiagramFinishSteps,
  ];
};

export default function Intro(props: { tutorialName: "write" | "draw" }) {
  const navigate = useNavigate();

  const [setTutorialSeen] = useSetTutorialSeenMutation();

  const [introStarted, setIntroStarted] = useState(false);

  const { variants, organizationId } = useSelector((state: RootState) => ({
    variants: state.pattern.meta.variants,
    organizationId: state.pattern.organizationId,
  }));

  const { data: yarn } = useOrganizationYarnQuery(organizationId);

  const dispatch = useDispatch();

  const { pathname } = useLocation();
  const [, edit, patternId] = pathname.split("/");

  const { t } = useTranslation();

  const { data: user } = useMeQuery();

  useEffect(() => {
    if (introStarted) return;
    if (variants.length === 0) return;
    if (yarn === undefined) return;
    if (user?.id === -1) return;
    // Must wait until user is loaded to check
    // If the block overlay should be gone or not

    setIntroStarted(true);

    if (user?.tutorial !== props.tutorialName) {
      return;
    }

    setTutorialSeen(props.tutorialName);
    startIntro(props.tutorialName);
  }, [variants, yarn]);

  function startIntro(tutorialName: "write" | "draw") {
    const filteredYarn = Object.values(yarn!)
      .map((yarn) => ({
        ...yarn,
        colors: yarn.colors,
      }))
      .filter((yarn) => yarn.colors.length !== 0)
      .sort((a, b) => {
        return a.name > b.name ? 1 : -1;
      });

    const intro = introJS();

    switch (tutorialName) {
      case "write":
        intro.addSteps(generateWriteSteps(t));
        break;
      case "draw":
        intro.addSteps(generateDrawSteps(t));
        break;
    }

    const actions: { [key: string]: () => void } = {
      "open information": () => {
        dispatch(setDisableHotkeys(true));
        dispatch(toggleDrawer());
        dispatch(setDisable(true));
      },
      "close information": () => {
        dispatch(setDisableHotkeys(false));
        dispatch(toggleDrawer());
        dispatch(setDisable(false));
        dispatch(setDisableWriterHotkeys(false));
      },
      "open drawing": () => {
        navigate(`/${edit}/${patternId}/draw`, { replace: true });
      },
      "new color": () => {
        dispatch(
          openSubdrawer({
            content: { type: "variant", id: variants[0].id },
          })
        );
      },
      "add colors": () => {
        for (let index of [0, 1]) {
          dispatch(
            addVariantColor({
              variantId: variants[0].id,
              color: colorFromApiColor(
                filteredYarn[0].colors[index],
                filteredYarn[0]
              ),
            })
          );
        }
      },
      "new diagram": () => {
        document.getElementById("intro-draw2")?.click();
      },
      "create diagram": () => {
        document.getElementById("intro-draw4")?.click();
      },
    };
    // Using 'element' or 'position' doesn't work if the element
    // is loaded dynamically. Therefore, just store them in
    // 'highlightClass' and do that work myself.
    const hidePrevButton = () => {
      const prevbutton = document.getElementsByClassName("introjs-prevbutton");
      if (prevbutton.length > 0) {
        (prevbutton[0] as HTMLDivElement).style.opacity = "0";
        (prevbutton[0] as HTMLDivElement).style.pointerEvents = "none";
        (prevbutton[0] as HTMLDivElement).tabIndex = -1;
      }
    };
    const disableBullets = () => {
      const bullets = document.getElementsByClassName("introjs-bullets");
      if (bullets.length > 0) {
        const presentations = bullets[0].children[0].children;
        for (let n = 0; n < presentations.length; n++) {
          const bullet = presentations[n].children[0];
          (bullet as HTMLDivElement).style.pointerEvents = "none";
          (bullet as HTMLDivElement).tabIndex = -1;
        }
      }
    };
    intro.onbeforechange(() => {
      const currentStep = intro._introItems[intro._currentStep];
      hidePrevButton();
      disableBullets();
      const json = currentStep.highlightClass;
      if (json === undefined) return true;
      const { element, position, action } = JSON.parse(json);
      action && actions[action]();
      if (!element) return true;
      currentStep.element = document.getElementById(element);
      currentStep.position = position;
      return true;
    });

    intro.setOptions({
      disableInteraction: false,
      exitOnOverlayClick: false,
      scrollToElement: true,
      hidePrev: true,
    });
    intro.start().then(() => {
      disableBullets();
    });
  }

  return <></>;
}
