import { useEffect, useRef, useState } from "react";
import { Util } from "../static/util";
import styles from "../../Metadata/Components/Roles.module.css";

function NumberInput(props: {
  input: any;
  setInput: any;
  min: any;
  max: any;
  onBlur: any;
}) {
  const input = useRef(null);
  function parseClamp(numberText: string) {
    let number = parseInt(numberText);
    if (numberText === "" || isNaN(number)) {
      number = 0;
    }
    return Util.clamp(number, props.min, props.max);
  }
  function blur() {
    (input!.current! as HTMLInputElement).blur();
  }
  const [inputRaw, setInputRaw] = useState<string | number>(props.input || 0);
  const [manualChange, setManualChange] = useState<boolean>(false);

  useEffect(() => {
    if (!manualChange) {
      setInputRaw(props.input);
    }
    setManualChange(false);
  }, [props.input]);
  return (
    <input
      className={styles.roleValue}
      type={"text"}
      ref={input}
      value={inputRaw}
      style={{
        outlineColor: "" + inputRaw !== "" + props.input ? "red" : "#bdd1b9",
        width: "0px", // no minimum size
        fontSize: "12px",
        textAlign: "center",
      }}
      onChange={(e) => {
        const text = e.target.value.replace(/[^0-9/-]/g, ``);
        setInputRaw(text);
        if (props.input !== parseClamp(text)) {
          props.setInput(parseClamp(text));
          setManualChange(true);
        }
      }}
      onBlur={() => {
        setInputRaw(parseClamp(inputRaw + ""));
        props.onBlur();
      }}
      onKeyDown={(e) => {
        if (["e", "E", "+"].includes(e.key)) {
          e.preventDefault();
          return;
        }
        if (e.key === "Enter") {
          blur();
        }
      }}
    ></input>
  );
}

export default NumberInput;
