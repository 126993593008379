import { SweaterPart } from "../SweaterPart";
import { SweaterPartArea } from "../enums";
import { Util } from "../static/util";

export class Isydd {
    shirt = {
      "Left Arm": [
          762.4473684210527,
          747,
          1284.5526315789473,
          1821
      ],
      "Right Arm": [
          2810.4473684210525,
          747,
          3332.5526315789475,
          1821
      ],
      "Collar": [
          1120.6842105263158,
          39,
          2973.315789473684,
          132
      ],
      "Back Torso": [
          619.2894736842105,
          2652,
          1427.7105263157896,
          3706
      ],
      "Front Torso": [
          2667.2894736842104,
          2652,
          3475.7105263157896,
          3706
      ]
  } as const

    parts() {
        let leftArm = new SweaterPart(
          "Left Arm",
          SweaterPartArea.LeftArm,
          Util.makeGrid(512),
          ...(this.shirt["Left Arm"]),
          1024
        );
        let rightArm = new SweaterPart(
          "Right Arm",
          SweaterPartArea.RightArm,
          Util.makeGrid(512),
          ...(this.shirt["Right Arm"]),
          1024
        );
        let back = new SweaterPart(
          "Back Torso",
          SweaterPartArea.BackTorso,
          Util.makeGrid(512),
          ...(this.shirt["Back Torso"]),
          3072
        );
    
        let front = new SweaterPart(
          "Front Torso",
          SweaterPartArea.FrontTorso,
          Util.makeGrid(512),
          ...(this.shirt["Front Torso"]),
          3072
        );
    
        let collar = new SweaterPart(
          "Collar",
          SweaterPartArea.Collar,
          Util.makeGrid(512),
          ...(this.shirt["Collar"]),
          -1,
          true //is_inverted
        );
  
        const sweaterParts = [front, back, leftArm, rightArm, collar]
        sweaterParts.forEach((v: SweaterPart, i: number) => v.index = i)
        return sweaterParts
      }
}