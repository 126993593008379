import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Controller, FieldError, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Button from "../ui/Button/Button";
import FacebookButton from "../ui/Button/FacebookButton";
import { LoginUserCredentials, LoginUserSchema } from "../../types/auth";
import Input from "../ui/Input/Input";
import { useTranslation } from "react-i18next";
import styles from "../../Login.module.css";
import { RootState } from "../../../store/store";

type Props = {
  onSubmit: (loginCredentials: LoginUserCredentials) => void;
};

type LoginFieldErrors = {
  email?: FieldError | undefined;
  password?: FieldError | undefined;
};

const LoginForm: React.FC<React.PropsWithChildren<Props>> = ({ onSubmit }) => {
  const { handleSubmit, control } = useForm<LoginUserCredentials>({
    resolver: yupResolver(LoginUserSchema),
  });

  const [errors, setErrors] = useState<LoginFieldErrors>();

  const handleValid = (credentials: LoginUserCredentials) =>
    onSubmit(credentials);

  const handleInvalid = (fieldErrors: LoginFieldErrors) =>
    setErrors(fieldErrors);

  const loginState = useSelector((state: RootState) => state.loginState);

  const { t } = useTranslation();

  return (
    <div className={styles.form}>
      <p
        style={{ textAlign: "center", fontSize: "20px", marginBottom: "20px" }}
      >
        {t("loginWoolit")}
      </p>
      <form
        onSubmit={handleSubmit(handleValid, handleInvalid)}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          rowGap: "1.75rem",
        }}
      >
        <Controller
          control={control}
          name="email"
          render={({ field: { value, onChange, name } }) => (
            <Input
              value={value}
              onChange={onChange}
              name={name}
              error={errors?.email?.message}
              label={t("email")}
            />
          )}
        />
        <Controller
          control={control}
          name="password"
          render={({ field: { value, onChange, name } }) => (
            <Input
              value={value}
              onChange={onChange}
              name={name}
              error={errors?.password?.message}
              type="password"
              label={t("password")}
            />
          )}
        />
        {loginState.loginAttempted && loginState.loginFailed && (
          <div>
            <p>
              Vi kunne ikke logge deg inn. Kan du dobbeltsjekke at brukernavnet
              og passordet stemmer?
            </p>
            <p>
              Du kan lage nytt passord{" "}
              <a
                target="_blank"
                href="https://woolit.no/oppdrag"
                rel="noreferrer"
              >
                her
              </a>{" "}
              (trykk på "Jeg har ikke passord").
            </p>
          </div>
        )}
        <Button
          type="submit"
          style={{ marginTop: "0.25rem", width: "100%" }}
          loading={loginState.loading}
        >
          {t("login")}
        </Button>
      </form>
      <div
        style={{
          display: "flex",
          marginTop: "0.5rem",
          marginBottom: "0.5rem",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <span
          style={{
            padding: "0.5rem",

            backgroundColor: "#ffffff",
            fontSize: "20px",
          }}
        >
          {t("or")}
        </span>
      </div>
      <FacebookButton />
    </div>
  );
};

export default LoginForm;
