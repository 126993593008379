import React, { Fragment, PropsWithChildren } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Cross1Icon } from "@radix-ui/react-icons";
import { Link } from "react-router-dom";
import styles from "../Styles/Sidebar.module.css";
import { t } from "i18next";

type Props = {
  open: boolean;
  onClose: () => void;
};

const SidebarMenu: React.FC<PropsWithChildren<Props>> = ({
  children,
  open,
  onClose,
}) => {
  return (
    <Transition show={open} as={Fragment}>
      <Dialog open={open} onClose={onClose} className={styles.sidebar} static>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className={styles.overlay} />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="transition-transform ease-out duration-200"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition-transform ease-in duration-200"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className={styles.container}>
            <div className={styles.innerBtnContainer}>
              <button onClick={onClose} className={styles.closeBtn}>
                <Cross1Icon height={25} width={25} />
              </button>
            </div>
            <div className={styles.linkContainer}>
              <Link to="/" className={styles.link} onClick={onClose}>
                Designs
              </Link>
              <Link
                to="/product/yarn"
                className={styles.link}
                onClick={onClose}
              >
                Garn
              </Link>
              <Link
                to="/product/needle"
                className={styles.link}
                onClick={onClose}
              >
                Pinner
              </Link>
              <Link
                to="https://design.woolit.no/landing"
                className={styles.link}
                onClick={onClose}
              >
                Woolit Design Studio Pro
              </Link>

              <Link
                style={{
                  color: "#3D8036",
                  cursor: "pointer",
                  fontSize: "1.25rem",
                  lineHeight: "1.75rem",
                }}
                to="/projects"
              >
                {t("goToWDS")}
              </Link>

              {/*  <Link
                  href="/product/yarn"
                  passHref
                  className="cursor-pointer text-xl"
                  onClick={onClose}
                >
                  Garn
                </Link>
                <Link
                  href="/product/needle"
                  passHref
                  className="cursor-pointer text-xl"
                  onClick={onClose}
                >
                  Pinner
                </Link> */}
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
};

export default SidebarMenu;
