import { useEffect, useRef, useState } from "react";
import ColorList from "./ColorList";
import ChangeDiagramView from "./ChangeDiagramView";
import Grid from "./Grid";
import GridButtons from "./GridButtons";
import SymbolList from "./SymbolList";

import styles from "./diagram.module.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";
import { redo, setActiveDiagram, undo, updateGrid } from "../store/gridSlice";
import { StateButtons } from "./StateButtons";
import { RepeatMenu } from "./RepeatMenu";
import useHotkeys from "@reecelucas/react-use-hotkeys";
import { useModal } from "../CommonComponents/useModal";
import { NewDiagram } from "./NewDiagram";
import { ModalButton } from "../CommonComponents/ModalButton";
import { useTranslation } from "react-i18next";
import { Global } from "../Visualizing3D/static/global";
import DiagramDelete from "./DiagramDelete";
import SimpleColorList from "./SimpleColorList";
import Library from "./Library";

export const DiagramEditor = () => {
  const dispatch = useDispatch();
  const { diagrams, id } = useSelector((state: RootState) => state.pattern);
  const { isShown, toggle } = useModal();
  const { t } = useTranslation();

  const { showMenuRight, disableHotKeys, activeDiagram } = useSelector(
    (state: RootState) => state.grid
  );

  const { hideDiagrams } = useSelector((state: RootState) => state.model);

  const first =
    activeDiagram !== ""
      ? diagrams[activeDiagram]
      : Object.values(diagrams).sort((a, b) => a.name.localeCompare(b.name))[0];

  const scrollRef = useRef<HTMLDivElement>(null);

  const { selectedView } = useSelector((state: RootState) => state.grid);

  const [libraryOpen, setLibraryOpen] = useState(false);

  useEffect(() => {
    if (first) {
      dispatch(setActiveDiagram({ id: first.id }));
      dispatch(updateGrid({ grid: first }));
    }
  }, []);

  useHotkeys(["Meta+z", "Control+z"], (e) => {
    if (Global.hoveringVisualizing3D) return;
    if (disableHotKeys) return;
    e.preventDefault();
    dispatch(undo());
  });

  useHotkeys(["Meta+shift+z", "Control+shift+z"], (e) => {
    if (Global.hoveringVisualizing3D) return;
    if (disableHotKeys) return;
    e.preventDefault();
    dispatch(redo());
  });

  // Auto scrolls the new diagram button into center
  useEffect(() => {
    scrollRef.current?.scrollIntoView({
      behavior: "auto",
      block: "center",
      inline: "center",
    });
  }, [scrollRef.current, selectedView]);

  return (
    <div className={styles.editorContainer}>
      <div className={styles.library}>
        <button
          id="intro-library"
          className={styles.libButton}
          onClick={() => {
            setLibraryOpen(true);
          }}
        >
          {t("my library")}
        </button>
      </div>
      {libraryOpen && (
        <Library
          handleClose={() => {
            setLibraryOpen(false);
          }}
        />
      )}
      <div
        className={styles.menuRight}
        style={{
          top: hideDiagrams ? "50%" : "33%",
        }}
      >
        {showMenuRight && (
          <>
            <StateButtons />
            <ColorList />
            <SimpleColorList />
            <SymbolList />
            <GridButtons />

            <RepeatMenu />
            <ChangeDiagramView />
            <DiagramDelete />
          </>
        )}
      </div>
      <div
        style={{
          position: "relative",
        }}
      >
        {Object.keys(diagrams).length > 0 ? (
          <Grid />
        ) : (
          <div className={styles.centralNewDiagramButtonContainer}>
            <div
              // This is the scrollIntoView position if no diagrams have been made
              ref={scrollRef}
              style={{
                position: "absolute",
                top: "50%",
                left: selectedView === "grid" ? "50%" : "60%",
              }}
            ></div>
            <ModalButton
              headerText={t("draw.create diagram")}
              style={{ border: "none", textAlign: "left", color: "gray" }}
              modalContent={<NewDiagram toggle={toggle} patternId={id} />}
              toggle={toggle}
              isShown={isShown}
            >
              <div className={styles.centralNewDiagramButton}>
                {t("draw.create diagram")}
              </div>
            </ModalButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default DiagramEditor;
