import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../ISO/Components/Button";
import {
  setKnitAlongSignUpDate,
  setKnitAlongStartDate,
  setKnitAlongEndDate,
  createKnitAlong,
  deleteKnitAlong,
  addKnitAlongHashtag,
  deleteKnitAlongHashtag,
} from "../../store/patternSlice";
import { RootState } from "../../store/store";
import styles from "./KnitAlong.module.css";
import { useTranslation } from "react-i18next";

export const KnitAlong = () => {
  const { meta } = useSelector((state: RootState) => state.pattern);
  const { knitAlong } = meta;
  const dispatch = useDispatch();
  const [hashtag, setHashtag] = useState("");
  const { t } = useTranslation();
  return (
    <div>
      <label style={{ marginBottom: "8px", justifyContent: "space-between" }}>
        {t("is knit along")}
      </label>
      <input
        type="checkbox"
        checked={!!knitAlong}
        onChange={(e) => {
          if (e.target.checked) {
            dispatch(createKnitAlong());
          } else {
            dispatch(deleteKnitAlong());
          }
        }}
      />
      {!!knitAlong && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className={styles.container}>
            <label style={{ marginRight: "8px", marginTop: "8px" }}>
              Påmeldingsfrist:
            </label>
            <input
              onChange={(e) => {
                const signUpDate = new Date(e.target.value).toISOString();
                dispatch(setKnitAlongSignUpDate({ signUpDate }));
              }}
              type="date"
              value={formatToInputDate(knitAlong?.signupDeadline)}
              style={{ textAlign: "center", marginTop: "8px" }}
            />
          </div>
          <div className={styles.container}>
            <label style={{ marginRight: "8px" }}>Oppstartsdato: </label>
            <input
              onChange={(e) => {
                const startDate = new Date(e.target.value).toISOString();
                dispatch(setKnitAlongStartDate({ startDate }));
              }}
              type="date"
              value={formatToInputDate(knitAlong?.startDate)}
              style={{ textAlign: "center" }}
            />
          </div>
          <div className={styles.container}>
            <label style={{ marginRight: "8px" }}>Sluttdato: </label>
            <input
              onChange={(e) => {
                const endDate = new Date(e.target.value).toISOString();
                dispatch(setKnitAlongEndDate({ endDate }));
              }}
              type="date"
              value={formatToInputDate(knitAlong?.endDate)}
              style={{ textAlign: "center" }}
            />
          </div>

          <div className={styles.container}>
            <label style={{ marginRight: "8px" }}>Hashtag: </label>
            <div>
              #
              <input
                type="text"
                value={hashtag}
                onChange={(e) => setHashtag(e.target.value)}
              />
              <Button
                onClick={() => {
                  dispatch(addKnitAlongHashtag({ hashtag }));
                  setHashtag("");
                }}
              >
                Legg til hashtag
              </Button>
            </div>
          </div>
          {knitAlong?.hashtags?.map((hashtag, i) => (
            <div key={i}>
              #{hashtag}{" "}
              <Button
                onClick={() => {
                  dispatch(deleteKnitAlongHashtag({ index: i }));
                }}
              >
                x
              </Button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const formatToInputDate = (date: string | undefined) => {
  if (date) {
    const d = new Date(date);
    return `${d.getFullYear()}-${formatMonth(d.getMonth())}-${
      d.getDate() <= 9 ? "0" + d.getDate() : d.getDate()
    }`;
  } else {
    const d = new Date();
    return `${d.getFullYear()}-${formatMonth(d.getMonth())}-${
      d.getDate() <= 9 ? "0" + d.getDate() : d.getDate()
    }`;
  }
};

const formatMonth = (month: number) => {
  const actualMonth = month + 1;
  return `${actualMonth <= 9 ? `0${actualMonth}` : actualMonth}`;
};
