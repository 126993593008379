import { woolitApi } from "../store/woolitApi";

interface YarnState {
  [key: string]: APIYarn;
}

interface APIYarn {
  id: number;
  sku: string;
  name: string;
  image: any;
  brand: string;
  weight: any; // Don't know what this is supposed to be, but it's nullable
  length: any; // Don't know what this is supposed to be, but it's nullable
  gaugeStockinette: boolean;
  colors: APIYarnColor[];
  gauge: {
    x: null | { cm: number; stitches: number };
    y: null | { cm: number; stitches: number };
  };
  needleSize: {
    min: number | string | null;
    max: number | string | null;
  };
}

interface Image {
  alt: string | null;
  url: string;
}

interface APIYarnColor {
  sku: string;
  name: string;
  color_tint_code: string;
  color_tint: string;
  price_ore: number;
  price_merchant_ore: number;
  discount_percent: number;
  image_swatch: ImageIdentification | null;
  image_swatch_data: Image | null;
  image_base: ImageIdentification | null;
  image_base_data: Image | null;
  stock: "SoldOut" | "Available";
  hex: string | null;
}

interface ImageIdentification {
  id: number;
  token: string;
  mimetype: string;
}

const woolitApiWithYarn = woolitApi.injectEndpoints({
  endpoints: (builder) => ({
    organizationYarn: builder.query<YarnState, number | null>({
      query: (orgId: number) => {
        if (orgId && orgId !== -1) {
          return `/yarn/org/${orgId}`;
        } else {
          return "/yarn/all";
        }
      },
    }),
  }),
});

export const { useOrganizationYarnQuery } = woolitApiWithYarn;
export type { YarnState, APIYarn, APIYarnColor, ImageIdentification };
