import useHotkeys from "@reecelucas/react-use-hotkeys";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Transforms, Text } from "slate";
import { ReactEditor } from "slate-react";

import { RootState } from "../../store/store";
import { ElementSelectorProps } from "./ElementSelector";

import styles from "./element.module.css";
import { useTranslation } from "react-i18next";

export type CalculationResultElementType = {
  type: "calculationResult";
  children: Text[];
  tableId: string;
  measureId: string;
  row1?: number;
  row2?: number;
  edgeKind?: "horizontal" | "frequency" | "vertical";
  commentId?: string;
};

export const CalculationResultSelect = ({
  closeSelector,
  editor,
  action,
  element,
}: ElementSelectorProps) => {
  const [allSizes] = useSelector((state: RootState) => [
    state.pattern.meta.sizes,
    state.displayState.activeSizes,
  ]);

  const { tableOrder, tables, graph } = useSelector(
    (state: RootState) => state.calculations
  );

  const sizes = useSelector((state: RootState) => state.pattern.meta.sizes);
  const activeSize = useSelector(
    (state: RootState) => state.displayState.activeSizes
  );

  const filteredSizes = () => {
    const activeSizes = activeSize.length > 0 ? activeSize : sizes;
    return activeSizes.map((size) => allSizes.indexOf(size));
  };

  const [tableIndex, setTableIndex] = useState(0);
  const [tableRowIndex, setTableRowIndex] = useState(0);

  useHotkeys("ArrowUp", (e) => {
    e.preventDefault();
    //setArrowIndex(arrowIndex - 1 < 0 ? 0 : arrowIndex - 1);
    if (tableIndex > 0 && tableRowIndex === 0) {
      const tmpTableIndex = tableIndex;
      setTableIndex(tmpTableIndex - 1);
      setTableRowIndex(
        (tables[tableOrder[tmpTableIndex - 1]].order.length - 1) * 2
      );
    } else if (tableRowIndex > 0) {
      setTableRowIndex(tableRowIndex - 1);
    }
  });

  useHotkeys("ArrowDown", (e) => {
    e.preventDefault();
    if (
      tableIndex < tableOrder.length - 1 &&
      tableRowIndex === (tables[tableOrder[tableIndex]].order.length - 1) * 2
    ) {
      setTableIndex(tableIndex + 1);
      setTableRowIndex(0);
    } else if (
      tableRowIndex <
      (tables[tableOrder[tableIndex]].order.length - 1) * 2
    ) {
      setTableRowIndex(tableRowIndex + 1);
    }
  });

  useHotkeys("Enter", (e) => {
    e.preventDefault();
    const tableId = tableOrder[tableIndex];
    const table = tables[tableId];

    if (!table) {
      return;
    }

    const rowId = table.order[tableRowIndex];

    insertCalculationResult(tableId, rowId);
  });

  const insertCalculationResult = (
    tableId: string,
    measureId: string,
    edgeKind?: "horizontal" | "frequency" | "vertical"
  ) => {
    if (action === "add") {
      Transforms.insertNodes(editor, [
        {
          type: "calculationResult",
          children: [{ text: "" }],
          tableId,
          measureId,
          edgeKind,
        },
        {
          type: "span",
          children: [{ text: " " }],
        },
      ]);
    } else {
      const path = element ? ReactEditor.findPath(editor, element) : undefined;
      Transforms.setNodes(
        editor,
        { tableId, measureId, edgeKind },
        { at: path }
      );
    }
    closeSelector();
  };

  const { t } = useTranslation();

  return (
    <>
      {tableOrder.map((tableId, tIndex) => {
        const table = tables[tableId];
        let rowIndex = 0;
        return (
          <div key={tIndex} className={styles.table}>
            <div className={styles.tableHead}>
              <p className={styles.tableLabel}>{table.label}</p>
              <div className={styles.sizeLabelRow}>
                {filteredSizes().map((size, i) => (
                  <p key={i}>{allSizes[size]}</p>
                ))}
              </div>
            </div>

            {table.order.map((measureId: string, i) => {
              const measure = table.measures[measureId];

              if (measure.kind === "node") {
                const row = (
                  <div
                    key={i}
                    style={{
                      background:
                        tIndex === tableIndex && rowIndex === tableRowIndex
                          ? "#faf6f2"
                          : "",
                    }}
                    onMouseOver={() => {
                      setTableIndex(tIndex);
                      setTableRowIndex(rowIndex);
                    }}
                    onClick={() => insertCalculationResult(table.id, measureId)}
                    className={`${styles.hoverable} ${styles.tableRow}`}
                  >
                    <p className={styles.tableRowLabel}>{measure.label}</p>
                    <div className={styles.rowNumbers}>
                      {filteredSizes().map((sizeIndex, i) => {
                        const element =
                          graph.elements[
                            graph.nodes[measure.nodes[allSizes[sizeIndex]]]
                              ?.element
                          ];
                        const value =
                          parseFloat(
                            element?.displayValue?.toFixed(2) ??
                              element?.value?.toFixed(2) ??
                              ""
                          ) ?? "**mangler**";
                        return (
                          <p key={i}>{i % 2 === 0 ? value : `(${value})`}</p>
                        );
                      })}
                    </div>
                  </div>
                );

                rowIndex++;
                return row;
              } else {
                const rows = (
                  <div key={i}>
                    <div
                      style={{
                        background:
                          tIndex === tableIndex && rowIndex === tableRowIndex
                            ? "#faf6f2"
                            : "",
                      }}
                      onMouseOver={() => {
                        setTableIndex(tIndex);
                        setTableRowIndex(rowIndex);
                      }}
                      onClick={() =>
                        insertCalculationResult(
                          table.id,
                          measureId,
                          "horizontal"
                        )
                      }
                      className={`${styles.hoverable} ${styles.tableRow}`}
                    >
                      <p className={styles.tableRowLabel}>
                        {measure.label}{" "}
                        <span style={{ color: "var(--neutral-50)" }}>
                          – Total endring
                        </span>
                      </p>
                      <div className={styles.rowNumbers}>
                        {filteredSizes().map((sizeIndex, i) => {
                          const element =
                            graph.elements[
                              graph.edges[measure.edges[allSizes[sizeIndex]]]
                                ?.horizontalChangeElement
                            ];
                          const value =
                            parseFloat(
                              element?.displayValue?.toFixed(2) ??
                                element?.value?.toFixed(2) ??
                                ""
                            ) ?? "**mangler**";
                          return (
                            <p key={i}>{i % 2 === 0 ? value : `(${value})`}</p>
                          );
                        })}
                      </div>
                    </div>

                    <div
                      style={{
                        background:
                          tIndex === tableIndex &&
                          rowIndex + 1 === tableRowIndex
                            ? "#faf6f2"
                            : "",
                      }}
                      onMouseOver={() => {
                        setTableIndex(tIndex);
                        setTableRowIndex(rowIndex + 1);
                      }}
                      onClick={() =>
                        insertCalculationResult(
                          table.id,
                          measureId,
                          "frequency"
                        )
                      }
                      className={`${styles.hoverable} ${styles.tableRow}`}
                    >
                      <p className={styles.tableRowLabel}>
                        {measure.label}{" "}
                        <span style={{ color: "var(--neutral-50)" }}>
                          – Mellom endringer
                        </span>
                      </p>
                      <div className={styles.rowNumbers}>
                        {filteredSizes().map((sizeIndex, i) => {
                          const element =
                            graph.elements[
                              graph.edges[measure.edges[allSizes[sizeIndex]]]
                                ?.frequencyElement
                            ];
                          const value =
                            parseFloat(
                              element?.displayValue?.toFixed(2) ??
                                element?.value?.toFixed(2) ??
                                ""
                            ) ?? "**mangler**";
                          return (
                            <p key={i}>{i % 2 === 0 ? value : `(${value})`}</p>
                          );
                        })}
                      </div>
                    </div>

                    <div
                      style={{
                        background:
                          tIndex === tableIndex &&
                          rowIndex + 2 === tableRowIndex
                            ? "#faf6f2"
                            : "",
                      }}
                      onMouseOver={() => {
                        setTableIndex(tIndex);
                        setTableRowIndex(rowIndex + 2);
                      }}
                      onClick={() =>
                        insertCalculationResult(table.id, measureId, "vertical")
                      }
                      className={`${styles.hoverable} ${styles.tableRow}`}
                    >
                      <p className={styles.tableRowLabel}>
                        {measure.label}{" "}
                        <span style={{ color: "var(--neutral-50)" }}>
                          {"-" + t("terms.height")}
                        </span>
                      </p>
                      <div className={styles.rowNumbers}>
                        {filteredSizes().map((sizeIndex, i) => {
                          const element =
                            graph.elements[
                              graph.edges[measure.edges[allSizes[sizeIndex]]]
                                ?.verticalDistanceElement
                            ];
                          const value =
                            parseFloat(
                              element?.displayValue?.toFixed(2) ??
                                element?.value?.toFixed(2) ??
                                ""
                            ) ?? "**mangler**";
                          return (
                            <p key={i}>{i % 2 === 0 ? value : `(${value})`}</p>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                );

                rowIndex += 3;
                return rows;
              }
            })}
          </div>
        );
      })}
    </>
  );
};

type CalculationResultElementProps = {
  attributes: any;
  children: Text[];
  element: CalculationResultElementType;
  onClick: (
    event: React.MouseEvent,
    element: CalculationResultElementType
  ) => void;
};

export const CalculationResultElement = ({
  attributes,
  children,
  element,
  onClick,
}: CalculationResultElementProps) => {
  // TODO move to SlateWriter
  const { elements, nodes, edges } = useSelector(
    (state: RootState) => state.calculations.graph
  );

  const activeSize = useSelector(
    (state: RootState) => state.displayState.activeSizes
  );

  const allSizes = useSelector(
    (state: RootState) => state.displayState.sizeOrder
  );

  const calculationResults = useSelector(
    (state: RootState) => state.calculations
  );

  const sizes = activeSize.length > 0 ? activeSize : allSizes;

  const measure =
    calculationResults.tables[element.tableId].measures[element.measureId];

  let measureString = "";
  let measureUnit = "";
  let measureDirection = "";
  if (measure === undefined) {
    measureString = "BEREGNING SLETTET";
  } else if (measure.kind === "node") {
    measureString = sizes
      .map((size, index) => {
        const node = nodes[measure.nodes[size]];

        if (!node) return "";

        const calculationElement = elements[node.element];
        const value = Math.abs(
          Math.round(calculationElement.displayValue ?? 0)
        );

        if (measureUnit === "") measureUnit = calculationElement.unit;
        if (measureDirection === "")
          measureDirection = calculationElement.direction;
        return index % 2 ? `(${value})` : value;
      })
      .join(" ");
  } else {
    measureString = sizes
      .map((size, index) => {
        const edge = edges[measure.edges[size]];
        let calculationElement;
        if (element.edgeKind === "horizontal") {
          calculationElement = elements[edge.horizontalChangeElement];
        } else if (element.edgeKind === "frequency") {
          calculationElement = elements[edge.frequencyElement];
        } else {
          calculationElement = elements[edge.verticalDistanceElement];
        }

        const value = Math.abs(
          Math.round(calculationElement.displayValue ?? 0)
        );

        if (measureUnit === "") measureUnit = calculationElement.unit;
        if (measureDirection === "")
          measureDirection = calculationElement.direction;

        return index % 2 ? `(${value})` : value;
      })
      .join(" ");
  }

  if (measureUnit === "Mask") {
    if (measureDirection === "Horizontal") {
      measureUnit = "m";
    } else {
      measureUnit = "omg";
    }
  } else if (measureUnit === "Cm") {
    measureUnit = "cm";
  }

  return (
    <span
      {...attributes}
      className={`${styles.void} ${styles.basicPatternElement} ${styles.nonEditable}`}
      onClick={(event) => onClick(event, element)}
      contentEditable={false}
    >
      {`${measureString} ${measureUnit}`}

      {children}
    </span>
  );
};
