import { Vector2 } from "three";
import { Part } from "./Part";

export class Pattern {
  grid: number[][];
  pos: Vector2;
  name: string;
  id: string;
  groupID: string;
  part: Part | undefined;
  repeated: boolean;
  gap: number;
  constructor(grid: number[][]) {
    this.grid = grid;
    this.pos = new Vector2(-1, -1);

    this.name = "";
    this.id = "";
    this.groupID = "";
    this.part = undefined;
    this.repeated = false;
    this.gap = 0;
  }

  anchor() {
    return [
      Math.floor((this.grid[0].length - 1) / 2),
      Math.floor((this.grid.length - 1) / 2),
    ];
  }

  sizeX() {
    return this.grid[0].length;
  }

  sizeY() {
    return this.grid.length;
  }

  copy() {
    const pattern = this.copyEmpty();
    pattern.grid = this.copyGrid();
    return pattern;
  }

  copyEmpty() {
    const pattern = new Pattern([[]]);
    pattern.name = this.name;
    pattern.id = this.id;
    pattern.pos = new Vector2(this.pos.x, this.pos.y);
    pattern.groupID = this.groupID;
    pattern.repeated = this.repeated;
    pattern.gap = this.gap;
    return pattern;
  }

  copyGrid() {
    const shallowGrid: number[][] = [];
    for (let innerGrid of this.grid) {
      shallowGrid.push([...innerGrid]);
    }
    return shallowGrid;
  }

  topY() {
    return this.pos.y;
  }

  bottomY() {
    return this.pos.y + this.sizeY() - 1;
  }

  middleY() {
    return (this.topY() + this.bottomY()) / 2;
  }

  isOverlappingY(y: number) {
    return y <= this.bottomY() && y >= this.topY();
  }
}
