import useHotkeys from "@reecelucas/react-use-hotkeys";
import { useState } from "react";
import Imgix from "react-imgix";
import { useSelector } from "react-redux";
import { BaseEditor, Transforms, Text } from "slate";
import { ReactEditor } from "slate-react";

import { MediaSection } from "../../CommonComponents/MediaSection";
import { imgixUrl } from "../../imgixUrl";
import { RootState } from "../../store/store";
import { shortUUID } from "../../utils/uuid";
import { ElementSelectorProps } from "./ElementSelector";

import styles from "./element.module.css";
import { t } from "i18next";

export type FigureElementType = {
  type: "figure";
  children: Text[];
  id: string;
  figureId: string;
  inline?: boolean;
  commentId?: string;
};

export const FigureSelect = ({
  closeSelector,
  editor,
  action,
}: Omit<ElementSelectorProps, "element">) => {
  const { media } = useSelector((state: RootState) => state.pattern);

  const mediaList = Object.keys(media);

  const [arrowIndex, setArrowIndex] = useState(0);
  useHotkeys("ArrowUp", (e) => {
    e.preventDefault();
    setArrowIndex(arrowIndex - 1 < 0 ? 0 : arrowIndex - 1);
  });

  useHotkeys("ArrowDown", (e) => {
    e.preventDefault();
    setArrowIndex(
      arrowIndex + 1 === mediaList.length
        ? mediaList.length - 1
        : arrowIndex + 1
    );
  });

  useHotkeys("Enter", (e) => {
    e.preventDefault();
    upsertFigure(mediaList[arrowIndex]);
  });

  const upsertFigure = (key: string) => {
    // We only handle "add" action since there is no replacing inline figures
    if (action === "add") {
      Transforms.insertNodes(editor, [
        {
          type: "figure",
          children: [{ text: "" }],
          id: shortUUID(),
          figureId: key,
        },
        {
          type: "span",
          children: [{ text: " " }],
        },
      ]);
    }
    closeSelector();
  };

  return (
    <>
      {mediaList.map((imageKey, i) => (
        <div
          onClick={() => {
            upsertFigure(imageKey);
          }}
          key={i}
          style={{ background: arrowIndex === i ? "#faf6f2" : "" }}
          className={styles.hoverable}
        >
          <div className={styles.imageRow}>
            Bilde {i} <Imgix src={`${imgixUrl}/${imageKey}`} height={36} />
          </div>
        </div>
      ))}
    </>
  );
};

type FigureElementProps = {
  attributes: any;
  children: Text[];
  element: FigureElementType;
  editor: BaseEditor & ReactEditor;
  onClick: (element: FigureElementType) => void;
};

export const FigureElement = ({
  attributes,
  children,
  element,
  editor,
  onClick,
}: FigureElementProps) => {
  return (
    <div {...attributes}>
      <button onClick={() => onClick(element)}>
        <img src="/x.svg" alt={"A cross"} />
      </button>
      - {t("datadrawer.remove media")}
      <MediaSection
        element={element}
        showOption
        editor={editor}
        figureElement={element}
      />
      {children}
    </div>
  );
};
