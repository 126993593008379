import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { RootState } from "../../store/store";
import { Part } from "../Part";
import { Pattern } from "../Pattern";
import { Isydd } from "../data/isydd";
import { Raglan } from "../data/raglan";
import { Rundfelling } from "../data/rundfelling";
import { Sock } from "../data/sock";
import {
  RepeatMode,
  SockPartArea,
  SockPartAreaGroup,
  SweaterPartArea,
} from "../enums";
import { fixColor } from "../knittingpreview/colorutil";
import {
  getParts,
  makeScene,
  setColorsScene,
} from "../knittingpreview/core/scene";
import { knitSizeDefaults } from "../knittingpreview/sweater/rescaler";
import { Global } from "../static/global";
import Grid from "./Grid";
import { setHasLoadedImages } from "./gridcanvas";

// Must be outside KnittingEditor, or else it wont be affected by loadPatternGrids()
let patternGrids: { [name: string]: number[][] } = {};

function KnittingEditor(props: any) {
  const [diagram, setDiagram] = useState<Pattern | undefined>(undefined);
  const [pointer, _setPointer] = useState<boolean>(false);
  const [pointerPattern, setPointerPattern] = useState<Pattern | undefined>(
    undefined
  );

  const [colorMode, setColorMode] = useState<boolean>(false);

  const [patterns, setPatterns] = useState<any>({});

  const [colors, setColors] = useState<string[]>([]);
  const [repeatOptions, setRepeatOptions] = useState([
    "None",
    "None",
    "None",
    "None",
  ]); // Is updated after selecting props.selectedPart

  const repeatModes = [
    RepeatMode.NONE,
    RepeatMode.ONE,
    RepeatMode.BOTH,
    RepeatMode.ALL,
  ];
  const [repeat, setRepeat] = useState(3);
  const [repeatMemo, setRepeatMemo] = useState(3);

  const [loadModel, setLoadModel] = useState<boolean>(false);

  const [hasInited, setHasInited] = useState(false);

  const color = useSelector((state: RootState) => state.color);
  const { form, knitMethod, measurements } = useSelector(
    (state: RootState) => state.pattern.meta
  );

  const { visualRepresentationSize } = useSelector(
    (state: RootState) => state.pattern.meta
  );

  const { id } = useSelector((state: RootState) => state.pattern);

  const { horizontal, vertical } = useSelector(
    (state: RootState) => state.calculations.gauge
  );

  const { pathname } = useLocation();
  const [, , patternId] = pathname.split("/");

  useEffect(() => {
    if (color.colors.length === 0) {
      return;
    }
    // This is the reason why the shirt is white at startup
    // I wait until it has finished initalizing with the previous
    // color, before I change it. I could just ignore the starting
    // color, but then you wont get any sweater if you havent
    // selected colors, so I dont like that solution.
    if (hasInited && !props.sweaterHasLoaded) {
      return;
    }
    const colorsFixed = color.colors.map((it: any) => fixColor(it.hex));
    setColorsScene(colorsFixed);
    setColors(colorsFixed);
  }, [color, props.sweaterHasLoaded]);

  // Load everything
  useEffect(() => {
    if (hasInited || `${id}` !== patternId) return;
    if (colors.length === 0) {
      setColors(["#ffffff"]); // Use this just to load a base sweater
      return;
    }
    setHasInited(true);
    init();
  }, [colors, hasInited, id]);

  useEffect(() => {
    setRepeatMemo(form === "sweater" ? 3 : 1);
  }, [form]);

  // Reload everything
  useEffect(() => {
    if (!hasInited) return;
    init();
  }, [
    form,
    knitMethod,
    measurements,
    visualRepresentationSize,
    horizontal,
    vertical,
  ]);

  useEffect(() => {
    setHasInited(false);
  }, []);

  useEffect(() => {
    if (props.selectedSweaterPart === undefined) return;
    updateUI(props.selectedSweaterPart);
  }, [props.selectedSweaterPart]);

  useEffect(() => {
    setPointer(false);
  }, [props.isMiniature]);

  function setPointer(value: boolean) {
    setPointerPattern(undefined);
    _setPointer(value);
  }

  function makeModelScene(colors: any) {
    // 2128, 1986, 3826, 4025

    let element = document.getElementById("canvas")!!;

    let parts;
    if (form === "sweater") {
      let partsDict: { [key: string]: Raglan | Isydd | Rundfelling } = {
        raglan: new Raglan(),
        "stitched-sleeves": new Isydd(),
        "circular-yoke": new Rundfelling(),
      };
      parts = partsDict[knitMethod].parts();
    } else {
      parts = new Sock().parts();
      //throw new Error("Unrecognized form at makeScene");
    }
    setColors(colors);
    makeScene(
      element,
      parts,
      colors,
      props.setSelectedSweaterPart,
      knitMethod,
      measurements[visualRepresentationSize] ?? knitSizeDefaults
    );
  }

  function updateUI(part: Part) {
    if (part.isSweater()) {
      if (part.isCollar()) {
        setRepeat(Math.min(1, repeatMemo));
        setRepeatOptions(["Off", part.name]);
      } else {
        const thirdRepeatOption = part.isArm() ? "Both Arms" : "Full Torso";
        setRepeat(repeatMemo);
        setRepeatOptions(["Off", part.name, thirdRepeatOption, "Arms & Torso"]);
      }
    } else {
      if (part.areaGroup() === SockPartAreaGroup.Foot) {
        const thirdRepeatOption = "Full Foot";
        setRepeat(Math.min(2, repeatMemo));
        setRepeatOptions(["Off", part.name, thirdRepeatOption]);
      } else {
        setRepeat(Math.min(1, repeatMemo));
        setRepeatOptions(["Off", part.name]);
      }
    }
  }

  function init() {
    Global.setMasksPer10Cm(
      (10 * horizontal.stitches) / horizontal.length,
      (10 *
        (vertical.stitches !== 0
          ? vertical.stitches
          : (horizontal.stitches * 23) / 18)) /
        (vertical.length !== 0 ? vertical.length : horizontal.length)
    );
    makeModelScene(colors);
    setLoadModel(true);
    setHasLoadedImages(false);

    const parts = getParts();
    const isSweater = parts[0].isSweater();
    if (isSweater) {
      const frontTorso = parts.find(
        (v: Part) => v.area === SweaterPartArea.FrontTorso
      );
      props.setSelectedSweaterPart(frontTorso);
    } else {
      const top = parts.find((v: Part) => v.area === SockPartArea.Leg);
      props.setSelectedSweaterPart(top);
    }
  }

  /*function changeMaskSize(size: string) {
    const oldMasksPer10Cm = Settings.masksPer10Cm
    switch (size) {
        case "S": Settings.masksPer10Cm = 28; break;
        case "M": Settings.masksPer10Cm = 18; break;
        case "L": Settings.masksPer10Cm = 12; break;
    }
    if (oldMasksPer10Cm === Settings.masksPer10Cm) return;
    for (let sweaterPart of getSweaterParts()) {
        sweaterPart.setDirty()
    }
    Settings.updateCanvasDimensions()
    resetCanvas()
    unCacheDraw()
    loadGrid(props.selectedSweaterPart, setGrid) //Re-select sweaterPart
}*/

  return (
    <div
      onContextMenu={(e) => {
        //Right click
        e.preventDefault();
      }}
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        minWidth: "100%",
        backgroundColor: "var(--whiteish)",
        maxWidth: "100%",
      }}
    >
      <Grid
        isSweaterPart={true}
        sweaterPart={props.selectedSweaterPart}
        setSelectedSweaterPart={props.setSelectedSweaterPart}
        useZoom={true}
        selectedEditPatternDone={props.selectedEditPattern === undefined}
        SavePattern={undefined}
        sendUndoRedoTrashRef={false}
        setEditPatternHeight={undefined}
        setSweaterHasLoaded={props.setSweaterHasLoaded}
        loadModel={loadModel}
        setLoadModel={setLoadModel}
        colors={colors}
        repeat={repeat}
        setRepeat={setRepeat}
        setRepeatMemo={setRepeatMemo}
        setRepeatOptions={setRepeatOptions}
        repeatOptions={repeatOptions}
        repeatModes={repeatModes}
        patternGrids={patternGrids}
        diagram={diagram}
        setDiagram={setDiagram}
        isMiniature={props.isMiniature}
        pointer={pointer}
        setPointer={setPointer}
        pointerPattern={pointerPattern}
        setPointerPattern={setPointerPattern}
        patterns={patterns}
        setPatterns={setPatterns}
        colorMode={colorMode}
        setColorMode={setColorMode}
      />
    </div>
  );
}

export default KnittingEditor;
