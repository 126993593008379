import { t } from "i18next";

import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import NavigationSection from "../LandingPage/Sections/NavigationSection";
import CardSection from "../LandingPage/Sections/CardSection";
import EndSection from "../LandingPage/Sections/EndSection";
import ProcessSection from "../LandingPage/Sections/ProcessSection";
import WoolitShopSection from "../LandingPage/Sections/WoolitShopSection";
import { backendApiAddress } from "../backendApi";
import { useState } from "react";
import { Loading } from "../CommonComponents/Loading";
import { ArrowRightIcon, CheckIcon } from "@radix-ui/react-icons";

function useQuery() {
  return new URLSearchParams(useLocation().search).get("session_id");
}

function CheckoutSuccess() {
  const response = useQuery();

  const [paymentStatus, setPaymentStatus] = useState<boolean | null>(null);

  const checkPayment = async (response: string | null): Promise<boolean> => {
    //console.log(`${backendApiAddress}/api/wds/payment/checkout_session/${response}`);

    const res = await fetch(
      `${backendApiAddress}/api/wds/payment/checkout_session/${response}`,
      {
        method: "GET",
        credentials: "include",
      }
    );
    const { status, subscriptionType } = await res.json();

    console.log("respons: " + status + " " + subscriptionType);

    if (status === "paid") {
      setPaymentStatus(true);
      return true;
    } else {
      setPaymentStatus(false);
      return false;
    }
  };

  checkPayment(response);

  return (
    <div>
      {/* sette inn loading mens vi sjekker betaling */}
      <NavigationSection></NavigationSection>
      {paymentStatus === null && (
        <div>
          <Loading></Loading>
        </div>
      )}
      {paymentStatus ? (
        <div
          style={{ display: "flex", flexDirection: "column", margin: "5%" }}
        >
          <h1>Velykket betaling! <CheckIcon width={30} height={30} ></CheckIcon></h1>
          <div style={{ marginBottom: "12px", fontSize:"19px" }}>
            Velkommen til Woolit Design Studio Pro
          </div>

          <div style={{ display: "flex" }}>
            <Link
              style={{ color: "#3D8036", cursor: "pointer" }}
              to="/projects"
            >
              {t("goToWDS")}<ArrowRightIcon color={ "#3D8036"} style={{marginBottom:"-3px"}}></ArrowRightIcon>
            </Link>
          </div>

          {/*  <button onClick={() => {
              checkPayment(response);
            }}>check payment</button> */}

          {/* {paymentStatus && <div> Verifisert betaling</div>} */}
        </div>
      ) : (
        <div
          style={{ display: "flex", flexDirection: "column", margin: "10%" }}
        >
          <h1>Betalingen din ble ikke verifisert</h1>
          <div style={{ marginBottom: "12px" }}>Vennligst prøv senere</div>
          {/*  <button onClick={() => {
              checkPayment(response);
            }}>check payment</button> */}
        </div>
      )}

      <ProcessSection />
      <CardSection />
      <WoolitShopSection />
      <EndSection />
    </div>
  );
}

export default CheckoutSuccess;
