import { SweaterPart } from "../SweaterPart";
import { SweaterPartArea } from "../enums";
import { Util } from "../static/util";

export class Raglan {
    shirt = {
      "Left Arm": [
          754.0263157894738,
          638,
          1292.9736842105262,
          1814
      ],
      "Right Arm": [
          2802.0263157894738,
          638,
          3340.9736842105262,
          1814
      ],
      "Collar": [
          1120.6842105263158,
          39,
          2973.315789473684,
          132
      ],
      "Back Torso": [
          619.2894736842105,
          2681,
          1427.7105263157896,
          3718
      ],
      "Front Torso": [
          2667.2894736842104,
          2681,
          3475.7105263157896,
          3718
      ]
  } as const

    parts() {
      let leftArm = new SweaterPart(
        "Left Arm",
        SweaterPartArea.LeftArm,
        Util.makeGrid(512),
        ...(this.shirt["Left Arm"]),
        1024
      );
      let rightArm = new SweaterPart(
        "Right Arm",
        SweaterPartArea.RightArm,
        Util.makeGrid(512),
        ...(this.shirt["Right Arm"]),
        1024
      );
      let back = new SweaterPart(
        "Back Torso",
        SweaterPartArea.BackTorso,
        Util.makeGrid(512),
        ...(this.shirt["Back Torso"]),
        3072
      );
  
      let front = new SweaterPart(
        "Front Torso",
        SweaterPartArea.FrontTorso,
        Util.makeGrid(512),
        ...(this.shirt["Front Torso"]),
        3072
      );
  
      let collar = new SweaterPart(
        "Collar",
        SweaterPartArea.Collar,
        Util.makeGrid(512),
        ...(this.shirt["Collar"]),
        -1,
        true //is_inverted
      );

      const sweaterParts = [front, back, leftArm, rightArm, collar]
      sweaterParts.forEach((v: SweaterPart, i: number) => v.index = i)
      return sweaterParts
    }
}