import {
  PlacementLineType,
  SweaterPartArea,
  SweaterPartAreaGroup,
} from "./enums";
import { Global } from "./static/global";
import { Pattern } from "./Pattern";
import { Vector2 } from "three";
import { crossAlign, updateGridHTML } from "./knittingeditor/gridcanvas";
import { Util } from "./static/util";
import { PlacementLineInfo } from "./knittingeditor/Grid";
import { getParts } from "./knittingpreview/core/scene";

export class SweaterPart {
  name: string;
  area: SweaterPartArea;
  grid: number[][];
  patterns: Pattern[];
  patternsIndexPreview: number[];
  dirtyPositionsGrid: boolean[][];
  dx: number = 0; // Unused, but must be kept because of old saves containing them
  dy: number = 0; // Unused, but must be kept because of old saves containing them
  corner1X: number;
  corner1Y: number;
  corner2X: number;
  corner2Y: number;
  connectY: number = -1;
  initialConnectY: number;
  inverted: boolean;
  invertedX: boolean;
  startXPixel: number = -1;
  startYPixel: number = -1;
  endXPixel: number = -1;
  endYPixel: number = -1;
  scaleX: number = -1;
  scaleY: number = -1;
  sizeX: number = -1;
  sizeY: number = -1;
  index: number;
  circularYoke: boolean = false; // Kinda unnecessary, could use knitMethod instead
  knitMethod: string = "";
  colorLines: { [key: number]: number };
  //corner1      corner
  //
  //corner       corner2
  constructor(
    name: string,
    area: SweaterPartArea,
    grid: number[][],
    corner1X: number,
    corner1Y: number,
    corner2X: number,
    corner2Y: number,
    _connectY: number,
    inverted: boolean = false,
    invertedX: boolean = false
  ) {
    this.name = name;
    this.area = area;
    this.grid = grid;
    this.index = -1;
    this.patterns = [];
    this.patternsIndexPreview = [];
    this.corner1X = corner1X / 4096;
    this.corner1Y = corner1Y / 4096;
    this.corner2X = corner2X / 4096;
    this.corner2Y = corner2Y / 4096;
    this.initialConnectY = _connectY;
    this.dirtyPositionsGrid = [];
    this.inverted = inverted;
    this.invertedX = invertedX;
    this.setDirty();

    // const minGridSizeX = 138;
    // const minGridSizeY = 116;

    this.calculateFromCorners();

    this.colorLines = {};
  }

  calculateFromCorners() {
    let connectY = this.initialConnectY;
    if (connectY === -1) {
      connectY = this.corner1Y * 4096;
    }

    this.startXPixel = this.corner1X * 4096;
    this.startYPixel = this.corner1Y * 4096;
    this.endXPixel = this.corner2X * 4096;
    this.endYPixel = this.corner2Y * 4096;

    this.scaleX = 4096 / Global.canvasWidth;
    this.scaleY = 4096 / Global.canvasHeight;
    //NB BUG: if endXPixel is too large, mirror will not work.
    //Should probably use calculateGridSize for calculation of sizeX
    this.sizeX = Math.round(
      (this.endXPixel - this.startXPixel) / (Global._maskWidth * this.scaleX)
    );
    this.sizeX += this.sizeX % 2;
    this.sizeY = Math.round(
      (this.endYPixel - this.startYPixel) / (Global._maskHeight * this.scaleY)
    );
    this.sizeY += this.sizeY % 2;
    this.connectY = Math.round(
      (connectY - this.startYPixel) / (Global._maskHeight * this.scaleY)
    );

    if (this.sizeX % 2 === 1 && this.area !== SweaterPartArea.Collar) {
      throw new Error(
        `Odd number (${this.sizeX}) is not allowed for sizeX.\nTo fix: Change corner1X/corner2X accordingly.`
      );
    }
  }

  // Fully redraw the SweaterPart inside the scene.
  setDirty() {
    function make2DArray(x: number, y: number, fillWith: any) {
      return new Array(y).fill(0).map(() => new Array(x).fill(fillWith));
    }
    this.dirtyPositionsGrid = make2DArray(
      this.grid[0].length,
      this.grid.length,
      true
    );
  }

  areaGroup() {
    switch (this.area) {
      case SweaterPartArea.LeftArm:
        return SweaterPartAreaGroup.Arms;
      case SweaterPartArea.RightArm:
        return SweaterPartAreaGroup.Arms;
      case SweaterPartArea.FrontTorso:
        return SweaterPartAreaGroup.Torso;
      case SweaterPartArea.BackTorso:
        return SweaterPartAreaGroup.Torso;
      case SweaterPartArea.Collar:
        return SweaterPartAreaGroup.Collar;
    }
  }

  areaGroupName() {
    return SweaterPartAreaGroup[this.areaGroup()!];
  }

  isArm() {
    return this.areaGroup() === SweaterPartAreaGroup.Arms;
  }

  isTorso() {
    return this.areaGroup() === SweaterPartAreaGroup.Torso;
  }

  isCollar() {
    return this.areaGroup() === SweaterPartAreaGroup.Collar;
  }

  updateGrid(x: number, y: number, newValue: number) {
    if (this.grid[y][x] !== newValue) {
      this.grid[y][x] = newValue;
      this.dirtyPositionsGrid[y][x] = true;
    }
  }
  copyPatterns() {
    return this.patterns.map((it) => it.copy());
  }

  copyState() {
    return {
      patterns: this.copyPatterns(),
      colorLines: { ...this.colorLines },
    };
  }

  copyGrid() {
    const shallowGrid = [];
    for (let innerGrid of this.grid) {
      shallowGrid.push([...innerGrid]);
    }
    return shallowGrid;
  }

  findOppositePart(allParts: SweaterPart[]) {
    return allParts.find(
      (it) => it.areaGroup() === this.areaGroup() && it !== this
    );
  }

  findOppositeGroup(allParts: SweaterPart[]) {
    return allParts.find(
      (it) =>
        it.areaGroup() !== this.areaGroup() &&
        it.areaGroup() !== SweaterPartAreaGroup.Collar
    );
  }

  isMask(x: number, y: number, _dx?: number, _dy?: number) {
    const sizeX = this.sizeX;
    const sizeY = this.sizeY;
    const scaleX = this.scaleX;
    const scaleY = this.scaleY;
    const startXPixel = this.startXPixel;
    const startYPixel = this.startYPixel;
    const endXPixel = this.endXPixel;
    const endYPixel = this.endYPixel;

    let minLimitXDraw = 0;
    let maxLimitXDraw = sizeX;
    let minLimitYDraw = 0;
    let maxLimitYDraw = sizeY;

    if (!(x >= minLimitXDraw && x < maxLimitXDraw)) {
      return false;
    }
    if (!(y >= minLimitYDraw && y < maxLimitYDraw)) {
      return false;
    }
    const midXPixel = (startXPixel + endXPixel) / 2;
    const midYPixel = (startYPixel + endYPixel) / 2;

    let xPixel = Math.round(
      (x - sizeX / 2) * scaleX * Global._maskWidth + midXPixel
    );
    let yPixel = Math.round(
      (y - sizeY / 2) * scaleY * Global._maskHeight + midYPixel
    );

    let xPixelNext = Math.round(
      (x + 1 - sizeX / 2) * scaleX * Global._maskWidth + midXPixel
    );
    let yPixelNext = Math.round(
      (y + 1 - sizeY / 2) * scaleY * Global._maskHeight + midYPixel
    );
    //let xPixelNext = xPixel + Math.round(Global._maskWidth * scaleX);
    //let yPixelNext = yPixel + Math.round(Global._maskHeight * scaleY);
    // Can consider merging the math.round (first commented),
    // but doing so results in unsymmetry, which is why this is done instead

    //NB: Consider caching this
    let NW = this.pixelData(Global.imageData, xPixel, yPixel) >= 128;
    let NE = this.pixelData(Global.imageData, xPixelNext, yPixel) >= 128;
    let SW = this.pixelData(Global.imageData, xPixel, yPixelNext) >= 128;
    let SE = this.pixelData(Global.imageData, xPixelNext, yPixelNext) >= 128;
    return NW || NE || SW || SE; //Do four corner checks, and adjust output depending
  }

  pixelData(imageData: any, x: number, y: number) {
    let index = x * 4 + y * 4 * 4096;
    if (index >= 4096 * 4096 * 4) throw new Error("out of index");
    return imageData.data[index];
  }

  savePattern(x: number, y: number, pattern: Pattern, preview: boolean) {
    const _pattern = pattern.copy();
    _pattern.pos = new Vector2(x, y);
    this.patterns.push(_pattern);
    if (preview) this.patternsIndexPreview.push(this.patterns.length - 1);
    _pattern.part = this;
    return _pattern;
  }

  getOverlap(pattern: Pattern) {
    if (this.isOutside(pattern.bottomY()) || this.isOutside(pattern.topY())) {
      return [undefined, true];
    }
    for (let n = this.patterns.length - 1; n >= 0; n--) {
      const _pattern = this.patterns[n];
      if (_pattern === pattern) continue;
      const meInsideIt =
        _pattern.isOverlappingY(pattern.bottomY()) ||
        _pattern.isOverlappingY(pattern.topY());
      const itInsideMe =
        pattern.isOverlappingY(_pattern.bottomY()) ||
        pattern.isOverlappingY(_pattern.topY());
      if (meInsideIt || itInsideMe) {
        return [_pattern, false];
      }
    }
    return undefined;
  }

  getOverlapMove(pattern: Pattern, direction: Vector2) {
    pattern.pos = pattern.pos.add(direction);
    const overlap = this.getOverlap(pattern);
    pattern.pos = pattern.pos.add(direction.multiplyScalar(-1));
    direction.multiplyScalar(-1);
    return overlap;
  }

  _movePattern(
    pattern: Pattern,
    direction: Vector2,
    gridHTML?: HTMLDivElement
  ) {
    this.clearPattern(pattern, gridHTML);
    pattern.pos = pattern.pos.add(direction);
    this.drawPattern(pattern.pos, pattern, false, gridHTML);
    return true;
  }

  // Prioritize this sweaterPart
  getGroupPatterns(groupID: string) {
    return Util.sortPriority(getParts(), this)
      .map((it) => ({
        sweaterPart: it,
        pattern: it.getPatternByGroupID(groupID),
      }))
      .filter((it) => it.pattern);
  }

  getOverlapMoveGroup(pattern: Pattern, direction: Vector2) {
    let groupPatterns = this.getGroupPatterns(pattern.groupID);
    for (let { sweaterPart, pattern } of groupPatterns) {
      const overlapMove = sweaterPart.getOverlapMove(pattern, direction);
      if (overlapMove) {
        const [pattern, isOutside] = overlapMove;
        return [pattern, isOutside, sweaterPart];
      }
    }
    return undefined;
  }

  moveGroup(pattern: Pattern, direction: Vector2, gridHTML?: HTMLDivElement) {
    let groupPatterns = this.getGroupPatterns(pattern.groupID);
    const overlap = this.getOverlapMoveGroup(pattern, direction);
    if (overlap) {
      return false;
    }
    for (let { sweaterPart, pattern } of groupPatterns) {
      sweaterPart._movePattern(
        pattern!,
        direction,
        sweaterPart === this ? gridHTML : undefined
      );
    }
    return true;
  }

  // And draw colorLine if exists
  clearPattern(pattern: Pattern, gridHTML: HTMLDivElement | undefined) {
    const drawPos = pattern.pos;
    const startY = drawPos.y;
    const endY = drawPos.y + pattern.sizeY();
    for (let y = startY; y < endY; y += 1) {
      for (let x = 0; x < this.sizeX; x++) {
        this.updateGrid(x, y, -1);
        if (this.isMask(x, y)) {
          if (!gridHTML) continue;
          updateGridHTML(
            x,
            y,
            -1,
            gridHTML,
            false,
            [false, false, false, false],
            false,
            this
          );
        }
      }
      if (y in this.colorLines) {
        this.drawColorLine(y, this.colorLines[y], false, gridHTML, true);
      }
    }
  }

  _deletePattern(pattern: Pattern, gridHTML?: HTMLDivElement) {
    this.clearPattern(pattern, gridHTML);
    this.patterns.splice(this.patterns.indexOf(pattern), 1);
  }

  deleteGroup(pattern: Pattern, gridHTML?: HTMLDivElement) {
    let groupPatterns = this.getGroupPatterns(pattern.groupID);
    for (let { sweaterPart, pattern } of groupPatterns) {
      sweaterPart._deletePattern(
        pattern!,
        sweaterPart === this ? gridHTML : undefined
      );
    }
  }

  drawColorLine(
    y: number,
    drawColor: number,
    preview: boolean,
    gridHTML?: HTMLDivElement,
    ignorePattern: boolean = false
  ) {
    const sweaterPart = this;
    const patternAtTop = this.getPattern(y);
    const getPatternColor = (x: number) => {
      if (!patternAtTop) return -1;
      const pos = patternAtTop.pos;
      return patternAtTop?.grid[y - pos.y][
        Util.mod(x - pos.x, patternAtTop.sizeX())
      ];
    };
    for (let x = 0; x < sweaterPart.grid[0].length; x += 1) {
      if (y < 0 || (!ignorePattern && getPatternColor(x) !== -1)) {
        continue;
      }
      if (!preview) {
        sweaterPart.updateGrid(x, y, drawColor);
      }
      if (gridHTML && sweaterPart.isMask(x, y)) {
        updateGridHTML(
          x,
          y,
          drawColor,
          gridHTML,
          preview,
          [false, false, false, false],
          false,
          this
        );
      }
    }
  }

  drawPattern(
    drawPos: Vector2,
    pattern: Pattern,
    preview: boolean,
    gridHTML?: HTMLDivElement
  ) {
    const sweaterPart = this;

    let [startX, startY] = [drawPos.x, drawPos.y];
    let [endX, endY] = [
      drawPos.x + pattern.sizeX(),
      drawPos.y + pattern.sizeY(),
    ];

    let xMod = startX;
    let xLen = endX - startX + pattern.gap;

    if (pattern.repeated) {
      startX = 0;
      endX = sweaterPart.grid[0].length;
    }

    endY = Math.min(sweaterPart.sizeY, endY);

    for (let x = startX; x < endX; x += 1) {
      for (let y = startY; y < endY; y += 1) {
        if (y < 0) {
          continue;
        }
        const patternY = y - startY;
        let patternX = Util.mod(x - xMod, xLen);
        let drawColor = sweaterPart.grid[y][x];
        let brushColor = -1;
        if (patternX < pattern!!.grid[patternY].length) {
          brushColor = pattern!!.grid[patternY][patternX];
        }
        if (brushColor !== -1) {
          if (preview && sweaterPart.isMask(x, y)) {
            drawColor = brushColor;
          } else if (!preview) {
            sweaterPart.updateGrid(x, y, brushColor);
            drawColor = brushColor;
          }
          if (gridHTML && sweaterPart.isMask(x, y)) {
            updateGridHTML(
              x,
              y,
              drawColor,
              gridHTML,
              preview,
              [false, false, false, false],
              false,
              this
            );
          }
        }
      }
    }
  }

  updatePattern(pattern: any, gridHTML: any) {
    this.clearPattern(pattern, gridHTML);
    this.drawPattern(pattern.pos, pattern, false, gridHTML);
  }

  clearPreview() {
    for (let index of this.patternsIndexPreview.reverse()) {
      this.patterns.splice(index, 1);
    }
    this.patternsIndexPreview = [];
  }

  isOutside(y: number) {
    return y < 0 || y >= this.sizeY;
  }

  getPatternByGroupID(groupID: string) {
    return this.patterns.find((pattern) => pattern.groupID === groupID);
  }

  isPatternNonPreview(y: number) {
    return this.getPattern(y, true) !== null;
  }

  isOverArms(y: number) {
    return y < this.connectY;
  }

  getPattern(y: number, filterAwayPreview?: boolean) {
    if (this.isOutside(y)) {
      return null;
    }
    for (let n = this.patterns.length - 1; n >= 0; n--) {
      if (filterAwayPreview && this.patternsIndexPreview.includes(n)) continue;
      const pattern = this.patterns[n];
      if (pattern.isOverlappingY(y)) {
        return pattern;
      }
    }
    return null;
  }

  isMultiplePatterns(y: number) {
    if (this.isOutside(y)) {
      return false;
    }
    let foundOne = false;
    for (let n = this.patterns.length - 1; n >= 0; n--) {
      const pattern = this.patterns[n];
      if (pattern.isOverlappingY(y)) {
        if (foundOne) {
          return true;
        }
        foundOne = true;
      }
    }
    return false;
  }

  getPlacementLineType(
    y: number,
    otherSweaterParts?: SweaterPart[]
  ): PlacementLineInfo {
    const pattern = this.getPattern(y);
    if (pattern) {
      const overlap = this.getOverlap(pattern);
      const illegal = overlap !== undefined;
      const outOfBounds = illegal && overlap[1] === true;
      let placementLineType = PlacementLineType.Center;
      if (y === pattern.topY()) {
        placementLineType = PlacementLineType.Top;
      }
      if (y === pattern.bottomY()) {
        placementLineType = PlacementLineType.Bottom;
      }
      return {
        placementLineType: placementLineType,
        illegal: illegal,
        small: pattern.sizeY() < 5,
        fromOtherSweater: false,
        outOfBounds: outOfBounds,
      };
    }
    if (otherSweaterParts) {
      for (let sweaterPart of otherSweaterParts) {
        if (sweaterPart === this) continue;
        const [, dy] = crossAlign(sweaterPart, this);
        const patternThere = sweaterPart.getPattern(y + dy);
        const outOfBounds = sweaterPart.isOutside(y + dy);
        const illegal = patternThere || outOfBounds;
        if (illegal) {
          return {
            placementLineType: PlacementLineType.Center,
            illegal: true,
            small: false,
            fromOtherSweater: true,
            outOfBounds: outOfBounds,
          };
        }
      }
    }
    return {
      placementLineType: PlacementLineType.None,
      illegal: false,
      small: false,
      fromOtherSweater: false,
      outOfBounds: false,
    };
  }

  isSweater() {
    return true;
  }
}
