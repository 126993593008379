import { useSelector } from "react-redux";
import { Navigate } from "react-router";
import { Loading } from "../../CommonComponents/Loading";
import { RootState } from "../../store/store";
import { useMeQuery } from "../../store/woolitApi";

function AdminRoute({ children, ...rest }: any) {
  let auth = useSelector((state: RootState) => state.loginState);
  const { data: user } = useMeQuery();

  if (user?.isAdmin ?? false) {
    return children;
  } else if (auth.loginFailed || !(user?.isAdmin ?? true)) {
    return <Navigate to={"/"} />;
  } else {
    return <Loading />;
  }
}

export default AdminRoute;
