import { updateMaskWidthHeight } from "../knittingpreview/texturecanvas";
import { Settings } from "./settings";

//Global variables: non-const
export class Global {
  static _masksPer10cm = Settings.startMasksPer10Cm;
  static _masksPer10cmRatio = Settings.startMasksPer10CmRatio;
  static _maskHeight = Math.round(16 / Settings.startMasksPer10CmRatio);
  static _maskWidth = 16;
  static _sizeIndex = -1;
  static canvasWidth = -1; //4096 => 106 cm
  static canvasHeight = -1;
  static gridSizeSweater = 5;
  static gridSizeSock = 10;
  static imageData: any = undefined;
  static gridSizeX = 138;
  static gridSizeY = 138;
  static hoveringVisualizing3D = false;
  static postedKey = "";
  static getVersionStorageName() {
    return Settings.versionStorageName;
  }
  static getSizeIndexStorageName() {
    return Settings.sizeIndexStorageName;
  }
  static getKnittingMethodIndexStorageName() {
    return Settings.knittingMethodIndexStorageName;
  }
  static getSweaterStorageName() {
    return Settings.sweaterStorageName;
  }
  static getColorsStorageName() {
    return Settings.colorsStorageName;
  }
  static getPatternStorageName() {
    return Settings.patternStorageName;
  }
  static _warningGrid: any = undefined;
  static warningDescriptionInfo(grid: any, x: number, y: number) {
    const isOutside = grid[y][x] === -2;
    if (isOutside) return undefined;
    const warningGrids = Global._warningGrid;
    if ("-1," + y in warningGrids) return warningGrids["-1," + y];
    if (x + "," + y in warningGrids) return warningGrids[x + "," + y];
    return undefined;
  }
  /*static setSizeIndex(newValue: number){
    this._sizeIndex = newValue
    const masksPer10CmMultipliers = [0,1,2,3,4].map(
      it => Settings.circumfences[it]/Settings.baseCircumfence
    );
    this.setMasksPer10Cm(
      Settings.startMasksPer10Cm * masksPer10CmMultipliers[newValue]
    );
  }*/
  static setMasksPer10Cm(width: number, height: number) {
    this._masksPer10cm = width;
    this._masksPer10cmRatio = height / width;
    this._maskHeight = Math.round(16 / this._masksPer10cmRatio);

    updateMaskWidthHeight(this._maskWidth, this._maskHeight);
    this.updateCanvasDimensions();
  }
  static updateCanvasDimensions() {
    const masksPer10CmCorrection = 19 / 18;
    // Everything have been scaled perfectly,
    // but with masksPer10Cm = 19 instead of 18.
    // Therefore this correction is used.
    // Too much manual work, and rescaling with
    // the actual model would be necessary to fix
    // this correction in the proper way.
    // This is perfectly fine, just a bit ugly.
    const masksPer10Cm = this._masksPer10cm * masksPer10CmCorrection;
    Global.canvasWidth = (4096 * masksPer10Cm) / 10;
    Global.canvasHeight = (4096 * masksPer10Cm) / 10;
  }
}
Global.updateCanvasDimensions();
