import { DesignerSales } from "../types/backend-generated/DesignerSales";
import { woolitApi } from "../store/woolitApi";

const woolitApiWithSales = woolitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllSales: builder.query<DesignerSales, number | null>({
      query: (orgId: number) => {
        if (orgId) {
          return `/wds/sales/${orgId}`;
        } else {
          return "/wds/sales";
        }
      },
    }),
  }),
});
export const { useGetAllSalesQuery } = woolitApiWithSales;
