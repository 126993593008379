import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../store/store";
import {
  setGaugeStitchesHorizontal,
  setGaugeStitchesVertical,
  setGaugeLengthHorizontal,
  setGaugeLengthVertical,
} from "../../store/calculationSlice";

import styles from "./Gauge.module.css";
import { Tooltip } from "../../CommonComponents/Tooltip";
import { useTranslation } from "react-i18next";

function Gauge() {
  const { t } = useTranslation();
  const { horizontal, vertical } = useSelector(
    (state: RootState) => state.calculations.gauge
  );

  const [hStich, setHStitch] = useState<string>(`${horizontal.stitches}`);
  const [hLength, setHLength] = useState<string>(`${horizontal.length}`);
  const [vStitches, setVStitches] = useState<string>(`${vertical.stitches}`);
  const [vLength, setVLength] = useState<string>(`${vertical.length}`);

  useEffect(() => {
    setHStitch(`${horizontal.stitches}`);
  }, [horizontal.stitches]);
  useEffect(() => {
    setHLength(`${horizontal.length}`);
  }, [horizontal.length]);
  useEffect(() => {
    setVStitches(`${vertical.stitches}`);
  }, [vertical.stitches]);
  useEffect(() => {
    setVLength(`${vertical.length}`);
  }, [vertical.length]);

  const dispatch = useDispatch();

  return (
    <div className={styles.container}>
      <div className={styles.gaugeRow}>
        <div className={styles.label}>{t("terms.width")}: </div>
        <div>
          <input
            type="text"
            value={hStich}
            onChange={(e) => setHStitch(e.target.value)}
            onBlur={(e) =>
              dispatch(setGaugeStitchesHorizontal(parseIntWithIsNan(hStich, 1)))
            }
          />
        </div>
        <div style={{ width: "90px" }}>
          {" "}
          {t("terms.stitch", {
            count: horizontal.stitches,
          }).toLowerCase()}{" "}
          ={" "}
        </div>
        <div>
          <input
            type="text"
            value={hLength}
            onChange={(e) => setHLength(e.target.value)}
            onBlur={(e) =>
              dispatch(setGaugeLengthHorizontal(parseIntWithIsNan(hLength, 1)))
            }
          />
        </div>
        <div> cm</div>
      </div>

      <div className={styles.gaugeRow}>
        <div className={styles.label} style={{ display: "flex" }}>
          {t("terms.height")}: <Tooltip>{t("tooltips.gauge height")}</Tooltip>
        </div>
        <div>
          <input
            type="text"
            value={vStitches}
            onChange={(e) => setVStitches(e.target.value)}
            onBlur={(e) =>
              dispatch(setGaugeStitchesVertical(parseIntWithIsNan(vStitches)))
            }
          />
        </div>
        <div style={{ width: "90px" }}>
          {" "}
          {t("terms.round", { count: vertical.stitches }).toLowerCase()} ={" "}
        </div>
        <div>
          <input
            type="text"
            value={vLength}
            onChange={(e) => setVLength(e.target.value)}
            onBlur={(e) =>
              dispatch(setGaugeLengthVertical(parseIntWithIsNan(vLength)))
            }
          />
        </div>
        <div> cm</div>
      </div>
    </div>
  );
}

export default Gauge;

const parseIntWithIsNan = (stringNumber: string, defaultValue: number = 0) => {
  if (stringNumber === "") {
    return defaultValue;
  }
  if (isNaN(parseInt(stringNumber))) {
    return defaultValue;
  } else {
    return parseInt(stringNumber);
  }
};
