import { useEffect } from "react";
import SweaterDesigner from "./SweaterDesigner";
import { Global } from "./static/global";
import { RootState } from "../store/store";
import { useSelector } from "react-redux";

function Visualizing3D(props: any) {
  let windowTarget: any;
  let canvasTarget: any;

  function onPointerMoveCheck(event: any) {
    windowTarget = event;
    Global.hoveringVisualizing3D = windowTarget === canvasTarget;
  }

  function onPointerMove(event: any) {
    canvasTarget = event;
  }

  const { marginBottomModel } = useSelector((state: RootState) => state.model);

  useEffect(() => {
    let canvas = document.getElementById("canvas_visualizing_3d")!!;
    window.addEventListener("pointermove", onPointerMoveCheck);
    canvas.addEventListener("pointermove", onPointerMove);
  }, []);

  return (
    <div
      id="canvas_visualizing_3d"
      style={{
        height: "100vh",
        backgroundColor: "var(--whiteish)",
      }}
    >
      <div
        id="canvas_visualizing_3d"
        style={{
          height: `calc(100vh - ${marginBottomModel}px)`,
        }}
      >
        <SweaterDesigner />
      </div>
    </div>
  );
}
export default Visualizing3D;
