import { shortUUID } from "../utils/uuid";
import { gridArray } from "./initialDiagramState";
import { Patch } from "immer";
import { User } from "../Login/LoginState";

import {
  CalculationSliceType,
  emptyCalculationSlice as emptyCalculation,
} from "../CalculationGraph/calculations";
import { DynamicSymbols, Symbols } from "@iterate/woolit-components";
import { isDynamic } from "../CommonComponents/Icons/lookup";
import { knitSizeDefaultResize } from "../Visualizing3D/knittingpreview/sweater/rescaler";

type Id = number;

type ContentId = String;

export const SIZES = ["XXS", "XS", "S", "M", "L", "XL", "XXL", "XXXL"];

interface PatternOwner {
  id: number;
  firstName: string | null;
  middleName: string | null;
  lastName: string | null;
  profilePicture: number | null;
  slug: string | null;
}

export interface SizeGroup {
  label: string;
  sizes: string[];
}

export type GridSymbol = {
  key: Symbols;
  label: string;
};

export type DynamicGridSymbol = {
  key: DynamicSymbols;
  label: string;
  length?: number | null;
};

export function isDynamicGridSymbol(
  x: GridSymbol | DynamicGridSymbol
): x is DynamicGridSymbol {
  return isDynamic(x.key);
}

type Meta = {
  title: string;
  design: string;
  designer: string;
  collection: string;
  description: string; // | null
  status: Status;
  sizeCategory: SizeCategory | null;
  sizeGroups: SizeGroup[];
  sizes: string[];
  variants: YarnProfileVariant[];
  keyMeasures: KeyMeasure[];
  roles: Role[];
  clothingMetaData: ClothingMetaData[];
  yarnProducer: string;
  gaugeNeedle: string | null; // NeedleRef
  needleOrder: string[];
  knitAlong?: KnitAlong;
  form: "freeform" | "sweater" | "sock";
  knitMethod: "raglan" | "stitched-sleeves" | "circular-yoke";
  measurements: { [key: string]: { [key: string]: number } };
  visualRepresentationSize: string;
};

type Pattern = {
  // Unique ID set by server.
  id: Id;
  owner: PatternOwner;
  // Computed by the server on last successful save. When a pattern is sent back
  // to the server, `loadedContentId` can be used to determine whether a write
  // conflict is pending.
  loadedContentId: ContentId;
  organizationId: number | null;

  meta: Meta;

  patternElements: PatternElement[];

  selectedElements: number[];

  copiedPatternElements: PatternElement[];

  undoStack: { patches: Patch[]; inversePatches: Patch[] }[];

  undoStackPointer: number;

  comments: { [key: string]: Comment };

  needles: { [key: string]: Needle };

  calculationResults: CalculationSliceType;

  figures: { [key: string]: Figure };

  diagrams: { [key: string]: DiagramData };

  yarnColors: { [key: string]: Color };

  media: { [key: string]: Image };

  useIcons: boolean;

  updatedAt: string;

  symbols: (GridSymbol | DynamicGridSymbol)[];

  tags: string[];
};

type KnitAlong = {
  startDate?: string;
  endDate?: string;
  signupDeadline?: string;
  hashtags?: string[];
};

type PatternOverview = {
  // Unique ID set by server.
  id: Id;
  owner: PatternOwner;

  meta: {
    variants: YarnProfileVariant[];
    title: string;
    design: string;
    designer: string;
    collection: string;
    yarnProducer: string;
    status?: Status;
  };
};

interface Role {
  title: string;
  value: string;
}

export interface ClothingMetaData {
  title: string;
  value: string;
}

interface Gauge {
  masks: number;
  rows: number;
  cmX: number;
  cmY: number;
  method: string;
  needle: Needle;
}

type NeedleType = "rundpinne" | "strømpepinner" | "heklenål";
const ALL_NEEDLE_TYPES: NeedleType[] = [
  "rundpinne",
  "strømpepinner",
  "heklenål",
];

interface Needle {
  type: NeedleType;
  diameter: number | string;
  size?: number | string;
}

interface KeyMeasure {
  name: string;
  measure: Measure;
}

interface Measure {
  [key: string]: number;
}

interface NaiveUser {
  name: string;
}

interface Image {
  src: string;
  alt: string;
}

interface YarnProfileVariant {
  name: string;
  id: string;
  colors: Color[];
  images: Image[];
  amounts: { [key: string]: Measure };
}

type Color = OnlyColor | YarnColor;

type OnlyColor = {
  sku: null;
  hex: string;
  name?: string;
};

type YarnColor = {
  name: string;
  code: string;
  sku: string;
  image: string;
  yarn: Yarn;
  hex: string | null;
  customHex?: boolean | null;
};

interface Yarn {
  name: string;
  sku: string;
  gauge?: Gauge;
  id?: number;
}

interface DiagramComment {
  comment: string;
  relation?: "lower" | "upper" | "middle";
  sizes: string[];
}

// Key should be index of row or col
interface DiagramComments {
  colComments: {
    [key: string]: DiagramComment;
  };
  rowComments: {
    [key: string]: DiagramComment;
  };
}

interface DiagramPattern {
  start: number;
  end: number;
  comment: string;
  sizes: string[];
}

interface DiagramPatterns {
  rowPatterns: { [key: string]: DiagramPattern };
  colPatterns: { [key: string]: DiagramPattern };
}

/*
  key: (col | row)_index
  example: row_0: [sizes], col_4: [sizes]
*/
interface Excluded {
  [key: string]: string[];
}

export interface DynamicDiagramSymbol {
  symbol: DynamicSymbols;
  start: { x: number; y: number };
  end: { x: number; y: number };
}

interface DiagramData {
  id: string;
  name: string;
  centerX: number | null;
  owner: NaiveUser;
  gridWidth: number;
  gridHeight: number;
  grid: GridCell[][];
  diagramComments?: DiagramComments;
  patterns?: DiagramPatterns;
  excluded?: Excluded;
  symbols: Symbol[];
  dynamicSymbols?: { [key: string]: DynamicDiagramSymbol }; // key: x1_y1_x2_y2
  lines?: { [key: string]: DiagramLine }; // key: idx_direction
}

export interface GridCell {
  color: number;
  symbol: Symbols;
}

type CommentType = "comment" | "change";
interface CommentAnswer {
  answer: string;
  user: User;
  timestamp: Date;
}
interface Comment {
  commentType: CommentType;
  comment: string;
  id: string;
  user: User;
  timestamp: Date;
  answers: CommentAnswer[];
}

export type StatusMsg =
  | "Utkast"
  | "Ferdig"
  | "Prøvestrikk"
  | "Korrektur"
  | "Forkastet"
  | "Avbrutt"
  | "Under arbeid";

interface StatusLog {
  statusMsg: StatusMsg;
  user: User;
  timestamp: string;
}
interface Status {
  statusMsg: StatusMsg;
  timestamp?: string;
  user?: User;
  logs?: StatusLog[];
}

interface DiagramLine {
  show: boolean;
  color?: string;
}

type PatternElement =
  | Span
  | TextLink
  | Subheading
  | CalculationResult
  | ColorRef
  | NeedleRef
  | Diagram
  | Heading
  | DiagramSection
  | FigureSection
  | SizeFilteredString
  | { type: "paragraphBreak"; commentId?: string };
export type Editable = Span | Heading | Subheading;

type TextLink = {
  type: "textlink";
  markdown: string;
  link: string;
  commentId?: string;
};

type Subheading = {
  type: "subheading";
  markdown: string;
  commentId?: string;
  style?: string;
};

type Heading = {
  type: "heading";
  markdown: string;
  commentId?: string;
  style?: string;
  publishDate?: string;
};

type FigureSection = {
  id: string;
  type: "figure";
  figureId: string;
  inline?: boolean;
  commentId?: string;
};

type DiagramSection = {
  id: string;
  type: "diagramSection";
  diagram: string;
  commentId?: string;
};

type ColorRef = {
  type: "color";
  ref: number;
  commentId?: string;
};

type Span = {
  type: "span";
  markdown: string;
  commentId?: string;
  style?: string;
};

type SizeFilteredString = {
  type: "sizeFilteredString";
  text: { [size: string]: string };
  commentId?: string;
};

type CalculationResult = {
  type: "calculationResult";
  tableId: string;
  measureId: string;
  row1?: number;
  row2?: number;
  commentId?: string;
  edgeKind?: "horizontal" | "frequency" | "vertical";
};

type Diagram = {
  type: "diagram";
  ref: string;
  id?: string;
  commentId?: string;
};

type NeedleRef = {
  type: "needle";
  ref: string;
  commentId?: string;
};

interface Figure {
  title: string;
  diagramUrl: string;
}

export interface NewModelKnitAssignment {
  priceOre: number;
  size: string | null;
  created?: string;
  variantId: string | null;
  knitterId?: number;
  organizationId: number | null;
  yarn?: any;
  deadline?: string;
  comment?: string;
  knitterTag?: string;
}

export interface ModelKnitAssignment {
  id: number;
  created: string;
  updatedAt: string;
  ownerId: number;
  ownerName: string;
  knitterId?: number;
  knitterName?: string;
  organizationId?: number;
  orderId: number;
  patternId: number;
  yarn: { [key: string]: number };
  price: { knitter: number; woolit: number };
  knitterTag?: string;
  deadline?: string;
  comment: string;
  size: string;
  variantId: string;
  state: ModelKnitState;
}

export interface Knitter {
  name: string;
  tag: string;
  id: number;
}

export interface ModelKnitGroup {
  name: string;
  tag: string;
  knitters: Knitter[];
}

export interface ModelKnitState {
  schedule: "on-time" | "delayed" | "none";
  progress:
    | {
        status:
          | "waiting-for-knitter"
          | "knitter-accepted"
          | "yarn-received"
          | "sent-back";
      }
    | { status: "knitting"; details: string }
    | { status: "received"; details: "waiting" | "approved" | "refused" };
}

// Manually set initialState id and contentId to empty string to communicate
// that these are not valid

const initialState: Pattern = {
  id: -1,
  loadedContentId: "",
  organizationId: -1,
  owner: {
    id: -1,
    firstName: null,
    lastName: null,
    middleName: null,
    slug: null,
    profilePicture: null,
  },
  meta: {
    title: "",
    design: "",
    designer: "",
    collection: "",
    description: "",
    status: {
      statusMsg: "Utkast",
      timestamp: "",
      user: {
        id: -1,
        firstName: "",
        lastName: "",
        middleName: "",
        email: "",
        isAdmin: false,
        subscriber: true,
        modules: [],
        subscription: {
          subscriptionType: "custom",
          isTrial: false,
          modules: [],
          trialDaysLeft: undefined,
        },
        tutorial: null,
      },
      logs: [],
    },
    sizeCategory: "Dame",
    sizeGroups: [
      {
        label: "Dame",
        sizes: ["XXS", "XS", "S", "M", "L", "XL", "XXL", "XXXL"],
      },
      { label: "Herre", sizes: ["XS", "S", "M", "L", "XL", "XXL", "XXXL"] },
      { label: "Barn", sizes: ["0-2 år", "2-4 år", "4-6 år"] },
      { label: "Unisex", sizes: SIZES },
    ],
    sizes: ["XS", "S", "M", "L", "XL", "XXL", "XXXL"],
    variants: [],
    keyMeasures: [],
    roles: [],
    clothingMetaData: [],
    yarnProducer: "",
    gaugeNeedle: null,
    needleOrder: [],
    form: "freeform",
    knitMethod: "raglan",
    measurements: {
      XXS: knitSizeDefaultResize("XXS"),
      XS: knitSizeDefaultResize("XS"),
      S: knitSizeDefaultResize("S"),
      M: knitSizeDefaultResize("M"),
      L: knitSizeDefaultResize("L"),
      XL: knitSizeDefaultResize("XL"),
      XXL: knitSizeDefaultResize("XXL"),
      XXXL: knitSizeDefaultResize("XXXL"),
    },
    visualRepresentationSize: "M",
  },
  patternElements: [
    {
      type: "heading",
      markdown: "",
    },
    { type: "span", markdown: "" },
  ],
  selectedElements: [],
  copiedPatternElements: [],
  undoStack: [],
  undoStackPointer: -1,
  comments: {},
  calculationResults: emptyCalculation,
  figures: {},
  diagrams: {},
  yarnColors: {},
  needles: {},
  useIcons: false,
  media: {},
  updatedAt: "",
  symbols: [],
  tags: [],
};

// Empty object creators
function emptyDiagramData(): DiagramData {
  return {
    id: shortUUID(),
    name: "New Diagram",
    centerX: null,
    owner: { name: "" },
    gridWidth: 10,
    gridHeight: 10,
    grid: gridArray,
    symbols: [],
  };
}

type SizeCategory = "Dame" | "Herre" | "Barn" | "Unisex" | string;

type Symbol = {
  label: string;
  symbolKey: string;
  hotkey: string;
};

export { initialState, emptyDiagramData, ALL_NEEDLE_TYPES };
export type {
  Meta,
  Pattern,
  PatternOverview,
  PatternElement,
  FigureSection,
  DiagramSection,
  Figure,
  Needle,
  NeedleType,
  Measure,
  YarnProfileVariant,
  Color,
  OnlyColor,
  YarnColor,
  Image,
  DiagramData,
  Heading,
  Span,
  SizeCategory,
  Role,
  Comment,
  CommentType,
  Status,
  KnitAlong,
};
