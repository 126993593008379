import React, { useMemo } from "react";
import { Column, useTable } from "react-table";
import styles from "./Table.module.css";
import Table from "./Table";
import { useGetUsersQuery } from "../services/organization";
import { User } from "../Login/LoginState";

type TableProps = {
  data: User[];
};

const MembersTable: React.FC<TableProps> = ({ data = [] }) => {
  const columns: Column<User>[] = useMemo(() => {
    const columns: Column<User>[] = [
      { Header: "ID", accessor: "id" },
      { Header: "Fornavn", accessor: "firstName" },
      {
        Header: "Etternavn",
        accessor: "lastName",
      },
    ];
    return columns;
  }, []);
  const instance = useTable({ columns, data });

  return (
    <div>
      <Table instance={instance} />
    </div>
  );
};

const Members = ({ orgId }: { orgId: string }) => {
  const members = useGetUsersQuery(orgId);
  if (!members.data) {
    return null;
  }
  return (
    <div>
      <div className={styles.titleContainer}>
        <h1>Medlemmer</h1>
      </div>

      <MembersTable data={members.data} />
    </div>
  );
};

export default Members;
