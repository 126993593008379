import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setActiveDiagram,
  setDisableHotkeys,
  updateGrid,
  updateMetaData,
} from "../store/gridSlice";
import { DiagramData } from "../store/pattern";
import {
  addEmptyDiagram,
  deleteDiagramById,
  updateDiagramById,
} from "../store/patternSlice";
import { RootState } from "../store/store";
import styles from "./diagram.module.css";
import PatternCanvas from "../Visualizing3D/knittingeditor/PatternCanvas";
import { Util } from "../Visualizing3D/static/util";
import { ContextMenu } from "./ContextMenu/RenderMenu";
import { shortUUID } from "../utils/uuid";
import { Settings } from "../Visualizing3D/static/settings";
import { setHideDiagrams, setMarginBottomModel } from "../store/modelSlice";
import { useTranslation } from "react-i18next";
import Button from "../ISO/Components/Button";
import { ModalButton } from "../CommonComponents/ModalButton";
import { NewDiagram } from "./NewDiagram";
import { useModal } from "../CommonComponents/useModal";
import { notInPattern } from "../utils/canDelete";
import { Modal } from "../CommonComponents/Modal";
export const DiagramSelector = () => {
  interface ContextMenuInfo {
    x: number;
    y: number;
    diagram: DiagramData;
  }
  const dispatch = useDispatch();
  const { diagrams, id, patternElements } = useSelector(
    (state: RootState) => state.pattern
  );
  const { activeDiagram } = useSelector((state: RootState) => state.grid);
  const color = useSelector((state: RootState) => state.color);
  const [contextMenuInfo, setContextMenuInfo] = useState<ContextMenuInfo>();

  const diagramListSizeRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  const { hideDiagrams } = useSelector((state: RootState) => state.model);

  const { isShown: addShow, toggle: addToggle } = useModal();
  const { isShown: showEditName, toggle: toggleEditName } = useModal();
  const [diagramNameInput, setDiagramNameInput] = useState("");

  useEffect(() => {
    dispatch(
      setMarginBottomModel(hideDiagrams ? 0 : Settings.diagramListHeight)
    );
  }, [hideDiagrams]);

  const handleDispatch = (diagram: DiagramData) => {
    dispatch(setActiveDiagram({ id: diagram.id }));
    dispatch(updateGrid({ grid: diagram }));
  };

  const makeDiagram = (diagram: DiagramData) => {
    dispatch(
      addEmptyDiagram({
        diagramId: diagram.id,
        diagramData: diagram,
      })
    );
    dispatch(
      updateDiagramById({
        patternId: id,
        diagram: diagram,
      })
    );

    dispatch(setActiveDiagram({ id: diagram.id }));
    dispatch(updateGrid({ grid: diagram }));
    dispatch(setDisableHotkeys(false));
  };

  const showEditDiagramNameModule = (diagram: DiagramData) => {
    dispatch(setActiveDiagram({ id: diagram.id }));
    dispatch(updateGrid({ grid: diagram }));

    setDiagramNameInput(diagram.name);

    toggleEditName();
  };

  const duplicateDiagram = (diagram: DiagramData) => {
    let duplicatedDiagram: DiagramData = {
      ...diagram,
      id: shortUUID(),
    };
    makeDiagram(duplicatedDiagram);
  };

  const deleteDiagram = (diagramKey: string, patternId: number) => {
    dispatch(
      deleteDiagramById({
        diagramId: diagramKey,
        patternId,
      })
    );
  };

  const canDelete = notInPattern(activeDiagram, patternElements);

  const contextMenuOptions = [];

  if (contextMenuInfo) {
    contextMenuOptions.push({
      label: t("edit_diagram_name"),
      icon: "/edit.svg",
      action: () => {
        showEditDiagramNameModule(contextMenuInfo.diagram);
      },
    });
    contextMenuOptions.push({
      label: t("duplicate"),
      icon: "/duplicate.svg",
      action: () => {
        duplicateDiagram(contextMenuInfo.diagram);
      },
    });
  }

  if (contextMenuInfo && canDelete) {
    contextMenuOptions.push({
      label: t("delete_diagram"),
      icon: "/3D/ui/delete2.png",
      fontColor: "red",
      action: () => {
        deleteDiagram(contextMenuInfo.diagram.id, id);
      },
    });
  }

  return (
    <>
      <Modal
        isShown={showEditName}
        hide={toggleEditName}
        headerText={t("edit_diagram_name")}
        modalContent={
          <div className={styles.modalContainer}>
            <div className={styles.modalContent}>
              <label>{t("diagram_name")}</label>
              <input
                style={{ width: "auto" }}
                className={styles.updated}
                value={diagramNameInput}
                onChange={(e) => setDiagramNameInput(e.target.value)}
              />
            </div>
            <button
              id="edit-name"
              style={{ width: "100%" }}
              onClick={() => {
                toggleEditName();
                dispatch(updateMetaData({ name: diagramNameInput }));
              }}
            >
              {t("edit_diagram_name")}
            </button>
          </div>
        }
      />
      {contextMenuInfo && (
        <div style={{ zIndex: 1000 }}>
          <ContextMenu
            x={contextMenuInfo.x}
            y={contextMenuInfo.y - 150}
            options={contextMenuOptions}
            handleClose={() => {
              setContextMenuInfo(undefined);
            }}
            defaultVisibility={true}
            dontDispatchActions
          />
        </div>
      )}
      <div className={`${styles.bottom}`}>
        <div
          style={{
            display: "flex",
            marginBottom: "30px",
          }}
        >
          <p
            style={{
              marginLeft: "30px",
            }}
          >
            {t("draw.my_diagrams")}:
          </p>
          <Button
            style={{
              pointerEvents: "auto",
              verticalAlign: "center",
              border: "none",
              backgroundColor: "#00000000",
              marginTop: "-5px",
            }}
            onClick={() => {
              dispatch(setHideDiagrams(!hideDiagrams));
            }}
          >
            <img
              alt={"arrow"}
              src={`/arrow_${hideDiagrams ? "up" : "down"}_triangle.png`}
              style={{
                height: "6px",
              }}
            ></img>
          </Button>
        </div>
        {!hideDiagrams && (
          <div
            style={{
              margin: "30px",
              marginTop: "10px",
              gap: "20px",
            }}
          >
            <div className={`${styles.diagramList}`}>
              <ModalButton
                headerText={t("draw.create diagram")}
                style={{
                  border: "none",
                  textAlign: "left",
                  color: "gray",
                  pointerEvents: "auto",
                  padding: "0px",
                  display: "flex",
                  width: "134px", // 120px + Padding _ 5 _ 5 + border 2px
                  height: "132px", // 120px + Padding 3 _ 5 _ + border 2px
                }}
                modalContent={<NewDiagram toggle={addToggle} patternId={id} />}
                toggle={addToggle}
                isShown={addShow}
              >
                <div
                  id="intro-draw2"
                  style={{
                    margin: "auto",
                    display: "flex",
                    width: "100px",
                    height: "100px",
                    backgroundColor: "var(--neutral-10)",
                  }}
                >
                  <img
                    alt="plus_black"
                    src="/plus_black.svg"
                    style={{
                      margin: "auto",
                      width: "50px",
                      height: "50px",
                    }}
                  ></img>
                </div>
              </ModalButton>
              <div
                ref={diagramListSizeRef}
                style={{
                  overflowX: "auto",
                  paddingTop: "50px", // To show the hover text
                }}
              >
                <div
                  className={`${styles.diagramList}`}
                  style={{
                    pointerEvents: "auto",
                  }}
                >
                  {Object.entries(diagrams)
                    // .sort(([_, diagram1], [__, diagram2]) =>
                    //   diagram1.name.localeCompare(diagram2.name)
                    // )
                    .map(([diagramKey, diagram]) => {
                      //const canDelete = notInPattern(diagramKey, patternElements);
                      return (
                        <Button
                          id="intro-draw5"
                          key={diagramKey}
                          active={activeDiagram === diagramKey}
                          style={{
                            padding: "0px",
                          }}
                          onClick={() => {
                            handleDispatch(diagram);
                          }}
                          onContextMenu={(e: PointerEvent) => {
                            e.preventDefault();
                            setContextMenuInfo({
                              x: e.screenX,
                              y: e.screenY,
                              diagram: diagram,
                            });
                          }}
                          contrast
                        >
                          <div
                            style={{
                              fontWeight: 500,
                              marginTop: "5px",
                              marginBottom: "-5px",
                            }}
                          >
                            {diagram.name}
                          </div>
                          <div
                            style={{
                              width: "120px",
                              height: "120px",
                              padding: "5px 5px 3px 5px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <PatternCanvas
                              pattern={Util.diagramToPattern(diagram)}
                              colors={color.colors.map((it: any) => it.hex)}
                            />
                          </div>
                        </Button>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DiagramSelector;
