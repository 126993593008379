import Button from "../../ISO/Components/Button";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { setGarmentForm } from "../../store/patternSlice";
import { Load } from "../../Visualizing3D/SaveLoad";
import { useTranslation } from "react-i18next";

export const FormCard = (props: {
  garmentForm: "freeform" | "sweater" | "sock";
  text: string;
  src: string;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { form } = useSelector((state: RootState) => state.pattern.meta);

  const { id } = useSelector((state: RootState) => state.pattern);
  const checkIfDesignExists = (newForm: string, action: any) => {
    Load(`${id}`, (load: any) => {
      const existsAndDifferent =
        load && load["design"] && load["name"] !== newForm;
      if (
        newForm !== "freeform" &&
        existsAndDifferent &&
        !window.confirm(t("datadrawer.overwrite model"))
      ) {
        return;
      }
      action();
    });
  };
  return (
    <div>
      <Button
        active={form === props.garmentForm}
        onClick={() => {
          if (form === props.garmentForm) return;
          checkIfDesignExists(props.garmentForm, () =>
            dispatch(setGarmentForm(props.garmentForm))
          );
        }}
        style={{
          height: "100px",
          padding: "0px",
        }}
        contrast
      >
        <img
          alt="form"
          src={props.src}
          style={{
            boxSizing: "border-box",
            borderRadius: "2px",
            height: "96px",
          }}
        ></img>
      </Button>
      <p
        style={{
          marginTop: "4px",
          fontSize: "12px",
          fontWeight: 500,
        }}
      >
        {" "}
        {props.text}
      </p>
    </div>
  );
};
