import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import {
  setVariantName,
  addVariantColor,
  removeVariantColor,
  moveVariantColor,
  deleteMetadataVariant,
  setVariantColor,
} from "../../store/patternSlice";
import { Color } from "../../store/pattern";
import ColorPreview from "../../CommonComponents/ColorPreview";
import YarnColorPicker from "../../CommonComponents/YarnColorPicker";
import VariantImageList from "./VariantImageList";
import AwareYarnSelector from "./YarnSelector";
import { VariantImageHandler } from "./VariantImageHandler";
import drawerStyles from "../../Metadata/MetadataDrawer.module.css";
import styles from "./Variant.module.css";
import { closeSubdrawer } from "../../store/writerSlice";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { setActiveVariant } from "../../ISO/displayState";
import { ArrowLeftIcon } from "@radix-ui/react-icons";
import Button from "../../ISO/Components/Button";
import { Tooltip } from "../../CommonComponents/Tooltip";
import { useTranslation } from "react-i18next";
import { useModal } from "../../CommonComponents/useModal";
import { useOrganizationYarnQuery } from "../../services/yarnApi";

interface PropType {
  id: string;
}

function Variant(props: PropType) {
  const { id } = props;
  const { t } = useTranslation();
  const { variant, variantIndex, variants, activeVariant, organizationId } =
    useSelector((state: RootState) => ({
      variant: state.pattern.meta.variants.filter(
        (variant) => variant.id === id
      )[0],
      variantIndex: state.pattern.meta.variants.findIndex(
        (variant) => variant.id === id
      ),
      variants: state.pattern.meta.variants,
      activeVariant: state.displayState.activeVariant,
      organizationId: state.pattern.organizationId,
    }));

  const { data: yarn } = useOrganizationYarnQuery(organizationId);

  const [title, setTitle] = useState(variant?.name || "");
  useEffect(() => setTitle(variant?.name || ""), [variant?.name]);

  const [editColor, setColorIndex] = useState(-1);

  const dispatch = useDispatch();

  const onColorSelectorComplete = (colorIndex: number, color: Color) => {
    dispatch(
      setVariantColor({
        variantId: variant.id,
        colorIndex,
        color,
      })
    );
  };

  const { isShown, toggle } = useModal();

  const ColorPickerModal: React.FC<{ index: number }> = ({ index }) => {
    const color = variant.colors[index];
    return color ? (
      <YarnColorPicker
        index={index}
        isShown={isShown}
        toggle={toggle}
        color={color}
        onChangeComplete={(col: Color) => {
          onColorSelectorComplete(index, col);
        }}
        yarn={yarn}
      />
    ) : (
      <></>
    );
  };

  return variant ? (
    <div className={styles.container}>
      <div className={drawerStyles.closeSubdrawerButton}>
        <Button onClick={() => dispatch(closeSubdrawer())}>
          <ArrowLeftIcon />
        </Button>
      </div>
      <Button
        contrast
        disabled={variants.length <= 1 || activeVariant === id}
        className={styles.deleteSection}
        onClick={() => {
          const newIndex =
            variantIndex === 0 ? variants.length - 1 : variantIndex - 1;

          dispatch(deleteMetadataVariant({ variantId: variant.id }));
          dispatch(setActiveVariant(variants[newIndex].id));
        }}
      >
        {t("delete color variant")}
      </Button>
      {activeVariant === id && (
        <Tooltip>{t("tooltips.delete variant")}</Tooltip>
      )}
      <input
        className={styles.title}
        value={title}
        placeholder={t("new color variant")}
        onChange={(e) => setTitle(e.target.value)}
        onBlur={(e) => dispatch(setVariantName({ id, name: e.target.value }))}
      />
      <hr className={styles.spacer} />
      <DragDropContext
        onDragEnd={(result: DropResult) => {
          if (result.destination?.index === undefined) {
            return;
          }
          dispatch(
            moveVariantColor({
              oldIndex: result.source.index,
              newIndex: result.destination.index,
              variantIndex,
            })
          );
        }}
      >
        <Droppable droppableId="colorList">
          {(droppableProvided) => (
            <div
              id="intro-information5"
              {...droppableProvided.droppableProps}
              ref={droppableProvided.innerRef}
              className={styles.colorList}
            >
              {variant.colors.map((color, index) => (
                <Draggable
                  draggableId={index.toString()}
                  index={index}
                  key={index}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}
                      key={`${color.sku || color.hex}-${index}`}
                      className={styles.rowContainer}
                    >
                      <div className={styles.preview}>
                        <button
                          className={styles.editColorButton}
                          onClick={() => {
                            toggle();
                            setColorIndex(index);
                          }}
                        >
                          <ColorPreview color={color} />
                        </button>
                        <div className={styles.colorLabel}>
                          {color.sku ? (
                            `${color.yarn.name} ${color.name} ${color.code}`
                          ) : (
                            <>
                              <input
                                className={styles.colorNameInput}
                                onFocus={(e) => e.target.select()} // Select all text when selecting the field
                                style={{
                                  width: `${(color.name?.length || 7) + 1}ch`,
                                }}
                                value={color.name}
                                placeholder={t("untitled")}
                                onChange={(e) =>
                                  dispatch(
                                    setVariantColor({
                                      variantIndex,
                                      colorIndex: index,
                                      color: { ...color, name: e.target.value },
                                    })
                                  )
                                }
                              />
                              <div className={styles.colorHex}>
                                &nbsp;{color.hex}
                              </div>
                            </>
                          )}
                        </div>
                        <div className={styles.pusher}>&nbsp;</div>
                        <button
                          className={styles.delete}
                          onClick={() =>
                            dispatch(
                              removeVariantColor({
                                variantId: variant.id,
                                colorIndex: index,
                              })
                            )
                          }
                        >
                          &nbsp;
                        </button>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <button
        className={styles.new}
        onClick={() => dispatch(addVariantColor({ variantId: variant.id }))}
      >
        {t("new color")}
      </button>
      <hr className={`${styles.spacer} ${styles.between}`} />
      <VariantImageList variant={variant} />
      <hr className={`${styles.spacer}`} />
      <VariantImageHandler variantId={variant.id} />
      <hr className={`${styles.spacer} ${styles.between}`} />
      <h2 className={`${styles.sectionTitle}`}>{t("choose yarn")}</h2>
      <hr className={`${styles.spacer}`} />
      <AwareYarnSelector organizationId={organizationId} />
      {editColor !== -1 && <ColorPickerModal index={editColor} />}
    </div>
  ) : (
    <p>No such variant</p>
  );
}

export default Variant;
