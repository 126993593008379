import React from "react";
import Imgix from "react-imgix";
import { useDispatch, useSelector } from "react-redux";
import { imgixUrl } from "../../imgixUrl";
import Button from "../../ISO/Components/Button";
import { addVariantImage } from "../../store/patternSlice";
import { RootState } from "../../store/store";
import { ImageUpload } from "./ImageUpload";
import styles from "./Variant.module.css";
import { t } from "i18next";

interface PropType {
  variantId: string;
  variantIndex?: number;
}

export const VariantImageHandler = (props: PropType) => {
  const { variantId, variantIndex } = props;

  const dispatch = useDispatch();

  const { id: patternId, media } = useSelector(
    (state: RootState) => state.pattern
  );

  return (
    <div>
      {t("datadrawer.add media")}
      <div className={styles.imageButtonContainer}>
        {Object.keys(media).map((image, index) => (
          <Button
            className={styles.imageButton}
            key={image}
            onClick={() => {
              dispatch(
                addVariantImage({
                  src: image,
                  alt: "",
                  variantIndex,
                  variantId,
                })
              );
            }}
          >
            <Imgix
              className={styles.image}
              key={index}
              src={`${imgixUrl}/${image}`}
            />
          </Button>
        ))}
      </div>
      {t("datadrawer.upload media")}
      <ImageUpload patternId={patternId} />
    </div>
  );
};
