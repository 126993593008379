import { useSelector, useDispatch } from "react-redux";

import { deleteDiagramById } from "../store/patternSlice";
import { RootState } from "../store/store";
import styles from "./diagram.module.css";
import { notInPattern } from "../utils/canDelete";

export const DiagramDelete = () => {
  const { diagrams, id, patternElements } = useSelector(
    (state: RootState) => state.pattern
  );
  const { activeDiagram } = useSelector((state: RootState) => state.grid);
  const dispatch = useDispatch();
  const deleteDiagram = (diagramKey: string, patternId: number) => {
    dispatch(
      deleteDiagramById({
        diagramId: diagramKey,
        patternId,
      })
    );
  };

  const canDelete = notInPattern(activeDiagram, patternElements);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
      }}
    >
      <button
        id="intro-delete"
        title={"Delete"}
        className={styles.stateButton}
        disabled={Object.keys(diagrams).length === 0 || !canDelete}
        onClick={() => {
          const prompt = window.confirm("Delete pattern?");
          if (prompt) {
            deleteDiagram(activeDiagram, id);
          }
        }}
        style={{ pointerEvents: "auto" }}
      >
        <img
          src="/3D/ui/delete2.png"
          style={{
            width: "16px",
            height: "16px",
          }}
          alt="reset"
        />
      </button>
    </div>
  );
};

export default DiagramDelete;
