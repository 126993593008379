import GridButton from "./components/GridButton";

function SplitViewMiniature(props: any) {
  const fullscreenStyle = [
    {
      position: "relative",
      height: "100%",
    } as const,
    {
      position: "absolute",
      left: "25px",
      top: "80px",
      width: "230px",
      aspectRatio: "1",
      zIndex: 100,
      border: "1px solid #ede9e6",
    } as const,
  ] as const;
  const fullscreenButton = () => (
    <div
      style={{
        margin: "5px",
        position: "absolute",
        zIndex: 200,
        marginLeft: "5px",
      }}
    >
      <GridButton
        customStyle={{
          height: "35px",
          minWidth: "35px",
          maxWidth: "35px",
        }}
        onClick={() => {
          props.hasSwitched(!props.switched);
        }}
        small
      >
        <img
          alt="fullscreen"
          src="/3D/ui/fullscreen.png"
          style={{ width: "25px", height: "25px" }}
        />
      </GridButton>
    </div>
  );
  return (
    <div
      style={{
        display: "flex",
        backgroundColor: "var(--whiteish)",
        paddingBottom: "0vh",
        position: "relative",
        flexDirection: "column",
        height: "100%", // Would really like a flex: 1 solution, but I couldnt find one
      }}
    >
      <div
        style={{
          ...fullscreenStyle[+props.switched],
          borderWidth: props.switched && props.dontShowAtSwitch ? 0 : 1,
        }}
      >
        {props.switched && fullscreenButton()}
        <div
          style={{
            height: "100%",
            width: "100%",
            opacity: props.switched && props.dontShowAtSwitch ? 0 : 1,
          }}
        >
          {props.children[0]}
        </div>
      </div>
      <div style={fullscreenStyle[+!props.switched]}>
        {!props.switched && fullscreenButton()}
        {props.children[1]}
      </div>
    </div>
  );
}

export default SplitViewMiniature;
