import useHotkeys from "@reecelucas/react-use-hotkeys";
import { useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import { BaseEditor, Transforms } from "slate";
import { ReactEditor } from "slate-react";

import { commands } from "../../utils/writerCommands";
import { CustomElement } from "../SlateWriter";
import { CalculationResultSelect } from "./CalculationResult";
import { ColorSelect } from "./Color";
import { DiagramReferenceSelect } from "./DiagramReference";
import { DiagramSectionSelect } from "./DiagramSection";
import { FigureSelect } from "./Figure";
import { NeedleSelect } from "./Needle";
import { SizeFilteredStringSelect } from "./SizeFilteredString";

import styles from "./element.module.css";
import { t } from "i18next";

interface PropType {
  paragraphElementType: string;
  closeSelector: Function;
  action: "" | "add" | "replace";
  x: number;
  y: number;
  screenY: number;
  editor: BaseEditor & ReactEditor;
  element: CustomElement | undefined;
}

export interface ElementSelectorProps {
  closeSelector: Function;
  action: "" | "add" | "replace";
  editor: BaseEditor & ReactEditor;
  element: CustomElement | undefined;
}

const ElementSelector = ({
  paragraphElementType,
  closeSelector,
  action,
  x: incomingX,
  y: incomingY,
  screenY,
  editor,
  element,
}: PropType) => {
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const clickOutside = (e: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(e.target as Node)) {
        closeSelector();
      }
    };
    document.addEventListener("mousedown", clickOutside);

    return () => {
      document.removeEventListener("mousedown", clickOutside);
    };
  });

  const [{ x, y }, setMenuPosition] = useState<{ x: number; y: number }>({
    x: incomingX,
    y: incomingY,
  });

  const [called, setCalled] = useState(false);

  useHotkeys("Backspace", (e) => {
    if (paragraphElementType !== "sizeFilteredString") {
      closeSelector();
    }
  });

  useEffect(() => {
    if (called) return;

    const menuHeight = menuRef.current?.clientHeight ?? 0;
    const innerHeight = window.innerHeight - 96;

    if (screenY + menuHeight > innerHeight) {
      setMenuPosition({ x, y: y - menuHeight + innerHeight - screenY });
    }
    setCalled(true);
  }, [menuRef.current?.clientHeight]);

  useEffect(() => {
    const itemWidth = menuRef.current?.clientWidth ?? 0;
    const innerWidth = window.innerWidth - 72;
    if (x + itemWidth > innerWidth) {
      setMenuPosition({ y, x: Math.max(innerWidth - itemWidth, 16) });
    }
  }, [menuRef.current?.clientWidth]);

  // Elements that dont need a selector window goes here
  useEffect(() => {
    if (
      paragraphElementType === "span" ||
      paragraphElementType === "heading" ||
      paragraphElementType === "subheading"
    ) {
      if (action === "add" || action === "") {
        Transforms.insertNodes(editor, {
          type: paragraphElementType,
          children: [{ text: "" }],
        });
      } else {
        const path = element
          ? ReactEditor.findPath(editor, element)
          : undefined;
        Transforms.setNodes(
          editor,
          {
            type: paragraphElementType,
            children: [{ text: "" }],
          },
          { at: path }
        );
      }
      closeSelector();
    }
  });

  const selectType = () => {
    switch (paragraphElementType) {
      case "calculationResult":
        return (
          <CalculationResultSelect
            action={action}
            closeSelector={closeSelector}
            editor={editor}
            element={element}
          />
        );
      case "sizeFilteredString":
        return (
          <SizeFilteredStringSelect
            action={action}
            closeSelector={closeSelector}
            editor={editor}
            element={element}
          />
        );
      case "needle":
        return (
          <NeedleSelect
            action={action}
            closeSelector={closeSelector}
            editor={editor}
            element={element}
          />
        );
      case "color":
        return (
          <ColorSelect
            action={action}
            closeSelector={closeSelector}
            editor={editor}
            element={element}
          />
        );
      case "diagram":
        return (
          <DiagramReferenceSelect
            action={action}
            closeSelector={closeSelector}
            editor={editor}
            element={element}
          />
        );
      case "diagramSection":
        return (
          <DiagramSectionSelect
            action={action}
            closeSelector={closeSelector}
            editor={editor}
          />
        );
      case "media":
        return (
          <FigureSelect
            action={action}
            closeSelector={closeSelector}
            editor={editor}
          />
        );

      default:
        return <p>Do I look like i know what a {paragraphElementType} is.</p>;
    }
  };

  return (
    <Draggable>
      <div
        ref={menuRef}
        className={styles.container}
        style={{
          position: "absolute",
          top: Math.max(y, 60),
          left: Math.max(x, 0),
        }}
      >
        <div className={styles.top}>
          <p>
            {t("add")} {commands[paragraphElementType]}
          </p>
          <button
            className={styles.closeButton}
            onClick={() => closeSelector()}
          >
            <img src="/x.svg" alt="lukk valg" />
          </button>
        </div>
        {selectType()}
      </div>
    </Draggable>
  );
};

export default ElementSelector;
