import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveColorIndex } from "../store/colorSlice";
import { setCursorMode } from "../store/gridSlice";
import { RootState } from "../store/store";
import colorStyles from "./colorlist.module.css";

interface PropType {
  index: number;
  color: any;
  row: number;
  variantIndex: number;
  className?: string;
}

const ColorType = (props: PropType) => {
  const { index, color, row, variantIndex } = props;

  const dispatch = useDispatch();
  const { activeIndex, cursorMode } = useSelector((state: RootState) => ({
    activeIndex: state.color.activeIndex,
    cursorMode: state.grid.cursorMode,
  }));

  if (!color) {
    return <></>;
  }

  return (
    <div
      className={props.className ?? colorStyles.colorContainer}
      onClick={() => {
        dispatch(setActiveColorIndex(index));
        if (cursorMode === "erase") {
          dispatch(setCursorMode({ mode: "color" }));
        }
      }}
    >
      <div
        title={`Ctrl+${index + 1} ${color.name}`}
        style={{
          padding: 2,
          borderRadius: 3,
          border: `1px ${
            activeIndex === index && row === variantIndex ? "black" : "white"
          } solid`,
        }}
      >
        <div
          style={{
            backgroundColor: color.hex,
            height: 18,
            width: 18,
            borderRadius: 2,
          }}
        />
      </div>
    </div>
  );
};

export default ColorType;
