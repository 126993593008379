import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setGridWidth,
  setGridHeight,
  setDisableHotkeys,
} from "../store/gridSlice";
import { RootState } from "../store/store";
import styles from "./diagram.module.css";
import { useTranslation } from "react-i18next";

const GridButtons = () => {
  const dispatch = useDispatch();

  const { gridWidth, gridHeight } = useSelector(
    (state: RootState) => state.grid.grid
  );

  useEffect(() => {
    setNewHeight(gridHeight);
  }, [gridHeight]);

  useEffect(() => {
    setNewWidth(gridWidth);
  }, [gridWidth]);

  const [newHeight, setNewHeight] = useState(gridHeight);
  const [newWidth, setNewWidth] = useState(gridWidth);
  const { t } = useTranslation();

  return (
    <div className={styles.toolContainer}>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <div className={styles.input}>
          <label>{t("terms.width") + ":"} </label>
          <input
            value={newWidth}
            onChange={(e) => setNewWidth(parseInt(e.target.value))}
            type="number"
            onFocus={() => dispatch(setDisableHotkeys(true))}
            onBlur={() => {
              dispatch(setDisableHotkeys(false));
              if (gridWidth - newWidth !== 0) {
                dispatch(setGridWidth({ newWidth }));
              }
            }}
          />
        </div>
        <div className={styles.input}>
          <label>{t("terms.height") + ":"} </label>
          <input
            value={newHeight}
            onChange={(e) => setNewHeight(parseInt(e.target.value))}
            type="number"
            onFocus={() => dispatch(setDisableHotkeys(true))}
            onBlur={() => {
              dispatch(setDisableHotkeys(false));
              if (gridHeight - newHeight !== 0) {
                dispatch(setGridHeight({ newHeight }));
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default GridButtons;
