import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ModelState {
  marginBottomModel: number;
  hideDiagrams: boolean;
}

const initialState: ModelState = {
  marginBottomModel: 0, // Use the full window if diagrams dont overflow into the window
  hideDiagrams: false,
};

const modelSlice = createSlice({
  name: "Model",
  initialState,
  reducers: {
    setMarginBottomModel(state, action: PayloadAction<number>) {
      state.marginBottomModel = action.payload;
    },
    setHideDiagrams(state, action: PayloadAction<boolean>) {
      state.hideDiagrams = action.payload;
    }
  },
});

export const { setMarginBottomModel, setHideDiagrams } = modelSlice.actions;

export { modelSlice };
