import React, { useState } from "react";
import styles from "./Comment.module.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import {
  acceptChange,
  addAnswer,
  answerPatternComment,
  resolveComment,
  resolvePatternComment,
} from "../../store/patternSlice";
import { formatDate } from "../../utils/dateFormat";
import { User } from "../../Login/LoginState";
import Button from "../../ISO/Components/Button";
import { CheckIcon, Cross1Icon } from "@radix-ui/react-icons";

interface PropType {
  commentRef: string;
  user: User;
  activeRef: string | null;
  setActiveRef: Function;
  patternId: string;
  listIndex: number;
}

const Comment = (props: PropType) => {
  const { commentRef, user, activeRef, setActiveRef, patternId, listIndex } =
    props;

  const dispatch = useDispatch();

  const comment = useSelector(
    (state: RootState) => state.pattern.comments[commentRef]
  );

  const { firstName, middleName, lastName } = comment.user;
  const fullName = `${firstName ?? ""} ${middleName ?? ""} ${lastName ?? ""}`;

  const [answer, setAnswer] = useState<string>("");

  return (
    <div className={`${styles.container}`}>
      <Button
        onClick={() => setActiveRef(commentRef)}
        className={`${styles.subtleButton} ${
          listIndex % 2 === 1 ? styles.odd : ""
        } ${activeRef === commentRef ? styles.active : styles.inactive}`}
      >
        <div className={styles.top}>
          <div className={styles.left}>
            <b>{fullName}</b>
            <p style={{ fontSize: "12px" }}>{formatDate(comment.timestamp)}</p>
          </div>
          <div>
            <Button
              active
              onClick={() => {
                if (comment.commentType === "change") {
                  dispatch(
                    acceptChange({ commentRef, change: comment.comment })
                  );
                } else {
                  // TODO, remove fra Slate writer
                  dispatch(resolveComment({ commentRef }));
                }
                dispatch(
                  resolvePatternComment({ patternId, commentId: commentRef })
                );
              }}
            >
              <CheckIcon />
            </Button>
            {comment.commentType === "change" && (
              <Button
                style={{ marginLeft: "4px" }}
                contrast
                onClick={() => {
                  // TODO, remove fra Slate writer
                  dispatch(resolveComment({ commentRef }));
                  dispatch(
                    resolvePatternComment({ patternId, commentId: commentRef })
                  );
                }}
              >
                <Cross1Icon />
              </Button>
            )}
          </div>
        </div>
        <div className={styles.comment}>
          {comment.commentType === "change" && <b>Endre til: </b>}
          {comment.comment}
        </div>
        {comment.answers.map((answer, i) => (
          <div key={i}>
            <div className={`${styles.top} ${styles.answer}`}>
              <div className={styles.left}>
                <b>
                  {answer.user?.firstName ?? ""} {answer.user?.middleName ?? ""}
                  {answer.user?.lastName ?? ""}
                </b>
                <p style={{ fontSize: "12px" }}>
                  {formatDate(answer.timestamp)}
                </p>
              </div>
            </div>
            <div className={styles.comment}>{answer.answer}</div>
          </div>
        ))}

        {activeRef === commentRef && (
          <div className={styles.answers}>
            <div className={styles.answerRow}>
              <textarea
                className={styles.textarea}
                value={answer}
                onChange={(e) => setAnswer(e.target.value)}
              />
              <Button
                active
                onClick={() => {
                  const timestamp = new Date();
                  dispatch(
                    addAnswer({
                      user,
                      answer,
                      commentRef,
                      timestamp,
                    })
                  );
                  dispatch(
                    answerPatternComment({
                      answer: answer,
                      patternId: patternId,
                      commentId: comment.id,
                    })
                  );
                  setAnswer("");
                }}
              >
                Svar
              </Button>
            </div>
          </div>
        )}
      </Button>
    </div>
  );
};

export default Comment;

/*

  const dispatch = useDispatch();

  const comment = useSelector(
    (state: RootState) => state.pattern.comments[commentRef]
  );

  const pattern = useSelector((state: RootState) => state.pattern);
  const patternId = `${pattern.id}`;
  const [answer, setAnswer] = useState("");

  const patternElements = useSelector(
    (state: RootState) => state.pattern.patternElements
  );

  const current_raw = useSelector((state: RootState) => state.loginState.user);
  const currentUser = {
    id: current_raw?.id,
    firstName: current_raw?.firstName,
    lastName: current_raw?.lastName,
    middleName: current_raw?.middleName,
    profilePicture: null,
    slug: null,
  };

  useEffect(() => {
    const patternElementIndex = patternElements.findIndex(
      (patternElement) => patternElement.commentId === commentRef
    );
    const { y } = getPosOfElementWithId(patternElementIndex);
    setYPos(y);
  }, [commentRef, patternElements]);

  formatDate(comment.timestamp);


<div
      style={{ position: "absolute", right: 10, top: yPos - 10 }}
      className={styles.container}
    >
      <div className={styles.top}>
        <div>
          <b>
            {comment.user.firstName} {comment.user.lastName}
          </b>{" "}
          - {formatDate(comment.timestamp)}
        </div>
        <div>
          <button
            className={styles.button}
            onClick={() => {
              if (comment.commentType === "change") {
                dispatch(acceptChange({ commentRef, change: comment.comment }));
              } else {
                dispatch(resolveComment({ commentRef }));
              }
              dispatch(
                resolvePatternComment({ patternId, commentId: commentRef })
              );
            }}
          >
            <img src={"/check.svg"} alt="Resolve comment" />
          </button>
          {comment.commentType === "change" && (
            <button
              className={styles.button}
              onClick={() => {
                dispatch(resolveComment({ commentRef }));
                dispatch(
                  resolvePatternComment({ patternId, commentId: commentRef })
                );
              }}
            >
              <img src={"/x.svg"} alt="Resolve comment" />
            </button>
          )}
        </div>
      </div>
      <div className={styles.comment}>
        {comment.commentType === "change" && <i>Endre til: </i>}{" "}
        {comment.comment}
      </div>

      {comment.answers.map((ans) => (
        <div>
          <div className={styles.answerTop}>
            <b>
              {ans.user.firstName} {ans.user.lastName}
            </b>{" "}
            - {formatDate(ans.timestamp)}
          </div>
          {ans.answer}
        </div>
      ))}
      <div className={styles.writeAnswer}>
        <input
          className={styles.input}
          value={answer}
          placeholder="Svar..."
          onChange={(e) => setAnswer(e.target.value)}
        />
        <button
          className={styles.button}
          onClick={() => {
            const timestamp = new Date();
            dispatch(
              addAnswer({ user: currentUser, answer, commentRef, timestamp })
            );
            dispatch(
              editPatternComment({
                patternId,
                comment: {
                  ...comment,
                  answers: [
                    ...comment.answers,
                    {
                      answer,
                      user: currentUser,
                      timestamp,
                    },
                  ],
                },
              })
            );

            setAnswer("");
          }}
        >
          Send
        </button>
      </div>
    </div>

*/
