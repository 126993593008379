import React from "react";
import { useLocation } from "react-router";
import styles from "../Styles/Sections.module.css";
import LogoutButton from "../../Login/LogoutButton";
import { useMeQuery } from "../../store/woolitApi";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function StatusSection() {
  let query = useQuery();
  const { data: user } = useMeQuery();

  const response = query.get("s");

  if (user && !user.subscriber) {
    return (
      <div className={styles.statusSection}>
        {" "}
        Du har ikke tilgang til Woolit Design Studio Pro{" "}
        <LogoutButton></LogoutButton>{" "}
      </div>
    );
  }

  if (!response) {
    return null;
  } else if (response === "loginfailed") {
    /* if (response === "noaccess") {
    return <div className={styles.statusSection}> Du har ikke tilgang til Woolit Design Studio Pro <LogoutButton></LogoutButton></div>;
  } */
    return <div className={styles.statusSection}> Inlogging feilet</div>;
  }
  return null;
}

export default StatusSection;
