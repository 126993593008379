import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { RootState } from "../../store/store";
import { setHighlightedComment } from "../../store/writerSlice";
import Comment from "./Comment";
import { useMeQuery } from "../../store/woolitApi";

const CommentList = () => {
  const { comments } = useSelector((state: RootState) => ({
    comments: state.pattern.comments,
  }));

  const { data: user } = useMeQuery();

  const dispatch = useDispatch();

  const [activeRef, setActiveRef] = useState<string | null>(null);

  useEffect(() => {
    dispatch(setHighlightedComment(activeRef));
  }, [activeRef]);

  const { patternId } = useParams();

  if (!patternId) {
    return null;
  }

  if (!user) return null;

  return (
    <>
      {Object.keys(comments).map((commentRef, i) => (
        <Comment
          key={i}
          commentRef={commentRef}
          user={user}
          activeRef={activeRef}
          setActiveRef={setActiveRef}
          patternId={patternId}
          listIndex={i}
        />
      ))}
    </>
  );
};

export default CommentList;
