import { useEffect, useRef } from "react";
function PatternCanvas(props: any) {
  const canvasRef = useRef<any>(null);

  function height() {
    let height = numSize(Math.min(props.pattern.sizeY(), 36)) * 3 + 1;
    // Change ratio to 18/23
    const _numMod = (numMod() * 3) / borderWidth;
    const _numModY = Math.ceil((_numMod * 18) / 23);
    height *= _numModY / _numMod;
    return height;
  }

  function width() {
    return numSize(Math.min(props.pattern.sizeX(), 36)) * 3 + 1;
  }

  function numSize(num: number) {
    return numMod() * num;
  }

  function numMod() {
    const height = props.pattern.sizeY();
    const width = props.pattern.sizeX();
    const max = Math.max(height, width);
    return Math.max(Math.floor(36 / max), 1);
  }

  const borderWidth = 0.5;

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas!!.getContext("2d");
    context.clearRect(0, 0, width(), height());
    const grid = props.pattern.grid;
    const _numMod = (numMod() * 3) / borderWidth;
    const _height = Math.min(grid.length, 36);
    const _width = Math.min(grid[0].length, 36);
    for (let y = 0; y < _height; y++) {
      for (let x = 0; x < _width; x++) {
        if (grid[y][x] !== -1) {
          //Our first draw
          let color = props.colors[grid[Math.floor(y)][Math.floor(x)]];
          if (!color) {
            continue; // same as === -1
          }
          const _numModY = Math.ceil((_numMod * 18) / 23);
          context.fillStyle = color;
          context.fillRect(
            x * _numMod + 1,
            y * _numModY + 1,
            _numMod,
            _numModY
          );
          context.fillStyle = "#000000";
          context.fillRect(x * _numMod, y * _numModY, 1, _numModY);
          context.fillRect(x * _numMod, y * _numModY, _numMod, 1);
          context.fillRect((x + 1) * _numMod, y * _numModY, 1, _numModY + 1);
          context.fillRect(x * _numMod, (y + 1) * _numModY, _numMod + 1, 1);
        }
      }
    }
  }, [props.pattern, props.colors]);

  return (
    <canvas
      ref={canvasRef}
      width={width() / borderWidth + ""}
      height={height() / borderWidth + ""}
      style={{ height: height() + "px", width: width() + "px" }}
    />
  );
}

export default PatternCanvas;
