export const commands: { [key: string]: string } = {
  span: "text",
  heading: "heading",
  subheading: "subheading",
  calculationResult: "calculation",
  needle: "needle",
  color: "color",
  diagramSection: "diagram",
  diagram: "diagram ref",
  sizeFilteredString: "size based text",
  media: "media",
  // snippet: "Info",
};
