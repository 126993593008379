import { Part } from "../../Part";
import { AreaGroup } from "../../enums";
import {
  _loadImagesCanvasSock,
  _makeSceneSock,
  _onLoadImagesSock,
  _removeEventsSock,
  _runAfterLoadSweaterSetSock,
  _setColorsSceneSock,
  _setUpdateCanvasNextFrameSock,
} from "../sock/scene";
import {
  _loadImagesCanvasSweater,
  _makeSceneSweater,
  _onLoadImagesSweater,
  _removeEventsSweater,
  _runAfterLoadSweaterSetSweater,
  _setColorsSceneSweater,
  _setUpdateCanvasNextFrameSweater,
} from "../sweater/scene";

export function makeScene(
  canvas: HTMLElement,
  parts_arg: Part[],
  colors_arg: string[],
  setSelectedPart_arg: any,
  knittingMethod: string,
  knitData: { [key: string]: number }
) {
  const isSweater = parts_arg[0].isSweater();
  _removeEventsSock();
  _removeEventsSweater();
  if (isSweater) {
    _makeSceneSweater(
      canvas,
      parts_arg,
      colors_arg,
      setSelectedPart_arg,
      knittingMethod,
      knitData
    );
  } else {
    _makeSceneSock(
      canvas,
      parts_arg,
      colors_arg,
      setSelectedPart_arg,
      knittingMethod,
      knitData
    );
  }
}

export function setUpdateCanvasNextFrame() {
  if (_isSweater()) {
    _setUpdateCanvasNextFrameSweater();
  } else {
    _setUpdateCanvasNextFrameSock();
  }
}

export function runAfterLoadSweaterSet(functionToRun: any) {
  if (_isSweater()) {
    _runAfterLoadSweaterSetSweater(functionToRun);
  } else {
    _runAfterLoadSweaterSetSock(functionToRun);
  }
}

export function setColorsScene(_colors: string[]) {
  if (_isSweater()) {
    _setColorsSceneSweater(_colors);
  } else {
    _setColorsSceneSock(_colors);
  }
}

export function loadImagesCanvas(knittingMethod: string) {
  if (_isSweater()) {
    return _loadImagesCanvasSweater(knittingMethod);
  } else {
    return _loadImagesCanvasSock(knittingMethod);
  }
}

export function onLoadImages(
  sweaterPart: Part,
  knitData: { [key: string]: number },
  setGrid: any
) {
  if (_isSweater()) {
    _onLoadImagesSweater(sweaterPart, knitData, setGrid);
  } else {
    _onLoadImagesSock(sweaterPart, knitData, setGrid);
  }
}

//-----

// The code above: It would be best if the scene.ts were classes.
// A technical debt, but it doesn't really affect the workflow, its just ugly.

export let parts: Part[];
export let colorsScene: string[] = [];
const _isSweater = () => {
  return parts ? parts[0].isSweater() : false;
};

export function getParts(areaGroup: AreaGroup | undefined = undefined) {
  if (areaGroup) {
    return parts.filter((it) => it.areaGroup() === areaGroup);
  }
  return parts;
}

export function getPartsExceptCollar() {
  return parts.filter((it) => !it.isCollar());
}

export function setParts(value: Part[]) {
  parts = value;
}

//Use setColorsScene for the non-dangerous function
export function _setColorsScene(colors: string[]) {
  colorsScene = colors;
}
