import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setColorVariant } from "../store/colorSlice";
import { RootState } from "../store/store";
import { toggleDrawer } from "../store/writerSlice";

import ColorType from "./ColorType";
import styles from "./diagram.module.css";
import colorStyles from "./colorlist.module.css";
import { setActiveVariant } from "../ISO/displayState";
import { hasAccess } from "../utils/hasAccess";
import { useMeQuery } from "../store/woolitApi";
import { addMetadataVariant } from "../store/patternSlice";

const ColorList = () => {
  const dispatch = useDispatch();
  const { data: user } = useMeQuery();

  const { variants } = useSelector((state: RootState) => state.pattern.meta);

  const activeVariantId = useSelector(
    (state: RootState) => state.displayState.activeVariant
  );

  const variantIndex =
    variants.length > 0
      ? variants.findIndex((v) => v.id === activeVariantId)
      : 0;

  useEffect(() => {
    if (variants.length > 0) {
      dispatch(setColorVariant({ variant: variants[variantIndex] }));
    }
  }, [variants]);

  if (!hasAccess(user, "full_diagram")) {
    return null;
  }

  return (
    <div className={`${styles.toolContainer} ${styles.overflowTool}`}>
      {variants.length === 0 && (
        <button
          style={{ border: "none", width: "100%" }}
          onClick={() => {
            dispatch(addMetadataVariant());
            dispatch(toggleDrawer());
          }}
        >
          + Fargevariant
        </button>
      )}
      {variants.map((variant, i) => (
        <div
          key={i}
          className={styles.hoverRow}
          style={{
            display: "flex",
            width: "100%",
          }}
          onClick={() => {
            dispatch(setActiveVariant(variant.id));
            dispatch(setColorVariant({ variant: variants[i] }));
          }}
        >
          <div
            className={colorStyles.variantNumber}
            style={{
              color: variantIndex === i ? "var(--contrast)" : "",
            }}
          >
            {i < 9 ? `0${i + 1}` : `${i + 1}`}
          </div>
          <div style={{ display: "flex" }}>
            {variant.colors.map((color, j) => (
              <ColorType
                key={j}
                variantIndex={variantIndex}
                row={i}
                index={j}
                color={color}
              />
            ))}
          </div>
          <div
            className={colorStyles.variantName}
            style={{
              color: variantIndex === i ? "var(--contrast)" : "",
              width: "100%",
            }}
          >
            {variant.name || `Variant ${i + 1}`}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ColorList;
