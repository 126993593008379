import React, { FC, useEffect } from "react";
import ReactDOM from "react-dom";
import styles from "./Modal.module.css";

export interface ModalProps {
  isShown: boolean;
  hide: () => void;
  modalContent: JSX.Element;
}

export const ModalBase: FC<ModalProps> = ({ isShown, hide, modalContent }) => {
  const onKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Escape" && isShown) {
      hide();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown, false);
    return () => {
      document.removeEventListener("keydown", onKeyDown, false);
    };
  }, [isShown]);

  const modal = (
    <>
      <div className={styles.backdrop} onClick={hide} />
      <div className={styles.wrapper} aria-modal tabIndex={-1} role="dialog">
        <div className={styles.styledModal}>{modalContent}</div>
      </div>
    </>
  );
  return isShown ? ReactDOM.createPortal(modal, document.body) : null;
};
