import { Part } from "../../Part";
import { SweaterPartAreaGroup } from "../../enums";

// @ts-ignore
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";

enum Sides {
  Top,
  Bottom,
}

const knitSizeToArea = {
  bustCircumference: [
    [SweaterPartAreaGroup.Arms, SweaterPartAreaGroup.Torso],
    [Sides.Top, Sides.Bottom],
    "x",
  ],
  fullLength: [
    [SweaterPartAreaGroup.Arms, SweaterPartAreaGroup.Torso],
    [Sides.Top],
    "y",
  ],
  sleeveLength: [[SweaterPartAreaGroup.Arms], [Sides.Bottom], "y"],
  bodyLength: [[SweaterPartAreaGroup.Torso], [Sides.Bottom], "y"],
} as const;

const sweaterPartLocations = (areaGroup: SweaterPartAreaGroup) =>
  areaGroup === SweaterPartAreaGroup.Arms ? 0.25 : 0.75;

export const knitSizeDefaults = {
  bustCircumference: 107,
  fullLength: 65,
  sleeveLength: 52,
  bodyLength: 42,
} as const;

//sizeDifference === 0 => medium size
export const knitSizeDefaultResize = (
  size: string,
  fallbackDiff: number = 0
) => {
  const sizeToSizeDifference = {
    XXS: -3,
    XS: -2,
    S: -1,
    M: 0,
    L: 1,
    XL: 2,
    XXL: 3,
    XXXL: 4,
  };
  let sizeDifference = fallbackDiff;
  if (size in Object.keys(sizeToSizeDifference)) {
    const sizeParsed = size as
      | "XXS"
      | "XS"
      | "S"
      | "M"
      | "L"
      | "XL"
      | "XXL"
      | "XXXL";
    sizeDifference = sizeToSizeDifference[sizeParsed];
  }
  return {
    bustCircumference:
      sizeDifference * 8 + knitSizeDefaults["bustCircumference"],
    fullLength: sizeDifference * 2 + knitSizeDefaults["fullLength"],
    sleeveLength: sizeDifference * 0 + knitSizeDefaults["sleeveLength"],
    bodyLength: sizeDifference * 2 + knitSizeDefaults["bodyLength"],
  } as const;
};

const knitSizeNames = [
  "bustCircumference",
  "fullLength",
  "sleeveLength",
  "bodyLength",
] as const;

function imagedata_to_image(imagedata: ImageData) {
  var canvas = document.createElement("canvas");
  var ctx = canvas.getContext("2d")!;
  canvas.width = imagedata.width;
  canvas.height = imagedata.height;
  ctx.putImageData(imagedata, 0, 0);

  var image = new Image();
  image.src = canvas.toDataURL();
  return image;
}

function _remapUVXY(
  x: number,
  y: number,
  knitSizeName:
    | "bustCircumference"
    | "fullLength"
    | "sleeveLength"
    | "bodyLength",
  knitData: { [key: string]: number }
) {
  const [groups, sides, axis] = knitSizeToArea[knitSizeName];
  const scaleAmount = calcScaleAmount(knitSizeName, knitData);
  if (scaleAmount === 1) {
    return [x, y];
  }
  const locations = groups.map((it: SweaterPartAreaGroup) =>
    sweaterPartLocations(it)
  );
  for (let location of locations) {
    for (let side of sides) {
      const _top = location - (side === Sides.Top ? 0.25 : 0);
      const top = Math.max(0.05, _top);
      const bottom = _top + 0.25;
      if (axis === "y") {
        if (y > top && y < bottom) {
          y = location + (y - location) * scaleAmount;
        }
      } else {
        if (y > top && y < bottom) {
          const locationX = x < 0.5 ? 0.25 : 0.75;
          x = locationX + (x - locationX) * scaleAmount;
        }
      }
    }
  }
  return [x, y];
}

function remapUVXY(x: number, y: number, knitData: { [key: string]: number }) {
  for (let knitSizeName of knitSizeNames) {
    [x, y] = _remapUVXY(x, y, knitSizeName, knitData);
  }
  return [x, y];
}

export function rescaleGLTFModel(
  geometry: any,
  knitData: { [key: string]: number },
  runAfter: any
) {
  let loader = new GLTFLoader();
  let uvAttribute = geometry.getAttribute("uv");
  for (let i = 0; i < uvAttribute.count; i++) {
    let x = uvAttribute.getX(i);
    let y = uvAttribute.getY(i);
    let [x_remap, y_remap] = remapUVXY(x, y, knitData);
    uvAttribute.setXY(i, x_remap, y_remap);
  }
  const positionAttribute = geometry.getAttribute("position");
  let waitFor = knitSizeNames.length;
  let diffX = new Array(positionAttribute.count).fill(0);
  let diffY = new Array(positionAttribute.count).fill(0);
  let diffZ = new Array(positionAttribute.count).fill(0);
  knitSizeNames.forEach(
    (
      knitSizeName:
        | "bustCircumference"
        | "fullLength"
        | "sleeveLength"
        | "bodyLength"
    ) => {
      loader.load(`/3D/shirt/sweater_${knitSizeName}.gltf`, (gltf: any) => {
        const defaultVal = knitSizeDefaults[knitSizeName];
        const scaleDiff = knitData[knitSizeName] - defaultVal;
        const scaleMult = scaleDiff / 10; // Every model is specifically 10cm bigger in knitSize
        let geometryScale = gltf.scene.children[0].geometry;
        const positionAttribute2 = geometryScale.getAttribute("position");
        for (let i = 0; i < positionAttribute.count; i++) {
          let x_start = positionAttribute.getX(i);
          let y_start = positionAttribute.getY(i);
          let z_start = positionAttribute.getZ(i);
          let x_end = positionAttribute2.getX(i);
          let y_end = positionAttribute2.getY(i);
          let z_end = positionAttribute2.getZ(i);
          let x_diff = x_end - x_start;
          let y_diff = y_end - y_start;
          let z_diff = z_end - z_start;
          diffX[i] += x_diff * scaleMult;
          diffY[i] += y_diff * scaleMult;
          diffZ[i] += z_diff * scaleMult;
        }
        waitFor--;
        if (waitFor === 0) {
          for (let i = 0; i < positionAttribute.count; i++) {
            let x_start = positionAttribute.getX(i);
            let y_start = positionAttribute.getY(i);
            let z_start = positionAttribute.getZ(i);
            let x_diff = diffX[i];
            let y_diff = diffY[i];
            let z_diff = diffZ[i];
            positionAttribute.setXYZ(
              i,
              x_start + x_diff,
              y_start + y_diff,
              z_start + z_diff
            );
          }
          runAfter(geometry);
        }
      });
    }
  );
}

function scaleImage(
  ctx: any,
  canvas2: any,
  from: any,
  to: any,
  anchor: any,
  runAfter: any
) {
  let ctx2 = canvas2.getContext("2d")!!;
  const size = 4096;
  const [x_start_f, y_start_f, x_end_f, y_end_f] = from;
  const [width_f, height_f] = [x_end_f - x_start_f, y_end_f - y_start_f];
  const [x_start_t, y_start_t, x_end_t, y_end_t] = to;
  const [width_t, height_t] = [x_end_t - x_start_t, y_end_t - y_start_t];
  const [x, y] = anchor;
  const imgData = ctx.getImageData(
    x_start_f * size,
    y_start_f * size,
    width_f * size,
    height_f * size
  );
  const img = imagedata_to_image(imgData);
  img.onload = () => {
    canvas2.width = width_f * size;
    canvas2.height = height_f * size;
    ctx2.drawImage(
      img,
      x * (width_f - width_t) * size,
      y * (height_f - height_t) * size,
      width_t * size,
      height_t * size
    );
    const imgDataScaled = ctx2.getImageData(
      0,
      0,
      canvas2.width,
      canvas2.height
    );
    ctx.putImageData(imgDataScaled, x_start_f * size, y_start_f * size);
    runAfter(ctx);
  };
}

export function calcScaleAmount(
  knitSizeName:
    | "bustCircumference"
    | "fullLength"
    | "sleeveLength"
    | "bodyLength",
  knitData: { [key: string]: number }
) {
  const defaultVal = knitSizeDefaults[knitSizeName];
  let _scaleAmount = knitData[knitSizeName] / defaultVal;
  if (knitSizeName === "fullLength") {
    const offset = knitSizeDefaults["bodyLength"];
    _scaleAmount = (knitData[knitSizeName] - offset) / (defaultVal - offset);
  }
  return _scaleAmount;
}

export function rescaleUVImage(
  ctx: CanvasRenderingContext2D,
  knitData: { [key: string]: number },
  runAfter: any
) {
  let canvas2: HTMLCanvasElement = document.createElement("canvas");
  let stack: any[] = [
    () => {
      runAfter(ctx);
    },
  ];
  for (let knitSizeName of knitSizeNames) {
    const [groups, sides, axis] = knitSizeToArea[knitSizeName];
    const scaleAmount = calcScaleAmount(knitSizeName, knitData);
    if (scaleAmount === 1) {
      continue;
    }
    const locations = groups.map((it: SweaterPartAreaGroup) =>
      sweaterPartLocations(it)
    );
    for (let location of locations) {
      for (let side of sides) {
        const _top = location - (side === Sides.Top ? 0.25 : 0);
        const top = Math.max(0.05, _top);
        const bottom = _top + 0.25;
        if (axis === "y") {
          const anchor = [0.5, side === Sides.Top ? 1 : 0];
          const from = [0, top, 1, bottom];
          let to: any[] = [];
          if (side === Sides.Top) {
            to = [0, bottom + (top - bottom) * scaleAmount, 1, bottom];
          } else {
            to = [0, top, 1, top + 0.25 * scaleAmount];
          }
          stack.push(() => {
            scaleImage(ctx, canvas2, from, to, anchor, () => {
              stack.pop()();
            });
          });
        } else {
          const anchor = [0.5, 0.5];
          // Left
          const from = [0, top, 0.5, bottom];
          let to = [
            0.25 - 0.25 * scaleAmount,
            top,
            0.25 + 0.25 * scaleAmount,
            bottom,
          ];
          stack.push(() => {
            scaleImage(ctx, canvas2, from, to, anchor, () => {
              stack.pop()();
            });
          });

          // Right
          const from2 = [0.5, top, 1, bottom];
          let to2 = [
            0.75 - 0.25 * scaleAmount,
            top,
            0.75 + 0.25 * scaleAmount,
            bottom,
          ];
          stack.push(() => {
            scaleImage(ctx, canvas2, from2, to2, anchor, () => {
              stack.pop()();
            });
          });
        }
      }
    }
  }
  stack.pop()();
}

export function rescaleUVBorders(
  sweaterParts: Part[],
  knitData: { [key: string]: number }
) {
  for (let sweaterPart of sweaterParts) {
    let x = sweaterPart.corner1X;
    let y = sweaterPart.corner1Y;
    let [x_remap, y_remap] = remapUVXY(x, y, knitData);
    sweaterPart.corner1X = x_remap;
    sweaterPart.corner1Y = y_remap;
    x = sweaterPart.corner2X;
    y = sweaterPart.corner2Y;
    [x_remap, y_remap] = remapUVXY(x, y, knitData);
    sweaterPart.corner2X = x_remap;
    sweaterPart.corner2Y = y_remap;
    sweaterPart.calculateFromCorners();
  }
}
