import { SweaterPart } from "../SweaterPart";
import { Settings } from "../static/settings";
import { Pattern } from "../Pattern";
import { DrawTypes } from "../enums";
import { mixColors } from "../knittingpreview/colorutil";
import { Util } from "../static/util";
import { Global } from "../static/global";
import { Vector2 } from "three";
import { shortUUID } from "../../utils/uuid";
import { Part } from "../Part";
import {
  colorsScene,
  getParts,
  loadImagesCanvas,
  onLoadImages,
} from "../knittingpreview/core/scene";

export let shirt_uv: HTMLImageElement;
export const setShirtUV = (value: any) => {
  shirt_uv = value;
};
export let prevSetupSweaterPart: Part | undefined;

let grid: any[][];
let last_previews: any[][] = [];

let consoledWarning = false;

export let hasLoadedImages: boolean;

export function setHasLoadedImages(newValue: boolean) {
  hasLoadedImages = newValue;
}

export function _loadGrid(sweaterPart: Part, setGrid: any) {
  drawGrid(sweaterPart);
  setGrid(getGrid());
}

export let lastVisitedBucket: any = {};

function make2DArray(x: number, y: number, fillWith: any = -2) {
  return new Array(y).fill(0).map(() => new Array(x).fill(fillWith));
}

//For redo/undo. Drawing should use draw()
export function setGrid2(newValue: any) {
  grid = newValue;
}

export function getGrid() {
  //return [...grid] // New reference for SetGridSlow
  if (!grid) return undefined;
  const shallowGrid = [];
  for (let innerGrid of grid) {
    shallowGrid.push([...innerGrid]);
  }
  return shallowGrid;
}

export type drawSelectionProps = {
  sweaterParts: Part[];
  pattern: Pattern;
  x: any;
  y: any;
  gridHTML: HTMLDivElement;
  drawType?: DrawTypes;
  sweaterPartInGrid?: SweaterPart;
  preview?: boolean;
  gap?: number;
  mirror?: number;
  repeat?: boolean;
  isColorLine: boolean;
};

export function drawSelection({
  sweaterParts,
  pattern,
  x,
  y,
  gridHTML,
  drawType,
  sweaterPartInGrid,
  preview = false,
  gap = 0,
  repeat = false,
  isColorLine = false,
}: drawSelectionProps) {
  _clearPreview();
  const anchor = pattern.anchor();
  const anchorX = anchor[0];
  const anchorY = anchor[1];
  pattern.groupID = shortUUID();
  let _pattern;
  for (let sweaterPart of sweaterParts) {
    const isSelected = sweaterPart === sweaterPartInGrid;
    if (!isSelected && preview) continue;
    const [dxCross, dyCross] = crossAlign(sweaterPart, sweaterPartInGrid!);

    const pos = new Vector2(x - anchorX, y - anchorY);
    pos.x += dxCross;
    pos.y += dyCross;

    if (isColorLine) {
      if (!preview) {
        sweaterPart.colorLines[pos.y] = pattern.grid[0][0];
      }
      sweaterPart.drawColorLine(
        pos.y,
        pattern.grid[0][0],
        preview,
        isSelected ? gridHTML : undefined
      );
    } else {
      const savedPattern = sweaterPart.savePattern(
        pos.x,
        pos.y,
        pattern,
        preview
      );

      if (sweaterPart === sweaterPartInGrid) {
        _pattern = savedPattern;
      }

      sweaterPart.drawPattern(
        pos,
        pattern,
        preview,
        isSelected ? gridHTML : undefined
      );
    }
  }
  return _pattern;
}

/*export function addSelection(sweaterPart: any, x: any, y: any) {
    let selectedX = x
    let selectedY = y
    draw(sweaterPart, selectedX - 1, selectedY - 1, selectedX + 2, selectedY + 2, true)
}

/*export function removeSelection(sweaterPart: any) {
    let selectedX = state.selectedTilePos[0]
    let selectedY = state.selectedTilePos[1]
    let temp = [...state.selectedTilePos]
    state.selectedTilePos = [-999, -999]
    draw(sweaterPart, selectedX - 1, selectedY - 1, selectedX + 2, selectedY + 2)
    state.selectedTilePos = [...temp]
}*/

export function clearGrid() {
  grid = make2DArray(Global.gridSizeX, Global.gridSizeY);
}

function calculateGridSize(sweaterPart: any) {
  const sizeX = sweaterPart.sizeX;
  const sizeY = sweaterPart.sizeY;

  const startX = 0;
  const startY = 0;
  const endX = sizeX;
  const endY = sizeY;

  let highestX = 0;
  let highestY = 0;
  let lowestX = 999;
  let lowestY = 999;
  for (let x = startX; x < endX; x += 1) {
    for (let y = startY; y < endY; y += 1) {
      if (sweaterPart.isMask(x, y)) {
        //Do four corner checks
        if (x > highestX) {
          highestX = x;
        }
        if (y > highestY) {
          highestY = y;
        }
        if (x < lowestX) {
          lowestX = x;
        }
        if (y < lowestY) {
          lowestY = y;
        }
      }
    }
  }
  const diffx = lowestX;
  if (diffx !== 0) {
    console.log(
      "Error: Missaligned sweaterpart, expected 0, but got x:" + diffx
    );
  }
  const diffy = lowestY;
  if (diffy !== 0) {
    //console.log("Error: Missaligned sweaterpart, expected 0, but got y:" + diffy)
  }
  highestX += 1;
  highestY += 1;
  return [highestX, highestY];
}

function setupSweaterPart(sweaterPart: Part) {
  prevSetupSweaterPart = sweaterPart;
  const gridSizes = calculateGridSize(sweaterPart);

  Global.gridSizeX = gridSizes[0];
  Global.gridSizeY = gridSizes[1];
  //Should be performed if:
  //* Size is changed
  //* Draw is performed on a new selectedSweaterPart
  clearGrid();
}

export function updateGridHTML(
  x: number,
  y: number,
  newValue: number,
  gridHTML: HTMLDivElement | undefined,
  preview: boolean,
  dirs: any[],
  isClearPreview: boolean = false,
  part: Part
) {
  const borderColorOutlineRGBstring = Settings.borderColorOutlineRGBstring;
  const borderColorOutlineSnapRGBstring =
    Settings.borderColorOutlineSnapRGBstring;
  const borderPosColors = [
    "borderTopColor",
    "borderLeftColor",
    "borderBottomColor",
    "borderRightColor",
  ] as const;
  const borderColor = Util.calculateBorderColor(
    x,
    y,
    Util.colorIndexToColor(colorsScene, newValue),
    newValue < -1,
    part
  );
  const hasUpdatedBorder = !dirs.every((it) => it === false);

  const oldValue = grid[y][x];
  // Draw/preview new color
  if (oldValue !== newValue) {
    grid[y][x] = newValue;
    if (gridHTML) {
      if (gridHTML.children.length < y || gridHTML.children[y].length < x) {
        if (!consoledWarning) {
          console.log(
            "Consider deleting patterns that are outside after resizing"
          );
          consoledWarning = true;
        }
        // Sweater has been resized and then reloaded.
        return;
      }
      const gridCell = gridHTML.children[y].children[x] as HTMLDivElement;
      const colorDraw = Util.calculateGridColor(
        x,
        y,
        colorsScene,
        newValue,
        part
      );
      const cellIsLightened = part?.circularYoke && y < part?.connectY;
      const lighten = preview && !cellIsLightened;
      const color = lighten ? mixColors([colorDraw, "#ffffff"]) : colorDraw;
      gridCell.style.backgroundColor = color;

      for (let borderPosColor of borderPosColors) {
        const isOutline =
          gridCell.style[borderPosColor] === borderColorOutlineRGBstring ||
          gridCell.style[borderPosColor] === borderColorOutlineSnapRGBstring;
        if (!isOutline) {
          gridCell.style[borderPosColor] = borderColor;
        }
      }
    }
  }
  // Draw/preview brush outline
  if (hasUpdatedBorder && gridHTML) {
    const gridCell = gridHTML.children[y].children[x] as HTMLDivElement;
    const borderOutlineColor = "#000000";
    for (let n = 0; n < 4; n++) {
      const borderPosColor = borderPosColors[n];
      const isOutline =
        gridCell.style[borderPosColor] === borderColorOutlineRGBstring ||
        gridCell.style[borderPosColor] === borderColorOutlineSnapRGBstring;
      const isPatternOutline = dirs[n];
      if (!isOutline && isPatternOutline) {
        if (!isClearPreview) {
          gridCell.style[borderPosColor] = borderOutlineColor;
        } else {
          gridCell.style[borderPosColor] = borderColor;
        }
      }
    }
  }
  if (preview) {
    last_previews.push([x, y, oldValue, gridHTML, dirs, part]);
  }
  //Ugly: Necessary to clear eraser outline
  if (!preview && hasUpdatedBorder && !isClearPreview) {
    last_previews.push([x, y, newValue, gridHTML, dirs, part]);
  }
}
export function crossAlign(part: Part, partInGrid: Part) {
  const selectedpart = partInGrid as Part;
  if (selectedpart.area !== part.area) {
    const [_sizeX, _sizeX2] = [part.sizeX, selectedpart.sizeX];
    const sizeXDiff = _sizeX - _sizeX2;
    const mirrorXDiff = sizeXDiff / 2;

    const topArmYDiff = part.connectY - selectedpart.connectY;

    const dxCross = mirrorXDiff;
    const dyCross = topArmYDiff;
    return [dxCross, dyCross];
  }
  return [0, 0];
}

export function _clearPreview() {
  if (last_previews.length !== 0) {
    lastVisitedBucket = {};
    // Opposite order, or else overlapping drawn areas will override incorrectly
    for (let n = last_previews.length - 1; n >= 0; n--) {
      const last_preview_info = last_previews[n];
      const x = last_preview_info[0];
      const y = last_preview_info[1];
      const oldBrushColor = last_preview_info[2];
      const gridHTML = last_preview_info[3];
      const dirs = last_preview_info[4];
      const sweaterPart = last_preview_info[5];
      updateGridHTML(
        x,
        y,
        oldBrushColor,
        gridHTML,
        false,
        dirs,
        true,
        sweaterPart
      );
    }
    last_previews = [];
  }
  let sweaterParts = getParts();
  if (sweaterParts) {
    for (let sweaterPart of sweaterParts) {
      sweaterPart.clearPreview();
    }
  }
}

export function drawGrid(
  sweaterPart: Part,
  startX: number = 0,
  startY: number = 0,
  endX: number = Infinity,
  endY: number = Infinity
) {
  _clearPreview();

  setupSweaterPart(sweaterPart);

  const sizeX = sweaterPart?.sizeX ?? 0;
  const sizeY = sweaterPart?.sizeY ?? 0;

  endX = Math.min(sizeX, endX);
  endY = Math.min(sizeY, endY);

  for (let x = startX; x < endX; x += 1) {
    for (let y = startY; y < endY; y += 1) {
      if (y < 0 || x < 0) {
        continue;
      }
      if (sweaterPart.isMask(x, y)) {
        let drawColor = sweaterPart.grid[y][x];
        updateGridHTML(
          x,
          y,
          drawColor,
          undefined,
          false,
          [false, false, false, false],
          false,
          sweaterPart
        );
      }
    }
  }
}

export function loadGrid(
  sweaterPart: SweaterPart,
  knitData: { [key: string]: number },
  setGrid: any,
  knittingMethod: string
) {
  if (!hasLoadedImages) {
    let waitForLoad = loadImagesCanvas(knittingMethod);
    for (let image of waitForLoad) {
      image.onload = () => {
        waitForLoad.pop();
        if (waitForLoad.length === 0) {
          onLoadImages(sweaterPart, knitData, setGrid);
        }
      };
    }
  } else {
    _loadGrid(sweaterPart, setGrid);
  }
}
