import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Button from "../../ISO/Components/Button";

import styles from "../../Diagram/diagram.module.css";
import { useTranslation } from "react-i18next";
import { Pencil1Icon } from "@radix-ui/react-icons";
import ColorType from "../../Diagram/ColorType";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import "../SweaterDesigner.css";
import { setActiveColorIndex } from "../../store/colorSlice";
import { getParts } from "../knittingpreview/core/scene";

function GridOverlay(props: any) {
  const overlayStyle: any = {
    display: "flex",
    margin: "25px",
    width: "calc(100% - 25px)",
    height: "calc(100% - 50px)",
    position: "absolute",
    pointerEvents: "none",
  };

  const { variants } = useSelector((state: RootState) => state.pattern.meta);

  const activeVariantId = useSelector(
    (state: RootState) => state.displayState.activeVariant
  );

  const variantIndex =
    variants.length > 0
      ? variants.findIndex((v) => v.id === activeVariantId)
      : 0;

  const { activeIndex } = useSelector((state: RootState) => ({
    activeIndex: state.color.activeIndex,
  }));

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const color =
    variants.length > 0
      ? variants[variantIndex] &&
        variants[variantIndex].colors[Math.max(0, activeIndex)]
      : undefined;

  return (
    <>
      <div style={{ ...overlayStyle, alignItems: "flex-start" }}>
        {!props.diagram && (
          <div
            style={{
              position: "absolute",
              left: "250px",
              right: "0px",
              height: "100%",
              display: "flex",
              backgroundColor: "black",
              opacity: 0.6,
              borderRadius: "3px",
              zIndex: 1,
            }}
          >
            <div
              style={{
                margin: "auto",
                fontSize: "35px",
                color: "white",
                fontWeight: 100,
              }}
            >
              {t("create a diagram to start")}
            </div>
          </div>
        )}
        <div
          style={{
            display: "flex",
            flex: 1,
            flexWrap: "wrap",
            justifyContent: "flex-start",
            maxWidth: "230px",
            gap: "8px",
            marginTop: "300px",
          }}
        >
          <div
            style={{
              display: "flex",
              minWidth: "230px",
              maxWidth: "230px",
              justifyContent: "center",
            }}
          >
            <div>
              <Button
                title={"Draws selected color across row"}
                className={styles.stateButton + " hoverLarge"}
                onClick={() => props.setColorMode(!props.colorMode)}
                active={props.colorMode}
                style={{
                  pointerEvents: "auto",
                  width: "230px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    margin: "auto 0px",
                  }}
                >
                  <img
                    src="/arrow-left.svg"
                    alt="arrow-left"
                    style={{ width: "10px" }}
                  />
                  <Pencil1Icon />
                  <img
                    src="/arrow-left.svg"
                    alt="arrow-right"
                    style={{
                      width: "10px",
                      transform: "rotate(180deg)",
                      zIndex: 100000,
                    }}
                  />
                </div>
                <p style={{ margin: "auto 0px" }}>{t("color row")}</p>
                <button
                  className="hoverSmall"
                  style={{
                    padding: "5px",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (variants.length === 0) return;
                    const size = variants[variantIndex].colors.length;
                    if (size === 0) return;
                    dispatch(
                      setActiveColorIndex((Math.max(0, activeIndex) + 1) % size)
                    );
                  }}
                >
                  {!color && (
                    <div
                      style={{
                        backgroundColor: "#ffffff",
                        minHeight: "24px",
                        minWidth: "24px",
                        borderRadius: "2px",
                      }}
                    ></div>
                  )}
                  <ColorType
                    key={activeIndex}
                    variantIndex={variantIndex}
                    row={variantIndex}
                    index={activeIndex}
                    color={color}
                    className="noClass"
                  />
                </button>
              </Button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              minWidth: "230px",
              maxWidth: "230px",
              justifyContent: "space-between",
            }}
          >
            <div>
              <button
                title={"Undo"}
                className={styles.stateButton}
                disabled={!props.undoButtonState()}
                onClick={() => props.undoButtonClick()}
                style={{ pointerEvents: "auto" }}
              >
                <img src="/undo-arrow.svg" alt="undo" />
              </button>
              <button
                title={"Redo"}
                className={styles.stateButton}
                disabled={!props.redoButtonState()}
                onClick={() => props.redoButtonClick()}
                style={{ pointerEvents: "auto" }}
              >
                <img src="/redo-arrow.svg" alt="redo" />
              </button>
            </div>
            <div>
              <Button
                title={"Zoom In"}
                active={false}
                onClick={() => {
                  props.zoom(1);
                }}
                style={{
                  pointerEvents: "auto",
                }}
              >
                <img height="16" src="/zoom-in.svg" alt="Zoom in" />
              </Button>
              <Button
                title={"Zoom Out"}
                active={false}
                onClick={() => {
                  props.zoom(-1);
                }}
                style={{
                  pointerEvents: "auto",
                }}
              >
                <img height="16" src="/zoom-out.svg" alt="Zoom out" />
              </Button>
            </div>
            <div>
              <button
                title={"Delete"}
                className={styles.stateButton}
                disabled={!props.trashButtonState()}
                onClick={() => props.trashButtonClick()}
                style={{ pointerEvents: "auto" }}
              >
                <img
                  src="/3D/ui/delete2.png"
                  style={{
                    width: "16px",
                    height: "16px",
                  }}
                  alt="delete"
                />
              </button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              pointerEvents: "auto",
            }}
          >
            <FormControl>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={props.sweaterPart?.index ?? 0}
                style={{
                  fontFamily: "var(--text-font-family)",
                  color: "var(--text-color)",
                  margin: 0,
                  lineHeight: "1.3em",
                  letterSpacing: "0.03em",
                  fontSize: "16px",

                  height: "36px", //+4 since border 2px
                  marginTop: "auto",
                  marginBottom: "auto",
                  backgroundColor: "white",
                  pointerEvents: "auto",
                  minWidth: "230px",
                  maxWidth: "230px",
                }}
                onChange={(e: any) => {
                  const sweaterPart = getParts()[e.target.value as number];
                  props.setSelectedSweaterPart(sweaterPart);
                }}
                sx={{
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "var(--neutral-10)",
                    borderWidth: "2px",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "var(--contrast)",
                    borderWidth: "2px",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "var(--neutral-10)",
                  },
                  "&:hover": {
                    backgroundColor: "var(--neutral-10) !important",
                  },
                }}
              >
                {/* //Eivind */}
                {(getParts() ?? ["", "", "", "", ""]).map((v, i) => (
                  <MenuItem value={i} key={i}>
                    {t("draw.view") + ": " + t("model." + v.name) ?? ""}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div
            style={{
              display: "flex",
              pointerEvents: "auto",
            }}
          >
            <FormControl>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={props.repeat}
                style={{
                  fontFamily: "var(--text-font-family)",
                  color: "var(--text-color)",
                  margin: 0,
                  lineHeight: "1.3em",
                  letterSpacing: "0.03em",
                  fontSize: "16px",

                  height: "36px", //+4 since border 2px
                  marginTop: "auto",
                  marginBottom: "auto",
                  backgroundColor: "white",
                  minWidth: "230px",
                  maxWidth: "230px",
                }}
                onChange={(e: any) => {
                  props.setRepeat(e.target.value as number);
                  props.setRepeatMemo(e.target.value as number);
                }}
                sx={{
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "var(--neutral-10)",
                    borderWidth: "2px",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "var(--contrast)",
                    borderWidth: "2px",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "var(--neutral-10)",
                  },
                  "&:hover": {
                    backgroundColor: "var(--neutral-10) !important",
                  },
                }}
              >
                {props.repeatOptions.map((v: any, i: any) => (
                  <MenuItem value={i} key={i}>
                    {t("draw.repeat") + ": " + t("model." + v)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
    </>
  );
}

export default GridOverlay;
