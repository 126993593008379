
import { Link} from "react-router-dom";
import NavigationSection from "../LandingPage/Sections/NavigationSection";
import CardSection from "../LandingPage/Sections/CardSection";
import EndSection from "../LandingPage/Sections/EndSection";
import ProcessSection from "../LandingPage/Sections/ProcessSection";
import WoolitShopSection from "../LandingPage/Sections/WoolitShopSection";
import { ArrowRightIcon } from "@radix-ui/react-icons";

function CheckoutError() {
  return (
    <div>
      <NavigationSection></NavigationSection>
      <div style={{ display: "flex", flexDirection: "column", margin: "10%" }}>
        <h1>Betalingen er kansellert</h1>

        <div style={{ display: "flex" }}>
          <Link style={{ color: "#3D8036", cursor: "pointer" }} to="/landing">
            Gå til startsiden{" "}
            <ArrowRightIcon
              color={"#3D8036"}
              style={{ marginBottom: "-3px" }}
            ></ArrowRightIcon>
          </Link>
        </div>
      </div>
      <ProcessSection />
      <CardSection />

      <WoolitShopSection />
      <EndSection />
    </div>
  );
}

export default CheckoutError;
