import { createSlice, current, Draft, PayloadAction } from "@reduxjs/toolkit";
import { grid as initialGrid } from "./initialDiagramState";
import { DiagramData, GridCell } from "./pattern";
import {
  produceWithPatches,
  produce,
  Patch,
  applyPatches,
  original,
} from "immer";
import clamp from "../utils/clamp";
import { DynamicSymbols, Symbols } from "@iterate/woolit-components";
import { aabb } from "../Diagram/utils/aabb";
import { isDynamic } from "../CommonComponents/Icons/lookup";
import { deleteDiagramById, patternSlice } from "./patternSlice";
import { overlap } from "../Diagram/utils/overlap";

const gds = (x1: number, y1: number, x2: number, y2: number) => {
  return `${x1}_${y1}_${x2}_${y2}`;
};

type Coord = { x: number; y: number };
export type MarkedArea = { a: Coord; b: Coord; c: Coord; d: Coord };
interface GridState {
  mouseIsDown: boolean;
  grid: DiagramData;
  activeDiagram: "";
  undoStack: { patches: Patch[]; inversePatches: Patch[] }[];
  undoStackPointer: number;
  squareMenu: [number, number];
  cellWidth: number;
  cellHeight: number;
  selectedView: "grid" | "fell" | "model";
  gridCursor: Coord;
  markedArea: MarkedArea;
  copiedArea: GridCell[][];
  disableHotKeys: boolean;
  zoomCount: number;
  repeat: null | "EW" | "NS";
  showMenuRight: boolean;
  showMenuLeft: boolean;
  cursorMode: "color" | "cursor" | "erase";
  emptyCellCol: string;
}

const emptyArea = {
  a: { x: -1, y: -1 },
  b: { x: -1, y: -1 },
  c: { x: -1, y: -1 },
  d: { x: -1, y: -1 },
};

const initialState: GridState = {
  mouseIsDown: false,
  grid: initialGrid,
  activeDiagram: "",
  undoStack: [],
  undoStackPointer: -1,
  squareMenu: [-1, -1],
  cellWidth: 20,
  cellHeight: 16,
  zoomCount: 1,
  gridCursor: { y: 0, x: 0 },
  selectedView: "grid",
  markedArea: emptyArea,
  copiedArea: [[]],
  disableHotKeys: false,
  repeat: null,
  showMenuLeft: true,
  showMenuRight: true,
  cursorMode: "color",
  emptyCellCol: "#ffffff",
};

const emptyCell: GridCell = { color: 0, symbol: "stitch" };

const produceWithUndoStack = (
  state: Draft<GridState>,
  nextState: Draft<GridState> | undefined,
  patches: Draft<Draft<Patch>>[],
  inversePatches: Draft<Draft<Patch>>[]
) =>
  produce(nextState, (draft) => {
    if (!draft) return;
    draft.undoStackPointer = state.undoStackPointer + 1;
    draft.undoStack.length = draft.undoStackPointer;
    draft.undoStack[draft.undoStackPointer] = { patches, inversePatches };

    if (draft.undoStack.length > 20) {
      draft.undoStackPointer--;
      draft.undoStack.shift();
    }
  });

const gridSlice = createSlice({
  name: "grid",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(patternSlice.actions.replaceAllSymbols, (state, action) => {
      const { type, replacer, toReplace } = action.payload;
      if (type === "single") {
        state.grid.grid.forEach((row, y) => {
          row.forEach((cell, x) => {
            if (cell.symbol === toReplace) {
              state.grid.grid[y][x].symbol = replacer as Symbols;
            }
          });
        });
      } else if (type === "dynamic") {
        Object.entries(state.grid.dynamicSymbols ?? {}).forEach(
          ([key, data]) => {
            const { symbol } = data;
            if (symbol === toReplace) {
              state.grid.dynamicSymbols = {
                ...state.grid.dynamicSymbols,
                [key]: { ...data, symbol: replacer as DynamicSymbols },
              };
            }
          }
        );
      }
    });
    builder.addCase(deleteDiagramById.fulfilled, (state, action) => {
      return { ...state, activeDiagram: "" };
    });
  },
  reducers: {
    reset(_state) {
      return initialState;
    },
    updateGrid(state, action) {
      const { grid } = action.payload;
      const gridType = typeof grid.grid[0][0];
      // convert old format
      if (gridType === "string" || gridType === "number") {
        const convertedGrid = [];
        for (let i = 0; i < grid.grid.length; i++) {
          const row = grid.grid[i];
          const newRow = [];
          for (let j = 0; j < row.length; j++) {
            const col = row[j];
            const cell = { color: col, symbol: "stitch" };
            newRow.push(cell);
          }
          convertedGrid.push(newRow);
        }
        const updated = { ...grid, grid: convertedGrid };
        state.grid = updated;
      } else {
        state.grid = grid;
      }
    },
    setDiagramView(state, action) {
      state.selectedView = action.payload;
    },
    setActiveDiagram(state, action) {
      const { id } = action.payload;
      state.activeDiagram = id;
      state.undoStack = [];
    },
    updateCell(
      state,
      action: PayloadAction<{
        row: number;
        col: number;
        cellData: { symbol: Symbols | DynamicSymbols; color: number };
      }>
    ) {
      const { row, col, cellData } = action.payload;
      const { symbol } = cellData;
      const cell = state.grid.grid[row][col];
      const [nextState, patches, inversePatches] = produceWithPatches(
        original(state),
        (draft) => {
          if (!draft) return;
          draft.grid.grid[row][col] = {
            ...cell,
            ...cellData,
            symbol: isDynamic(symbol) ? "stitch" : (symbol as Symbols),
          };
        }
      );
      return produceWithUndoStack(
        state,
        nextState as Draft<GridState>,
        patches,
        inversePatches
      );
    },

    toggleColorDrag(state, action) {
      state.mouseIsDown = !state.mouseIsDown;
    },
    // will always remove from left to right, meaning index must be leftmost value in array
    removeRowsOrCols(
      state,
      action: PayloadAction<{
        direction: "row" | "col";
        index: number;
        count?: number;
        shiftContents?: boolean;
      }>
    ) {
      const {
        direction,
        index,
        count = 1,
        shiftContents = false,
      } = action.payload;

      const start = index;
      const end = index + count;
      const [nextState, patches, inversePatches] = produceWithPatches(
        original(state),
        (draft) => {
          if (!draft) return;
          if (direction === "row") {
            draft.grid.grid = state.grid.grid.filter(
              (_, i) => i < start || i >= end
            );
            draft.grid.gridHeight -= count;
          } else {
            draft.grid.grid = state.grid.grid.map((row) =>
              row.filter((_, i) => i < start || i >= end)
            );
            draft.grid.gridWidth -= count;
          }

          // Delete lines, exclusions and comments
          if (direction === "col") {
            Object.entries(draft.grid.lines ?? {}).forEach(([key, data]) => {
              const [posString, direction] = key.split("_");
              const pos = parseInt(posString);

              if (pos >= start && pos <= end) {
                delete draft.grid.lines?.[key];
              } else if (shiftContents) {
                const newPos = pos - count;
                if (draft.grid.lines) {
                  draft.grid.lines[`${newPos}_${direction}`] = data;
                  delete draft.grid.lines?.[key];
                }
              }
            });
          }
          const commentKey =
            direction === "row" ? "rowComments" : "colComments";
          Object.entries(
            draft.grid?.diagramComments?.[commentKey] ?? {}
          ).forEach(([key, data]) => {
            const pos = parseInt(key);
            if (pos >= start && pos <= end) {
              delete draft.grid.diagramComments?.[commentKey][key];
            } else if (shiftContents) {
              const newPos = pos - count;
              if (draft.grid.diagramComments) {
                draft.grid.diagramComments[commentKey][newPos] = data;
                delete draft.grid.diagramComments?.[commentKey][key];
              }
            }
          });

          // Delete exclusions
          Object.entries(draft.grid.excluded ?? {}).forEach(([key, data]) => {
            const [direction, posString] = key.split("_");
            const pos = parseInt(posString);

            if (pos > start && pos < end) {
              delete draft.grid.excluded?.[key];
            } else if (shiftContents) {
              const newPos = pos - count;
              if (draft.grid.excluded) {
                draft.grid.excluded[`${direction}_${newPos}`] = data;
                delete draft.grid.excluded?.[key];
              }
            }
          });

          const patternKey =
            direction === "row" ? "rowPatterns" : "colPatterns";

          Object.entries(draft.grid.patterns?.[patternKey] ?? {}).forEach(
            ([key, data]) => {
              const { start: commentStart, end: commentEnd } = data;
              if (!draft.grid.patterns) return;
              if (
                start < commentStart &&
                end > commentStart &&
                end < commentEnd
              ) {
                // shift comment start
                const newStart = Math.max(commentStart - count, 0);
                const newEnd = Math.max(commentEnd - count, 0);
                const d = draft.grid.patterns[patternKey][key];
                draft.grid.patterns[patternKey][newStart] = {
                  ...d,
                  start: newStart,
                  end: newEnd,
                };
                delete draft.grid.patterns[patternKey][key];
                delete draft.grid.patterns?.[patternKey][key];
              } else if (start < commentEnd && end > commentEnd) {
                // shift comment end
                const oldEnd = draft.grid.patterns[patternKey][key].end;
                draft.grid.patterns[patternKey][key].end = oldEnd - count;
              } else if (shiftContents) {
                const newStart = commentStart - count;
                const newEnd = commentEnd - count;

                const data = draft.grid.patterns[patternKey][key];
                draft.grid.patterns[patternKey][newStart] = {
                  ...data,
                  start: newStart,
                  end: newEnd,
                };
                delete draft.grid.patterns?.[patternKey][key];
              }
            }
          );
          Object.entries(draft.grid.dynamicSymbols ?? {}).forEach(
            ([key, data]) => {
              if (!draft.grid.dynamicSymbols) return;
              const { x: x1, y: y1 } = data.start;
              const { x: x2, y: y2 } = data.end;
              if (direction === "row") {
                const [newStart, newEnd] = overlap(
                  y1,
                  y2,
                  index,
                  index + count - 1,
                  shiftContents
                );
                if (newStart === -1) {
                  delete draft.grid.dynamicSymbols[key];
                } else {
                  delete draft.grid.dynamicSymbols[key];
                  const newKey = gds(x1, newStart, x2, newEnd);
                  draft.grid.dynamicSymbols[newKey] = {
                    ...data,
                    start: { x: x1, y: newStart },
                    end: { x: x2, y: newEnd },
                  };
                }
              } else if (direction === "col") {
                const [newStart, newEnd] = overlap(
                  x1,
                  x2,
                  index,
                  index + count - 1,
                  shiftContents
                );
                if (newStart === -1) {
                  delete draft.grid.dynamicSymbols[key];
                } else {
                  delete draft.grid.dynamicSymbols[key];
                  const newKey = gds(newStart, y1, newEnd, y2);
                  draft.grid.dynamicSymbols[newKey] = {
                    ...data,
                    start: { x: newStart, y: y1 },
                    end: { x: newEnd, y: y2 },
                  };
                }
              }
            }
          );
        }
      );
      return produceWithUndoStack(
        state,
        nextState as Draft<GridState>,
        patches,
        inversePatches
      );
    },
    addRowsOrCols(
      state,
      action: PayloadAction<{
        index: number;
        direction: "row" | "col";
        count?: number;
        shiftContents?: boolean;
      }>
    ) {
      // TODO: Shift lines, comments and such
      // Cases:

      const { index, direction, count = 1, shiftContents } = action.payload;
      const [nextState, patches, inversePatches] = produceWithPatches(
        original(state),
        (draft) => {
          if (!draft) return;
          if (direction === "row") {
            const newRows = Array(count)
              .fill("b")
              .map(() => new Array(state.grid.gridWidth).fill(emptyCell));
            draft.grid.grid.splice(index, 0, ...newRows);
            draft.grid.gridHeight += count;
          } else {
            const newCols = new Array(count)
              .fill(emptyCell)
              .map(() => ({ ...emptyCell }));
            draft.grid.grid.map((row) => row.splice(index, 0, ...newCols));
            draft.grid.gridWidth += count;
          }

          if (shiftContents) {
            if (direction === "col") {
              Object.entries(draft.grid.lines ?? {}).forEach(([key, data]) => {
                const [oldPos, direction] = key.split("_");
                const newPos = parseInt(oldPos) + count;
                if (draft.grid.lines) {
                  draft.grid.lines[`${newPos}_${direction}`] = data;
                  delete draft.grid.lines[key];
                }
              });
            }

            const patternKey =
              direction === "row" ? "rowPatterns" : "colPatterns";

            Object.entries(draft.grid.patterns?.[patternKey] ?? {}).forEach(
              ([key, data]) => {
                const newKey = parseInt(key) + count;
                const newEnd = data.end + count;
                const newData = { ...data, start: newKey, end: newEnd };
                if (draft.grid.patterns) {
                  draft.grid.patterns[patternKey][newKey] = newData;
                  delete draft.grid.patterns[patternKey][key];
                }
              }
            );

            const commentKey =
              direction === "row" ? "rowComments" : "colComments";

            Object.entries(
              draft.grid?.diagramComments?.[commentKey] ?? {}
            ).forEach(([key, data]) => {
              const newKey = parseInt(key) + count;
              const newData = { ...data, row: newKey };
              if (draft.grid.diagramComments) {
                draft.grid.diagramComments[commentKey][newKey] = newData;
                delete draft.grid.diagramComments[commentKey][key];
              }
            });

            // Update exclusions
            Object.entries(draft.grid.excluded ?? {}).forEach(([key, data]) => {
              const [direction, oldPos] = key.split("_");
              const newPos = parseInt(oldPos) + count;
              if (draft.grid.excluded) {
                draft.grid.excluded[`${direction}_${newPos}`] = data;
                delete draft.grid.excluded[key];
              }
            });

            // Update dynamic symbols
            Object.entries(draft.grid.dynamicSymbols ?? {}).forEach(
              ([key, data]) => {
                const [x1, y1, x2, y2] = key.split("_").map((x) => parseInt(x));

                if (direction === "row" && draft.grid.dynamicSymbols) {
                  const newKey = `${x1}_${y1 + count}_${x2}_${y2 + count}`;
                  draft.grid.dynamicSymbols[newKey] = {
                    ...data,
                    start: { x: x1, y: y1 + count },
                    end: { x: x2, y: y2 + count },
                  };
                  delete draft.grid.dynamicSymbols[key];
                } else if (direction === "col" && draft.grid.dynamicSymbols) {
                  const newKey = `${x1 + count}_${y1}_${x2 + count}_${y2}`;
                  draft.grid.dynamicSymbols[newKey] = {
                    ...data,
                    start: { x: x1 + count, y: y1 },
                    end: { x: x2 + count, y: y2 },
                  };
                  delete draft.grid.dynamicSymbols[key];
                }
              }
            );
          }
        }
      );
      return produceWithUndoStack(
        state,
        nextState as Draft<GridState>,
        patches,
        inversePatches
      );
    },
    setGridHeight(state, action) {
      const { newHeight } = action.payload;
      const [nextState, patches, inversePatches] = produceWithPatches(
        original(state),
        (draft) => {
          if (!draft) return;
          if (newHeight > state.grid.gridHeight) {
            if (!draft) return;
            const row = new Array(draft.grid.gridWidth).fill(emptyCell);
            const rows = new Array(newHeight - state.grid.gridHeight).fill(row);
            draft.grid.grid.push(...rows);
          } else {
            draft.grid.grid = state.grid.grid.filter((_, i) => i < newHeight);
          }
          draft.grid.gridHeight = newHeight;
        }
      );

      return produceWithUndoStack(
        state,
        nextState as Draft<GridState>,
        patches,
        inversePatches
      );
    },
    setGridWidth(state, action) {
      const { newWidth } = action.payload;
      const widthDiff = newWidth - state.grid.gridWidth;
      const [nextState, patches, inversePatches] = produceWithPatches(
        original(state),
        (draft) => {
          if (!draft) return;
          if (newWidth - state.grid.gridWidth > 0) {
            for (let i = 0; i < widthDiff; i++) {
              draft.grid.grid.map((row) => row.push(emptyCell));
            }
          } else {
            draft.grid.grid = state.grid.grid.map((row) =>
              row.filter((_, i) => i < newWidth)
            );
          }
          draft.grid.gridWidth = newWidth;
        }
      );

      return produceWithUndoStack(
        state,
        nextState as Draft<GridState>,
        patches,
        inversePatches
      );
    },

    insertDynamicSymbol(
      state,
      action: PayloadAction<{
        cellData: { color: number; symbol: DynamicSymbols | Symbols };
        area: { row: number; col: number }[];
      }>
    ) {
      const { cellData, area } = action.payload;
      const { symbol } = cellData;
      if (!isDynamic(symbol)) return;
      const [nextState, patches, inversePatches] = produceWithPatches(
        original(state),
        (draft) => {
          if (!draft) return;
          const len = area.length - 1;
          const x1 = area[0].col;
          const y1 = area[0].row;
          const x2 = area[len].col;
          const y2 = area[len].row;

          const key = `${x1}_${y1}_${x2}_${y2}`;
          if (!draft.grid.dynamicSymbols) {
            draft.grid.dynamicSymbols = {};
          }

          draft.grid.dynamicSymbols[key] = {
            symbol: symbol as DynamicSymbols,
            start: { x: x1, y: y1 },
            end: { x: x2, y: y2 },
          };
        }
      );

      return produceWithUndoStack(
        state,
        nextState as Draft<GridState>,
        patches,
        inversePatches
      );
    },
    removeDynamicSymbols(state, action: PayloadAction<{ keys: string[] }>) {
      const { keys } = action.payload;
      const [nextState, patches, inversePatches] = produceWithPatches(
        original(state),
        (draft) => {
          if (!draft) return;
          keys.forEach((key) => delete draft.grid.dynamicSymbols?.[key]);
        }
      );

      return produceWithUndoStack(
        state,
        nextState as Draft<GridState>,
        patches,
        inversePatches
      );
    },

    fillArea(
      state,
      action: PayloadAction<{ area: MarkedArea; cellData: GridCell }>
    ) {
      const { area, cellData } = action.payload;
      const { a, c } = area;
      const [nextState, patches, inversePatches] = produceWithPatches(
        original(state),
        (draft) => {
          if (!draft) return;
          for (let i = a.y; i < c.y + 1; i++) {
            for (let j = a.x; j < c.x + 1; j++) {
              const cell = draft.grid.grid[i][j];
              draft.grid.grid[i][j] = { ...cell, ...cellData };
            }
          }
        }
      );

      return produceWithUndoStack(
        state,
        nextState as Draft<GridState>,
        patches,
        inversePatches
      );
    },
    rotateArea(state, action) {
      const { area, degrees } = action.payload;
      const len = area.length - 1;
      const gX = state.gridCursor.x; //origo
      const gY = state.gridCursor.y; //origo
      // const gX = area[0].col; //old
      // const gY = area[0].row; //old

      const [nextState, patches, inversePatches] = produceWithPatches(
        original(state),
        (draft) => {
          if (!draft) return;

          function isOutside(row: number, col: number) {
            let minCol: number = 0;
            let maxCol: number = state.grid.grid[0].length - 1; //X
            let minRow: number = 0;
            let maxRow: number = state.grid.grid.length - 1; //X
            let outside: boolean =
              row < minRow || row > maxRow || col < minCol || col > maxCol;
            return outside; // true if outside of grid, false if inside
          }

          const oldCells: string[] = [];
          const newCells: string[] = [];

          if (degrees === 90) {
            //* rot(90)(x,y) = Global Origo (Gx,Gy) + Local Coordinate (-Ly,Lx) => Rotated global coord (x,y)
            for (let i = 0; i < area.length; i++) {
              const { row, col } = area[i];
              const lX = col - gX; //local x
              const lY = row - gY; //local y
              const rotatedCol = gX + lY * -1; //global x
              const rotatedRow = gY + lX; //global y
              if (isOutside(rotatedRow, rotatedCol) === true) {
                continue;
              }
              //if outside = true, dont put data there
              draft.grid.grid[rotatedRow][rotatedCol] =
                state.grid.grid[row][col];

              oldCells.push(`${row},${col}`);
              newCells.push(`${rotatedRow},${rotatedCol}`);
            }
          } else if (degrees === 180) {
            for (let i = 0; i < area.length; i++) {
              const { row, col } = area[i];
              const row2 = area[len - i].row;
              const col2 = area[len - i].col;

              draft.grid.grid[row][col] = state.grid.grid[row2][col2];
            }
          } else if (degrees === 270) {
            //* rot(270)(x,y) = Global Origo (Gx,Gy) + Local Coordinate (Ly,-Lx)
            //*   => Rotated global coord (x,y)
            for (let i = 0; i < area.length; i++) {
              const { row, col } = area[i];

              const lX = col - gX; //local x
              const lY = row - gY; //local y
              const rotatedCol = gX + lY; //global x
              const rotatedRow = gY + lX * -1; //global y
              if (isOutside(rotatedRow, rotatedCol) === true) {
                continue;
              }
              draft.grid.grid[rotatedRow][rotatedCol] =
                state.grid.grid[row][col];

              oldCells.push(`${row},${col}`);
              draft.grid.grid[rotatedRow][rotatedCol] &&
                newCells.push(`${rotatedRow},${rotatedCol}`);
            }
          }
          oldCells.forEach((cell) => {
            if (!newCells.includes(cell)) {
              const [row, col] = cell.split(",");
              draft.grid.grid[parseInt(row)][parseInt(col)] = emptyCell;
            }
          });
        }
      );

      return produceWithUndoStack(
        state,
        nextState as Draft<GridState>,
        patches,
        inversePatches
      );
    },

    hFlipArea(state, action) {
      const { area } = action.payload;
      const [nextState, patches, inversePatches] = produceWithPatches(
        original(state),
        (draft) => {
          if (!draft) return;
          for (let i = 0; i < area.length; i++) {
            const { row, col } = area[i];
            const flipped = area[area.length - 1 - i];
            draft.grid.grid[row][col] = state.grid.grid[flipped.row][col];
          }
        }
      );

      return produceWithUndoStack(
        state,
        nextState as Draft<GridState>,
        patches,
        inversePatches
      );
    },

    vFlipArea(state, action) {
      const { area } = action.payload;
      const [nextState, patches, inversePatches] = produceWithPatches(
        original(state),
        (draft) => {
          if (!draft) return;
          for (let i = 0; i < area.length; i++) {
            const { row, col } = area[i];
            const flipped = area[area.length - 1 - i];
            draft.grid.grid[row][col] = state.grid.grid[row][flipped.col];
          }
        }
      );

      return produceWithUndoStack(
        state,
        nextState as Draft<GridState>,
        patches,
        inversePatches
      );
    },

    updateExclusions(
      state,
      action: PayloadAction<{
        direction: "row" | "col";
        index: number;
        sizes: string[];
      }>
    ) {
      const { direction, index, sizes } = action.payload;

      const [nextState, patches, inversePatches] = produceWithPatches(
        original(state),
        (draft) => {
          if (!draft) return;
          // Delete
          if (sizes.length === 0) {
            delete draft.grid.excluded?.[`${direction}_${index}`];
          }
          // Add
          else {
            draft.grid.excluded = {
              ...state.grid.excluded,
              [`${direction}_${index}`]: sizes,
            };
          }
        }
      );

      return produceWithUndoStack(
        state,
        nextState as Draft<GridState>,
        patches,
        inversePatches
      );
    },
    updateMetaData(state, action) {
      state.grid = { ...state.grid, ...action.payload };
    },
    setContextMenuCoords(state, action) {
      const { row, cell } = action.payload;
      state.squareMenu = [row, cell];
    },
    setGridCursor(state, action: PayloadAction<{ x: number; y: number }>) {
      const x = clamp(action.payload.x, 0, state.grid.gridWidth - 1);
      const y = clamp(action.payload.y, 0, state.grid.gridHeight - 1);
      state.gridCursor = { x, y };
    },
    // Assumses sorted list
    setMarkedArea(
      state,
      action: PayloadAction<{ grid: { row: number; col: number }[] }>
    ) {
      const { grid } = action.payload;
      const len = grid.length - 1;
      if (len === -1) {
        state.markedArea = emptyArea;
      } else {
        state.gridCursor = {
          x: grid[0].col,
          y: grid[0].row,
        };

        state.markedArea = {
          a: { x: grid[0].col, y: grid[0].row },
          b: { x: grid[len].col, y: grid[0].row },
          c: { x: grid[len].col, y: grid[len].row },
          d: { x: grid[0].col, y: grid[len].row },
        };
      }
    },

    increaseTop(state, action: PayloadAction<{ x: number; y: number }>) {
      const { x, y } = action.payload;
      const newY = clamp(y - 1, 0, state.grid.gridHeight - 1);

      const { x1, y1, x2, y2 } = {
        x1: state.markedArea.a.x,
        y1: state.markedArea.a.y,
        x2: state.markedArea.c.x,
        y2: state.markedArea.c.y,
      };
      if (state.markedArea.a.x === -1) {
        state.markedArea = {
          a: { x: x1, y: newY },
          b: { x: x2, y: newY },
          c: { x, y },
          d: { x, y },
        };
      } else if (y1 === y && x >= x1 && x <= x2) {
        state.markedArea = {
          ...state.markedArea,
          a: { x: x1, y: newY },
          b: { x: x2, y: newY },
        };
      } else if (y2 === y && x >= x1 && x <= x2) {
        state.markedArea = {
          ...state.markedArea,
          c: { x: x2, y: y2 - 1 },
          d: { x: x1, y: y2 - 1 },
        };
      } else if (
        !aabb(
          { top: y, height: 1, left: x, width: 1 },
          { top: y1, width: x2 - x1, left: x1, height: y2 - y1 }
        )
      ) {
        state.markedArea = {
          a: { x, y: newY },
          b: { x, y: newY },
          c: { x, y },
          d: { x, y },
        };
      }
      state.gridCursor = { x, y: newY };
    },
    increaseBottom(state, action: PayloadAction<{ x: number; y: number }>) {
      const { x, y } = action.payload;
      const newY = clamp(y + 1, 0, state.grid.gridHeight - 1);

      const { x1, y1, x2, y2 } = {
        x1: state.markedArea.a.x,
        y1: state.markedArea.a.y,
        x2: state.markedArea.c.x,
        y2: state.markedArea.c.y,
      };

      // Base Case
      if (state.markedArea.a.x === -1) {
        state.markedArea = {
          a: { x: x, y: y },
          b: { x: x, y: y },
          c: { x, y: newY },
          d: { x, y: newY },
        };
      } else if (y2 === y && x >= x1 && x <= x2) {
        state.markedArea = {
          ...state.markedArea,
          d: { x: x1, y: newY },
          c: { x: x2, y: newY },
        };
      } else if (y1 === y && x >= x1 && x <= x2) {
        state.markedArea = {
          ...state.markedArea,
          a: { x: x1, y: y1 + 1 },
          b: { x: x2, y: y1 + 1 },
        };
      } else if (
        !aabb(
          { top: y, height: 1, left: x, width: 1 },
          { top: y1, width: x2 - x1, left: x1, height: y2 - y1 }
        )
      ) {
        state.markedArea = {
          a: { x, y },
          b: { x, y },
          c: { x, y: newY },
          d: { x, y: newY },
        };
      }
      state.gridCursor = { x, y: newY };
    },
    increaseLeft(state, action: PayloadAction<{ x: number; y: number }>) {
      const { x, y } = action.payload;
      const newX = clamp(x - 1, 0, state.grid.gridWidth - 1);

      const { x1, y1, x2, y2 } = {
        x1: state.markedArea.a.x,
        y1: state.markedArea.a.y,
        x2: state.markedArea.c.x,
        y2: state.markedArea.c.y,
      };

      // Base Case
      if (state.markedArea.a.x === -1) {
        state.markedArea = {
          a: { x: newX, y },
          b: { x, y },
          c: { x, y },
          d: { x: newX, y },
        };
      } else if (x1 === x && y >= y1 && y <= y2) {
        state.markedArea = {
          ...state.markedArea,
          a: { x: newX, y: y1 },
          d: { x: newX, y: y2 },
        };
      } else if (x2 === x && y >= y1 && y <= y2) {
        state.markedArea = {
          ...state.markedArea,
          b: { x: x2 - 1, y: y1 },
          c: { x: x2 - 1, y: y2 },
        };
      } else if (
        !aabb(
          { top: y, height: 1, left: x, width: 1 },
          { top: y1, width: x2 - x1, left: x1, height: y2 - y1 }
        )
      ) {
        state.markedArea = {
          a: { x: newX, y },
          b: { x, y },
          c: { x, y },
          d: { x: newX, y },
        };
      }
      state.gridCursor = { x: newX, y: y };
    },
    increaseRight(state, action: PayloadAction<{ x: number; y: number }>) {
      const { x, y } = action.payload;
      const newX = clamp(x + 1, 0, state.grid.gridWidth - 1);

      const { x1, y1, x2, y2 } = {
        x1: state.markedArea.a.x,
        y1: state.markedArea.a.y,
        x2: state.markedArea.c.x,
        y2: state.markedArea.c.y,
      };

      // Base Case
      if (state.markedArea.a.x === -1) {
        state.markedArea = {
          a: { x, y },
          b: { x: newX, y },
          c: { x: newX, y },
          d: { x: x, y },
        };
      } else if (x2 === x && y >= y1 && y <= y2) {
        state.markedArea = {
          ...state.markedArea,
          b: { x: newX, y: y1 },
          c: { x: newX, y: y2 },
        };
      } else if (x1 === x && y >= y1 && y <= y2) {
        state.markedArea = {
          ...state.markedArea,
          a: { x: x1 + 1, y: y1 },
          d: { x: x1 + 1, y: y2 },
        };
      } else if (
        !aabb(
          { top: y, height: 1, left: x, width: 1 },
          { top: y1, width: x2 - x1, left: x1, height: y2 - y1 }
        )
      ) {
        state.markedArea = {
          a: { x, y },
          b: { x: newX, y },
          c: { x: newX, y },
          d: { x: x, y },
        };
      }
      state.gridCursor = { x: newX, y: y };
    },

    cutMarkedArea(state) {
      /*
          a(x1,y1) --- b(x2,y1)
            |           |
            |           |
          d(x1,y2) --- c(x2,y2)
      */

      const { a, b, c } = state.markedArea;
      const [nextState, patches, inversePatches] = produceWithPatches(
        original(state),
        (draft) => {
          if (!draft) return;
          const rows = [];
          for (let i = a.y; i <= c.y; i++) {
            if (!draft) return;
            const row = state.grid.grid[i].slice(a.x, b.x + 1);
            rows.push(row);

            for (let k = 0; k < state.grid.gridWidth; k++) {
              if (k >= a.x && k <= b.x) {
                draft.grid.grid[i][k] = emptyCell;
              } else {
                continue;
              }
            }
          }
          draft.copiedArea = rows;
        }
      );

      return produceWithUndoStack(
        state,
        nextState as Draft<GridState>,
        patches,
        inversePatches
      );
    },

    copyMarkedArea(state) {
      // Make hard copy instead of ref to prevent changes in copied data after copy
      const { a, b, c } = state.markedArea;
      const rows = [];
      for (let i = a.y; i <= c.y; i++) {
        const row = state.grid.grid[i].slice(a.x, b.x + 1);
        rows.push(row);
      }
      state.copiedArea = rows;
    },
    pasteMarkedArea(state) {
      const { x, y } = { x: state.gridCursor.x + 1, y: state.gridCursor.y + 1 };
      const { gridWidth, gridHeight } = state.grid;
      const width = state.copiedArea[0].length;
      const height = state.copiedArea.length;

      const [nextState, patches, inversePatches] = produceWithPatches(
        original(state),
        (draft) => {
          if (!draft) return state;

          // Add necessary cols
          if (x + width - 1 > gridWidth) {
            const widthIncrease = x + width - 1 - gridWidth;
            for (let i = 0; i < widthIncrease; i++) {
              draft.grid.grid.map((row) => row.push(emptyCell));
            }
            draft.grid.gridWidth += widthIncrease;
          }

          // Add necessary rows
          if (y + height - 1 > gridHeight) {
            const heightIncrease = y + height - 1 - gridHeight;
            const row = new Array(draft.grid.gridWidth).fill(emptyCell);
            const rows = new Array(heightIncrease).fill(row);
            draft.grid.grid.push(...rows);
            draft.grid.gridHeight += heightIncrease;
          }

          // Logic needlessly complex, but splice logic caused weired bugs
          for (let i = 0; i < height; i++) {
            const newRow = new Array(draft.grid.gridWidth)
              .fill("b")
              .map((_, k) => {
                if (k + 1 >= x && k + 1 < x + state.copiedArea[i].length) {
                  return current(state.copiedArea[i][k + 1 - x]);
                } else {
                  return draft.grid.grid[y - 1 + i][k];
                }
              });
            draft.grid.grid[y - 1 + i] = newRow;
          }
        }
      );
      return produceWithUndoStack(
        state,
        nextState as Draft<GridState>,
        patches,
        inversePatches
      );
    },

    // PatternNode is combo of diagramPattern and diagramComment
    setPatternNote(
      state,
      action: PayloadAction<{
        from: number;
        to: number | null;
        sizes: string[];
        direction: "row" | "col";
        comment: string;
        relation?: "upper" | "lower" | "middle";
      }>
    ) {
      const { from, to, sizes, direction, comment, relation } = action.payload;

      if (!state.grid.patterns) {
        state.grid.patterns = { colPatterns: {}, rowPatterns: {} };
      }

      if (!state.grid.diagramComments) {
        state.grid.diagramComments = { colComments: {}, rowComments: {} };
      }
      if (to) {
        state.grid.patterns[
          direction === "col" ? "colPatterns" : "rowPatterns"
        ][`${from}`] = {
          start: from,
          end: to,
          sizes,
          comment,
        };
      } else {
        state.grid.diagramComments[
          direction === "col" ? "colComments" : "rowComments"
        ][`${from}`] = { comment, relation, sizes };
      }
    },
    removeDiagramPattern(
      state,
      action: PayloadAction<{ direction: "row" | "col"; index: number }>
    ) {
      const { direction, index } = action.payload;
      delete state.grid.patterns?.[
        direction === "col" ? "colPatterns" : "rowPatterns"
      ][index];
    },
    removeDiagramComment(
      state,
      action: PayloadAction<{ direction: "row" | "col"; index: number }>
    ) {
      const { direction, index } = action.payload;
      delete state.grid.diagramComments?.[
        direction === "col" ? "colComments" : "rowComments"
      ][index];
    },
    setRepeatMode(state, action) {
      const { mode } = action.payload;
      if (mode === state.repeat) {
        state.repeat = null;
      } else {
        state.repeat = mode;
      }
    },
    addLine(
      state,
      action: PayloadAction<{
        direction: "row" | "col";
        index: number;
        color?: string;
      }>
    ) {
      const { index, direction, color = "#33251a" } = action.payload;
      const [nextState, patches, inversePatches] = produceWithPatches(
        original(state),
        (draft) => {
          if (!draft) return;
          const key = `${index}_${direction}`;
          draft.grid.lines = {
            ...draft.grid.lines,
            [key]: { show: true, color },
          };
        }
      );
      return produceWithUndoStack(
        state,
        nextState as Draft<GridState>,
        patches,
        inversePatches
      );
    },
    removeLine(
      state,
      action: PayloadAction<{ index: number; direction: "row" | "col" }>
    ) {
      const { index, direction } = action.payload;
      const [nextState, patches, inversePatches] = produceWithPatches(
        original(state),
        (draft) => {
          const key = `${index}_${direction}`;
          delete draft?.grid.lines?.[key];
        }
      );
      return produceWithUndoStack(
        state,
        nextState as Draft<GridState>,
        patches,
        inversePatches
      );
    },
    toggleShowMenuLeft(state, action) {
      state.showMenuLeft = action.payload;
    },
    toggleShowMenuRight(state, action) {
      state.showMenuRight = action.payload;
    },
    setCursorMode: (
      state,
      action: PayloadAction<{ mode: "erase" | "cursor" | "color" }>
    ) => {
      const { mode } = action.payload;
      state.cursorMode = mode;
    },
    undo(state) {
      //const patch = state.gridPast[state.gridPast.length - 1];
      if (state.undoStackPointer < 0 || state.undoStack.length === 0) return;
      const patches = state.undoStack[state.undoStackPointer].inversePatches;
      state.undoStackPointer--;
      state = applyPatches(state, patches);
    },
    redo(state) {
      //const patch = state.gridFuture[state.gridFuture.length - 1];
      if (
        state.undoStackPointer === state.undoStack.length - 1 ||
        state.undoStack.length === 0
      ) {
        return;
      }

      state.undoStackPointer++;
      const patches = state.undoStack[state.undoStackPointer].patches;
      state = applyPatches(state, patches);
    },
    setDisableHotkeys(state, action: PayloadAction<boolean>) {
      state.disableHotKeys = action.payload;
    },
    clearUndoStack(state) {
      state.undoStackPointer = 0;
      state.undoStack = [];
    },
    zoomIn(state) {
      if (state.zoomCount < 6) {
        state.zoomCount++;
        state.cellWidth += 4;
        state.cellHeight += 4;
      }
    },
    zoomOut(state) {
      if (state.zoomCount > 0) {
        state.zoomCount--;
        state.cellWidth -= 4;
        state.cellHeight -= 4;
      }
    },
  },
});

export const {
  updateGrid,
  toggleColorDrag,
  setGridHeight,
  setGridWidth,
  updateCell,
  updateMetaData,
  setActiveDiagram,
  removeRowsOrCols,
  addRowsOrCols,
  setContextMenuCoords,
  toggleShowMenuLeft,
  toggleShowMenuRight,
  setCursorMode,
  fillArea,
  rotateArea,
  hFlipArea,
  vFlipArea,
  updateExclusions,
  setGridCursor,
  setMarkedArea,
  cutMarkedArea,
  copyMarkedArea,
  pasteMarkedArea,
  setDisableHotkeys,
  setPatternNote,
  removeDiagramPattern,
  removeDiagramComment,
  addLine,
  removeLine,
  zoomIn,
  zoomOut,
  clearUndoStack,
  setRepeatMode,
  undo,
  redo,
  setDiagramView,
  reset,
  increaseTop,
  increaseBottom,
  increaseLeft,
  increaseRight,
  insertDynamicSymbol,
  removeDynamicSymbols,
} = gridSlice.actions;

export { gridSlice };
