import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";

import Top from "../CommonComponents/Top";
import { fetchPatternById } from "../store/patternSlice";
import { RootState } from "../store/store";
import DiagramEditor from "./DiagramEditor";
import { clearUndoStack, setDiagramView } from "../store/gridSlice";
import { DragSelect } from "./DragSelect";
import { Loading } from "../CommonComponents/Loading";
import Visualizing3D from "../Visualizing3D/Visualizing3D";
import SplitView from "./SplitView";
import DiagramSelector from "./DiagramSelector";
import DiagramViewer from "../Visualizing/DiagramViewer";
import Intro from "../Intro";
import MobileMessage from "../MobileMessage";

import styles from "./diagram.module.css";
import mixpanel from "mixpanel-browser";

const DiagramWrapper = () => {
  const { cellWidth, cellHeight } = useSelector(
    (state: RootState) => state.grid
  );
  const { patternId } = useParams();

  // State
  const { pattern } = useSelector((state: RootState) => state);

  const { disabled } = useSelector((state: RootState) => state.dragSelect);

  const dispatch = useDispatch();

  const loadDataCallback = useCallback(
    () =>
      patternId && pattern.id.toString() !== patternId
        ? dispatch(fetchPatternById(patternId))
        : null,
    [dispatch, patternId, pattern.id]
  );

  useEffect(() => {
    return () => {
      dispatch(clearUndoStack());
    };
  }, []);

  useEffect(() => {
    loadDataCallback();
  }, [loadDataCallback]);

  useEffect(() => {
    mixpanel.track("diagram:view");
  }, []);

  const { selectedView } = useSelector((state: RootState) => state.grid);

  const { form } = useSelector((state: RootState) => state.pattern.meta);

  useEffect(() => {
    dispatch(setDiagramView(form === "freeform" ? "grid" : "model"));
  }, [form]);

  if (isMobile) {
    return <MobileMessage />;
  }

  return (
    <>
      <div style={{ display: "flex" }}>
        <Intro tutorialName={"draw"} />
        <Top transparent />
        <div style={{ position: "relative" }}>
          {!disabled && <DragSelect disabled={disabled} />}
        </div>
        <SplitView>
          {selectedView !== "grid" && (
            <>
              {/* Modell */}
              {selectedView === "model" && <Visualizing3D />}
              {/* Strikk sammen*/}
              {selectedView === "fell" && <DiagramViewer />}
            </>
          )}
          <>
            {pattern.id && pattern.id !== -1 ? (
              <div
                style={{
                  overflow: "auto",
                  height: "100%",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    backgroundSize: `${cellWidth}px ${cellHeight}px`,
                    minHeight: "200%",
                    minWidth: "200%",
                  }}
                  className={`${styles.background}`}
                  id={"grid-anchor"}
                >
                  <DiagramEditor />
                </div>
              </div>
            ) : (
              <div
                style={{ backgroundSize: `${cellWidth}px ${cellHeight}px` }}
                className={`${styles.background} ${styles.coverScreen}`}
                id={"grid-anchor"}
              >
                <Loading />
              </div>
            )}
          </>
        </SplitView>
        <DiagramSelector />
      </div>
    </>
  );
};

export default DiagramWrapper;
