import { useEffect } from "react";
import { useTranslation } from "react-i18next";

function AdjustGap(props: any) {
  let diff = props.adjustGapStartPos.x - props.adjustGapPos.x;
  let width = Math.abs(diff);
  let offset = Math.min(-diff, 0);
  let gap = Math.round(width / 5);

  const { t } = useTranslation();

  useEffect(() => {
    if (!props.pointerPattern) return;
    const groupPatterns = props.sweaterPart.getGroupPatterns(
      props.pointerPattern.groupID
    );
    for (let groupPattern of groupPatterns) {
      const { sweaterPart, pattern } = groupPattern;
      pattern.gap = gap;
      const gridHTML =
        sweaterPart === props.sweaterPart ? props.gridHTML : undefined;
      sweaterPart.updatePattern(pattern, gridHTML);
    }
    props.render();
  }, [props.adjustGapPos.x]);
  return (
    <>
      <div
        style={{
          position: "fixed",
          left: props.adjustGapStartPos.x + offset - 100,
          top: props.adjustGapStartPos.y,
          width: width + 200,
          zIndex: 5000,
          pointerEvents: "none",
          transform: "translate(0, -100%)",
          display: "flex",
        }}
      >
        <p
          style={{
            backgroundColor: "rgba(0,0,0,0.5)",
            color: "white",
            margin: "auto",
            overflow: "",
            borderRadius: "5px",
            padding: "0px 5px",
          }}
        >
          {t("model.gap")}: {gap}
        </p>
      </div>
      <hr
        style={{
          position: "fixed",
          left: props.adjustGapStartPos.x,
          top: props.adjustGapStartPos.y - 5,
          width: "0px",
          height: "10px",
          border: "1px solid var(--positive)",
          zIndex: 5000,
          pointerEvents: "none",
        }}
      />
      <hr
        style={{
          position: "fixed",
          left: props.adjustGapPos.x,
          top: props.adjustGapStartPos.y - 5,
          width: "0px",
          height: "10px",
          border: "1px solid var(--positive)",
          zIndex: 5000,
          pointerEvents: "none",
        }}
      />
      <hr
        style={{
          position: "fixed",
          left: props.adjustGapStartPos.x + offset,
          top: props.adjustGapStartPos.y,
          width: width + "px",
          border: "1px solid var(--positive)",
          zIndex: 5000,
          pointerEvents: "none",
        }}
      />
    </>
  );
}
export default AdjustGap;
