import { Tooltip } from "@mui/material";
import { PlacementLineType } from "../enums";
import { Settings } from "../static/settings";
import { useTranslation } from "react-i18next";

function PlacementLine(props: any) {
  const { t } = useTranslation();
  if (!props.placementLineType) {
    return <></>;
  }
  const textLine = (top: boolean) => (
    <div
      style={{
        position: "absolute",
      }}
    >
      <div
        style={{
          borderBottom: "1px solid " + (props.illegal ? "red" : "black"),
          height: "0px",
          width: "4px",
        }}
      ></div>
      <div
        style={{
          position: "absolute",
          textAlign: "center",
          marginLeft: "15px",
          height: props.gridSize + "px",
          top: top ? "0px" : "-10px",
        }}
      >
        {(top || !props.small) && (
          <p
            style={{
              fontSize: "10px",
              color: props.illegal ? "red" : "black",
            }}
          >
            {Settings.reverseY ? props.gridSizeY - props.y : props.y}
          </p>
        )}
      </div>
    </div>
  );

  function tooltipTitle() {
    if (props.fromOtherSweater) {
      return props.outOfBounds
        ? t("model.out of bounds sweater part")
        : t("model.occupied sweater part");
    }
    return "";
  }
  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          zIndex: 300,
          marginLeft: "20px",
          marginTop: props.gridSize * props.y,
        }}
      >
        {props.placementLineType === PlacementLineType.Top && textLine(true)}
        <Tooltip title={tooltipTitle()}>
          <div
            style={{
              width: "10px",
              marginLeft: "-3.5px",
            }}
          >
            <div
              style={{
                marginLeft: "5px",
                borderLeft: "1px solid " + (props.illegal ? "red" : "black"),
                width: "0px",
                height: props.gridSize + "px",
              }}
            ></div>
          </div>
        </Tooltip>
        {props.placementLineType === PlacementLineType.Bottom &&
          textLine(false)}
      </div>
    </div>
  );
}

export default PlacementLine;
