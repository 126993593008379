import styles from "../Styles/Sections.module.css";
import stylesOnboarding from "../Styles/Onboarding.module.css";
import { CheckIcon, Cross1Icon } from "@radix-ui/react-icons";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import {
  useMeQuery,
  useGetFreeSubscriptionMutation,
} from "../../store/woolitApi";
import mixpanel from "mixpanel-browser";

function OnboardingCard(props: {
  title: string;
  description: { includes: string[]; notIncludes: string[] };
}) {
  const { data: user } = useMeQuery();

  const navigate = useNavigate();
  const [getFreeSubscription] = useGetFreeSubscriptionMutation();

  const getFreeSubscriptionIfLoggedIn = async () => {
    mixpanel.track("landingPage:subscription", {
      subscriptionType: "free",
    });

    if (!user) {
      navigate("/login", {
        state: {
          subscribe: {
            onboarding: "free",
          },
        },
      });
      return;
    }

    getFreeSubscription();
  };

  return (
    <div className={`${stylesOnboarding.onboardingCardContainer}`}>
      <div className={`${stylesOnboarding.onboardingCard}`}>
        <div
          style={{ color: "#8F6D57", fontSize: "18px", alignSelf: "center" }}
        >
          Woolit Design Studio
        </div>

        <div
          style={{ fontSize: "31px", paddingBottom: "5%", paddingTop: "2%" }}
        >
          {" "}
          {props.title}
        </div>

        <div className={stylesOnboarding.desciptionField}>
          {props.description.includes.map((description) => {
            return (
              <div className={stylesOnboarding.descriptionRow}>
                <CheckIcon color="green"></CheckIcon>
                <div className={stylesOnboarding.description}>
                  {description}
                </div>
              </div>
            );
          })}

          {props.description.notIncludes.map((description) => {
            return (
              <div className={stylesOnboarding.descriptionRow}>
                <Cross1Icon color="red"></Cross1Icon>
                <div className={stylesOnboarding.description}>
                  {description}
                </div>
              </div>
            );
          })}
        </div>

        <div style={{ paddingTop: "10px" }}>{}</div>

        <div
          style={{
            fontSize: "31px",
            paddingBottom: "0px",
            display: "flex",
            flexDirection: "row",
            alignItems: "baseline",
          }}
        >
          {t("landing.free")}
        </div>
        {!user?.subscriber && (
          <button
            onClick={() => {
              getFreeSubscriptionIfLoggedIn();
            }}
            className={styles.ctaButton}
          >
            {t("landing.free_start")}
          </button>
        )}
        {user?.subscriber && (
          <a href="/projects" className={styles.ctaButton}>
            {t("goToWDS")}
          </a>
        )}
      </div>
    </div>
  );
}

export default OnboardingCard;
