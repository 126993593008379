import { DynamicSymbols, Symbols } from "@iterate/woolit-components";
import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";
import { setCursorMode } from "./gridSlice";

type SymbolType = "single" | "dynamic" | null;
interface ColorState {
  colors: { name: string; hex: string }[];
  activeIndex: number;
  activeSymbol: Symbols | DynamicSymbols;
  symbolType: SymbolType;
}

const initialState: ColorState = {
  colors: [
    { name: "Farge 1", hex: "#eee" },
    { name: "Farge 2", hex: "#E0A320" },
    { name: "Farge 3", hex: "#EAE414" },
  ],
  activeIndex: 0,
  activeSymbol: "stitch",
  symbolType: null,
};

const colorSlice: Slice<ColorState> = createSlice({
  name: "color",
  initialState,
  reducers: {
    setColorVariant(state, action) {
      const { variant } = action.payload;
      state.colors = variant.colors;
    },
    updateColorValue(state, action) {
      const { index, update } = action.payload;
      state.colors[index] = { ...state.colors[index], ...update };
    },
    setActiveColorIndex: (state, action: PayloadAction<number>) => {
      state.activeIndex = action.payload;
    },
    setActiveSymbol(
      state,
      action: PayloadAction<{
        symbol: Symbols | DynamicSymbols;
        type: SymbolType;
      }>
    ) {
      state.activeSymbol = action.payload.symbol;
      state.symbolType = action.payload.type;
    },
  },
  extraReducers: {
    [setCursorMode.type](state, action) {
      const { mode } = action.payload;
      if (mode === "erase") {
        state.activeIndex = -1;
      } else {
        state.activeIndex = Math.max(state.activeIndex, 0);
      }
    },
  },
});

export const {
  updateColorValue,
  setActiveColorIndex,
  setActiveSymbol,
  setColorVariant,
} = colorSlice.actions;

export { colorSlice };
