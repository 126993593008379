import { useEffect, useState } from "react";
import React from "react";
import styles from "./library.module.css";
import { backendApiAddress } from "../backendApi";
import PatternCanvas from "../Visualizing3D/knittingeditor/PatternCanvas";
import { Util } from "../Visualizing3D/static/util";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";
import { Link } from "react-router-dom";
import { DiagramData } from "../store/pattern";
import { shortUUID } from "../utils/uuid";
import { addEmptyDiagram, updateDiagramById } from "../store/patternSlice";
import {
  setActiveDiagram,
  setDisableHotkeys,
  updateGrid,
} from "../store/gridSlice";
import { useTranslation } from "react-i18next";

function Library(props: any) {
  const [menuIndex, setMenuIndex] = useState(0);

  const [patternsList, setPatternsList] = useState([]);
  const [diagramsList, setDiagramsList] = useState([]);
  const color = useSelector((state: RootState) => state.color);

  const { diagrams, id } = useSelector((state: RootState) => state.pattern);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  useEffect(() => {
    if (Object.keys(diagrams).length > 0) {
      const first = diagrams[Object.keys(diagrams)[0]];
      dispatch(setActiveDiagram({ id: first.id }));
      dispatch(updateGrid({ grid: first }));
    }
  }, [id]);

  const makeDiagram = (diagram: DiagramData) => {
    dispatch(
      addEmptyDiagram({
        diagramId: diagram.id,
        diagramData: diagram,
      })
    );
    dispatch(
      updateDiagramById({
        patternId: id,
        diagram: diagram,
      })
    );

    dispatch(setActiveDiagram({ id: diagram.id }));
    dispatch(updateGrid({ grid: diagram }));
    dispatch(setDisableHotkeys(false));
  };

  const duplicateDiagram = (diagram: DiagramData) => {
    let duplicatedDiagram: DiagramData = {
      ...diagram,
      id: shortUUID(),
    };
    makeDiagram(duplicatedDiagram);
  };

  const fetchData = async () => {
    const responseDiagrams = await fetch(
      `${backendApiAddress}/api/pattern/diagrams?limit=50&offset=0`,
      {
        method: "GET",
        credentials: "include",
      }
    );
    const _diagrams = await responseDiagrams.json();
    setDiagramsList(_diagrams.data.map((item: any) => item.diagram));

    const responsePatterns = await fetch(
      `${backendApiAddress}/api/pattern/list?limit=50&offset=0`,
      {
        method: "GET",
        credentials: "include",
      }
    );
    const _patterns = await responsePatterns.json();
    setPatternsList(_patterns);
  };

  useEffect(() => {
    fetchData();
  }, []);
  const patternsLib = () => (
    <div
      className="showScrollbar"
      style={{
        flex: "1",
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
        padding: "10px",
        gap: "10px",
      }}
    >
      {patternsList.map((pattern: any) => (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "5px 10px",
            }}
          >
            <p
              style={{
                margin: "5px 0",
              }}
            >
              {pattern.meta.title !== ""
                ? pattern.meta.title
                : `${t("untitled")} (${pattern.id})`}
            </p>
            {pattern.id !== id && (
              <Link to={`/edit/${pattern.id}/draw`}>
                <button>{t("open")}</button>
              </Link>
            )}
          </div>
          <hr
            style={{
              margin: "0px",
              marginLeft: "-20px",
              width: "calc(100% + 40px)",
              opacity: "0.5",
            }}
          />
        </>
      ))}
    </div>
  );

  const { variants } = useSelector((state: RootState) => state.pattern.meta);

  const activeVariantId = useSelector(
    (state: RootState) => state.displayState.activeVariant
  );

  const variantIndex =
    variants.length > 0
      ? variants.findIndex((v) => v.id === activeVariantId)
      : 0;

  const hasColors =
    variants[variantIndex] && variants[variantIndex].colors.length > 0;

  const diagramsLib = () => (
    <>
      {!hasColors && <h4 style={{ padding: "10px" }}>{t("select colors")}</h4>}
      {hasColors && (
        <div
          className="showScrollbar"
          style={{
            flex: "1",
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gridTemplateRows: "130px",
            overflowY: "auto",
            padding: "10px",
            gap: "10px",
          }}
        >
          {diagramsList.map((diagram: any) => (
            <div
              style={{
                display: "flex",
                maxHeight: "120px",
              }}
            >
              <div
                style={{
                  margin: "auto",
                }}
              >
                <button
                  className={styles.patternbutton}
                  style={{
                    position: "relative",
                    width: "120px",
                    height: "120px",
                    padding: "5px 5px 3px 5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    duplicateDiagram(diagram);
                  }}
                >
                  <PatternCanvas
                    key={diagram.id}
                    pattern={Util.diagramToPattern(diagram)}
                    colors={color.colors.map((it: any) => it.hex)}
                  />
                  <div
                    className={styles.showHover}
                    style={{
                      borderRadius: "5px",
                      position: "absolute",
                      padding: "5px 5px 3px 5px",
                      minWidth: "100%",
                      height: "100%",
                      backgroundColor: "rgba(0,0,0,0.5)",
                    }}
                  ></div>
                  <div
                    className={styles.showHover}
                    style={{
                      position: "absolute",
                      width: "70px",
                      left: "50%",
                      top: "50%",
                      transform: "translate(-50%,-50%)",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <img
                      alt="plus"
                      src="/plus_white.png"
                      style={{
                        margin: "auto",
                        width: "40px",
                        height: "40px",
                      }}
                    ></img>
                    <p
                      style={{
                        color: "var(--neutral-10)",
                        textAlign: "center",
                        fontWeight: 500,
                        fontSize: "14px",
                      }}
                    >
                      {t("draw.add to pattern")}
                    </p>
                  </div>
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );

  return (
    <>
      <div
        style={{
          position: "fixed",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          backgroundColor: "rgba(0,0,0,0.5)",
          zIndex: 500,
          display: "flex",
          flexDirection: "column",
          pointerEvents: "auto",
        }}
        onClick={() => {
          props.handleClose();
        }}
      ></div>
      <div
        style={{
          position: "fixed",
          left: "75%",
          right: 0,
          top: 0,
          bottom: 0,
          backgroundColor: "white",
          zIndex: 1000,
          display: "flex",
          flexDirection: "column",
          pointerEvents: "auto",
        }}
      >
        <div
          style={{
            padding: "20px",
          }}
        >
          <h1
            style={{
              fontSize: "30px",
            }}
          >
            {t("my library")}
          </h1>
          <p>{t("draw.your saved patterns and diagrams")}</p>
        </div>
        <div
          style={{
            padding: "0px",
          }}
        >
          <button
            className={
              styles.menubutton + " " + (menuIndex === 0 ? styles.active : "")
            }
            onClick={() => {
              setMenuIndex(0);
            }}
          >
            <p
              style={{
                fontSize: "14px",
                fontWeight: 500,
                padding: "5px",
              }}
            >
              {t("draw.patterns")}
            </p>
          </button>
          <button
            className={
              styles.menubutton + " " + (menuIndex === 1 ? styles.active : "")
            }
            onClick={() => {
              setMenuIndex(1);
            }}
          >
            <p
              style={{
                fontSize: "14px",
                fontWeight: 500,
                padding: "5px",
                boxSizing: "border-box",
              }}
            >
              {t("draw.diagrams")}
            </p>
          </button>
        </div>
        <hr
          style={{
            margin: "0px",
            width: "100%",
          }}
        />
        {menuIndex === 0 && patternsLib()}
        {menuIndex === 1 && diagramsLib()}
      </div>
    </>
  );
}
export default Library;
