import { useDispatch, useSelector } from "react-redux";
import { removeVariantColor, addVariantColor } from "../../store/patternSlice";
import { RootState } from "../../store/store";
import { APIYarn, APIYarnColor } from "../../services/yarnApi";
import { Color } from "../../store/pattern";
import ColorPreview from "../../CommonComponents/ColorPreview";
import styles from "./YarnSelectorColor.module.css";
import { backendApiAddress } from "../../backendApi";

export function colorFromApiColor(color: APIYarnColor, yarn: APIYarn): Color {
  if (color.sku) {
    const url =
      color.image_swatch !== null
        ? `${backendApiAddress}/images/v1/get/${color.image_swatch.id}/${color.image_swatch.token}?width=16&height=16&crop=false`
        : color.image_base !== null
        ? `${backendApiAddress}/images/v1/get/${color.image_base.id}/${color.image_base.token}?width=16&height=16&crop=false`
        : "";

    return {
      name: color.color_tint,
      code: color.color_tint_code,
      sku: color.sku,
      image: url,
      yarn: { name: yarn.name, sku: yarn.sku, id: yarn.id },
      hex: color.hex,
    };
  } else {
    return { hex: color.hex ?? "#fafafa", sku: null };
  }
}

function makeColorForPreview(color: APIYarnColor, currentYarn: APIYarn): Color {
  if (color.hex !== null) {
    return { hex: color.hex, sku: null };
  } else {
    return colorFromApiColor(color, currentYarn);
  }
}

interface PropType {
  color: APIYarnColor;
  yarn: APIYarn;
}

function YarnSelectorColor(props: PropType) {
  const { color, yarn } = props;

  let previewColor = makeColorForPreview(color, yarn);

  const dispatch = useDispatch();

  const activeVariantId = useSelector(
    (state: RootState) => state.writer.subdrawerContent.id as string
  );

  const selectedYarnKeys = useSelector((state: RootState) => {
    const variant = state.pattern.meta.variants.filter(
      (variant) => variant.id === activeVariantId
    )[0];
    return variant?.colors.map((color) => color.sku ?? color.hex) ?? [];
  });

  const selected = color.sku
    ? selectedYarnKeys.includes(color.sku)
    : color.hex
    ? selectedYarnKeys.includes(color.hex)
    : false;

  function handleClickYarnColor(
    selected: boolean,
    activeVariantId: string,
    color: Color
  ) {
    if (selected) {
      const colorIndex = selectedYarnKeys.findIndex(
        (key) => key === (color.sku ?? color.hex)
      );

      dispatch(removeVariantColor({ variantId: activeVariantId, colorIndex }));
    } else {
      dispatch(addVariantColor({ variantId: activeVariantId, color }));
    }
  }

  return (
    <div
      key={color.sku}
      className={styles.color}
      onClick={() =>
        handleClickYarnColor(
          selected,
          activeVariantId,
          colorFromApiColor(color, yarn)
        )
      }
    >
      <div className={styles.preview}>
        <div className={styles.previewInner}>
          <ColorPreview color={previewColor} fillParent={true} />
        </div>
      </div>
      <div className={styles.colorName}>
        {color.color_tint + " " + color.color_tint_code}
      </div>
      <div
        className={`${styles.selector} ${selected ? styles.selected : ""}`}
      ></div>
    </div>
  );
}

export default YarnSelectorColor;
