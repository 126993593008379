import React from "react";

import { Link, useNavigate } from "react-router-dom";
import { ArrowRightIcon } from "@radix-ui/react-icons";
import { useTranslation } from "react-i18next";

import { useGetFreeSubscriptionMutation, useMeQuery } from "../store/woolitApi";

import styles from "./HeaderSection.module.css";
import diagram from "./assets/diagram.gif";
import mixpanel from "mixpanel-browser";

function HeaderSection() {
  const { data: user } = useMeQuery();

  const { t } = useTranslation();

  const navigate = useNavigate();
  const [getFreeSubscription] = useGetFreeSubscriptionMutation();

  const getFreeSubscriptionIfLoggedIn = async () => {
    mixpanel.track("diagramLandingPage:subscription", {
      subscriptionType: "free",
    });

    if (!user) {
      navigate("/login", {
        state: {
          subscribe: {
            onboarding: "free",
          },
        },
      });
      return;
    }

    getFreeSubscription();
  };

  return (
    <div>
      <div className={styles.headerSection} id="header-section">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: "20px",
          }}
        >
          <div style={{ alignSelf: "center", position: "relative" }}>
            <img
              width="413"
              height="330"
              src={diagram}
              style={{ alignSelf: "center" }}
              alt="Illustrasjon av Woolit Design Studio Pro"
            />
          </div>
        </div>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "left",
              alignItems: "flex-start",
              flexDirection: "column",
              background: "#ffffff",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            <span className={styles.headerAnnotation}>{t("landing.free")}</span>
            <h1 className={styles.headerTitle}>
              {t("landing_diagram.open for all")}
            </h1>
            <p className={styles.headerDescription}>
              {t("landing_diagram.description")}
            </p>
            <>
              {" "}
              {user?.subscription && (
                <button className={styles.ctaButton}>
                  <Link style={{ cursor: "pointer" }} to="/projects">
                    {t("goToWDS")}
                    <ArrowRightIcon
                      style={{ marginBottom: "-3px" }}
                    ></ArrowRightIcon>
                  </Link>
                </button>
              )}
              {!user?.subscription && (
                <button className={styles.ctaButton}>
                  <button
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      getFreeSubscriptionIfLoggedIn();
                    }}
                  >
                    {t("tryWDS")}
                    <ArrowRightIcon
                      style={{ marginBottom: "-3px" }}
                    ></ArrowRightIcon>
                  </button>
                </button>
              )}
            </>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderSection;
