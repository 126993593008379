import Button from "../../ISO/Components/Button";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { Load } from "../../Visualizing3D/SaveLoad";
import { useTranslation } from "react-i18next";
import { setKnitMethod } from "../../store/patternSlice";

export const KnitMethodCard = (props: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { knitMethod } = useSelector((state: RootState) => state.pattern.meta);

  const { id } = useSelector((state: RootState) => state.pattern);
  const checkIfDesignExists = (action: any) => {
    Load(`${id}`, (load: any) => {
      const exists = load && load["design"];
      if (exists && !window.confirm(t("datadrawer.overwrite model"))) {
        return;
      }
      action();
    });
  };
  return (
    <Button
      active={props.knitMethod === knitMethod}
      onClick={() => {
        if (props.knitMethod === knitMethod) return;
        checkIfDesignExists(() => dispatch(setKnitMethod(props.knitMethod)));
      }}
      style={{
        minWidth: "180px",
        maxWidth: "180px",
        height: "50px",
        display: "flex",
        paddingRight: "220px",
      }}
      contrast
    >
      <img alt="knit_method" src={props.src} style={{ height: "40px" }}></img>
      <p
        style={{
          margin: "auto",
        }}
      >
        {props.text}
      </p>
    </Button>
  );
};
