import React, { FunctionComponent } from "react";
import styles from "./ChangeStatusModal.module.css";
import { Status, StatusMsg } from "../store/pattern";
import { formatDate } from "../utils/dateFormat";
import { useUpdatePatternStatusMutation } from "../services/patternApi";

interface ChangeStatusModalProps {
  onConfirm: () => void;
  onCancel: () => void;
  message: string;
  user: {};
  status: Status;
  patternId: number;
}
export const ChangeStatusModal: FunctionComponent<ChangeStatusModalProps> = ({
  status,
  patternId,
}) => {
  const [updateStatus] = useUpdatePatternStatusMutation();

  const statusMsgs: StatusMsg[] = [
    "Utkast",
    "Ferdig",
    "Prøvestrikk",
    "Korrektur",
    "Forkastet",
    "Avbrutt",
    "Under arbeid",
  ];

  return (
    <>
      <div className={styles.confirmationButtons}>
        {statusMsgs.map((statusMsg) => (
          <button
            className={`${styles.button} ${
              status.statusMsg === statusMsg ? styles.currentStatus : ""
            }`}
            onClick={() => updateStatus({ id: patternId, statusMsg })}
          >
            {statusMsg}
          </button>
        ))}
        {status.logs && status?.logs?.length > 0 && (
          <div className={styles.logBox}>
            <ol reversed className={styles.logBoxList}>
              {status.logs.map((log) => (
                <dt>
                  {log.timestamp ? formatDate(log.timestamp) : "Ukjent dato"}
                  {" - "}
                  {log.statusMsg} {" - "} {log.user.lastName},{""}
                  {log.user.firstName}
                </dt>
              ))}
            </ol>
          </div>
        )}
      </div>
    </>
  );
};
