import { Pattern, PatternOverview, StatusMsg } from "../store/pattern";
import { patternSlice } from "../store/patternSlice";
import { woolitApi } from "../store/woolitApi";

type DateString = string;

export type Published = {
  created: DateString;
  comment: string | null;
};

interface PublishData {
  priceDetails?: {
    designer_excl_vat: number;
    designer_vat: number;
    woolit_excl_vat: number;
    woolit_vat: number;
  };
  published: Published[];
}

export type LibraryPatterns = {
  mine: PatternOverview[];
  comment: PatternOverview[];
  edit: PatternOverview[];
  organizations: {
    id: number;
    name: string;
    patterns: PatternOverview[];
  }[];
  all?: PatternOverview[];
};

const woolitApiWithPattern = woolitApi.injectEndpoints({
  endpoints: (builder) => ({
    patternPublished: builder.query<PublishData, string>({
      query: (patternId) => `/pattern/${patternId}/published`,
      providesTags: ["PUBLICATION"],
    }),
    updatePatternPrice: builder.mutation({
      query: ({ id, price }) => ({
        url: `/pattern/${id}/price`,
        method: "POST",
        body: JSON.stringify({ ...price }),
      }),
      invalidatesTags: ["PUBLICATION"],
    }),
    updateSymbols: builder.mutation({
      query: ({ id, symbols }) => ({
        url: `/pattern/${id}/symbols`,
        method: "POST",
        body: JSON.stringify(symbols),
      }),
      async onQueryStarted({ id, ...patch }, { dispatch }) {
        const { symbols } = patch;
        dispatch(patternSlice.actions.setPatternSymbols({ symbols }));
      },
      invalidatesTags: ["SYMBOLS"],
    }),
    addToOrganization: builder.mutation({
      query: ({ id, newOrgId }) => ({
        url: `/pattern/${id}/add_to_organization/${newOrgId}`,
        method: "POST",
      }),
      invalidatesTags: ["ORGANIZATION"],
    }),
    fetchLibrary: builder.query<LibraryPatterns, void>({
      query: () => "/pattern/library",
      providesTags: ["LIBRARY"],
    }),
    deletePattern: builder.mutation<
      Pattern,
      Partial<Pattern> & Pick<Pattern, "id">
    >({
      query: ({ id }) => ({
        url: `/pattern/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["LIBRARY"],
    }),
    removeFromShop: builder.mutation<
      Pattern,
      Partial<Pattern> & Pick<Pattern, "id">
    >({
      query: ({ id }) => ({
        url: `/pattern/${id}/remove_from_shop`,
        method: "POST",
      }),
      invalidatesTags: ["LIBRARY"],
    }),
    updatePatternStatus: builder.mutation<
      LibraryPatterns,
      { id: number; statusMsg: StatusMsg }
    >({
      query: ({ id, statusMsg }) => ({
        url: `/pattern/${id}/status`,
        method: "POST",
        body: JSON.stringify({ statusMsg }),
      }),
      invalidatesTags: ["LIBRARY"],
    }),
  }),
});

export const {
  usePatternPublishedQuery,
  useUpdatePatternPriceMutation,
  useUpdateSymbolsMutation,
  useAddToOrganizationMutation,
  useFetchLibraryQuery,
  useDeletePatternMutation,
  useRemoveFromShopMutation,
  useUpdatePatternStatusMutation,
} = woolitApiWithPattern;
