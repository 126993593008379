import {
  DiagramSymbol,
  DynamicSymbol,
  DynamicSymbols,
  Symbols,
} from "@iterate/woolit-components";
import { Cross1Icon } from "@radix-ui/react-icons";
import React, { CSSProperties, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  dynamicSymbolKeys,
  symbolKeys,
} from "../CommonComponents/Icons/lookup";

import { ModalButton } from "../CommonComponents/ModalButton";
import { useModal } from "../CommonComponents/useModal";
import Button from "../ISO/Components/Button";
import { useUpdateSymbolsMutation } from "../services/patternApi";
import { setDisable } from "../store/dragSelectSlice";
import { setDisableHotkeys } from "../store/gridSlice";
import { DynamicGridSymbol, GridSymbol } from "../store/pattern";
import {
  removeSymbolFromDiagrams,
  replaceAllSymbols,
  SymbolType,
} from "../store/patternSlice";
import { RootState } from "../store/store";
import styles from "./SymbolModal.module.css";

interface PropType {
  index: number;
  className?: string;
  style?: CSSProperties;
  symbolKey?: Symbols | DynamicSymbols;
  symbolLabel?: string;
  currentSymbols: (GridSymbol | DynamicGridSymbol)[];
  symbolType: SymbolType;
  action: "add" | "edit" | "delete";
}

export const SymbolModalButton: React.FC<PropType> = (props) => {
  const { isShown, toggle } = useModal();

  const dispatch = useDispatch();
  useEffect(() => {
    if (isShown) {
      dispatch(setDisable(true));
      dispatch(setDisableHotkeys(true));
    } else {
      dispatch(setDisable(false));
      dispatch(setDisableHotkeys(false));
    }
  }, [isShown]);

  const { children } = props;
  return (
    <ModalButton
      {...props}
      headerText={""}
      isShown={isShown}
      toggle={toggle}
      modalContent={<ModalContent {...props} toggle={toggle} />}
    >
      {children}
    </ModalButton>
  );
};

interface ModalProps {
  index: number;
  symbolKey?: Symbols | DynamicSymbols;
  symbolType: SymbolType;
  symbolLabel?: string;
  toggle: () => void;
  currentSymbols: (GridSymbol | DynamicGridSymbol)[];
  action: "add" | "edit" | "delete";
}

const ModalContent = (props: ModalProps) => {
  const {
    index,
    symbolKey,
    symbolLabel,
    toggle,
    currentSymbols,
    symbolType,
    action,
  } = props;

  const [updateSymbols] = useUpdateSymbolsMutation();
  const { t } = useTranslation();
  const { id } = useSelector((state: RootState) => state.pattern);

  const [symbolToDisplay, setSymbolToDisplay] =
    useState<SymbolType>(symbolType);

  useEffect(() => {
    if (symbolKey) {
      setSymbol(symbolKey);
    }
  }, [symbolKey]);
  const dispatch = useDispatch();

  const [selectedSymbol, setSymbol] = useState<Symbols | DynamicSymbols | null>(
    null
  );
  const [label, setLabel] = useState<string>(
    symbolLabel ?? t("draw.new symbol")
  );

  return (
    <div className={styles.modal}>
      <div className={styles.top}>
        <h3>{index === -1 ? t("draw.add symbol") : t("draw.edit symbol")}</h3>
        <button onClick={() => toggle()}>
          <Cross1Icon />
        </button>
      </div>
      <hr className={styles.hr} style={{ marginBottom: "24px" }} />
      <div className={styles.active}>
        {symbolToDisplay === "single" && (
          <div className={styles.activeSymbol}>
            <DiagramSymbol
              symbol={(selectedSymbol as Symbols) ?? "stitch"}
              iconProps={{ width: 18, height: 18, fill: "black" }}
            />
          </div>
        )}
        {symbolToDisplay === "dynamic" && selectedSymbol !== null && (
          <div className={styles.activeSymbol}>
            <DynamicSymbol
              symbol={selectedSymbol as DynamicSymbols}
              iconProps={{ width: 120, height: 24, fill: "black" }}
            />
          </div>
        )}
        {symbolToDisplay === "both" && "Velg symbol"}
        {symbolToDisplay !== "both" && (
          <input
            className={styles.input}
            value={label}
            onChange={(e) => setLabel(e.target.value)}
            placeholder={t("draw.symbol name")}
          />
        )}
      </div>
      <hr className={styles.hr} style={{ margin: "24px 0" }} />
      <div style={{ width: "100%" }}>{t("draw.symbols")}</div>
      {symbolType !== "dynamic" && (
        <>
          <div className={styles.symbolGroup}>
            {symbolKeys.map((key, i) => (
              <button
                style={{ padding: 0 }}
                className={`${styles.symbolContainer} ${
                  key === selectedSymbol ? styles.activeSymbol : ""
                }`}
                disabled={
                  !!currentSymbols.find((s) => s.key === key) &&
                  !(symbolKey === key)
                }
                onClick={() => {
                  setSymbol(key);
                  setSymbolToDisplay("single");
                }}
                key={key}
              >
                <DiagramSymbol
                  symbol={key}
                  iconProps={{ width: 18, height: 18, fill: "black" }}
                />
              </button>
            ))}
          </div>
          <hr className={styles.hr} style={{ marginTop: "24px" }} />
        </>
      )}
      {symbolType !== "single" && (
        <>
          <div className={styles.symbolGroup}>
            {dynamicSymbolKeys.map((key, i) => (
              <button
                key={i}
                onClick={() => {
                  setSymbol(key);
                  setSymbolToDisplay("dynamic");
                }}
                className={`${styles.dynamicSymbolContainer} ${
                  key === selectedSymbol ? styles.activeSymbol : ""
                }`}
                disabled={
                  !!currentSymbols.find((s) => s.key === key) &&
                  !(symbolKey === key)
                }
              >
                <DynamicSymbol
                  symbol={key}
                  iconProps={{ width: 112, height: 24, fill: "black" }}
                />
              </button>
            ))}
          </div>
          <hr className={styles.hr} style={{ marginTop: "24px" }} />
        </>
      )}

      <div className={styles.bottom}>
        <Button
          contrast
          onClick={() => {
            const tmp = [...currentSymbols];
            tmp.splice(index, 1);
            updateSymbols({ id, symbols: tmp, type: action });
            dispatch(
              removeSymbolFromDiagrams({
                symbolToRemove: symbolKey! as DynamicSymbols | Symbols,
                type: symbolType,
              })
            );
            toggle();
          }}
        >
          {t("draw.remove symbol")}
        </Button>

        <Button
          onClick={() => {
            if (action === "add") {
              const newSymbol = { key: selectedSymbol, label } as
                | DynamicGridSymbol
                | GridSymbol;
              const tmp = [...currentSymbols, newSymbol];
              updateSymbols({ id, symbols: tmp, type: action });
            } else if (action === "edit") {
              const tmp = [...currentSymbols];
              const updatedSymbols = { key: selectedSymbol, label } as
                | DynamicGridSymbol
                | GridSymbol;
              tmp.splice(index, 1, updatedSymbols);
              updateSymbols({ id, symbols: tmp, type: action });
              dispatch(
                replaceAllSymbols({
                  type: symbolType,
                  toReplace: symbolKey as DynamicSymbols | Symbols,
                  replacer: selectedSymbol as DynamicSymbols | Symbols,
                })
              );
            }
            toggle();
          }}
          disabled={symbolToDisplay === "both"}
          active
        >
          {action === "edit" ? t("draw.edit symbol") : t("draw.add symbol")}
        </Button>
      </div>
    </div>
  );
};
