import useHotkeys from "@reecelucas/react-use-hotkeys";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Transforms, Text } from "slate";

import DiagramSection from "../../ISO/DiagramSection";
import { RootState } from "../../store/store";
import { shortUUID } from "../../utils/uuid";
import { ElementSelectorProps } from "./ElementSelector";

import styles from "./element.module.css";
import { t } from "i18next";

export type DiagramSectionElementType = {
  type: "diagramSection";
  children: { text: string }[];
  id: string;
  diagram: string;
  commentId?: string;
};

export const DiagramSectionSelect = ({
  closeSelector,
  editor,
  action,
}: Omit<ElementSelectorProps, "element">) => {
  const diagrams = useSelector((state: RootState) => state.pattern.diagrams);

  const [arrowIndex, setArrowIndex] = useState(0);
  useHotkeys("ArrowUp", (e) => {
    e.preventDefault();
    setArrowIndex(arrowIndex - 1 < 0 ? 0 : arrowIndex - 1);
  });

  const diagramList = Object.entries(diagrams);

  useHotkeys("ArrowDown", (e) => {
    e.preventDefault();
    setArrowIndex(
      arrowIndex + 1 === diagramList.length
        ? diagramList.length - 1
        : arrowIndex + 1
    );
  });

  useHotkeys("Enter", (e) => {
    e.preventDefault();
    addDiagramSection(diagramList[arrowIndex][0]);
  });

  const addDiagramSection = (key: string) => {
    // We only handle "add" action since there is no replacing inline diagrams
    if (action === "add") {
      Transforms.insertNodes(editor, [
        {
          type: "diagramSection",
          children: [{ text: "" }],
          id: shortUUID(),
          diagram: key,
        },
        {
          type: "span",
          children: [{ text: " " }],
        },
      ]);
    }
    closeSelector();
  };

  return (
    <>
      {Object.entries(diagrams).map(([key, diagram], index) => (
        <div
          key={index}
          onMouseOver={() => setArrowIndex(index)}
          style={{ background: arrowIndex === index ? "#faf6f2" : "" }}
          onClick={() => addDiagramSection(key)}
          className={styles.hoverable}
        >
          {index + 1 <= 9 ? `0${index + 1}` : `${index + 1}`} {diagram.name}
        </div>
      ))}
    </>
  );
};

type DiagramSectionElementProps = {
  attributes: any;
  children: Text[];
  element: DiagramSectionElementType;
  onClick: (element: DiagramSectionElementType) => void;
};

export const DiagramSectionElement = ({
  attributes,
  children,
  element,
  onClick,
}: DiagramSectionElementProps) => {
  return (
    <div {...attributes} style={{ width: "100%" }} contentEditable={false}>
      <button onClick={() => onClick(element)}>
        <img src="/x.svg" alt={"A cross"} />
      </button>
      - {t("delete_diagram")}
      <DiagramSection sectionData={element} />
      {children}
    </div>
  );
};
