// @ts-ignore
import { hexToRgb } from "./colorutil";
import { Global } from "../static/global";
import { Part } from "../Part";
import { setUpdateCanvasNextFrame } from "./core/scene";

let maskWidth = -1; // Is overwritten by the below function
let maskHeight = -1; // Is overwritten by the below function

export function updateMaskWidthHeight(width: number, height: number) {
  maskWidth = width;
  maskHeight = height;
}

let prerender: { [x: string]: HTMLCanvasElement } = {};

let image_base: HTMLImageElement;
let image_mask: HTMLImageElement;

let color_to_image: { [x: string]: ImageData } = {};

export function loadImages() {
  let waitForLoad: HTMLImageElement[] = [];

  image_base = new Image(maskWidth, maskHeight);
  image_base.src = "/3D/shirt/patterns2.png";
  waitForLoad.push(image_base);

  image_mask = new Image(maskWidth, maskHeight);
  image_mask.src = "/3D/shirt/patterns2_regions.png";
  waitForLoad.push(image_mask);

  return waitForLoad;
}

export function createCanvas() {
  let canvas = document.createElement("canvas");

  canvas.width = Global.canvasWidth;
  canvas.height = Global.canvasHeight;

  return canvas;
}

export function darkenCanvas(
  canvas: HTMLCanvasElement,
  canvas_backup: HTMLCanvasElement
) {
  let ctx = canvas.getContext("2d")!!;
  ctx.clearRect(0, 0, Global.canvasWidth, Global.canvasHeight);
  ctx.drawImage(canvas_backup, 0, 0);
}

export function lightenCanvas(
  canvas: HTMLCanvasElement,
  selectedPart: Part,
  canvas_backup: HTMLCanvasElement
) {
  let ctx_backup = canvas_backup.getContext("2d")!!;
  ctx_backup.clearRect(0, 0, Global.canvasWidth, Global.canvasHeight);
  ctx_backup.drawImage(canvas, 0, 0);

  let ctx = canvas.getContext("2d")!!;
  //ctx.fillStyle = "rgba(0, 50, 150, 0.5)"
  ctx.fillStyle = "rgba(255, 255, 255, 0.25)";
  const minX = selectedPart.corner1X * Global.canvasWidth;
  const minY = selectedPart.corner1Y * Global.canvasHeight;
  const maxX = selectedPart.corner2X * Global.canvasWidth;
  const maxY = selectedPart.corner2Y * Global.canvasHeight;
  ctx.fillRect(minX, minY, maxX - minX, maxY - minY);
}

function drawRotatedImage(
  context: any,
  image: any,
  x: any,
  y: any,
  angle: any,
  sizeMultX: any,
  sizeMultY: any
) {
  if (angle < 0) {
    angle += 360;
  }
  const TO_RADIANS = Math.PI / 180;
  // save the current co-ordinate system
  // before we screw with it
  context.save();

  // move to the middle of where we want to draw our image
  context.translate(x, y);

  // rotate around that point, converting our
  // angle from degrees to radians
  context.rotate(angle * TO_RADIANS);

  // draw it up and to the left by half the width
  // and height of the image
  context.drawImage(
    image,
    -((image.width * sizeMultX) / 2),
    -((image.height * sizeMultY) / 2),
    image.width * sizeMultX,
    image.height * sizeMultY
  );

  // and restore the co-ords to how they were when we began
  context.restore();
}

function drawRowPhysics(
  y: number,
  sweaterPart: Part,
  colors: string[],
  mask_n_x: number,
  mask_n_y: number,
  canvas: any,
  ctx: any
) {
  const isDirtyRow = sweaterPart.dirtyPositionsGrid[y]
    .slice(0, mask_n_x)
    .find((it) => it);
  const diffArms = sweaterPart.connectY - y;
  if (!isDirtyRow) return;
  const row = sweaterPart.grid[y].slice(0, mask_n_x);
  const gaps = row
    .map((color, index) => [color, index])
    .filter((it) => it[0] === -1)
    .map((it) => it[1]);
  const gapsCopy = [...gaps];
  const gapsReverse = [...gapsCopy.reverse()];
  const hasGaps = gaps.length > 0;
  const hasOnlyGaps = gaps.length === row.length;
  const ignoreGaps =
    !sweaterPart.isPatternNonPreview(y) || !hasGaps || hasOnlyGaps;

  let firstIsMask = mask_n_x;
  let lastIsMask = 0;
  for (let x = 0; x < mask_n_x; x++) {
    if (sweaterPart.isMask(x, y)) {
      if (x < firstIsMask) {
        firstIsMask = x;
      }
      if (x > lastIsMask) {
        lastIsMask = x;
      }
    }
  }
  const isMaskLength = lastIsMask - firstIsMask;
  //const sizeMult = 1;

  //texture_canvas_rot.width = maskWidth * sizeMult;
  //texture_canvas_rot.height = maskHeight * 2;
  //const ctx_rot = texture_canvas_rot.getContext("2d")!!;

  const middleX = (mask_n_x - 1) * 0.5;
  for (let x = 0; x < mask_n_x; x++) {
    sweaterPart.dirtyPositionsGrid[y][x] = false;
    let color = colors[sweaterPart.grid[y][x]];
    if (color === undefined) {
      if (!ignoreGaps) {
        continue;
      }
      color = colors[0];
    }

    let x_draw = x;
    let mult = 1;
    if (!ignoreGaps) {
      const toGapIndex = gaps.find((it) => it > x) ?? mask_n_x;
      const fromGapIndex = gapsReverse.find((it) => it < x) ?? 0;
      const toGap = toGapIndex - x;
      const fromGap = x - fromGapIndex;
      const sum = toGap + fromGap;
      let right = 0;
      while (gaps.includes(toGapIndex + right)) {
        right++;
      }
      let left = 0;
      while (gapsReverse.includes(fromGapIndex - left)) {
        left++;
      }
      const spaceToOccupy = right / 2 + (sum - 1) + left / 2;
      const index = fromGap - 1;
      const fraction = (index + 0.5) / (sum - 1);

      const space = fraction * spaceToOccupy;
      const move = space - index - left / 2 - 0.5;
      x_draw += move;

      mult = row.length / (row.length - gaps.length);
      mult = 1 + (mult - 1) / 2.5;
    }
    let y_draw = y;

    const sizeMult = mult;

    const _x = x_draw * maskWidth + sweaterPart.corner1X * canvas.width;
    const _y = y_draw * maskHeight + sweaterPart.corner1Y * canvas.height;
    const __x = _x + (maskWidth * sizeMult) / 2;
    const __y = _y + maskHeight;

    const shiftAmount = (middleX - x_draw) / (isMaskLength / 2); //shift by x
    const shiftAmount2 = Math.sign(shiftAmount) * Math.pow(shiftAmount, 2); //shift by x^2, stronger effects at edges

    const shiftX = maskWidth * shiftAmount * (diffArms / 7);
    const shiftY = -maskHeight * Math.abs(shiftAmount) * (diffArms / 14); //Opposite scalars
    const shiftAngle = shiftAmount2 * 10;

    drawRotatedImage(
      ctx,
      prerender[color],
      __x + shiftX * (sweaterPart.isArm() ? 1 : 1.5),
      __y + shiftY,
      shiftAngle,
      sizeMult,
      sizeMult
    );

    /*ctx.drawImage(
      prerender[color],
      _x,
      _y,
      maskWidth * sizeMult,
      maskHeight * 2
    );*/
  }
}

function drawRow(
  y: number,
  part: Part,
  colors: string[],
  mask_n_x: number,
  mask_n_y: number,
  canvas: any,
  ctx: any
) {
  for (let x = 0; x <= mask_n_x; x++) {
    if (part.dirtyPositionsGrid[y][x]) {
      let color = colors[part.grid[y][x]];
      if (color === undefined) {
        color = colors[0];
      }
      let x_draw = x;
      let y_draw = y;
      if (part.invertedX) {
        x_draw = mask_n_x - 0.5 - x;
      }
      if (part.inverted) {
        x_draw = mask_n_x - 1 - x; // Maybe -0.5 but lets not affect older models
        x_draw = (x_draw + Math.round(mask_n_x / 2)) % mask_n_x; //offset x by 50%
        y_draw = mask_n_y - 1 - y;
      }

      // Three js renderer is prone to scaling artifacts
      // These offsets works, found with manual testing
      let scalingArtifactOffset = 0;
      if (part.isSweater()) {
        const scalingArtifactOffsets = {
          "Left Arm": part.knitMethod !== "stitched-sleeves" ? 2 : -1,
          "Right Arm": 1,
          "Front Torso": 0,
          "Back Torso": 0,
          Collar: 0,
        };
        const sweaterPartName = part.name as
          | "Left Arm"
          | "Right Arm"
          | "Front Torso"
          | "Back Torso"
          | "Collar";
        scalingArtifactOffset = scalingArtifactOffsets[sweaterPartName];
      } else {
        const scalingArtifactOffsets = {
          Leg: 0.5,
          TopFoot: 1,
          Heel: 1,
          BottomFoot: 0,
        };
        const sweaterPartName = part.name as
          | "Leg"
          | "TopFoot"
          | "Heel"
          | "BottomFoot";
        scalingArtifactOffset = scalingArtifactOffsets[sweaterPartName];
      }
      if (scalingArtifactOffset == null) {
        throw new Error("No scaling artifact offset");
      }

      ctx.drawImage(
        prerender[color],
        x_draw * maskWidth +
          part.corner1X * canvas.width +
          scalingArtifactOffset,
        y_draw * maskHeight + part.corner1Y * canvas.height
      );
      part.dirtyPositionsGrid[y][x] = false;
    }
  }
}

// Doesnt cost that much to just redraw
// the full upperbody if one cell is dirty.
// for 'rundfelling', cells are placed differently
// depending on gaps in the diagram, therefore
// we need to clear the whole upper body
// to ensure that there are no remains from the
// old draw.
function setDirtyIfDirtyUpper(
  mask_n_x: number,
  width: number,
  height: number,
  sweaterPart: Part,
  canvas: any,
  ctx: any
) {
  for (let y = sweaterPart.connectY; y >= 0; y--) {
    let isDirty = sweaterPart.dirtyPositionsGrid[y]
      .slice(0, mask_n_x)
      .find((it) => it);
    if (isDirty) {
      ctx.clearRect(
        sweaterPart.corner1X * canvas.width,
        sweaterPart.corner1Y * canvas.height,
        width,
        height
      );
      sweaterPart.setDirty();
      // Whole sweater must be set dirty since
      // Masks from upper body can affect lower body
      // Could be optimized slightly, but why
      // make it hard for myself when it
      // isn't that costly anyway.
      break;
    }
  }
}

export function drawCanvas(
  canvas: any,
  parts_arg: Part[],
  colors: string[],
  repeatY: boolean,
  selectedPart: Part | undefined
) {
  if (!Global.imageData) {
    setUpdateCanvasNextFrame();
    return;
  }
  for (const color of colors) {
    if (!color) continue; // Dont render undefined
    if (!(color in prerender)) {
      prerender[color] = prerenderCanvas(maskWidth, maskHeight, color);
    }
  }

  let ctx = canvas.getContext("2d");

  for (let sweaterPart of parts_arg) {
    let width = (sweaterPart.corner2X - sweaterPart.corner1X) * canvas.width;
    let height = (sweaterPart.corner2Y - sweaterPart.corner1Y) * canvas.height;

    let mask_n_x = Math.ceil(width / maskWidth);
    let mask_n_y = Math.floor(height / maskHeight); //NB: Fixes "pattern i collar, så bucket remove = bug, litt svart rundt", but not well tested

    if (sweaterPart.circularYoke) {
      setDirtyIfDirtyUpper(mask_n_x, width, height, sweaterPart, canvas, ctx);
    }

    for (let y = mask_n_y - 1; y >= 0; y--) {
      //const isPattern = sweaterPart.isPatternNonPreview(y);
      const isOverArms = sweaterPart.isOverArms(y);
      const applyPhysics = isOverArms && sweaterPart.circularYoke;
      if (applyPhysics) {
        drawRowPhysics(y, sweaterPart, colors, mask_n_x, mask_n_y, canvas, ctx);
      } else {
        drawRow(y, sweaterPart, colors, mask_n_x, mask_n_y, canvas, ctx);
      }
    }
  }
}

export function prerenderCanvas(
  maskWidth: number,
  maskHeight: number,
  color: string
) {
  let canvas = document.createElement("canvas");

  canvas.width = maskWidth;
  canvas.height = maskHeight * 2;

  let ctx = canvas.getContext("2d")!!;

  if (!(color in color_to_image)) {
    throw new Error("Color: " + color + " is not prerendered");
  }

  ctx.putImageData(color_to_image!![color], 0, 0);

  return canvas;
}

function color_image_mask(
  canvas: HTMLCanvasElement,
  imageDataMask: any,
  color: string
) {
  let ctx = canvas.getContext("2d")!!;
  let w = maskWidth;
  let h = maskHeight;
  let colored_image = image_base!!;
  ctx.drawImage(colored_image, 0, 0, w, h);
  ctx.drawImage(colored_image, 0, h, w, h);
  let imageData = ctx.getImageData(0, 0, w, h * 2);
  let rgb = hexToRgb(color)!!;
  const darknessRed = 1 - rgb.r / 255;
  const darknessGreen = 1 - rgb.g / 255;
  const darknessBlue = 1 - rgb.b / 255;
  const darkness = darknessRed * darknessGreen * darknessBlue;
  const lightenAmount = 0.1 * darkness; // Necessary, or else fully black colors wont have visible masks
  rgb.r = rgb.r * (1 - lightenAmount) + lightenAmount * 255;
  rgb.g = rgb.g * (1 - lightenAmount) + lightenAmount * 255;
  rgb.b = rgb.b * (1 - lightenAmount) + lightenAmount * 255;
  for (let i = 0; i < imageData.data.length; i += 4) {
    let me;
    let other;
    if (i > imageData.data.length / 2) {
      me = imageDataMask.data[i] / 255.0;
      other = imageDataMask.data[i + 2] / 255.0;
    } else {
      me = imageDataMask.data[i + 2] / 255.0;
      other = imageDataMask.data[i] / 255.0;
    }
    let background = imageDataMask.data[i + 1] / 255.0;
    let max = Math.max(me, background, other);
    if (max === background) {
      max *= 0.75;
    } else if (max === other) {
      imageData.data[i + 3] = 0;
    }
    imageData.data[i] *= (max * rgb.r) / 255.0;
    imageData.data[i + 1] *= (max * rgb.g) / 255.0;
    imageData.data[i + 2] *= (max * rgb.b) / 255.0;
  }
  color_to_image[color] = imageData;
}

export function renderAfterLoad(canvas: any, colors: any) {
  let ctx = canvas.getContext("2d")!!;
  let w = maskWidth;
  let h = maskHeight;
  ctx.drawImage(image_mask!!, 0, 0, w, h);
  ctx.drawImage(image_mask!!, 0, h, w, h);
  let region_image_mask = ctx.getImageData(0, 0, w, h * 2);
  for (let color of colors) {
    color_image_mask(canvas, region_image_mask, color);
  }
}
