import { useTranslation } from "react-i18next";

import diagram_landing_diagram from "./assets/diagram_landing_diagram.png";
import diagram_landing_test from "./assets/diagram_landing_test.png";
import diagram_landing_app from "./assets/diagram_landing_app.png";
import styles from "./ProcessSection.module.css";

function ProcessSection() {
  const { t } = useTranslation();
  return (
    <div className={styles.processSection}>
      <div className={styles.processImageContainer}>
        <div className={styles.processImageCard}>
          <img
            width="283"
            height="274"
            src={diagram_landing_diagram}
            style={{ alignSelf: "center" }}
            alt="Illustrasjon av Woolit Design Studio"
          />
          <div className={styles.processImageDescriptionContainer}>
            <div className={styles.processIcon}>1</div>

            <div className={styles.processImageDescription}>
              <h2 className={styles.processImageTitle}>
                {t("landing_diagram.create designs in WDS")}
              </h2>
              <p>{t("landing_diagram.create patterns and garments")}</p>
            </div>
          </div>
        </div>

        <div className={styles.processImageCard}>
          <img
            width="346"
            height="228"
            src={diagram_landing_test}
            style={{ alignSelf: "center" }}
            alt="Strikket genser og oppskrift  til salg."
          />
          <div className={styles.processImageDescriptionContainer}>
            <div className={styles.processIcon}>2</div>

            <div className={styles.processImageDescription}>
              <h2 className={styles.processImageTitle}>
                {t("landing_diagram.test diagrams digitally")}
              </h2>
              <p>{t("landing_diagram.test diagrams digitally description")}</p>
            </div>
          </div>
        </div>

        <div className={styles.processImageCard}>
          <img
            width="224"
            height="255"
            src={diagram_landing_app}
            style={{ alignSelf: "center" }}
            alt="Jordkloden"
          />
          <div className={styles.processImageDescriptionContainer}>
            <div className={styles.processIcon}>3</div>

            <div className={styles.processImageDescription}>
              <h2 className={styles.processImageTitle}>
                {t("landing_diagram.get diagrams in app")}
              </h2>
              <p>{t("landing_diagram.all diagrams in one place")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProcessSection;
