import React, { useEffect } from "react";
import NavigationSection from "../LandingPage/Sections/NavigationSection";
import StatusSection from "../LandingPage/Sections/StatusSection";
import HeaderSection from "./HeaderSection";
import ProcessSection from "./ProcessSection";
import mixpanel from "mixpanel-browser";

function App() {
  useEffect(() => {
    mixpanel.track("diagramLandingPage:view");
  }, []);

  return (
    <div style={{}}>
      <StatusSection />

      <NavigationSection />
      <HeaderSection />
      <ProcessSection />
    </div>
  );
}

export default App;
