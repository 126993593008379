import { useState } from "react";
import "./SweaterDesigner.css";

import KnittingPreview from "./knittingpreview/KnittingPreview";
import KnittingEditor from "./knittingeditor/KnittingEditor";
import { SweaterPart } from "./SweaterPart";
import { Pattern } from "./Pattern";
import SplitViewMiniature from "./SplitViewMiniature";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";

function SweaterDesigner() {
  const [selectedSweaterPart, setSelectedSweaterPart] = useState<
    SweaterPart | undefined
  >(undefined);
  const [selectedEditPattern, setSelectedEditPattern] = useState<
    Pattern | undefined
  >(undefined);

  const [sweaterHasLoaded, setSweaterHasLoaded] = useState<Boolean>(false);
  const [saving, setSaving] = useState<Boolean>(false);
  const { form } = useSelector((state: RootState) => state.pattern.meta);

  const [switched, hasSwitched] = useState(false);

  const { t } = useTranslation();

  const knittingPreview = () => (
    <div
      style={{
        position: "relative",
        height: "100%",
      }}
    >
      {!sweaterHasLoaded && (
        <div
          style={{
            position: "absolute",
            height: "100%",
            width: "50%",
            display: "flex",
            zIndex: 1,
          }}
        >
          <div
            style={{
              margin: "auto",
              fontSize: "30px",
              color: "black",
              fontWeight: 100,
            }}
          >
            {t("model.loading 3D model")}...
          </div>
        </div>
      )}
      <KnittingPreview saving={saving} setSaving={setSaving} />
    </div>
  );

  const knittingEditor = () => (
    <div
      style={{
        position: "relative",
        height: "100%",
      }}
    >
      {!selectedSweaterPart && !switched && (
        <div
          style={{
            position: "absolute",
            height: "100%",
            marginLeft: "260px",
            width: "calc(100% - 260px)",
            display: "flex",
            backgroundColor: "black",
            opacity: 0.6,
            borderRadius: "3px",
            zIndex: 1,
          }}
        >
          <div
            style={{
              margin: "auto",
              fontSize: "28px",
              color: "white",
              fontWeight: 100,
            }}
          >
            {t(`model.click on any part of the ${form} to start`)}
          </div>
        </div>
      )}
      <KnittingEditor
        selectedSweaterPart={selectedSweaterPart}
        selectedEditPattern={selectedEditPattern}
        setSelectedEditPattern={setSelectedEditPattern}
        setSelectedSweaterPart={setSelectedSweaterPart}
        sweaterHasLoaded={sweaterHasLoaded}
        setSweaterHasLoaded={setSweaterHasLoaded}
        isMiniature={switched}
      />
    </div>
  );

  return (
    <>
      <SplitViewMiniature
        switched={switched}
        hasSwitched={hasSwitched}
        dontShowAtSwitch={true}
      >
        {knittingEditor()}
        {knittingPreview()}
      </SplitViewMiniature>
    </>
  );
}

export default SweaterDesigner;
