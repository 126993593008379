function generateLinks(diagram) {
  if (!diagram) {
    return { links: [], nodes: [] };
  }
  const links = [];

  const nX = diagram.gridWidth; //witdh of grid
  const nY = diagram.gridHeight; //height of grid

  const gridArr = diagram.grid; ////////! FOR DUPE TESTING
  // const gridArr = diagram.grid; ////////! FOR DUPE TESTING
  const actualGridWidth = diagram.actualGridWidth;

  //create nodes
  let diagNodes = [];
  function createNodes() {
    let counter = 0;
    diagNodes = [];
    //TODO Hent ut koordinater direkte fra gridarr, trenger ikke bygge opp ny
    for (let y = 0; y < nY; ++y) {
      // y < nY works
      for (let x = 0; x < nX; ++x) {
        // x < nX works
        let colorValue = gridArr[y][x].color;
        let symbol = gridArr[y][x].symbol;
        let castVal = 0;

        if (colorValue === "-1") {
          castVal = 1;
        }
        if (colorValue === -1) {
          castVal = 1;
        }
        if (colorValue === null) {
          castVal = 1;
        }
        diagNodes.push({
          index: counter,
          value: castVal,
          oldIndex: counter,
          color: colorValue,
          symbol: symbol,
          sourceX: x, // 0 index
          sourceY: y, // 0 index
          origGridWidth: actualGridWidth,
          //TODO se på mulighetene for å bare bruke girdArr
        });
        counter++;
      }
    }
  }
  createNodes();

  if (diagNodes.length === 0) return;

  function pushLinks(source, target, direction, x, y) {
    links.push({
      source: source,
      target: target,
      direction: direction,
      sourceX: x,
      sourceY: y,
    });
  }
  // Finds first node actually containing data
  function findSafeX(sourceX, nX, sourceY, xDirection) {
    for (
      let x = sourceX + xDirection;
      x < nX && x >= 0 && sourceY <= nY - 1;
      x += xDirection
    ) {
      let source = sourceY * nX + sourceX; //*source er denne
      let safeSourceX;
      let nextXonRow = sourceY * nX + x;
      if (diagNodes[nextXonRow] === 0) {
        safeSourceX = nextXonRow;
      }
      let target = (sourceY - 1) * nX + x; // *target en opp, en til høyre+/-venstre
      if (
        x < nX &&
        diagNodes[target].value === 0 &&
        diagNodes[source].value === 0
      ) {
        pushLinks(source, target, "y", sourceX, sourceY);
        //finds first occurence of safe x in case of empty start nodeX
        return safeSourceX;
      }
    }
  }
  let lastSafeNodeY;
  let lastSafeNodeX;
  // for (let y = 0; y < nY; ++y) {
  for (let y = nY - 1; y >= 0; y--) {
    for (let x = 0; x < nX; ++x) {
      // if (y < nY) { //!
      // if (y > -1) { //TODO Sjekk opp i om det skal være ny-1 eller y>-1
      //? trenger jeg å sjekke at den er større enn -1?
      let source = y * nX + x; //source er denne
      let yTarget = (y - 1) * nX + x; // yTarget er neste node
      let isSourceInsideY = y <= nY - 1 && y > -1;
      if (!isSourceInsideY) {
        continue;
      }

      let isYtargetInside = yTarget > -1 && yTarget < nX * nY - 1;
      if (isYtargetInside) {
        let sVal = diagNodes[source].value;
        let tVal = diagNodes[yTarget].value;

        if (sVal === 0) {
          lastSafeNodeY = source; //finner trygg y-node, lagrer til neste trygge yTarget
        }
        // if (y < nY - 1 && sVal === 0 && tVal !== 0) {
        // safe source, unsafe yTarget
        if (sVal === 0 && tVal === 1) {
          findSafeX(x, nX, y, 1);
        }
        // if (y < nY - 1 && tVal === 0 && diagNodes[lastSafeNodeY].value === 0) {
        //next safe yTarget in same line
        if (tVal === 0 && diagNodes[lastSafeNodeY]?.value === 0) {
          pushLinks(lastSafeNodeY, yTarget, "y", x, y);
          lastSafeNodeY = yTarget;
        }
      }
      //////////* x x x x x x //////////
      let xTarget = y * nX + (x + 1); //xTarget er neste x node

      let isInsideX = x < nX - 1 && x >= 0; //removed  -1 (x<nX-1) //*is --target-- inside
      if (!isInsideX) {
        continue;
      }

      let sValX = diagNodes[source].value;
      let tValX = diagNodes[xTarget].value;

      let safeNodeXVal;
      if (sValX === 0) {
        lastSafeNodeX = source; //finner trygg x node, lagrer til neste trygge xTarget
        safeNodeXVal = diagNodes[lastSafeNodeX].value;
      }
      if (sValX === 0 && tValX === 1) {
        for (let newX = x + 1; newX <= nX - 1 && newX >= 0; newX++) {
          //! SOURCE X
          let targetNode = y * nX + newX;
          if (diagNodes[targetNode].value === 0) {
            pushLinks(source, targetNode, "x", x, y);
            break;
          }
        }
      }
      if (tValX === 0 && safeNodeXVal !== undefined) {
        //push next safe xTarget in same x row
        pushLinks(lastSafeNodeX, xTarget, "x", x, y);
        //remember safe x node
        lastSafeNodeX = xTarget;
      }
      // } //!
    }
  }

  var outputData = {
    nodes: [],
    links: [],
  };
  outputData.nodes = diagNodes;
  outputData.links = links;

  return outputData;
}

export default generateLinks;
