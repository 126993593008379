import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../store/store";
import { setDisableWriterHotkeys, toggleDrawer } from "../store/writerSlice";
import { setDisable } from "../store/dragSelectSlice";
import { setDisableHotkeys } from "../store/gridSlice";

import AwareVariantList from "./Components/VariantList";
import AwareMedia from "./Components/MediaList";
import AwareNeedles from "./Components/Needles";
import AwareSizeSelector from "./Components/SizeSelector";
import Gauge from "./Components/Gauge";
import AwareYarnAmounts from "./Components/YarnAmounts";
import AwareRoles from "./Components/Roles";
import SubdrawerContent from "./Components/Subdrawer";
import { ClothingData } from "./Components/ClothingData";

import DrawerTitle from "./DrawerTitle";
import DrawerDescription from "./DrawerDescription";

import { ArrowLeftIcon } from "@radix-ui/react-icons";

import styles from "./MetadataDrawer.module.css";
import { useTranslation } from "react-i18next";
import { KnitAlong } from "./Components/KnitAlong";
import TagsInput from "./Components/TagsInput";
import { KnitMethodCard } from "./Components/KnitMethodCard";
import { KnitData } from "./Components/KnitData";
import { FormCard } from "./Components/FormCard";
import { Design } from "./Components/Design";
import { hasAccess } from "../utils/hasAccess";
import { useMeQuery } from "../store/woolitApi";

function MetadataDrawer() {
  const { t } = useTranslation();
  const { data: user } = useMeQuery();

  const { drawerOpen, subdrawerOpen } = useSelector(
    (state: RootState) => state.writer
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (drawerOpen) {
      dispatch(setDisableHotkeys(true));
      dispatch(setDisableWriterHotkeys(true));
      dispatch(setDisable(true));
    }
  }, [drawerOpen]);

  const handleClose = () => {
    dispatch(setDisableHotkeys(false));
    dispatch(toggleDrawer());
    dispatch(setDisable(false));
    dispatch(setDisableWriterHotkeys(false));
  };

  const { form } = useSelector((state: RootState) => state.pattern.meta);

  return (
    <div
      className={`${styles.drawerContainer} ${drawerOpen ? "" : styles.hidden}`}
    >
      <div className={styles.drawerBackground} onClick={() => handleClose()} />
      <div id="intro-information2" className={styles.drawer}>
        <div className={styles.drawerTitleContainer}>
          <DrawerTitle />
          <button onClick={() => handleClose()}>
            <ArrowLeftIcon aria-label={t("navigation.close")} />
          </button>
        </div>
        <div className={styles.drawerTitleContainer}>
          <DrawerDescription />
        </div>
        <div className={styles.drawerSection}>
          <h2 className={styles.subtitle}>{t("datadrawer.form")}</h2>
          <div
            style={{
              display: "flex",
              marginTop: "10px",
              gap: "10px",
              marginBottom: "-20px",
            }}
          >
            <FormCard
              garmentForm={"freeform"}
              text={t("datadrawer.freeform")}
              src="/Freeform.png"
            />
            <FormCard
              garmentForm={"sweater"}
              text={t("datadrawer.sweater")}
              src="/sweater.png"
            />
            <FormCard
              garmentForm={"sock"}
              text={t("datadrawer.sock")}
              src="/sock.png"
            />
          </div>
        </div>
        {form === "sweater" && (
          <>
            <div className={styles.drawerSection}>
              <h2 className={styles.subtitle}>{t("datadrawer.knit method")}</h2>
              <div
                style={{
                  marginTop: "10px",
                  display: "flex",
                  marginBottom: "-20px",
                  gap: "10px",
                }}
              >
                <KnitMethodCard
                  text={t("datadrawer.raglan")}
                  knitMethod="raglan"
                  src="/3D/ui/Genser-ikon-1.png"
                />
                <KnitMethodCard
                  text={t("datadrawer.stitched sleeves")}
                  knitMethod="stitched-sleeves"
                  src="/3D/ui/Genser-ikon-2.png"
                />
                <KnitMethodCard
                  text={t("datadrawer.circular yoke")}
                  knitMethod="circular-yoke"
                  src="/3D/ui/Genser-ikon-3.png"
                />
              </div>
            </div>
            <div className={styles.drawerSection}>
              <h2 className={styles.subtitle}>
                {t("datadrawer.measurements")}
              </h2>
              <KnitData />
            </div>
            <div className={styles.drawerSection}>
              <h2 className={styles.subtitle}>{t("datadrawer.design")}</h2>
              <Design />
            </div>
          </>
        )}

        <div className={styles.drawerSection}>
          <h2 className={styles.subtitle}>{t("terms.gauge")}</h2>
          <Gauge />
        </div>

        <div className={styles.drawerSection}>
          <h2 className={styles.subtitle}>
            {t("datadrawer.roles")}
            <p className={styles.smallSubtitle}>
              {t("datadrawer.roles description")}
            </p>
          </h2>
          <AwareRoles />
        </div>

        <div className={styles.drawerSection}>
          <h2 className={styles.subtitle}>
            {t("datadrawer.clothing info")}
            <p className={styles.smallSubtitle}>
              {t("datadrawer.cloting info description")}
            </p>
          </h2>
          <ClothingData />
        </div>

        <div className={styles.drawerSection}>
          <h2 className={styles.subtitle}>{t("categories")}</h2>
          <TagsInput />
        </div>

        <div className={styles.drawerSection}>
          <h2 className={styles.subtitle}>{t("size", { count: 2 })}</h2>
          <AwareSizeSelector />
        </div>

        <div id="intro-information3" className={styles.drawerSection}>
          <h2 className={styles.subtitle}>
            {t("color variant", { count: 2 })}
          </h2>
          <AwareVariantList />
        </div>

        <div className={styles.drawerSection}>
          <h2 className={styles.subtitle}>{t("terms.yarn consumption")}</h2>
          <AwareYarnAmounts />
        </div>

        <div className={styles.drawerSection}>
          <h2 className={styles.subtitle}>{t("terms.needles")}</h2>
          <AwareNeedles />
        </div>

        <div className={styles.drawerSection}>
          <h2 className={styles.subtitle}>{t("image_and_video")}</h2>
          <AwareMedia />
        </div>

        {hasAccess(user, "model_knit") && (
          <div className={styles.drawerSection}>
            <h2 className={styles.subtitle}>
              Samstrikk{" "}
              <p className={styles.smallSubtitle}>
                {t("datadrawer.knit along description")}
              </p>
            </h2>

            <KnitAlong />
          </div>
        )}

        <div style={{ marginBottom: "10vw" }} />
      </div>
      <div
        className={`${styles.subdrawer} ${subdrawerOpen ? "" : styles.hidden}`}
      >
        <SubdrawerContent />
      </div>
    </div>
  );
}

export { MetadataDrawer };
