import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useGetOrganizationByIdQuery,
  useUpdateOrganizationMutation,
} from "../services/organization";
import styles from "./Table.module.css";
import { useModal } from "../CommonComponents/useModal";
import { Modal } from "../CommonComponents/Modal";
import Button from "../ISO/Components/Button";
import { TextareaAutosize } from "@mui/material";

type Props = {
  orgId: string;
};

const Description: React.FC<Props> = ({ orgId }) => {
  const editDescriptionModal = useModal();

  const [updateOrganization] = useUpdateOrganizationMutation();

  const { t } = useTranslation();

  const orgQuery = useGetOrganizationByIdQuery(orgId, {
    skip: orgId === "-1",
  });

  const [description, updatedDescription] = useState(
    orgQuery.data?.description || ""
  );

  useEffect(() => {
    if (orgQuery.data?.description) {
      updatedDescription(orgQuery.data.description);
    }
  }, [orgQuery.data?.description]);

  return (
    <div>
      <div className={styles.titleContainer}>
        <h1>{t("description")}</h1>
        <div>
          <button
            style={{ marginRight: "16px" }}
            onClick={() => editDescriptionModal.setIsShown(true)}
          >
            {t("edit description")}
          </button>
          <Modal
            hide={() => editDescriptionModal.setIsShown(false)}
            isShown={editDescriptionModal.isShown}
            modalContent={
              <div style={{ padding: "24px 36px" }}>
                <h1>{t("edit description")}</h1>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    gap: "10px",
                  }}
                >
                  <TextareaAutosize
                    autoFocus
                    value={description || undefined}
                    minRows={3}
                    onChange={(e) => updatedDescription(e.target.value)}
                  />
                  <Button
                    active
                    onClick={() => {
                      updateOrganization({
                        id: orgId,
                        description,
                      });
                      editDescriptionModal.setIsShown(false);
                    }}
                  >
                    {t("navigation.save")}
                  </Button>
                </div>
              </div>
            }
            headerText=""
          />
        </div>
      </div>
      <div style={{ margin: "0 20vw", marginBottom: "100px" }}>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default Description;
