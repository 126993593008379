import * as THREE from "three";

// @ts-ignore
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
// @ts-ignore
import { OrbitControls } from "three/addons/controls/OrbitControls.js";

//import Detector from "three/examples/js/Detector.js";

import { SweaterPart } from "../../SweaterPart";
// @ts-ignore
import { Part } from "../../Part";
import {
  _loadGrid,
  setHasLoadedImages,
  setShirtUV,
  shirt_uv,
} from "../../knittingeditor/gridcanvas";
import { Global } from "../../static/global";
import {
  _setColorsScene,
  colorsScene,
  getParts,
  parts,
  setParts,
} from "../core/scene";
import {
  createCanvas,
  darkenCanvas,
  drawCanvas,
  lightenCanvas,
  loadImages,
  renderAfterLoad,
} from "../texturecanvas";
import { rescaleGLTFModel, rescaleUVBorders, rescaleUVImage } from "./rescaler";

let pointer: THREE.Vector2;
let hoveredSweaterPart: Part | undefined;
let material: THREE.MeshPhongMaterial;
let texture_canvas: HTMLCanvasElement;
let texture_canvas_backup: HTMLCanvasElement;
//export let texture_canvas_rot: HTMLCanvasElement;

let camera: THREE.PerspectiveCamera;
let renderer: THREE.WebGLRenderer;
let last_resize: Date;
let repeatY: boolean;
let waitForLoad: HTMLImageElement[];
let raycaster = new THREE.Raycaster();
let setSelectedSweaterPart: any;

let moveCounter: number;
let updateCanvasNextFrame: boolean;
let updatedSweaterParts: SweaterPart[] = [];
let sweaterMesh: THREE.Mesh;

let windowTarget: any;
let canvasTarget: any;
let _canvas: any;

let runAfterLoadCanvasQueue: any[] = [];
let runAfterLoadCanvasQueuePriority: any[] = [];
let runAfterLoadSweaterQueue: any[] = [];
let runAfterLoadCanvasDone: boolean = false;
let runAfterLoadSweaterDone: boolean = false;
let orbitControls: OrbitControls = undefined;

const cameraStartPos = [0, 1.75, 6] as const;

let scene: THREE.Scene;

export function clearPriority() {
  runAfterLoadCanvasQueuePriority = [];
}

export function runAfterLoadCanvasPush(functionToRun: any, priority: boolean) {
  if (runAfterLoadCanvasDone) {
    functionToRun();
  } else {
    if (priority) {
      runAfterLoadCanvasQueuePriority.push(functionToRun);
    } else {
      runAfterLoadCanvasQueue.push(functionToRun);
    }
  }
}

export function _runAfterLoadSweaterSetSweater(functionToRun: any) {
  if (runAfterLoadSweaterDone) {
    functionToRun();
  } else {
    runAfterLoadSweaterQueue = [functionToRun];
  }
}

function clearHover() {
  if (hoveredSweaterPart) {
    //This fixes: Hover + Undo = bug
    darkenCanvas(texture_canvas, texture_canvas_backup);
    hoveredSweaterPart = undefined;
  }
}

export function _setColorsSceneSweater(_colors: string[]) {
  clearHover();

  if (!texture_canvas) {
    texture_canvas = createCanvas();
    texture_canvas_backup = createCanvas(); // Backup for reverting: texture canvas lighten up on hover
    //texture_canvas_rot = createCanvas();
  }

  let colorsHex: string[] = [];
  let ctx = texture_canvas.getContext("2d")!!;
  for (let color of _colors) {
    ctx.fillStyle = color;
    colorsHex.push(ctx.fillStyle);
  }
  _setColorsScene(colorsHex);
  clearPriority();
  runAfterLoadCanvasPush(() => {
    renderAfterLoad(texture_canvas, colorsScene);
    for (let sweaterPart of getParts()) {
      sweaterPart.setDirty();
    }
    updateCanvas();
  }, true);
}

export function _makeSceneSweater(
  canvas: HTMLElement,
  sweaterParts_arg: Part[],
  colors_arg: string[],
  setSelectedSweaterPart_arg: any,
  knittingMethod: string,
  knitData: { [key: string]: number }
) {
  sweaterParts_arg.forEach((sweaterPart) => {
    sweaterPart.knitMethod = knittingMethod;
  });

  runAfterLoadCanvasDone = false;
  runAfterLoadSweaterDone = false;
  rescaleUVBorders(sweaterParts_arg, knitData);
  setSelectedSweaterPart = setSelectedSweaterPart_arg;
  setParts(sweaterParts_arg);
  _setColorsSceneSweater(colors_arg);

  material = new THREE.MeshPhongMaterial({
    side: THREE.DoubleSide,
  });
  let _scene = new THREE.Scene();
  camera = new THREE.PerspectiveCamera(50, 1000 / 1000, 1, 2000);
  renderer = new THREE.WebGLRenderer({
    antialias: true,
  });

  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.outputColorSpace = THREE.LinearSRGBColorSpace;
  renderer.shadowMap.enabled = true;
  resize();

  if (canvas.children.length > 0) {
    canvas.removeChild(canvas.firstChild!);
  }
  canvas.appendChild(renderer.domElement);

  last_resize = new Date();
  repeatY = false;

  camera.position.set(...cameraStartPos);
  orbitControls = new OrbitControls(camera, renderer.domElement);
  orbitControls.enablePan = false;
  orbitControls.minDistance = 1;
  orbitControls.maxDistance = 10;
  orbitControls.target.set(0, 0.25, 0);
  orbitControls.update();

  _scene.background = new THREE.Color(0xf8f5f2);
  const _oldKnitData = { ...knitData };

  let loader = new GLTFLoader();
  loader.load(`/3D/shirt/sweater.gltf`, (gltf: any) => {
    if (_scene !== scene) return;
    for (let key of Object.keys(knitData)) {
      if (_oldKnitData[key] !== knitData[key]) return;
    }
    //Rescale model

    //let geometry = gltf.scene.children[0].geometry // ISO
    let geometry = gltf.scene.children[0].geometry;
    if (knittingMethod === "stitched-sleeves") {
      geometry.setAttribute("uv", geometry.getAttribute("uv1"));
      //uv1 at >v152, and https://discourse.threejs.org/t/updates-to-color-management-in-three-js-r152/50791
    }
    rescaleGLTFModel(geometry, knitData, (_geometry: any) => {
      sweaterMesh = new THREE.Mesh(_geometry, material);
      sweaterMesh.position.y = -1.35;
      sweaterMesh.scale.set(5, 5, 5);
      sweaterMesh.name = "sweater";
      sweaterMesh.castShadow = true;
      sweaterMesh.traverse((node: any) => {
        if (node.isMesh) {
          node.castShadow = true;
        }
      });
      scene.add(sweaterMesh);

      const diffX = 3;
      const diffY = 10;
      const positions = [
        [-diffX, 1.75 + diffY, 6],
        [diffX, 1.75 + diffY, 6],
      ];
      for (let pos of positions) {
        const light2 = new THREE.DirectionalLight(0xffffff); // soft white light
        light2.intensity = 0.5; //1.25
        const _pos = [pos[0], pos[1], pos[2]] as const;
        light2.position.set(..._pos);
        light2.target = sweaterMesh;
        light2.castShadow = true;
        _scene.add(light2);
      }

      const groundGeometry = new THREE.PlaneGeometry(7, 7);
      groundGeometry.rotateX(-Math.PI / 2);
      const groundMaterial = new THREE.MeshPhongMaterial({
        color: new THREE.Color(0xddd9d6),
      });
      const groundMesh = new THREE.Mesh(groundGeometry, groundMaterial);
      groundMesh.position.y = new THREE.Box3().setFromObject(sweaterMesh).min.y;
      groundMesh.receiveShadow = true;
      _scene.add(groundMesh);

      runAfterLoadSweater();
    });
  });

  const light = new THREE.AmbientLight(0xffffff); // soft white light
  light.intensity = 3; //1.25
  _scene.add(light);

  scene = _scene;

  resetCanvas();
  updateCanvasNextFrame = false;

  window.addEventListener("pointermove", onPointerMoveCheck);
  canvas.addEventListener("pointermove", onPointerMove);
  canvas.addEventListener("mousedown", () => {
    moveCounter = 0;
  });
  canvas.addEventListener("mouseup", onClick);

  requestAnimationFrame(() => {
    resize();
    animate();
  });

  waitForLoad = loadImages();

  waitForLoad.forEach((image: HTMLImageElement) => {
    image.onload = () => {
      waitForLoad.pop();
      if (waitForLoad.length === 0) {
        runAfterLoadCanvas();
      }
    };
  });
  _canvas = canvas;
}

export function _removeEventsSweater() {
  window.removeEventListener("pointermove", onPointerMoveCheck);
  _canvas?.removeEventListener("pointermove", onPointerMove);
  _canvas?.removeEventListener("mousedown", () => {
    moveCounter = 0;
  });
  _canvas?.removeEventListener("mouseup", onClick);
}

function resize() {
  if (!renderer.domElement.parentNode) {
    return;
  }

  let displayWidth = (renderer.domElement.parentNode as HTMLElement)
    .clientWidth;
  let displayHeight = (renderer.domElement.parentNode as HTMLElement)
    .clientHeight;

  // Check if the texture_canvas is not the same size.
  if (
    renderer.domElement.width !== displayWidth ||
    renderer.domElement.height !== displayHeight
  ) {
    camera.aspect = displayWidth / displayHeight;
    renderer.setSize(displayWidth, displayHeight);
    camera.updateProjectionMatrix();
  }

  last_resize = new Date();
}

function onPointerMoveCheck(event: any) {
  windowTarget = event;
  if (windowTarget !== canvasTarget) {
    pointer = new THREE.Vector2(-1, -1);
  } else {
    // calculate pointer position in normalized device coordinates
    // (-1 to +1) for both components
    const rect = event.target.getBoundingClientRect();
    let x = (event.clientX - rect.left) / rect.width; //x position within the element.
    let y = (event.clientY - rect.top) / rect.height; //y position within the element.
    x = x * 2 - 1; // 0..1 -> -1..1
    y = y * 2 - 1; // 0..1 -> -1..1
    y *= -1;
    pointer = new THREE.Vector2(x, y);
    moveCounter += 1;
  }
}

function onPointerMove(event: any) {
  canvasTarget = event;
}

function onClick(_: any) {
  if (moveCounter <= 1 && pointer.x > -1) {
    //NB
    setSelectedSweaterPart(hoveredSweaterPart);
  }
}

function render() {
  if (!pointer) {
    return;
  }
  if (!updateCanvasNextFrame) {
    // update the picking ray with the camera and pointer position
    raycaster.setFromCamera(pointer, camera);
    //updateDirLightPosition();

    // calculate objects intersecting the picking ray
    const intersects = raycaster.intersectObjects(scene.children, false);
    let oldHoveredSweaterPart = hoveredSweaterPart;
    hoveredSweaterPart = undefined;
    if (
      pointer.x !== -1 &&
      intersects.length > 0 &&
      ["sweater", "sock"].includes(intersects[0].object.name)
    ) {
      let uv = intersects[0].uv!!;
      for (let n = 0; n < parts.length; n++) {
        let target = parts[n];
        let insideX = uv.x < target.corner2X && uv.x > target.corner1X;
        let insideY = uv.y < target.corner2Y && uv.y > target.corner1Y;
        if (insideX && insideY) {
          hoveredSweaterPart = parts[n];
        }
      }
    }
    if (oldHoveredSweaterPart !== hoveredSweaterPart) {
      //TODO: dont update canvas here, but just lighten the uv
      if (oldHoveredSweaterPart) {
        darkenCanvas(texture_canvas, texture_canvas_backup);
      }
      if (hoveredSweaterPart) {
        lightenCanvas(
          texture_canvas,
          hoveredSweaterPart,
          texture_canvas_backup
        );
      }
      material.map!!.needsUpdate = true;
    }
  }
  if (updateCanvasNextFrame) {
    updateCanvas();
    updateCanvasNextFrame = false;
  }
}

//Default => All sweater parts are updated
export function _setUpdateCanvasNextFrameSweater(
  updatedSweaterParts_arg: SweaterPart[] = []
) {
  updateCanvasNextFrame = true;
  updatedSweaterParts = updatedSweaterParts_arg;
  clearHover();
}

function runAfterLoadCanvas() {
  runAfterLoadCanvasDone = true;
  for (let n = 0; n < runAfterLoadCanvasQueuePriority.length; n++) {
    runAfterLoadCanvasQueuePriority[n]();
  }
  runAfterLoadCanvasQueuePriority = [];
  for (let n = 0; n < runAfterLoadCanvasQueue.length; n++) {
    runAfterLoadCanvasQueue[n]();
  }
  runAfterLoadCanvasQueue = [];
}

function runAfterLoadSweater() {
  runAfterLoadSweaterDone = true;
  for (let n = 0; n < runAfterLoadSweaterQueue.length; n++) {
    runAfterLoadSweaterQueue[n]();
  }
  runAfterLoadSweaterQueue = [];
}

function animate() {
  setTimeout(() => {
    requestAnimationFrame(() => animate());
  }, 1000 / 30);
  if (new Date().getTime() - last_resize.getTime() > 1000) {
    resize();
  }
  renderer.render(scene, camera);
  render();
}

export function resetCanvas() {
  texture_canvas.width = Global.canvasWidth;
  texture_canvas.height = Global.canvasHeight;

  texture_canvas_backup.width = texture_canvas.width;
  texture_canvas_backup.height = texture_canvas.height;

  material.map = new THREE.Texture(texture_canvas);
  material.map.flipY = false;
  //material.map.colorSpace = THREE.SRGBColorSpace;
  //material.map.wrapS = THREE.RepeatWrapping;

  //material.transparent = true; //Isnt really transparent once all parts are done

  updateCanvasNextFrame = true;
}

function updateCanvas() {
  if (texture_canvas) {
    requestAnimationFrame(() => {
      let sweaterParts_arg =
        updatedSweaterParts.length > 0 ? updatedSweaterParts : parts;
      drawCanvas(
        texture_canvas,
        sweaterParts_arg,
        colorsScene,
        repeatY,
        hoveredSweaterPart
      );
      material.map!!.needsUpdate = true;
      updatedSweaterParts = [];
    });
  }
}

export function getScreenshot() {
  camera.position.set(...cameraStartPos);
  orbitControls.update();
  const originalWidth = renderer.domElement.width;
  const originalHeight = renderer.domElement.height;
  camera.aspect = 1920 / 2 / 1024;
  renderer.setSize(1920 / 2, 1024);
  camera.updateProjectionMatrix();
  renderer.render(scene, camera);
  let res = renderer.domElement.toDataURL("image/png");
  camera.aspect = originalWidth / originalHeight;
  renderer.setSize(originalWidth, originalHeight);
  camera.updateProjectionMatrix();
  renderer.render(scene, camera);
  return res;
}

export function _loadImagesCanvasSweater(knittingMethod: string) {
  let waitForLoad = [];
  setShirtUV(new Image(4096, 4096));
  if (knittingMethod === "circular-yoke") {
    knittingMethod = "raglan"; // Use the same image for circular-yoke
  }
  shirt_uv.src = `/3D/shirt/diffuse_sweater_${knittingMethod}.png`;
  //Rescale img
  waitForLoad.push(shirt_uv);

  return waitForLoad;
}

export function _onLoadImagesSweater(
  sweaterPart: Part,
  knitData: { [key: string]: number },
  setGrid: any
) {
  let canvas: HTMLCanvasElement = document.createElement("canvas");
  canvas.width = 4096;
  canvas.height = 4096;
  let ctx = canvas.getContext("2d")!!;

  ctx.drawImage(shirt_uv, 0, 0);

  rescaleUVImage(ctx, knitData, (_ctx: any) => {
    Global.imageData = _ctx.getImageData(0, 0, 4096, 4096);

    _loadGrid(sweaterPart, setGrid);

    setHasLoadedImages(true);
  });
}
