import styles from "./Roles.module.css";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import NumberInput from "../../Visualizing3D/components/NumberInput";
import { setMeasurements } from "../../store/patternSlice";
import {
  knitSizeDefaultResize,
  knitSizeDefaults,
} from "../../Visualizing3D/knittingpreview/sweater/rescaler";

export const KnitData = () => {
  const { t } = useTranslation();

  const { measurements } = useSelector(
    (state: RootState) => state.pattern.meta
  );

  const dispatch = useDispatch();
  const measurementsLimitsMin = {
    bustCircumference: 50,
    fullLength: 50,
    sleeveLength: 25,
    bodyLength: 10,
  } as const;
  const measurementsLimitsMax = {
    bustCircumference: 150,
    fullLength: 80,
    sleeveLength: 65,
    bodyLength: 60,
  } as const;
  const [measurementsPlaceholder, setMeasurementsPlaceholder] = useState<any>(
    {}
  );

  const { sizes } = useSelector((state: RootState) => state.pattern.meta);

  useEffect(() => {
    const _measurements: { [key: string]: {} } = {};
    for (let n = 0; n < sizes.length; n++) {
      const size = sizes[n];
      const diffFromMiddle = n - (sizes.length - 1) / 2;
      _measurements[size] =
        measurements[size] ?? knitSizeDefaultResize(size, diffFromMiddle);
    }
    setMeasurementsPlaceholder(_measurements);
    if (JSON.stringify(_measurements) !== JSON.stringify(measurements)) {
      dispatch(setMeasurements(_measurements));
    }
  }, [measurements, sizes]);

  return (
    <div className={styles.roles}>
      <div
        className={styles.role}
        style={{
          minWidth: "500px",
          marginBottom: "-15px",
        }}
      >
        <div
          style={{
            flexBasis: "50%",
          }}
        ></div>
        <div
          style={{
            flexBasis: "50%",
          }}
        >
          <div
            className={styles.role}
            style={{
              justifyContent: "space-around",
              gap: "4px",
            }}
          >
            {sizes.map((size) => (
              <div
                style={{
                  width: "0px",
                }}
                key={size}
              >
                <p
                  className={styles.label}
                  style={{
                    position: "absolute",
                    transform: "translate(-50%, 0%)",
                  }}
                >
                  {size}
                </p>
              </div>
            ))}
          </div>
        </div>
        <button
          className={styles.removeItem}
          style={{
            display: "flex",
            border: "none",
            pointerEvents: "none",
            opacity: 0,
          }}
          disabled
        >
          <p
            style={{
              margin: "auto",
            }}
          >
            cm
          </p>
        </button>
      </div>
      {Object.keys(knitSizeDefaults).map((_key: string) => {
        const key = _key as
          | "bustCircumference"
          | "fullLength"
          | "sleeveLength"
          | "bodyLength";
        return (
          <div
            className={styles.role}
            style={{
              minWidth: "500px",
            }}
            key={key}
          >
            <input
              style={{
                flexBasis: "50%",
              }}
              className={styles.roleTitle}
              disabled
              value={t(`datadrawer.${key}`)}
            />
            <div
              style={{
                flexBasis: "50%",
                padding: "0 8px", //Since the other component has 8px padding, this needs one aswell
              }}
            >
              <div
                className={styles.role}
                style={{
                  margin: "0 -8px",
                  gap: "4px",
                }}
              >
                {sizes.map((size: string) => {
                  if (!(size in measurementsPlaceholder)) return <></>;
                  return (
                    <NumberInput
                      input={measurementsPlaceholder[size][key]}
                      setInput={(newValue: any) => {
                        const copy = {
                          ...measurementsPlaceholder,
                        };
                        copy[size] = {
                          ...copy[size],
                        };
                        copy[size][key] = newValue;
                        setMeasurementsPlaceholder(copy);
                      }}
                      onBlur={() => {
                        const value =
                          key in (measurements[size] ?? {})
                            ? measurements[size][key]
                            : -1;
                        const newValue = measurementsPlaceholder[size][key];
                        if (value !== newValue) {
                          dispatch(setMeasurements(measurementsPlaceholder));
                        }
                      }}
                      min={measurementsLimitsMin[key]}
                      max={measurementsLimitsMax[key]}
                    />
                  );
                })}
              </div>
            </div>
            <button
              className={styles.removeItem}
              style={{
                display: "flex",
                border: "none",
                pointerEvents: "none",
                opacity: 1,
              }}
              disabled
            >
              <p
                style={{
                  margin: "auto",
                }}
              >
                cm
              </p>
            </button>
          </div>
        );
      })}
    </div>
  );
};
