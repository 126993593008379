import React, { ChangeEvent, useState } from "react";
import { Transition } from "@headlessui/react";
import { EyeClosedIcon, EyeOpenIcon } from "@radix-ui/react-icons";
import styles from "../../../Login.module.css";

type Props = {
  value?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  type?: React.InputHTMLAttributes<HTMLInputElement>["type"];
  name?: string;
  error?: string;
  required?: boolean;
  className?: string;
  autoComplete?: React.InputHTMLAttributes<HTMLInputElement>["autoComplete"];
  autoFocus?: React.InputHTMLAttributes<HTMLInputElement>["autoFocus"];
  placeholder?: string;
};

const Input: React.FC<React.PropsWithChildren<Props>> = ({
  value,
  onChange,
  label,
  type,
  name,
  error,
  required,
  className,
  autoComplete,
  autoFocus,
  placeholder,
}) => {
  const [internalValue, setInternalValue] = useState("");
  const internalOnChange = (e: ChangeEvent<HTMLInputElement>) =>
    setInternalValue(e.target.value);

  const text = value ?? internalValue;
  const handleOnChange = onChange ?? internalOnChange;

  const isPasswordField = type === "password";
  const [showPassword, toggleShowPasswrod] = useState<boolean>(false);

  return (
    <div style={{ width: "100%", alignSelf: "center" }}>
      {label && <label className={styles.label}>{label}</label>}
      <div style={{ display: "flex" }}>
        <input
          autoComplete={autoComplete}
          id={name}
          name={name}
          required={required}
          type={isPasswordField ? (showPassword ? "text" : type) : type}
          value={text}
          onChange={handleOnChange}
          autoFocus={autoFocus}
          placeholder={placeholder}
          className={styles.textInput}
        />
        {isPasswordField && (
          <div
            onClick={(e) => {
              e.preventDefault();
              toggleShowPasswrod(!showPassword);
            }}
          >
            {showPassword ? <EyeOpenIcon /> : <EyeClosedIcon />}
          </div>
        )}
      </div>
      <Transition
        show={Boolean(error)}
        enter="transition-all duration-100"
        enterFrom="opacity-0 -translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition-all duration-100"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 -translate-y-1"
      >
        <p className="text-par-s-reg text-negative">{error}</p>
      </Transition>
    </div>
  );
};

export default Input;
