import React from "react";
import styles from "../Styles/Sections.module.css";
import ipad_wds from "../../Visualizing/LandingPage/ipad_WDS.png";
import { Link } from "react-router-dom";
import { ArrowRightIcon } from "@radix-ui/react-icons";
import { useTranslation } from "react-i18next";
import { useMeQuery } from "../../store/woolitApi";

function HeaderSection() {
  const { data: user } = useMeQuery();

  const { t } = useTranslation();

  const description: string = t("landing.woolit ecosystem");
  return (
    <div>
      {/* <div className={styles.wdsBanner}>
        {" "}
        Ønsker du å designe for privat bruk?
        <Link
          style={{ color: "#33251a", margin: "12px", cursor: "pointer" }}
          to="https://3d.woolit.no/"
        >
          <text style={{ alignItems: "center" }}>
            Gå til{" "}
            <text style={{ fontWeight: 500 }}>
              {t("goToWDS3D")}
              <ArrowRightIcon style={{ marginBottom: "-3px" }}></ArrowRightIcon>
            </text>
          </text>
        </Link>
      </div> */}
      <div className={styles.headerSection} id="header-section">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: "20px",
          }}
        >
          {" "}
          <div style={{ alignSelf: "center", position: "relative" }}>
            <img
              width="350"
              height="460"
              src={ipad_wds}
              style={{ alignSelf: "center" }}
              alt="Illustrasjon av Woolit Design Studio Pro"
            />
          </div>
        </div>
        {/* <div style={{ alignSelf: "center", position: "relative" }}>
            <img
              width="350"
              height="350"
              src={example_sweather}
              style={{ alignSelf: "center" }}
              alt="Illustrasjon av Woolit Design Studio Pro"
            />
            <div style={{ position: "absolute", bottom: "8px", right: "16px" }}>
              by Hikaru Nakamura
            </div>
          </div>

          <text
            style={{
              maxWidth: "350px",
              marginTop:"10px"
            }}
          >
            <text
              style={{
                fontWeight: "bold",
                flexWrap: "wrap",
                wordBreak: "break-word",
              }}
            >
              As seen on Norway Chess!
            </text>{" "}
            <text
              style={{
                color: "#f27900",
                flexWrap: "wrap",
                wordBreak: "break-word",
              }}
            >
              Sweater Design Program
            </text>{" "}
            makes it simple for anyone to create their own sweater.
          </text>
        </div> */}
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              background: "#ffffff",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            <div className={styles.headerTitle}>
              {t("landing.design publish sell")}
            </div>
            <div className={styles.headerDescription}>{description}</div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              {user?.subscription && (
                <button className={styles.ctaButton}>
                  <Link style={{ cursor: "pointer" }} to="/projects">
                    {t("goToWDS")}
                    <ArrowRightIcon
                      style={{ marginBottom: "-3px" }}
                    ></ArrowRightIcon>
                  </Link>
                </button>
              )}
              {!user?.subscription && (
                <button className={styles.ctaButton}>
                  <Link style={{ cursor: "pointer" }} to="#onboarding">
                    {t("tryWDS")}
                    <ArrowRightIcon
                      style={{ marginBottom: "-3px" }}
                    ></ArrowRightIcon>
                  </Link>
                </button>
              )}
            </div>
          </div>

          {/*   <EmailInputForm /> */}
        </div>
      </div>
    </div>
  );
}

export default HeaderSection;
