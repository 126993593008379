import { APIYarn } from "../../services/yarnApi";
import YarnSelectorColor from "./YarnSelectorColor";
import styles from "./YarnSelectorYarn.module.css";

interface PropType {
  yarn: APIYarn;
}

function YarnSelectorYarn(props: PropType) {
  const { yarn } = props;

  return (
    <div className={styles.container}>
      <div className={styles.yarnTitle}>
        <h3 className={styles.yarnName}>
          {yarn.name}
          {yarn.gauge.x
            ? ` - ${yarn.gauge.x.stitches} m / ${yarn.gauge.x.cm} cm`
            : ""}
          {yarn.needleSize.min ? ` - pinne ${yarn.needleSize.min}` : ""}
        </h3>
        <hr className={styles.titleHr} />
      </div>
      <div id="intro-information4" className={styles.colors}>
        {yarn.colors.map((color, _colorIndex) => (
          <YarnSelectorColor
            key={color.sku ?? color.hex}
            color={color}
            yarn={yarn}
          />
        ))}
      </div>
    </div>
  );
}

export default YarnSelectorYarn;
