import { createSlice } from "@reduxjs/toolkit";
import { woolitApi } from "../store/woolitApi";

interface LoginState {
  isLoggedIn: boolean;
  loginFailed: boolean;
  loginAttempted: boolean;
  checkedUserLogin: boolean;
  loading: boolean;
}

type WdsModule =
  | "simple_diagram"
  | "full_diagram"
  | "information"
  | "writer"
  | "sweater_designer"
  | "model_knit"
  | "knit_along"
  | "pattern_visualizer"
  | "calculation_tool"
  | "export";

interface User {
  id: number;
  firstName: string;
  middleName: string | null;
  lastName: string;
  email: string;
  isAdmin: boolean;
  subscriber: boolean;
  modules: WdsModule[];
  subscription: Subscription;
  tutorial: "write" | "draw" | null;
}

interface Subscription {
  subscriptionType: SubscriptionType;
  isTrial: boolean;
  modules: WdsModule[];
  trialDaysLeft: number | undefined;
}

type SubscriptionType =
  | "custom"
  | "free"
  | "basic"
  | "designer"
  | "professional";

const isPayedSubscription = (subscription: Subscription) => {
  if (subscription.isTrial) {
    return false;
  }

  if (subscription.subscriptionType === "custom") {
    return false;
  }

  if (subscription.subscriptionType === "free") {
    return false;
  }

  return true;
};

const initialState: LoginState = {
  isLoggedIn: false,
  loginFailed: false,
  loginAttempted: false,
  checkedUserLogin: false,
  loading: false,
};

const user = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addMatcher(woolitApi.endpoints.login.matchPending, (state) => {
      return {
        ...state,
        loginAttempted: true,
        loading: true,
      };
    });

    builder.addMatcher(
      woolitApi.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        return {
          ...state,
          isLoggedIn: true,
          loginFailed: false,
          loading: false,
        };
      }
    );

    builder.addMatcher(woolitApi.endpoints.login.matchRejected, (state) => {
      return {
        ...state,
        isLoggedIn: false,
        loginFailed: true,
        loading: false,
      };
    });

    builder.addMatcher(woolitApi.endpoints.logout.matchFulfilled, () => {
      woolitApi.util.resetApiState();

      return initialState;
    });

    builder.addMatcher(woolitApi.endpoints.logout.matchRejected, () => {
      woolitApi.util.resetApiState();

      return initialState;
    });

    builder.addMatcher(woolitApi.endpoints.register.matchPending, (state) => {
      return {
        ...state,
        loginAttempted: true,
        loading: true,
      };
    });

    builder.addMatcher(
      woolitApi.endpoints.register.matchFulfilled,
      (state, { payload }) => {
        return {
          ...state,
          isLoggedIn: true,
          loginFailed: false,
          loading: false,
        };
      }
    );

    builder.addMatcher(woolitApi.endpoints.register.matchRejected, (state) => {
      return {
        ...state,
        isLoggedIn: false,
        loginFailed: true,
        loading: false,
        loggedInUrl: null,
      };
    });

    builder.addMatcher(woolitApi.endpoints.me.matchPending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });

    builder.addMatcher(woolitApi.endpoints.me.matchFulfilled, (state) => {
      return {
        ...state,
        isLoggedIn: true,
        loginFailed: false,
        checkedUserLogin: true,
        loading: false,
      };
    });

    builder.addMatcher(woolitApi.endpoints.me.matchRejected, (state) => {
      return {
        ...state,
        isLoggedIn: false,
        checkedUserLogin: true,
        loading: false,
      };
    });
  },
});

export default user;

export { isPayedSubscription };

export type { User, WdsModule, SubscriptionType };
