import React from "react";

import styles from "../Styles/Sections.module.css";

function Card(props: {
  imgIcon: string;
  title: string;
  description: string;
}) {
  return (
    <div className={styles.card}>
      <img
        className={styles.DSIcon}
        src={props.imgIcon}
        alt="Beskrivende ikon"
      ></img>
      <div style={{ fontSize: "22px", paddingBottom: "0px" }}>
        {" "}
        {props.title}
      </div>
      <div className={styles.cardDescription}>{props.description}</div>
    </div>
  );
}

export default Card;
