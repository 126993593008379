import { useSelector } from "react-redux";

import { Navigate } from "react-router";
import Auth from "../Auth";
import styles from "../Login.module.css";
import { RootState } from "../../store/store";

function Login() {
  const loginState = useSelector((state: RootState) => state.loginState);

  return loginState.isLoggedIn ? (
    <Navigate to={"/projects"} />
  ) : (
    <div className={styles.modal}>
      <Auth />
    </div>
  );
}

export default Login;
