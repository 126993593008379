import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { setDisable } from "../store/dragSelectSlice";
import { setDisableHotkeys } from "../store/gridSlice";
import { toggleDrawer } from "../store/writerSlice";
import styles from "./EditNavbar.module.css";
import { hasAccess } from "../utils/hasAccess";
import { useMeQuery } from "../store/woolitApi";
import { useModal } from "./useModal";
import { ModalBase } from "./ModalBase";
import { UpgradeModalContent } from "./UpgradeModalContent";
import mixpanel from "mixpanel-browser";

const EditNavbar = () => {
  const { data: user } = useMeQuery();

  const { pathname } = useLocation();
  const [, , patternId, mode] = pathname.split("/");
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { isShown, toggle } = useModal();

  const informationDisabled = !hasAccess(user, "information");
  const writerDisabled = !hasAccess(user, "writer");
  const calculationDisabled = !hasAccess(user, "calculation_tool");
  const calculationDisabledFree =
    calculationDisabled && user?.subscription.subscriptionType === "free";

  return (
    <div
      style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}
    >
      <div>
        {!informationDisabled && (
          <button
            style={{ marginRight: 16 }}
            onClick={() => {
              dispatch(setDisableHotkeys(true));
              dispatch(toggleDrawer());
              dispatch(setDisable(true));
            }}
            id="intro-information"
            tooltip-position="bottom"
            className={`${styles.navButton}`}
          >
            {t("information")}
          </button>
        )}
        {informationDisabled && (
          <>
            <button
              style={{ marginRight: 16 }}
              onClick={() => {
                mixpanel.track("upgradeModal:view", {
                  buttonClicked: "information",
                });
                toggle();
              }}
              tooltip-position="bottom"
              className={`${styles.navButton} ${styles.disabled}`}
            >
              {t("information")}
            </button>
            <ModalBase
              isShown={isShown}
              hide={toggle}
              modalContent={<UpgradeModalContent hide={toggle} />}
            ></ModalBase>
          </>
        )}
      </div>
      <div
        style={{
          display: "flex",
        }}
      >
        {!writerDisabled && (
          <Link to={`/edit/${patternId}/write`}>
            <button
              className={`${mode === "write" ? styles.active : ""} ${
                styles.left
              } ${styles.navButton}`}
            >
              {t("write")}
            </button>
          </Link>
        )}
        {writerDisabled && (
          <>
            <button
              onClick={() => {
                mixpanel.track("upgradeModal:view", {
                  buttonClicked: "write",
                });
                toggle();
              }}
              tooltip-position="bottom"
              className={`${styles.left} ${styles.navButton} ${styles.disabled}`}
            >
              {t("write")}
            </button>
            <ModalBase
              isShown={isShown}
              hide={toggle}
              modalContent={<UpgradeModalContent hide={toggle} />}
            ></ModalBase>
          </>
        )}
        <Link to={`/edit/${patternId}/draw`}>
          <button
            id="intro-draw"
            className={`${mode === "draw" ? styles.active : ""} ${
              styles.middle
            } ${styles.navButton}`}
          >
            {t("diagram")}
          </button>
        </Link>
        {!calculationDisabledFree && (
          <Link to={`/edit/${patternId}/calculate`}>
            <button
              className={`${mode === "calculate" ? styles.active : ""} ${
                styles.right
              } ${styles.navButton} ${
                calculationDisabled ? styles.disabled : ""
              }`}
              disabled={calculationDisabled}
            >
              {t("calculate")}
            </button>
          </Link>
        )}
        {calculationDisabledFree && (
          <>
            <button
              onClick={() => {
                mixpanel.track("upgradeModal:view", {
                  buttonClicked: "calculate",
                });
                toggle();
              }}
              tooltip-position="bottom"
              className={`${styles.right} ${styles.navButton} ${styles.disabled}`}
            >
              {t("calculate")}
            </button>
            <ModalBase
              isShown={isShown}
              hide={toggle}
              modalContent={<UpgradeModalContent hide={toggle} />}
            ></ModalBase>
          </>
        )}
      </div>
    </div>
  );
};

export default EditNavbar;
