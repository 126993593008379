import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../store/store";
import { deleteTable, setTableLabel } from "../store/calculationSlice";

import TableMarkSelector from "./TableMarkSelector";

import styles from "./TableLabel.module.css";
import { useTranslation } from "react-i18next";

type PropType = {
  tableId: string;
};

function TableLabel(props: PropType) {
  const { tableId } = props;

  const { label, marks } = useSelector(
    (state: RootState) => state.calculations.tables[tableId]
  );

  const dispatch = useDispatch();

  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <button
        className={styles.deleteTable}
        onClick={() => {
          if (window.confirm(t("calculations.warning delete")))
            dispatch(deleteTable({ tableId }));
        }}
      >
        {t("navigation.delete")}
      </button>
      <div className={styles.labelContainer}>
        {label.length > 0 && (
          <div className={styles.label}>{t("calculations.table title")}</div>
        )}
        <input
          className={styles.input}
          type="text"
          value={label}
          onChange={(e) =>
            dispatch(setTableLabel({ tableId, label: e.target.value }))
          }
          placeholder={`${t("calculations.table title")}...`}
        />
      </div>

      <div className={styles.markContainer}>
        <div className={styles.markLabel}>
          {t("calculations.amount marks")}:
        </div>
        <TableMarkSelector tableId={tableId} marks={marks} />
      </div>
    </div>
  );
}

export default TableLabel;
