import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBbox } from "../store/dragSelectSlice";
import { useMousePos } from "../utils/useMousePos";
import { Global } from "../Visualizing3D/static/global";
import { RootState } from "../store/store";

interface PropType {
  disabled?: boolean;
}

export const DragSelect = (props: PropType) => {
  const { disabled } = props;
  const [{ x, y }, setStart] = useState({ x: -1, y: -1 });
  const { posX, posY, isMouseDown } = useMousePos();
  const { cursorMode } = useSelector((state: RootState) => state.grid);

  const dispatch = useDispatch();

  const bbox = {
    top: posY < y ? posY : y,
    left: posX < x ? posX : x,
    height: posY > y ? posY - y : y - posY,
    width: posX > x ? posX - x : x - posX,
  };

  useEffect(() => {
    if (isMouseDown && !disabled && x === -1) {
      setStart({ x: posX, y: posY });
    } else if (!isMouseDown && !disabled && x !== -1) {
      setStart({ x: -1, y: -1 });
      dispatch(setBbox(bbox));
    }
  }, [isMouseDown]);

  return isMouseDown &&
    !disabled &&
    !Global.hoveringVisualizing3D &&
    cursorMode === "cursor" &&
    x !== -1 ? (
    <div
      style={{
        pointerEvents: "none",
        position: "absolute",
        userSelect: "none",
        border: "2px solid #faf6f2",
        backgroundColor: "#563e2c",
        opacity: 0.1,
        ...bbox,
        zIndex: 100,
      }}
    />
  ) : null;
};
