import React from "react";
import { useSelector } from "react-redux";
import { ModalButton } from "../../CommonComponents/ModalButton";
import { useModal } from "../../CommonComponents/useModal";
import { RootState } from "../../store/store";
import { ModelKnitModal } from "./ModelKnitModal";
import { hasAccess } from "../../utils/hasAccess";
import { useMeQuery } from "../../store/woolitApi";

export const ModelKnitButton = () => {
  const { isShown, toggle } = useModal();
  const { organizationId, id } = useSelector(
    (state: RootState) => state.pattern
  );
  const { data: user } = useMeQuery();

  if (!hasAccess(user, "model_knit")) {
    return null;
  }

  if (organizationId === null) {
    return null;
  }

  return (
    <ModalButton
      style={{ marginRight: "8px", height: "32px" }}
      headerText=""
      modalContent={
        <ModelKnitModal
          id={id}
          organizationId={organizationId}
          toggle={toggle}
        />
      }
      isShown={isShown}
      toggle={toggle}
    >
      Modellstrikk
    </ModalButton>
  );
};
