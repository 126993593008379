import React, { CSSProperties } from "react";
import styles from "./Button.module.css";

interface PropType {
  href?: string;
  style?: CSSProperties;
  target?: string;
  onClick?: any;
  onContextMenu?: any;
  disabled?: boolean;
  active?: boolean;
  contrast?: boolean;
  className?: string;
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>["type"];
  title?: string;
  id?: string;
}

const Button: React.FC<PropType> = (props) =>
  props.href ? (
    <a
      id={props.id}
      style={props.style}
      className={`${styles.button} ${styles.a}`}
      href={props.href}
      target={props.target}
      title={props.title}
    >
      {props.children}
    </a>
  ) : (
    <button
      id={props.id}
      title={props.title}
      type={props.type}
      style={props.style}
      disabled={props.disabled}
      onClick={props.onClick}
      onContextMenu={props.onContextMenu}
      className={`${styles.button} ${props.active && styles.positive} ${
        props.contrast && props.active && styles.contrast
      } ${props.className}`}
    >
      {props.children}
    </button>
  );

export default Button;
