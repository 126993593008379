import { useSelector } from "react-redux";
import { Navigate, useParams } from "react-router";
import { GlobalContent } from "../CommonComponents/GlobalContent";
import { Loading } from "../CommonComponents/Loading";
import { RootState } from "../store/store";
import { useMeQuery } from "../store/woolitApi";

function WriterRoute({ children }: any) {
  const { patternId } = useParams();

  let auth = useSelector((state: RootState) => state.loginState);
  const { data: user } = useMeQuery();

  if (!user) {
    if (auth.loginFailed) {
      return <Navigate to={"/landing?s=loginfailed"} />;
    } else if (auth.checkedUserLogin && !auth.loading) {
      return <Navigate to={"/landing"} />;
    } else {
      return <Loading />;
    }
  } else {
    if (!user.subscriber) {
      return <Navigate to={"/landing?s=noaccess"} />;
    } else if (user.subscription.modules.includes("calculation_tool")) {
      return (
        <>
          <GlobalContent />
          {children}
        </>
      );
    } else {
      return <Navigate replace to={`/edit/${patternId}/write`} />;
    }
  }
}

export default WriterRoute;
