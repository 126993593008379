import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { RootState } from "../store/store";
import { RelationIndexEntry } from "../utils/useTableRelationIndex";
import {
  addTableRelation,
  updateCalculations,
} from "../store/calculationSlice";

import NodeRow from "./NodeRow";
import EdgeRow from "./EdgeRow";
import TableRelationModal from "./TableRelationModal";
import TableLabel from "./TableLabel";
import TableHead from "./TableHead";
import NewRowBottom from "./NewRowBottom";

import styles from "./CalculationTable.module.css";
import { useTranslation } from "react-i18next";

interface PropType {
  tableId: string;
  relations: RelationIndexEntry | undefined;
}

function CalculationTable(props: PropType) {
  const { tableId, relations } = props;

  const sizes = useSelector((state: RootState) => state.pattern.meta.sizes);

  const table = useSelector(
    (state: RootState) => state.calculations.tables[tableId]
  );

  const dispatch = useDispatch();

  // Enable automatically opening newly created relations
  const [topIsNew, setTopIsNew] = useState(false);
  const [bottomIsNew, setBottomIsNew] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    // Reset the automatic opening
    if (topIsNew && relations?.before) {
      setTopIsNew(false);
    }
    if (bottomIsNew && relations?.after) {
      setBottomIsNew(false);
    }
  });

  // Autosave full pattern when changing relations or row order
  useEffect(() => {
    dispatch(updateCalculations());
  }, [table.order, relations]);

  return (
    <div className={styles.table}>
      {relations?.before ? (
        <TableRelationModal
          relationId={relations.before}
          tableId={tableId}
          isNew={topIsNew}
        />
      ) : (
        <button
          className={styles.addRelation}
          onClick={() => {
            setTopIsNew(true);
            dispatch(addTableRelation({ firstTop: table.id }));
          }}
        >
          {t("calculations.new link to first")}
        </button>
      )}

      <TableLabel tableId={tableId} />

      <TableHead sizes={sizes} />

      {table.order.map((measureId, measureIndex) => {
        const measure = table.measures[measureId];

        return measure.kind === "node" ? (
          <NodeRow
            key={measureId}
            tableId={tableId}
            measure={measure}
            sizes={sizes}
            measureIndex={measureIndex}
            last={measureIndex === table.order.length - 1}
          />
        ) : (
          <EdgeRow
            key={measureId}
            tableId={tableId}
            measure={measure}
            sizes={sizes}
          />
        );
      })}

      <NewRowBottom tableId={tableId} />

      {relations?.after ? (
        <TableRelationModal
          relationId={relations.after}
          tableId={tableId}
          isNew={bottomIsNew}
        />
      ) : (
        <button
          className={styles.addRelation}
          onClick={() => {
            setBottomIsNew(true);
            dispatch(addTableRelation({ firstBottom: table.id }));
          }}
        >
          {t("calculations.new link to last")}
        </button>
      )}
    </div>
  );
}

export default CalculationTable;
