import React from "react";

import FacebookIconRound from "../../icons/FacebookIconRound";
import { backendApiAddress } from "../../../../backendApi";
import { LocationState } from "../../../../types/location";
import { useLocation } from "react-router";

type Props = {
  target?: React.AnchorHTMLAttributes<HTMLAnchorElement>["target"];
};

const FacebookButton: React.FC<React.PropsWithChildren<Props>> = ({
  target = "_blank",
}) => {
  const state = useLocation().state as LocationState;

  let onboardingState;

  if (state && state.subscribe) {
    if (state.subscribe.onboarding === "checkout") {
      onboardingState = state.subscribe.subscriptionType;
    } else {
      onboardingState = state.subscribe.onboarding;
    }
  }

  const stateQueryParam =
    state && state.subscribe ? `&state=${onboardingState}` : "";

  return (
    <a
      href={`https://www.facebook.com/v17.0/dialog/oauth?client_id=352549798431521&scope=public_profile,email&redirect_uri=${backendApiAddress}/api/login/facebook_return_design${stateQueryParam}`}
      target={target}
      style={{
        display: "flex",
        padding: "1rem",
        color: "#ffffff",
        fontSize: "20px",
        lineHeight: "1",
        whiteSpace: "nowrap",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "0.25rem",
        columnGap: "0.5rem",
        background: "#1877F2",
      }}
    >
      <FacebookIconRound />
      <span>Continue with Facebook</span>
    </a>
  );
};

export default FacebookButton;
