import { ModelKnitGroup, ModelKnitAssignment, Knitter } from "../store/pattern";
import { woolitApi } from "../store/woolitApi";

export type Price = {
  id: number;
  name: string;
  priceOre: number;
};

export type NewPrice = {
  name: string;
  priceOre: number;
};

export type NewKnitter = {
  email: string;
  firstName?: string;
  lastName?: string;
  address?: string;
  postnumber?: string;
  postplace?: string;
  phone?: string;
  tags?: string[];
};

const woolitApiWithModelKnit = woolitApi.injectEndpoints({
  endpoints: (builder) => ({
    modelKnitaAssignments: builder.query<ModelKnitAssignment[], string>({
      query: (patternId) => `/model/pattern/${patternId}`,
      providesTags: ["ModelKnitAssignment"],
    }),
    updateModelKnitAssignments: builder.mutation({
      query: ({ patternId, body }) => ({
        url: `/model/pattern/${patternId}`,
        method: "POST",
        body: JSON.stringify(body),
      }),
      invalidatesTags: ["ModelKnitAssignment"],
    }),
    modelKnitters: builder.query<ModelKnitGroup[], string>({
      query: (orgId) => `/model/knitters/${orgId}`,
      providesTags: ["ModelKnitter"],
    }),
    updateModelKnitGroup: builder.mutation({
      query: ({ orgId, body }) => ({
        url: `/model/knitters/${orgId}`,
        method: "POST",
        body: JSON.stringify(body),
      }),
      invalidatesTags: ["ModelKnitter"],
    }),
    organizationModelKnitAssignments: builder.query<
      ModelKnitAssignment[],
      string
    >({
      query: (orgId) => `/model/organization/${orgId}`,
    }),
    createModelKnitGroup: builder.mutation<
      void,
      { orgId: string; groupName: string }
    >({
      query: ({ orgId, groupName }) => ({
        url: `/model/knitters/${orgId}`,
        method: "POST",
        body: { name: groupName },
      }),
    }),
    findKnitter: builder.mutation<Knitter, { orgId: string; email: string }>({
      query: ({ orgId, email }) => ({
        url: `/model/knitters/${orgId}/find_user`,
        method: "POST",
        body: { email },
      }),
    }),
    addKnitterToOrg: builder.mutation<void, { orgId: string; userId: string }>({
      query: ({ orgId, userId }) => ({
        url: `/model/knitters/${orgId}/add_to_org/${userId}`,
        method: "POST",
      }),
      invalidatesTags: ["ModelKnitter"],
    }),
    addKnitterToGroup: builder.mutation<
      void,
      { orgId: string; userId: number; groupTag: string }
    >({
      query: ({ orgId, userId, groupTag }) => ({
        url: `/model/knitters/${orgId}/add_to_group/${userId}`,
        method: "POST",
        body: { tag: groupTag },
      }),
      invalidatesTags: ["ModelKnitter"],
    }),
    removeKnitterFromGroup: builder.mutation<
      void,
      { orgId: string; userId: number; groupTag: string }
    >({
      query: ({ orgId, userId, groupTag }) => ({
        url: `/model/knitters/${orgId}/remove_from_group/${userId}`,
        method: "POST",
        body: { tag: groupTag },
      }),
      invalidatesTags: ["ModelKnitter"],
    }),
    addNewKnitterToOrg: builder.mutation<
      void,
      { orgId: string; newKnitter: NewKnitter }
    >({
      query: ({ orgId, newKnitter }) => ({
        url: `/model/knitters/${orgId}/new_user`,
        method: "POST",
        body: newKnitter,
      }),
      invalidatesTags: ["ModelKnitter"],
    }),
    // Price
    priceList: builder.query<Price[], string>({
      query: (orgId) => `/model/price/${orgId}/all`,
      providesTags: ["Price"],
    }),
    newPrice: builder.mutation<any, { orgId: string } & NewPrice>({
      query: ({ orgId, name, priceOre }) => ({
        url: `/model/price/${orgId}`,
        method: "POST",
        body: { name, priceOre },
      }),
      invalidatesTags: ["Price"],
    }),
    updatePrice: builder.mutation<void, { orgId: string } & Price>({
      query: ({ orgId, id, name, priceOre }) => ({
        url: `/model/price/${orgId}/${id}`,
        method: "PUT",
        body: { name, priceOre },
      }),
      invalidatesTags: ["Price"],
    }),
    deletePrice: builder.mutation<void, { orgId: string; priceId: number }>({
      query: ({ orgId, priceId }) => ({
        url: `/model/price/${orgId}/${priceId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Price"],
    }),
  }),
});

export const {
  useModelKnitaAssignmentsQuery,
  useUpdateModelKnitAssignmentsMutation,
  useModelKnittersQuery,
  useOrganizationModelKnitAssignmentsQuery,
  useCreateModelKnitGroupMutation,
  usePriceListQuery,
  useNewPriceMutation,
  useUpdatePriceMutation,
  useDeletePriceMutation,
  useFindKnitterMutation,
  useAddKnitterToOrgMutation,
  useAddKnitterToGroupMutation,
  useRemoveKnitterFromGroupMutation,
  useAddNewKnitterToOrgMutation,
} = woolitApiWithModelKnit;
