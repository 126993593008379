import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { RootState } from "./store/store";
import { fetchPatternById } from "./store/patternSlice";

import styles from "./ISO.module.css";

import MetaSection from "./ISO/MetaSection";
import Hero from "./ISO/Hero";
import Header from "./ISO/Header";
import PatternElementList from "./ISO/PatternElementList";
import Bottom from "./CommonComponents/Bottom";

function ISO() {
  const { patternId } = useParams();

  const pattern = useSelector((state: RootState) => state.pattern);
  const dispatch = useDispatch();

  const loadDataCallback = useCallback(
    () =>
      patternId && pattern.id.toString() !== patternId
        ? dispatch(fetchPatternById(patternId))
        : null,
    [dispatch, patternId, pattern.id]
  );

  const variant = useSelector((state: RootState) =>
    pattern.meta.variants.find(
      (item) => item.id === state.displayState.activeVariant
    )
  );

  useEffect(() => {
    loadDataCallback();
  }, [loadDataCallback]);

  if (!patternId) {
    return null;
  }

  return (
    <>
      <Header id={patternId} />
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <div className={styles.metaWrapper}>
            {variant?.images && variant.images.length > 0 && (
              <Hero images={variant?.images} variant={variant} />
            )}
            <MetaSection data={pattern.meta} />
          </div>
          <div className={styles.mainWrapper}>
            <PatternElementList patternElements={pattern.patternElements} />
          </div>
        </div>
      </div>
      <Bottom pattern={pattern} />
    </>
  );
}

export default ISO;
